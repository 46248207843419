import React, { Component } from "react";
import { connect } from "react-redux";
import BackButtonWithNotification from "../../Elements/backButtonWithNotification";
import { FiEdit } from "react-icons/fi";
import { GrNext } from "react-icons/gr";
import sideman from "../../../assets/images/man standing with bicycle.png";
import { BottomSheet } from "react-spring-bottom-sheet";
import { AiFillCheckSquare, AiFillHome } from "react-icons/ai";
import { BsCheck2Circle, BsFillCheckCircleFill } from "react-icons/bs";
import { FaCircle, FaWeight } from "react-icons/fa";
import { RiEdit2Line } from "react-icons/ri";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import ContentLoader from 'react-content-loader'
import { MdOutlineAddCircle } from "react-icons/md";
import { IoSquareOutline } from "react-icons/io5";
import { getAllCategory } from '../../../redux/mobile/item/action'
// import { addItems, addProduct } from '../../../redux/mobile/store/action'
import { Link } from "react-router-dom";
import { courierCheckOut } from "../../../redux/mobile/mobileOrder/action"

export class PickUpAndDrop extends Component {
    state = {
        category: [],
        selectedItem: '',
        packagemenu: false,
        address: false,
        delivery: false,
        instruction: false,
        loading: false,
        delivery_address: [],
        pickup_address: [],
        nostore: false,
        nodelivery: false,
        instructions: "",
    };
    CheckMenu = () => {
        this.setState({ packagemenu: !this.state.packagemenu });
    };
    PickupAddress = () => {
        this.setState({ address: !this.state.address });
    };
    DeliveryAddress = () => {
        this.setState({ delivery: !this.state.delivery });
    };
    Instructions = (e) => {
        if (e === "clear") {
            this.setState({ instructions: null })
            localStorage.removeItem("instructions");
        } else {
            this.setState({ instruction: !this.state.instruction });
        }
    };

    componentDidMount() {
        this.setState({ loading: true });
        this.props.getAllCategory().then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({
                    category: response.payload.catogory,
                    loading: false,
                });
            } else {
                ////// console.log("API error");
            }
        });
        let deliveryAddress = JSON.parse(localStorage.getItem("deliveryAddress"));
        this.setState({ delivery_address: deliveryAddress });
        let pickAddress = JSON.parse(localStorage.getItem("pickUpAddress"));
        this.setState({ pickup_address: pickAddress });
        let instructions = localStorage.getItem("instructions");
        this.setState({ instructions: instructions });
        let packageItems = JSON.parse(localStorage.getItem("packageItems"));
        this.setState({ selectedItem: packageItems });
    }

    selectedItem = (item, index) => {
        this.setState({ selectedItem: item });
        localStorage.setItem("packageItems", JSON.stringify(item));
    }
    grabDeliveryLocation = () => {
        localStorage.setItem("LocationFrom", "COURIER");
        localStorage.setItem("BackTo", "DELIVERY");
        this.props.history.push('/set-location')
    }
    grabPickupLocation = () => {
        localStorage.setItem("LocationFrom", "COURIER");
        localStorage.setItem("BackTo", "PICKUP");
        this.props.history.push('/set-location')
    }
    NotSelected = (type) => {
        if (type === 'pickupAddress') {
            this.setState({ nostore: true })
        }
        else if (type === 'delivery_address') {
            this.setState({ nodelivery: true })
        }
    }
    handleInstruction = (e) => {
        e.preventDefault();
        let value = this.state.instructions;
        if (value) {
            localStorage.setItem("instructions", value);
        } else {
            localStorage.removeItem("instructions");
        }
        this.setState({ instruction: false });
    }

    CheckOut = (e) => {
        const { user } = this.props;
        e.preventDefault();
        const { selectedItem, delivery_address, pickup_address, instructions } = this.state;
        this.setState({ loading: true });
        this.props.courierCheckOut(user.data.auth_token, pickup_address, delivery_address, selectedItem, instructions).then((response) => {
            if (response && response.payload && response.payload.success) {
                localStorage.removeItem("deliveryAddress");
                localStorage.removeItem("pickUpAddress");
                localStorage.removeItem("instructions");
                localStorage.removeItem("packageItems");
                this.setState({ loading: false });
            }
        })
    }
    render() {
        const { user } = this.props;
        const { category, selectedItem, delivery_address, pickup_address, nostore, nodelivery, instructions } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>
                            <rect x="12" y="20" rx="10" ry="10" width="32" height="27" />
                            <rect x="85" y="25" rx="10" ry="10" width="100" height="20" />
                            <rect x="230" y="20" rx="10" ry="10" width="32" height="27" />
                            <rect x="10" y="79" rx="10" ry="10" width="255" height="80" />
                            <rect x="10" y="175" rx="10" ry="10" width="259" height="160" />
                            <rect x="10" y="360" rx="10" ry="10" width="171" height="28" />
                            <rect x="175" y="452" rx="11" ry="11" width="75" height="80" />
                            <rect x="10" y="530" rx="11" ry="11" width="259" height="33" />
                        </ContentLoader>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <BackButtonWithNotification title="PickUpAndDrop" />
                        <div className="px-4" style={{ paddingTop: "25vw" }}>
                            <div>
                                <div className="pickup-div d-flex flex-column px-3 py-3"
                                    style={{ backgroundColor: "#FFF9ED", borderRadius: "14px", }} >
                                    <div className="d-flex flex-row align-items-center">
                                        <FaWeight style={{ color: "#F9A84D", fontSize: "1.1rem", }} />
                                        <span className="px-1" style={{ fontSize: "1.2em", fontWeight: "700", color: "#FF9012", }} >
                                            Watch the weight
                                        </span>
                                    </div>
                                    <span className="mt-2" style={{ fontSize: "1em" }} >
                                        Please ensure that the package can be carried on a bike
                                    </span>
                                </div>
                                <div className="d-flex flex-column" style={{ backgroundColor: "#ffffff", }} >
                                    <div className="position-relative py-1" style={{ height: "290px", borderRadius: "40px", }}  >
                                        <div className="d-flex flex-column py-3">
                                            <div className="d-flex flex-row  mb-4" style={{ paddingLeft: "10px" }} >
                                                <div style={{ zIndex: "2" }}>
                                                    <div className="p-1" style={{ zIndex: "2", color: "#8399A9", backgroundColor: "#fff", borderRadius: "50%", }} >
                                                        {pickup_address && pickup_address.address ? (
                                                            <BsFillCheckCircleFill style={{ color: "#ff9012", fontSize: "1.2rem", }} />
                                                        ) : (
                                                            <FaCircle style={{ color: "#DADADA", fontSize: "1.2rem", }} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2" onClick={() => this.PickupAddress()}>
                                                    <div className="d-flex flex-column py-1">
                                                        <div className=" fw-bolder text-muted fs-6">
                                                            Pickup address
                                                        </div>
                                                        <div className="text-muted pe-2">
                                                            {pickup_address && pickup_address.address ? (
                                                                pickup_address.house ? pickup_address.house + ", " + pickup_address.address : pickup_address.address
                                                            ) : (
                                                                'Select pickup location '
                                                            )}
                                                        </div>
                                                        {nostore &&
                                                            <span style={{ color: 'red' }}> Need to select Pickup Address</span>
                                                        }
                                                    </div>
                                                    <div>
                                                        {pickup_address && pickup_address.address ? (
                                                            <FiEdit size={'1.4em'} />
                                                        ) : (
                                                            <GrNext />
                                                        )}

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row mb-4" style={{ paddingLeft: "10px" }} >
                                                <div style={{ zIndex: "2" }}>
                                                    <div className="p-1" style={{ zIndex: "2", color: "#8399A9", backgroundColor: "#fff", borderRadius: "50%", }} >
                                                        {delivery_address && delivery_address.address ? (
                                                            <BsFillCheckCircleFill style={{ color: "#ff9012", fontSize: "1.2rem", }} />
                                                        ) : (
                                                            <FaCircle style={{ color: "#DADADA", fontSize: "1.2rem", }} />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2" onClick={pickup_address ? () => this.DeliveryAddress() : () => this.NotSelected('pickupAddress')}>
                                                    <div className="d-flex flex-column py-1">
                                                        <div className=" fw-bolder text-muted fs-6">
                                                            Delivery address
                                                        </div>
                                                        <div className="text-muted pe-2">
                                                            {delivery_address && delivery_address.address ? (
                                                                delivery_address.house ? delivery_address.house + ", " + delivery_address.address : delivery_address.address
                                                            ) : (
                                                                'Select delivery location '
                                                            )}
                                                        </div>
                                                        {nodelivery &&
                                                            <span style={{ color: 'red' }}> Need to select Delivery Address</span>
                                                        }
                                                    </div>
                                                    <div>
                                                        {delivery_address && delivery_address.address ? (
                                                            <FiEdit size={'1.4em'} />
                                                        ) : (
                                                            <GrNext />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex flex-row mb-4" style={{ paddingLeft: "10px" }} >
                                                <div className="position-relative" style={{ zIndex: "2" }}>
                                                    <div className="p-1" style={{ zIndex: "2", color: "#8399A9", backgroundColor: "#fff", borderRadius: "50%", }}  >
                                                        {selectedItem ? (
                                                            <BsFillCheckCircleFill style={{ color: "#ff9012", fontSize: "1.2rem", }} />
                                                        ) : (
                                                            <FaCircle style={{ color: "#DADADA", fontSize: "1.2rem", }} />
                                                        )}
                                                    </div>
                                                    <div className="position-absolute px-2">
                                                        <div style={{ borderLeft: "10px solid #fff", height: "150px", zIndex: "1", }} >

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="flex-grow-1 d-flex flex-row justify-content-between align-items-center ps-2" onClick={pickup_address ? delivery_address ? () => this.CheckMenu() : () => this.NotSelected('delivery_address') : () => this.NotSelected('pickupAddress')}>
                                                    <div className="d-flex flex-column py-1">
                                                        <div className=" fw-bolder text-muted fs-6">
                                                            Select package content
                                                        </div>
                                                        <div className="text-muted">
                                                            {selectedItem ? (
                                                                <span>{selectedItem.name}</span>
                                                            ) : (
                                                                <span>Example : food ,content.</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        {selectedItem && selectedItem.length > 0 ? (
                                                            <FiEdit size={'1.4em'} />
                                                        ) : (
                                                            <GrNext />
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex px-2 py-1 mt-3" style={{ zIndex: "2", }} >
                                                <div className="d-flex flex-row align-items-center px-2 py-1" style={{ backgroundColor: '#F3F3F3', borderRadius: "14px", }} onClick={() => this.Instructions()} >
                                                    <RiEdit2Line style={{ fontSize: "1.5rem", color: "#F9A84D", zIndex: "2" }} />
                                                    <div className="instruction ms-2" style={{ fontSize: "15px", color: "black", zIndex: "2" }} >
                                                        Any instructions?
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="position-absolute px-4">
                                                <div style={{ borderLeft: "1px dashed #000", height: "300px", zIndex: "-999", }} >
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="pickup-btn px-3 py-3 position-fixed bottom-0 start-50 translate-middle-x  w-100">
                                    <div className="position-relative">
                                        <div className="position-absolute" style={{ bottom: "-18px", right: '-15px' }} >
                                            <img src={sideman} className="sideman" alt="sideman" />
                                        </div>
                                    </div>
                                    {user && user.success ? (
                                        <button type="button" className="pickup-button btn btn py-2 w-100" onClick={(e) => this.CheckOut(e)} >
                                            <b style={{ color: "white" }}>Checkout</b>
                                        </button>
                                    ) : (
                                        <Link to="/signup" className='text-dark'>
                                            <button type="button" className="pickup-button btn btn py-2 w-100">
                                                <b style={{ color: "white" }}>Checkout</b>
                                            </button>
                                        </Link>
                                    )}
                                </div>
                            </div>
                        </div>
                        <BottomSheet
                            open={this.state.packagemenu}
                            onDismiss={() => this.CheckMenu()} >
                            <div className="text-center mt-2 ">
                                <span style={{ fontSize: "19px", color: "#FF9012", }} >
                                    <b>select package content</b>
                                </span>
                            </div>
                            <div className="mt-3">
                                {category && category.length > 0 &&
                                    category.map((item, index) => (
                                        <div className="px-3 pb-4 w-100">
                                            <span className="form-check"
                                                onClick={(e) => { this.selectedItem(item, index); }} >
                                                {selectedItem && (item.id === selectedItem.id) ? (
                                                    <AiFillCheckSquare className="me-2" style={{ color: "orange", fontSize: "1.5rem", }} />
                                                ) : (
                                                    <IoSquareOutline className="me-2" style={{ fontSize: "1.5rem", color: "#c3c3c3", }} />
                                                )}
                                                <span style={{ color: "black" }} >
                                                    {item.name}
                                                </span>
                                            </span>
                                        </div>
                                    ))}
                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-between gap-2 py-3 px-3"
                                style={{ backgrounColor: "red", border: "1px 0px 0px 0px solid #aaa", }} >
                                <button onClick={() => { this.setState({ selectedItem: [] }) }} className="anystr-cancel-button py-3 w-100">
                                    <b style={{ color: "#FF9012" }}>Cancel</b>
                                </button>
                                <button onClick={() => this.CheckMenu()} className="anystr-done-button py-3 w-100">
                                    <b style={{ color: "white" }} >Done</b>
                                </button>
                            </div>
                        </BottomSheet>

                        <BottomSheet
                            open={this.state.address}
                            onDismiss={() => this.PickupAddress()}  >
                            <div className="px-3">
                                <div className=" mt-2 ">
                                    <span style={{ fontSize: "16px", fontWeight: "700" }}>
                                        Choose PickUp Address
                                    </span>
                                </div>

                                {pickup_address && pickup_address.address ? (
                                    <>
                                        <div className="d-flex flex-row align-items-center py-3 px-3 mt-3">
                                            <div onClick={this.grabPickupLocation}>
                                                <MdOutlineAddCircle style={{ color: "#F9A84D", backgroundColor: "white", fontSize: "1.5rem", }} />
                                                <span className="px-3" style={{ fontSize: "14px", fontWeight: "500", color: "#F9A84D", }} >
                                                    Change PickUp Address
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-row align-items-center justify-content-between py-2 px-3 " onClick={this.setPickupAddress}>
                                            <div>
                                                <AiFillHome style={{ fontSize: "1.5rem" }} />
                                            </div>
                                            <div className="px-2" style={{ fontSize: "14px", fontWeight: "500" }}>
                                                {pickup_address.house ? pickup_address.house + ", " + pickup_address.address : pickup_address.address}
                                            </div>
                                            <div>
                                                {pickup_address && pickup_address.address ? (
                                                    <BsCheck2Circle style={{ color: "DA6317", fontSize: "1.5rem" }} />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex flex-row align-items-center  py-3 px-4 mt-3">
                                        <div onClick={this.grabPickupLocation}>
                                            <MdOutlineAddCircle style={{ color: "#F9A84D", backgroundColor: "white", fontSize: "1.5rem", }} />
                                            <span className="px-3" style={{ fontSize: "14px", fontWeight: "500", color: "#F9A84D", }} >
                                                Add PickUp Address
                                            </span>
                                        </div>
                                    </div>
                                )}

                                <hr />
                                <div className="px-3  pb-4 w-100">
                                    <button className="select-button btn btn py-2 w-100" type="button" onClick={() => this.PickupAddress()} >
                                        <b style={{ color: "white" }}>Close</b>
                                    </button>
                                </div>
                            </div>
                        </BottomSheet>

                        <BottomSheet
                            open={this.state.delivery}
                            onDismiss={() => this.DeliveryAddress()} >
                            <div className="px-3">
                                <div className=" mt-2">
                                    <span style={{ fontSize: "1.5em", fontWeight: "700" }} >
                                        Delivery Address
                                    </span>
                                </div>

                                {delivery_address && delivery_address.address ? (
                                    <>
                                        <div className="d-flex flex-row align-items-center  py-2 px-4 mt-3">
                                            <div onClick={this.grabDeliveryLocation}>
                                                <MdOutlineAddCircle style={{ color: "#F9A84D", backgroundColor: "", fontSize: "1.5rem", }} />
                                                <span className="px-3" style={{ fontSize: "14px", fontWeight: "500", color: "#F9A84D", }}  >
                                                    Change Delivery Address
                                                </span>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="d-flex flex-row align-items-center justify-content-between py-2 px-4 ">
                                            <div>
                                                <AiFillHome style={{ fontSize: "1.5rem" }} />
                                            </div>
                                            <div className="px-3" style={{ fontSize: "14px", fontWeight: "500", }}>
                                                {delivery_address.house ? delivery_address.house + ", " + delivery_address.address : delivery_address.address}
                                            </div>

                                            <div>
                                                {delivery_address && delivery_address.address ? (
                                                    <BsCheck2Circle style={{ color: "DA6317", fontSize: "1.5rem" }} />
                                                ) : (
                                                    ''
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="d-flex flex-row align-items-center  py-2 px-4 mt-3">
                                        <div onClick={this.grabDeliveryLocation}>
                                            <MdOutlineAddCircle style={{ color: "#F9A84D", backgroundColor: "", fontSize: "1.5rem", }} />
                                            <span className="px-3" style={{ fontSize: "14px", fontWeight: "500", color: "#F9A84D", }}  >
                                                Add Delivery Address
                                            </span>
                                        </div>
                                    </div>
                                )}
                                <hr />
                                <div className=" px-3  pb-4 w-100">
                                    <button type="button" className="select-button btn btn py-2 w-100" onClick={() => this.DeliveryAddress()} >
                                        <b style={{ color: "white" }}>Close</b>
                                    </button>
                                </div>
                            </div>
                        </BottomSheet>

                        <BottomSheet
                            open={this.state.instruction}
                            onDismiss={() => this.Instructions()} >
                            <div className="px-3 py-3">
                                <Form onSubmit={(e) => this.handleInstruction(e)}>
                                    <InputGroup className="mt-3 coupon-input" style={{ boxSizing: "border-box" }} >
                                        <FormControl as="textarea" rows={5} value={instructions ? instructions : ''} placeholder="Any instructions" className="input-coupon"
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.setState({ instructions: value })
                                            }} />
                                    </InputGroup>
                                    <div className="d-flex flex-row align-items-center justify-content-between gap-2 py-3 px-3"
                                        style={{ backgrounColor: "red", border: "1px 0px 0px 0px solid #aaa", }} >
                                        {instructions && instructions.length > 0 ?
                                            <div onClick={() => this.Instructions('clear')} className="anystr-cancel-button text-center cour-btn w-100">

                                                <b style={{ color: "#FF9012" }}>Clear</b>
                                            </div>
                                            :
                                            <button onClick={() => this.Instructions('cancel')} className="anystr-cancel-button cour-btn w-100">

                                                <b style={{ color: "#FF9012" }}>Cancel</b>
                                            </button>
                                        }
                                        <button type="submit" className="anystr-done-button cour-btn w-100">
                                            <b style={{ color: "white" }} >Submit</b>
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </BottomSheet>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default connect(mapStateToProps, {
    getAllCategory,
    courierCheckOut
})(PickUpAndDrop);

