import {
    SEND_OTP,
    LOGIN_OTP_USER,
    REGISTER_USER,
    GET_USER_DETAILS,
    GET_DEFAULT_ADDRESS,
    GET_USER_ADDRESS,
    GET_USER_WALLET,
    UPDATE_USER_REDUX_INFO,
    DELETE_USER,
    LOGOUT_USER,
} from "./actionType";

const initialState = {
    user: [],
    userDetails: [],
    userAddress: [],
    allUserAddress: [],
    userWallet: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case UPDATE_USER_REDUX_INFO:
            return { ...state, user: action.payload };
        case SEND_OTP:
            return { ...state, user: action.payload };
        case LOGIN_OTP_USER:
            return { ...state, user: action.payload };
        case REGISTER_USER:
            return { ...state, user: action.payload };
        case DELETE_USER:
            return { ...state, user: action.payload };
        case GET_USER_DETAILS:
            return { ...state, userDetails: action.payload };
        case GET_DEFAULT_ADDRESS:
            return { ...state, userAddress: action.payload };
        case GET_USER_ADDRESS:
            return { ...state, allUserAddress: action.payload };
        case GET_USER_WALLET:
            return { ...state, userWallet: action.payload };
        case LOGOUT_USER:
            return { ...state, user: action.payload };
        default:
            return state;
    }
}
