import React, { Component } from "react";
import { connect } from "react-redux";
import successtick from "../../../assets/images/successtick.png";
import cancel from "../../../assets/images/cancel.png";
import ContentLoader from "react-content-loader";
import { orderStatus } from "../../../redux/mobile/checkOut/action";
import {Link} from "react-router-dom"
class OrderSuccess extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
    };
    componentDidMount() {
        const { user } = this.props;
        ////// console.log(this.state.id);
        this.setState({ loading: true });
        this.props.orderStatus(user.data.auth_token, this.props.match.params.id).then((response) => {

            //// console.log('====================================');
            //// console.log(response,'response');
            //// console.log('====================================');
            this.setState({ loading: false });
            setTimeout(() => {
                ////// console.log(this.props.match.params.id,'dddddddddddd')
                window.location.replace('/running-orders/normal')
            }, 3000);
        })

    }
    render() {
        const { status } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <circle cx="140" cy="230" r="50" />
                            <rect
                                x="90"
                                y="290"
                                rx=""
                                ry=""
                                width="100"
                                height="18"
                            />
                            <rect
                                x="22"
                                y="320"
                                rx=""
                                ry=""
                                width="230"
                                height="18"
                            />
                            <rect
                                x="90"
                                y="350"
                                rx="10"
                                ry="10"
                                width="100"
                                height="18"
                            />
                            <rect
                                x="13"
                                y="510"
                                rx="10"
                                ry="10"
                                width="250"
                                height="30"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div>
                            <div className="mobile-subs-success-bg"></div>
                            <div className="d-flex justify-content-center mobile-subs-success-img">
                                {status?.data?.order_status_id == 1 ? (
                                    <img src={successtick} alt="Success" />
                                ) : status?.data?.order_status_id == 8 ? (
                                    <img src={cancel} alt="Success" />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="d-flex justify-content-center mobile-subs-success-head">
                                {status?.data?.order_status_id == 1 ? (
                                    <span>Congrats!</span>
                                ) : status?.data?.order_status_id == 8 ? (
                                    <span>Sorry</span>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="mobile-subs-success-text px-4">
                                <div>
                                    {status?.data?.order_status_id == 1 ? (
                                        <span>
                                            Your Order Placed Successfully
                                        </span>
                                    ) : status?.data?.order_status_id == 8 ? (
                                        <span>Transaction Failed</span>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>


                            {/* <div className="d-flex justify-content-center mobile-order-success-button">
                                <button className="py-1 px-2 mobile-order-success-btn">
                                    Scratch Card Earned
                                </button>
                            </div> */}
                            {/* <Link to="/home">
                                <div className="d-grid gap-2 px-3 position-fixed w-100 mobile-subs-success-btn">
                                    <button className="p-3 mobile-subs-success-button">
                                        Back to Home
                                    </button>
                                </div>
                            </Link> */}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    status: state.checkout.orderStatus,
});

export default connect(mapStateToProps, { orderStatus })(OrderSuccess);
