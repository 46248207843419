import React, { Component } from 'react'
import { HiOutlineChevronLeft } from 'react-icons/hi'
import { connect } from 'react-redux'

export class BackButtonOnly extends Component {
    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <React.Fragment>
                <div className="d-flex align-items-center position-fixed px-4 w-100 py-4" style={{ top: '0px', zIndex: '99999999' }}>
                    <div className="px-2 pb-1 back-button" onClick={() => this.onHandleBack()}>
                        <span className="fs-2 back-button-icon">
                            <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                        </span>
                    </div>

                    <div className=' mt-3 mobile-back-button-title fs-5'>
                        <span className='mt-3'>{this.props.title}</span>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(BackButtonOnly)
