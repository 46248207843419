import {
    GET_ALL_MEDICINE_DATA,
    GET_ALL_TOP_DEALS,
    GET_MOST_ORDERS,
    GET_ALL_RECOMMENDED_MEDICINE,
    GET_MEDICINE_NEAR_STORES,
    GET_FILTER_STORES,
    GET_ALL_MEDICAL_STORES,
    ORDER_WITH_PRESCRIPTION,
    PRESCRIPTION_IMAGE,
    PRESCRIPTION_DELIVERY_CHARGE
} from "./actionType";

import {
    GET_ALL_MEDICINE_DATA_URL,
    GET_ALL_TOP_DEALS_URL,
    GET_MOST_ORDERS_URL,
    GET_ALL_RECOMMENDED_MEDICINE_URL,
    GET_MEDICINE_NEAR_STORES_URL,
    GET_FILTER_STORES_URL,
    ADD_PRESCRIPTION_IMAGE_URL,
    REMOVE_PRESCRIPTION_IMAGE_URL,
    GET_ALL_MEDICAL_STORES_URL,
    ORDER_WITH_PRESCRIPTION_URL,
    GET_ALL_PRESCRIPTION_IMAGES_URL,
    GET_PRESCRIPTION_DELIVERY_CHARGE_URL
} from "../../../api";

import Axios from "axios";
// import { PRESCRIPTION_IMAGE } from "../cart/actionType";
// import { updateCart } from "../../vendor/order/action";
import { APPLY_COUPON } from "../mobileCoupon/actionTypes";


export const getAllMedicineData = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_ALL_MEDICINE_DATA_URL, {
        latitude, longitude
    })
        .then((response) => {
            let medicine = response.data;
            return dispatch({
                type: GET_ALL_MEDICINE_DATA,
                payload: medicine
            })

        })
}
export const getMedicineNearStores = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_MEDICINE_NEAR_STORES_URL, {
        latitude, longitude
    })
        .then((response) => {
            let nearmedicine = response.data;
            return dispatch({
                type: GET_MEDICINE_NEAR_STORES,
                payload: nearmedicine
            })

        })
}

export const getAllTopDeals = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_ALL_TOP_DEALS_URL, {
        latitude, longitude
    })
        .then((response) => {
            let medicine = response.data;
            return dispatch({
                type: GET_ALL_TOP_DEALS,
                payload: medicine
            })

        })
}
export const getMostOrders = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_MOST_ORDERS_URL, {
        latitude, longitude
    })
        .then((response) => {
            let mostorders = response.data;
            return dispatch({
                type: GET_MOST_ORDERS,
                payload: mostorders
            })

        })
}

export const getAllRecommendedMedicines = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_ALL_RECOMMENDED_MEDICINE_URL, {
        latitude, longitude
    })
        .then((response) => {
            let recommendedMedicines = response.data;
            return dispatch({
                type: GET_ALL_RECOMMENDED_MEDICINE,
                payload: recommendedMedicines
            })

        })
}

export const getAllStores = (latitude, longitude) => (dispatch) => {
    return Axios.post(GET_ALL_MEDICAL_STORES_URL, {
        latitude, longitude
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: GET_ALL_MEDICAL_STORES, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const orderWithPrescription = (token, store_id, address, image) => (dispatch, getState) => {
    return Axios.post(ORDER_WITH_PRESCRIPTION_URL, {
        token, store_id, address, image
    })
        .then((response) => {
            const checkout = response.data;
            return dispatch({ type: ORDER_WITH_PRESCRIPTION, payload: checkout });
        })
        .catch(function (error) {
            return error.response;
        });

};
export const clearPrescriptionImage = () => (dispatch) => {
    let data = [];
    return dispatch({ type: PRESCRIPTION_IMAGE, payload: data });
};

export const getAllAddedImages = (token) => (dispatch) => {
    return Axios.post(GET_ALL_PRESCRIPTION_IMAGES_URL, { token })
        .then((response) => {
            const image = response.data;
            return dispatch({ type: PRESCRIPTION_IMAGE, payload: image });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const addPrescription = (formData) => (dispatch) => {
    return Axios.post(ADD_PRESCRIPTION_IMAGE_URL, formData)
        .then((response) => {
            const image = response.data;
            return dispatch({ type: PRESCRIPTION_IMAGE, payload: image });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const prescriptionDelete = (token, id) => (dispatch) => {
    return Axios.post(REMOVE_PRESCRIPTION_IMAGE_URL, { token, id })
        .then((response) => {
            const image = response.data;
            return dispatch({ type: PRESCRIPTION_IMAGE, payload: image });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const prescriptionDeliveryCharge = (token, id, latitude, longitude) => (dispatch) => {
    return Axios.post(GET_PRESCRIPTION_DELIVERY_CHARGE_URL, { token, id, latitude, longitude })
        .then((response) => {
            const charge = response.data;
            return dispatch({ type: PRESCRIPTION_DELIVERY_CHARGE, payload: charge });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
