import React, { Component } from "react";
import { connect } from "react-redux";
import Ink from "react-ink";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";

import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export class FoodBanner extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentSlide: 0,
            totalSlides: 0
        };
    }

    componentDidMount() {
        this.setState({ totalSlides: 2 });
    }

    handleAfterChange = (current) => {
        this.setState({ currentSlide: current });
    };

    render() {
        const { banners } = this.props;

        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            arrows: false,
            speed: 400,
            autoplaySpeed: 5000,
            cssEase: "linear",
            afterChange: this.handleAfterChange

            // customPaging: function (i) {
            //   return <div className="dot"></div>;
            // },
            // dotsClass: "slick-dots slick-thumb"
        };

        return (
            <React.Fragment>
                {banners && banners.data && banners.data.length > 0 &&
                    <div className="px-3 py-3">

                        <Slider {...settings} >
                            {banners?.data?.map((banner) => (
                                <Link to={'/top-offer/banner/' + banner.id} key={banner.id} onClick={() => localStorage.setItem('scrollPosition', window.scrollY)}>
                                    <div className="d-flex flex-column justify-content-center align-items-center text-center position-relative b-r-40">
                                        <Ink className="touch-ink " />
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + banner?.image}
                                            placeholder={
                                                PLACE_HOLDER_IMAGE
                                            }
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        width: "90vw",
                                                        height: "35vw",
                                                        borderRadius: "20px",
                                                        objectFit: "cover",
                                                        backgroundRepeat: "round",
                                                    }}
                                                    className=""
                                                    alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                </Link>
                            ))}
                        </Slider>
                        <div style={{ paddingLeft: '42vw' }}>
                            <div style={{ backgroundColor: 'rgb(255, 154, 98)', fontWeight: 'bold', width: '10vw', borderRadius: 20, textAlign: 'center' }}>
                                {this.state.currentSlide + 1}/{this.state.totalSlides}
                            </div>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    banners: state.home.banners,
});

export default connect(mapStateToProps, {})(FoodBanner);
