import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Redirect } from "react-router";
import img from '../../../assets/images/grosav-logo.png';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import Axios from "axios";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { Link } from 'react-router-dom';
import { loginStoreUser, sendOtp, verifyOtp, viewAllCities, viewAllStoreCategories } from '../../../redux/vendor/user/action'
import { WEBSITE_URL } from '../../../api/index'
import OtpInput from 'react-otp-input';
// import Ink from 'react-ink'
import HeadShake from "react-reveal/HeadShake";
import { BsInfoCircle } from 'react-icons/bs';


export class VendorLogin extends Component {
    state = {
        all_cities: [],
        all_categories: [],
        login: true,
        otp_send: true,
        otp_enter: false,
        register: false,
        registered: false,
        phone: '',
        email: '',
        password: '',
        error: false,
        store_owner_name: '',
        store_owner_email: '',
        store_owner_password: '',
        store_owner_contact: '',
        store_name: '',
        store_contact: '',
        address: '',
        city_id: '',
        store_type_id: '',
        license_number: '',
        license_document: null,
        vat_document: null,
    };
    componentDidMount() {
        this.props.viewAllCities().then((response) => {
            this.setState({ all_cities: response.data.cities });
        });
    }
    register = e => {
        this.setState({ login: false, register: true });

    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleInput = (e) => {
        const { name, value, files } = e.target;
        this.setState({ [name]: files ? files[0] : value });
    }

    handleLogin = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        this.props.loginStoreUser(this.state.email, this.state.password).then((response) => {
            if (response && response.payload && response.payload.success &&
                response.payload.data && response.payload.data == "NEWUSER") {
                this.setState({ loading: false, oldAcc: false, otp_enter: false, register: true, });
            } else if (response && response.payload && response.payload.success && !this.state.register) {
                window.location.replace("/vendor-order");
            } else if (response && response.payload.data === "DO NOT MATCH") {
                this.setState({ loading: false, invalid_otp: true });
            }
        });
    };

    componentWillReceiveProps(nextProps) {
        const { store_user } = this.props;
        if (store_user !== nextProps.store_user) {
            this.setState({ loading: false });
            if (nextProps.store_user.success === false) {
                this.setState({ error: true });
            }
        }
        // if (nextProps.store_user.success) {
        // this.context.router.push("/delivery");
        // }
    }

    __sendOtp = (e) => {
        e.preventDefault();
        // this.setState({ loading: true });
        this.props.sendOtp(this.state.phone).then((response) => {
            //// console.log(response, 'response')
            if (response && response.payload && response.payload.success && response.payload.message == "OLDUSER") {
                this.setState({
                    loading: false,
                    otp_send: false,
                    otp_enter: true,
                    login: false,
                    register: false,
                    registered: false,
                });
            } else if (response && response.payload && response.payload.success && response.payload.data == "OTPSEND") {
                this.setState({ loading: false, otp_send: false, otp_enter: true, login: false, register: false, registered: false, });
            } else {
                this.setState({ loading: true });
            }
        });
    };

    __verifyOtp = () => {
        this.setState({ loading: true });
        this.props.verifyOtp(this.state.phone, this.state.Otp).then((response) => {
            if (response && response.payload && response.payload.success &&
                response.payload.data && response.payload.data == "NEWUSER") {
                this.setState({ loading: false, oldAcc: false, otp_enter: false, register: true, });
            } else if (response && response.payload && response.payload.success && !this.state.register) {
                window.location.replace("/vendor-order");
            } else if (response && response.payload.data === "DO NOT MATCH") {
                this.setState({ loading: false, invalid_otp: true });
            }
        });
    };

    __handleInputOtp = (Otp) => {
        this.setState({ Otp });
    };

    handleRegister = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        const formData = new FormData();
        formData.append("store_owner_name", this.state.store_owner_name);
        formData.append("store_owner_email", this.state.store_owner_email);
        formData.append("store_owner_password", this.state.store_owner_password);
        formData.append("store_owner_contact", this.state.store_owner_contact);
        formData.append("store_name", this.state.store_name);
        formData.append("store_contact", this.state.store_contact);
        formData.append("address", this.state.address);
        formData.append("city_id", this.state.city_id);
        formData.append("store_type_id", this.state.store_type_id);
        formData.append("license_number", this.state.license_number);
        formData.append("license_document", this.state.license_document);
        formData.append("vat_document", this.state.vat_document);

        Axios.post(WEBSITE_URL + "/register-store", formData)
            .then(response => {
                // //// console.log(response);
                const data = response.data;
                if (data.success) {
                    this.setState({ registered: true })
                    setTimeout(
                        () => {
                            this.setState({ registered: false })
                            window.location.reload(false);
                        },
                        3000
                    );
                }
            });
    };
    handleInputPhone = (e) => {
        this.setState({ phone: e.target.value });
    };
    onChangeState = () => {
        if (this.state.otp_send) {
            this.setState({ otp_send: false, otp_enter: false, login: true })
        } else {
            this.setState({ otp_send: true, otp_enter: true, login: false })
        }
    }
    starNumber = (num) => {
        let arr = Object.values(num);
        return (
            arr.splice(0, arr.length - 4).fill("*").join("") + arr.splice(-4).join("")
        );
    };
    backToEnterNumber = () => {
        this.setState({
            otp_send: true,
            otp_enter: false,
            oldAcc: false,
            Otp: "",
        });
    };
    render() {
        const { store_user } = this.props;
        if (store_user.success && store_user?.data?.auth_token) {
            return (
                //redirect to account page
                <Redirect to={"/vendor-order"} />
            );
        }
        return (
            <React.Fragment>
                {this.state.registered === true ?
                    <div className="no-click" style={{ "position": "fixed", "bottom": "0px", "textAlign": "center", "width": "100%", "backgroundColor": "#000", "color": "#fff", "padding": "0.8rem", "zIndex": "2147483647" }}>
                        <div className="error">Store Registration successful</div>
                    </div>
                    :
                    <React.Fragment>
                        {this.state.otp_send || this.state.login ?
                            <div style={{ position: "fixed", top: "5px", right: '5px', "padding": "0.8rem", "zIndex": "9999999" }}>
                                <label className="switch">
                                    <input type="checkbox" id="togBtn" checked={this.state.otp_send} onChange={() => this.onChangeState()} />
                                    <div className="slider"></div>
                                </label>
                            </div>
                            : null}
                        {this.state.otp_send ? (
                            <React.Fragment>
                                <div>
                                    <div className="login-style ">
                                    </div>
                                    <div className='login-logo'>
                                        <img src={img} alt="" />
                                        <div className='text-center'>
                                            <span style={{ fontSize: '15px', fontWeight: '500' }}>Manage your restuarant</span>
                                        </div>
                                        <div className='text-center'>
                                            <span style={{ fontSize: '15px', fontWeight: '500' }}>on Grosav</span>
                                        </div>
                                    </div>
                                </div>
                                <Form onSubmit={(e) => this.__sendOtp(e)}>
                                    <div className="pt-5 ">
                                        <div className=" px-4 ">
                                            <InputGroup
                                                hasValidation
                                                className="b-r-10  phone-input py-3"
                                                style={{
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <InputGroup.Text
                                                    className="mobile-input-91 fw-bold"
                                                    id=""
                                                >
                                                    +91
                                                </InputGroup.Text>

                                                <FormControl
                                                    type="tel"
                                                    placeholder="Phone Number"
                                                    isValid={
                                                        this.state.phone &&
                                                        this.state.phone.match(
                                                            /^[6-9]{1}[0-9]{9}$/
                                                        )
                                                    }
                                                    isInvalid={
                                                        this.state.phone &&
                                                        !this.state.phone.match(
                                                            /^[6-9]{1}[0-9]{9}$/
                                                        )
                                                    }
                                                    value={this.state.phone}
                                                    required
                                                    minLength="10"
                                                    maxLength="10"
                                                    name="phone"
                                                    className="mobile-signup"
                                                    onChange={this.handleInputChange}
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Phone Number should start with
                                                6,7,8,9 "&" Min and Max length
                                                should be 10 digits
                                            </Form.Control.Feedback>
                                        </div>
                                    </div>
                                    <div className='d-flex flex-column align-items-center w-100'>
                                        <button type="button" className='register-btn btn mt-3 ' onClick={(e) => this.register(e)}>
                                            Register here
                                        </button>
                                    </div>
                                    <div className='py-4 px-4 d-flex flex-column align-items-center w-100 position-fixed' style={{ bottom: '0px' }}>
                                        <button type="submit" className='login-btn btn'>
                                            Login
                                        </button>
                                    </div>
                                </Form>
                            </React.Fragment>
                        ) : this.state.otp_enter ? (
                            <React.Fragment>
                                <div className="w-100 mobile-add-pickup-bg">
                                    <div className="d-flex align-items-center position-fixed px-4 w-100 py-5"
                                        style={{ top: "0px", zIndex: "99999999", }}  >
                                        <div className="px-2 pb-1 back-button"
                                            onClick={this.backToEnterNumber}
                                        >
                                            <span className="fs-2 back-button-icon">
                                                <HiOutlineChevronLeft
                                                    style={{ color: "#DA6317" }}
                                                />
                                            </span>
                                        </div>

                                        <div className=" mt-3 mobile-back-button-title fs-5">
                                            <span className="mt-3">
                                                {this.props.title}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-column px-4 w-100"
                                        style={{ paddingTop: "18vw" }}
                                    >
                                        <div className="mobile-pickup-drop-heading">
                                            Enter 4-digit Verification code
                                        </div>
                                        <div className="code mt-2">
                                            OTP sent to +91{" "}
                                            {this.starNumber(this.state.phone)}
                                            {/* This OTP wil
                                            <br />
                                            expired in 01:30 */}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around align-items-center mt-5">
                                    <div>
                                        <OtpInput
                                            value={this.state.Otp}
                                            onChange={this.__handleInputOtp}
                                            numInputs={4}
                                            separator={
                                                <span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            }
                                            isInputNum={true}
                                            inputStyle={{
                                                height: "12vw",
                                                width: "12vw",
                                                border: ".01px solid #00000030",
                                                backgroundColor: "#ffffff",
                                                outline: "none",
                                                borderRadius: "10px",
                                                color: "#000",
                                                fontSize: "1.5em",
                                                fontWeight: "500",
                                            }}
                                            focusStyle={true}
                                            shouldAutoFocus={true}
                                            required
                                        />
                                    </div>
                                </div>
                                {this.state.invalid_otp && (
                                    <HeadShake>
                                        <div className="d-flex flex-row pt-2 justify-content-center mt-3">
                                            <span className="text-danger text-center">
                                                <BsInfoCircle color={"red"} />{" "}
                                                Invalid OTP. Pls try again
                                            </span>
                                        </div>
                                    </HeadShake>
                                )}
                                <div className="fixed-bottom px-3 py-2 pb-4 w-100">
                                    <button
                                        className="footer-button btn btn py-3 w-100"
                                        style={{ border: "none" }}
                                        type="button"
                                        onClick={this.__verifyOtp}
                                    >
                                        <b style={{ color: "white" }}>
                                            Verify Otp
                                        </b>
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : this.state.login ? (
                            <React.Fragment>
                                <div className='h-100'>
                                    <Form onSubmit={this.handleLogin}>
                                        <div>
                                            <div className="login-style ">
                                            </div>
                                            <div className='login-logo'>
                                                <img src={img} alt="" />
                                                <div className='text-center'>
                                                    <span style={{ fontSize: '15px', fontWeight: '500' }}>Manage your restuarant</span>
                                                </div>
                                                <div className='text-center'>
                                                    <span style={{ fontSize: '15px', fontWeight: '500' }}>on Grosav</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="py-2">
                                            <div className=" px-4 ">
                                                <InputGroup hasValidation className="b-r-10 mt-5 phone-input py-3" style={{ boxSizing: 'border-box' }} >
                                                    <FormControl
                                                        type="email"
                                                        placeholder="Email"
                                                        autoComplete='off'
                                                        value={this.state.email}
                                                        required
                                                        name="email"
                                                        className="input-login-phone"
                                                        onChange={this.handleInputChange}
                                                    />
                                                </InputGroup>
                                                <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >
                                                    <FormControl
                                                        type="password"
                                                        placeholder="Password"
                                                        autoComplete='off'
                                                        value={this.state.password}
                                                        required
                                                        name="password"
                                                        className="input-login-phone"
                                                        onChange={this.handleInputChange}
                                                    />
                                                </InputGroup>
                                                {this.state.error &&
                                                    <div className='text-center text-danger mt-2'>Email or password incorrect</div>
                                                }
                                            </div>
                                        </div>
                                        <div className='d-flex flex-column align-items-center w-100'>
                                            <button type="button" className='register-btn btn mt-3 ' onClick={(e) => this.register(e)}>
                                                Register here
                                            </button>
                                        </div>
                                        <div className='py-4 px-4 d-flex flex-column align-items-center w-100 position-fixed' style={{ bottom: '0px' }}>
                                            <button type="submit" className='login-btn btn'>
                                                Login
                                            </button>

                                        </div>
                                    </Form>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                            <div className="d-flex align-items-center position-fixed px-4 w-100 py-5"
                                        style={{ top: "0px", zIndex: "99999999", }}  >
                                        <div className="px-2 pb-1 back-button"
                                            onClick={this.backToEnterNumber}
                                        >
                                            <span className="fs-2 back-button-icon">
                                                <HiOutlineChevronLeft
                                                    style={{ color: "#DA6317" }}
                                                />
                                            </span>
                                        </div>

                                        <div className=" mt-3 mobile-back-button-title fs-5">
                                            <span className="mt-3">
                                                {this.props.title}
                                            </span>
                                        </div>
                                    </div>
                                <Form onSubmit={this.handleRegister}>
                                    <div>
                                        <div className="login-style ">
                                        </div>
                                        <div className='login-logo'>
                                            <img src={img} alt="" />
                                        </div>
                                    </div>
                                    <div className='pt-5 px-3'>
                                        <div style={{
                                            color: '#FD8814',
                                            fontWeight: '600',
                                            fontSize: '22px',
                                        }}>
                                            <span>Register</span>
                                        </div>
                                        <div className='text-muted'>
                                            <span>Enter details to register</span>
                                        </div>
                                    </div>
                                    <div className="px-4">
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-4 phone-input py-3" style={{ boxSizing: 'border-box' }} >

                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="text"
                                                    placeholder="Storeowner Name"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >

                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="email"
                                                    placeholder="Storeowner Email"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >

                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="password"
                                                    placeholder="Storeowner Password"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="  ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >
                                                <InputGroup.Text className="input-91 fw-bold" id="">+91</InputGroup.Text>

                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="tel"
                                                    placeholder="Storeowner Phone Number"
                                                    isValid={this.state.phone && this.state.phone.match(/^[6-9]{1}[0-9]{9}$/)}
                                                    isInvalid={this.state.phone && !this.state.phone.match(/^[6-9]{1}[0-9]{9}$/)}
                                                    value={this.state.phone}
                                                    required
                                                    minLength="10"
                                                    maxLength="10"
                                                    name="phone"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>


                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >

                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="text"
                                                    placeholder="Store Name"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className="  ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >
                                                <InputGroup.Text className="input-91 fw-bold" id="">+91</InputGroup.Text>

                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="tel"
                                                    placeholder="Store Phone Number"
                                                    isValid={this.state.phone && this.state.phone.match(/^[6-9]{1}[0-9]{9}$/)}
                                                    isInvalid={this.state.phone && !this.state.phone.match(/^[6-9]{1}[0-9]{9}$/)}
                                                    value={this.state.phone}
                                                    required
                                                    minLength="10"
                                                    maxLength="10"
                                                    name="phone"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>


                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >
                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="text"
                                                    placeholder="Address"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >
                                                <Form.Select aria-label="Select city" onChange={this.handleInput}>
                                                    <option>Select city</option>
                                                    {this.state.all_cities && this.state.all_cities.length && this.state.all_cities.map((city, index) =>
                                                        <option value={city.id}>{city.name}</option>
                                                    )}
                                                </Form.Select>
                                            </InputGroup>
                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >
                                                <Form.Select aria-label="Select Store Type" onChange={this.handleInput}>
                                                    <option>Select Store Type</option>
                                                    {this.state.all_categories && this.state.all_categories.length && this.state.all_categories.map((category, index) =>
                                                        <option value={category.id}>{category.name}</option>
                                                    )}
                                                </Form.Select>
                                            </InputGroup>
                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >

                                                <FormControl
                                                    onChange={this.handleInput}
                                                    type="text"
                                                    placeholder="License Number"
                                                    className="input-login-phone"
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >

                                                <Form.Group controlId="licence_doc" className="mb-3">
                                                    <Form.Label>License Document</Form.Label>
                                                    <Form.Control type="file" onChange={this.handleInput} />
                                                </Form.Group>
                                            </InputGroup>
                                        </div>
                                        <div className=" ">
                                            <InputGroup hasValidation className="b-r-10 mt-3 phone-input py-3" style={{ boxSizing: 'border-box' }} >

                                                <Form.Group controlId="vat_doc" className="mb-3">
                                                    <Form.Label>VAT Document</Form.Label>
                                                    <Form.Control type="file" onChange={this.handleInput} />
                                                </Form.Group>
                                            </InputGroup>
                                        </div>
                                        <div className='pt-3 px-3 text-muted'>
                                            <label>
                                                <input type="checkbox" />
                                                <span className='px-2'>I want to get critical updates via whatsapp on this number to ensure nothing gets missed.</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className='px-5 pt-5 text-muted'>
                                        <span>By Registering I agree to Grosav <Link to="#" style={{ color: '#FF9012' }}> Terms and Conditions</Link></span>
                                    </div>

                                    <div className='px-4 pt-4 pb-3'>
                                        <button type="submit" className='login-btn btn'>
                                            Register
                                        </button>
                                    </div>

                                </Form>
                            </React.Fragment>
                        )
                        }
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    store_user: state.vendor.store_user,
})


export default connect(mapStateToProps, {
    loginStoreUser,
    viewAllCities,
    viewAllStoreCategories,
    sendOtp,
    verifyOtp
})(VendorLogin)
