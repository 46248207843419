import {
    GET_ALL_STORE_CATEGORIES,
    GET_SLIDERS,
    GET_SINGLE_SLIDER,
    GET_COUPONS,
    GET_SINGLE_COUPON,
    GET_ALL_BANNER_FOOD,
    GET_TOP_FOOD,
    GET_TOP_DEALS,
    GET_ALL_RESTURANTS,
    GET_ALL_RECOMMENTED_STORES,
    GET_ALL_NEAR_STORES,
    GET_ALL_POPULAR_STORES,
    GET_ALL_ITEM_CATEGORY,
    GET_ALL_ITEM_GROUP,
    GET_POPULAR_BRANDS,
    GET_DEALS_TODAY
} from './actionType'


const initialState = {
    storecategories: [],
    item_group: [],
    sliders: [],
    singleslider: [],
    coupons: [],
    singlecoupon: [],
    banners: [],
    topFoods: [],
    topDeals: [],
    allStores: [],
    popularStores: [],
    recStore: [],
    itemCategory: [],
    nearStore: [],
    popular_brands: [],
    deals_today:[]

};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_STORE_CATEGORIES:
            return { ...state, storecategories: action.payload };
        case GET_SLIDERS:
            return { ...state, sliders: action.payload };
        case GET_SINGLE_SLIDER:
            return { ...state, singleslider: action.payload };
        case GET_COUPONS:
            return { ...state, coupons: action.payload };
        case GET_SINGLE_COUPON:
            return { ...state, singlecoupon: action.payload };
        case GET_ALL_BANNER_FOOD:
            return { ...state, banners: action.payload };
        case GET_TOP_FOOD:
            return { ...state, topFoods: action.payload };
        case GET_TOP_DEALS:
            return { ...state, topDeals: action.payload };
        case GET_ALL_RESTURANTS:
            return { ...state, allStores: action.payload };
        case GET_ALL_RECOMMENTED_STORES:
            return { ...state, recStore: action.payload };
        case GET_ALL_POPULAR_STORES:
            return { ...state, popularStores: action.payload };
        case GET_ALL_ITEM_CATEGORY:
            return { ...state, itemCategory: action.payload };
        case GET_ALL_NEAR_STORES:
            return { ...state, nearStore: action.payload };
        case GET_ALL_ITEM_GROUP:
            return { ...state, item_group: action.payload };
        case GET_POPULAR_BRANDS:
            return { ...state, popular_brands: action.payload };
            case GET_DEALS_TODAY:
                return { ...state, deals_today: action.payload };
        default:
            return state;
    }
}
