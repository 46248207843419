import React, { Component } from "react";
import { connect } from "react-redux";
import cod from "../../../assets/images/cash-on-delivery 1.png";
import upi from "../../../assets/images/UPI1.jpg";
import mscard1 from "../../../assets/images/Mastercard_logo 1.png";
import ContentLoader from "react-content-loader";


export class PaymentMethod extends Component {
    state = {
        button: true,
        loading:false,
    };
    button = () => {
        this.setState({ button: !this.state.button });
    };
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
    }

    render() {
        return (
            <React.Fragment>
 {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >

                            <rect x="14" y="80" rx="" ry="" width="140" height="15"/>
                            <rect x="14" y="115" rx="" ry="" width="160" height="30"/>
                            <rect x="14" y="170" rx="10" ry="10" width="250" height="55"/>
                            <rect x="14" y="240" rx="10" ry="10" width="250" height="55"/>
                            <rect x="14" y="310" rx="10" ry="10" width="250" height="55"/>
                            <rect x="14" y="520" rx="10" ry="10" width="250" height="40"/>






                        </ContentLoader>
                    </React.Fragment>
                ) : (
            <React.Fragment>
                <div className="mobile-payment-heading py-2">
                    <div className="payment-tag  px-4">
                        <span className="mobile-payment-header">
                            Payment Method
                        </span>
                    </div>
                    <div className="pymt-security mt-3 px-4" style={{fontSize:'12px',fontWeight:'500',color:"#000000"}}>
                        This data will be displayed in your account profile for
                        security
                    </div>
                </div>
                {/* {this.state.button && (

                )} */}
                <div className="fixed-bottom px-3 py-2 pb-4 w-100">
                    <button
                        className="payment-button btn btn py-3 w-100"
                        type="button"
                        onClick={() => this.button()}
                    >
                        <b style={{ color: "white" }}>Next</b>
                    </button>
                </div>
            </React.Fragment>
                )}
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(PaymentMethod);
