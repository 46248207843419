import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IoCheckmarkCircle } from 'react-icons/io5';
import { BsExclamationCircleFill, BsFillCloudDownloadFill } from 'react-icons/bs';
import noOrder from "../../../../assets/images/NoOrder.png";
import { IMAGE_BASE_URL, WEBSITE_URL } from '../../../../api';
import Modal from "react-modal";
import moment from 'moment';
import { courierPaymentStatusCheck } from '../../../../redux/mobile/checkOut/action';
import Countdown from 'react-countdown';
import Ink from 'react-ink';
import { formatPrice } from '../../../Helpers/formatPrice';
import Truncate from 'react-truncate';
import { MdDeliveryDining } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
export class CourierOrders extends Component {
    state = {
        loading: true,
        couriers: [],
        open: false,
        unique_courier_id: "",
        cancel: true,
        hasMore: false,
        cancel_reason: [],
    };
    componentDidMount() {
        const { couriers } = this.props;
        this.setState({ couriers: couriers });
    }


    componentDidUpdate(prevProps) {
        if (prevProps.couriers !== this.props.couriers) {
            // console.log('Orders updated:', this.props.orders);
        this.setState({ couriers: this.props.couriers });

            // Do something with the new orders, e.g., update the state or rerender
        }
    }
    getOrderStatus = (created_at) => {
        const date = new Date(created_at);
        const today = new Date();
        var dif = Math.abs((date.getTime() - today.getTime()) / 1000);
        // window.location.reload()
        if (dif < 60) {
            return "Cancel";
        } else {
            return "placed";
        }
    };
    getDate = (created_at) => {
        const date = new Date(created_at);
        return date;
    };
    hideCancel = (id) => {
        this.props.couriers.find((order) => order.id === id).cancel = "timeout";
        this.forceUpdate();
    };
    checkTimeOut = (id) => {
        if (this.props.couriers.find((o) => o.id === id).cancel === "timeout") {

            return false;
        }
        return true;
    };
    componentWillUnmount() {
        clearInterval(this.updateTimer);
    }
    openSheet = (unique_courier_id) => {
        this.setState({ open: true, unique_courier_id: unique_courier_id, });
    };
    onSheetClose = () => {
        this.setState({ open: false, });
    };
    printOrderBill = (order_id) => {
        const { user } = this.props;

        ////// console.log(this.props);
        window.location = WEBSITE_URL + "/print-courier/" + user.data.auth_token + "/" + order_id;
    }
    recheckPayment = (id) => {
        this.props.courierPaymentStatusCheck(id).then((response) => {
            if (response.data.success) {
                window.location.reload();
            } else {
                ////// console.log(response.data.message);
            }
        })
    }
    render() {
        const { cancelCourier } = this.props;
        const { couriers } = this.props;
        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return null;
            } else {
                return (
                    <span>
                        {seconds}s
                    </span>
                );
            }
        };
        return (
            <React.Fragment>
                <div className="px-3 " style={{ paddingBottom: '30vw' }}>
                    {couriers && couriers?.length > 0 ?
                        couriers?.map((data, index) => (
                            <div className='mobile-runningorders-card p-3 mt-2' style={{ position: "relative" }}>
                                <div className="d-flex flex-row">
                                    <div className="pe-2 flex-grow-1 d-flex flex-column">
                                        <span style={{ fontWeight: "700", fontSize: "1.1em" }}>
                                            {JSON.parse(data?.package_content)?.name}
                                        </span>
                                        {data?.courier_status_id == 7 ? (
                                            <div className='fw-bolder'>
                                                <IoCheckmarkCircle size={20} color='#18D26B' />
                                                <span className='ps-1' style={{ color: '#18D26B' }}>Courier Delivered</span>
                                            </div>
                                        )
                                            : null
                                        }
                                    </div>
                                    <div className=' d-flex flex-column'>
                                        {data?.courier_status_id === 7 ? (
                                            <div className="" style={{ color: '#1bae1b', fontWeight: '700' }} >{data?.courierstatus?.name}</div>
                                        ) : data?.courier_status_id === 3 ? (
                                            <div className="" style={{ color: '#ff4c4c', fontWeight: '700' }} >{data?.courierstatus?.name}</div>
                                        ) : <div>
                                            {(data?.courier_status_id !== 7 ||
                                                data?.courier_status_id !== 3) && (
                                                    <div className="" style={{ color: '#FD8814', fontWeight: '700' }} >
                                                        {data?.courierstatus?.name}
                                                    </div>
                                                )}
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div> Courier ID </div>
                                        <div style={{ color: '#FD8814', fontWeight: '600' }}> #{data?.unique_courier_id} </div>
                                    </div>
                                    <div className="d-flex mt-2">
                                        <div style={{ whiteSpace: 'wrap', width: '100%' }}> Courier Content </div>
                                        <div className='text-end flex-grow-1' style={{ color: '#FD8814', fontWeight: '600', width: '100%' }}>
                                            <Truncate lines={1}>
                                                {JSON.parse(data?.package_content)?.map((value, index, arr) => (
                                                    (index != (arr.length - 1) ? value.name + ', ' : value.name)
                                                ))}
                                            </Truncate>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div className='me-3' style={{ whiteSpace: 'wrap' }}> Pickup Address </div>
                                        <div style={{ fontWeight: '600' }} className='text-end'> {JSON.parse(data?.pickup_address)?.tag ?? ""}, {JSON.parse(data?.pickup_address)?.landmark ?? ""}, {JSON.parse(data?.pickup_address)?.house ?? ""},<br /> {JSON.parse(data?.pickup_address)?.address} </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div className='me-3' style={{ whiteSpace: 'wrap' }}> Delivery Address </div>
                                        <div style={{ fontWeight: '600' }} className="text-end">{JSON.parse(data?.delivery_address)?.tag ?? ""}, {JSON.parse(data?.delivery_address)?.landmark ?? ""},{JSON.parse(data?.delivery_address)?.house ?? ""},<br /> {JSON.parse(data?.delivery_address)?.address}</div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div className='me-3' style={{ whiteSpace: 'wrap' }}> Note </div>
                                        <div style={{ fontWeight: '600' }} className="text-end">{data?.instruction}</div>
                                    </div>
                                    {data?.courier_status_id === 3 ? ''
                                        : data?.courier_status_id === 7 ? ''
                                            : <div className="d-flex justify-content-between mt-2">
                                                <div> Courier Status </div>
                                                <div className="" style={{ color: '#FD8814', fontWeight: '700' }} >{data?.courierstatus?.name}</div>
                                            </div>
                                    }
                                    <div className="d-flex justify-content-between mt-2">
                                        <span>Payment Mode</span>

                                        <div>
                                            <span className='ps-1' style={{ fontWeight: '700', fontSize: '.9em', }}>{data?.payment_mode}</span>
                                        </div>
                                    </div>
                                    {data?.payment_mode !== "COD" &&

                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Payment Status</span>
                                            { data?.payment_status === "SUCCESS" ? (
                                                <div>
                                                    <IoCheckmarkCircle size={20} color='#18d26b' />
                                                    <span className='ps-1' style={{ fontWeight: '700', fontSize: '.9em', color: '#18d26b' }}>{data?.payment_status}</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <BsExclamationCircleFill size={17} color='#ff4c4c' />
                                                    <span className='ps-1' style={{ fontWeight: '700', fontSize: '.9em', color: '#ff4c4c' }}>{data?.payment_status}</span>
                                                </div>
                                            )}
                                        </div>
                                    }
                                    <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <span>Convenience Fee</span>
                                        <span>₹ {data?.courier_fee ? formatPrice(parseFloat(data?.courier_fee)) : 0.00}</span>
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <span>Delivery Charge</span>
                                        <span>₹ {data?.delivery_charge ? formatPrice(data?.delivery_charge) : 0.00}</span>
                                    </div>
                                    <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>

                                    {data?.courier_status_id == 7 &&
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Download</span>
                                            <button className='border px-2 py-1 rounded-5 bg-white' onClick={() => this.printOrderBill(data?.id)} >
                                                <span className='me-2' style={{ color: "#FD8814" }}>Download Invoice</span>
                                                <BsFillCloudDownloadFill color='#FD8814' />
                                            </button>
                                        </div>
                                    }
                                    {(data?.courier_status_id >= 3 && data?.courier_status_id != 7 && data?.agent_contact)  &&

                                    <div className="d-flex justify-content-between mb-1">
                                    <span className='mt-1'><MdDeliveryDining style={{
                                        color: '#FD8814',
                                        fontSize : '19px',
                                        marginRight : '2px'
                                    }}/> Agent: {data?.agent_name}</span>

                                    <a href={`tel:${data?.agent_contact}`} className='border px-2 py-1 rounded-5 bg-white' style={{ textDecoration: 'none' }}>
                                        <FaPhoneAlt style={{
                                            color: '#FD8814',
                                            marginRight: '5px',
                                            fontSize: '11px'
                                        }} />
                                        <span className='me-2' style={{ color: "#FD8814" }}>{data?.agent_contact}</span>
                                    </a>
                                    </div>
                                    }
                                    <div className="d-flex justify-content-between mt-2 pb-2">
                                        <span className='fw-bolder fs-6'>Total Amount</span>
                                        <span className='fw-bolder fs-6'>₹ {formatPrice(parseFloat(data?.amount))}</span>
                                    </div>
                                    <div className='d-flex flex-column'>
                                        {data?.courier_status_id == 1 && this.checkTimeOut(data.id) ? (
                                            <React.Fragment>
                                                {this.getOrderStatus(data.created_at) === "Cancel" ? (
                                                    <div className="d-grid">
                                                        <button className="btn btn-danger border-radius-10 user-select-none p-2"
                                                            onClick={() => this.openSheet(data.unique_courier_id)} >
                                                            Cancel Order({" "}
                                                            <Countdown date={Date.parse(data.created_at) + 60000}
                                                                id={data.id} renderer={renderer}
                                                                onComplete={() => this.hideCancel(data.id)}
                                                            />
                                                            )
                                                        </button>
                                                    </div>
                                                ) : ("")}
                                            </React.Fragment>
                                        ) : data?.courier_status_id == 9 && moment(data?.created_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? (
                                            <React.Fragment>
                                                <div className="d-grid">
                                                    <button className="btn border-radius-10 user-select-none p-2 text-white" style={{ background: '#FD8814', }} onClick={() => { this.recheckPayment(data.id) }} >
                                                        Re-check Payment
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        ) : ("")}
                                    </div>
                                </div>
                            </div>
                        )) : (
                            <div className='d-flex flex-column text-center pt-4'>
                                <img src={noOrder} className="" style={{ width: '100%', borderRadius: '12px' }} alt='main-item' />
                                <div style={{ fontSize: '1.2em', fontWeight: '700' }}>No Courier Found</div>
                            </div>
                        )}
                </div>
                <Modal
                    isOpen={this.state.open}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={this.onSheetClose}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{ borderRadius: "5px", height: "145px", zIndex: '999999999999999999999' }}
                    >
                        <React.Fragment>
                            <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center text-center">
                                <span className="mt-2 mb-0" style={{ fontSize: '1.3em', fontWeight: '700' }}>
                                    Would you like to cancel this order ?
                                </span>
                            </div>

                            <div className="d-flex justify-content-around w-100 gap-4  mt-3">
                                <div className="text-center mr-4 w-100">
                                    <button className="delete-button-meals p-2 px-2 w-100 position-relative"
                                        style={{
                                            borderColor: "#fd8814",
                                            color: "#fd8814",
                                            // border: '1px solid #fd8814',
                                            width: "100%",
                                        }} onClick={this.onSheetClose} >
                                        Close
                                        <Ink duration={500} style={{ color: "#000" }} />
                                    </button>
                                </div>

                                <div className="text-center w-100">
                                    <button
                                        className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#ce0060",
                                            borderColor: "#ce0060",
                                        }} onClick={() =>
                                            cancelCourier(this.state.unique_courier_id)} >
                                        Confirm
                                        <Ink duration={500} style={{ color: "#000" }} />
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,

})

export default connect(mapStateToProps, {
    courierPaymentStatusCheck
})(CourierOrders)

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #fead1d',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};
