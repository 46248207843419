import React, { Component } from 'react'
import { connect } from 'react-redux'
import { FaClinicMedical } from "react-icons/fa";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
// import Ink from "react-ink";
import Truncate from 'react-truncate';
import { getMedicineNearStores } from "../../../../redux/mobile/medicine/action.js";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api';
import { Rating } from 'react-simple-star-rating';



export class StoreNearYou extends Component {
    componentDidMount() {
        this.props.getMedicineNearStores(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
    }
    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }
    render() {
        const { nearMedicines } = this.props;
        return (
            <React.Fragment>
                {nearMedicines && nearMedicines.success && nearMedicines?.data?.length > 0 ? (
                    <div>
                        <div className="px-3 py-3 d-flex flex-row align-items-center ">
                            <FaClinicMedical
                                style={{ fontSize: "1.5rem", color: "#FF9A62" }}
                            />
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                }}
                            >
                                Stores Near You
                            </span>
                        </div>
                        <div className='d-flex flex-row align-items-center px-3'>
                            <div className='mt-3 main-menu hidden-scroll-bar gap-3 '
                                style={nearMedicines?.data?.length > 4 ?
                                    { gridTemplateColumns: `repeat(${nearMedicines?.data?.length},1fr)` } :
                                    { gridTemplateColumns: "repeat(4,1fr)" }}>
                                {nearMedicines?.data?.map((store) =>
                                    <Link to={'/single-store/' + store.id}>
                                        <div className='mt-2 position-relative'>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + store.image}
                                                placeholder={
                                                    PLACE_HOLDER_IMAGE
                                                }
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            objectFit: "cover",
                                                            backgroundRepeat: 'none',
                                                            height: '20vw',
                                                            width: '10vh',
                                                            borderRadius: '10px'
                                                        }}
                                                        className=""
                                                        alt="grosav"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            {/* <div className='d-flex justify-content-center position-absolute w-100' style={{ bottom: '5px' }}>
                                <Ink className="touch-ink " />
                                <span className='restuarant-near-offer text-center px-2'>20 % off</span>
                            </div> */}
                                        </div>
                                        <div className='top-res-name'>
                                            <Truncate lines={1}>
                                                <span className='fw-700' >
                                                    {store.name}
                                                </span>
                                            </Truncate>
                                            {store?.ratings?.length > 0 ?
                                                <div>
                                                    <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={this.ratingCount(store?.ratings, store?.ratings.length)}
                                                        readonly="true"
                                                    />
                                                </div>
                                                :
                                                <Rating
                                                    size={"1em"}
                                                    className="mobile-rate-star"
                                                    initialValue={store?.rating}
                                                    readonly="true"
                                                />
                                            }
                                            <div className='d-inline-block'>
                                                <div>
                                                    <span className='top-res-deli-time text-muted '>
                                                        {store.approx_time_delivery > 0 ?
                                                            <>
                                                                {store.approx_time_delivery}Mins
                                                            </>
                                                            :
                                                            <>
                                                                0Min
                                                            </>
                                                        }
                                                        {store.delivery_radius > 0 ?
                                                            <>
                                                                - {store.delivery_radius}km
                                                            </>
                                                            :
                                                            <>
                                                                - 0km
                                                            </>
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                ) : ("")}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    nearMedicines: state.medicine.nearMedicines,
})

export default connect(mapStateToProps, { getMedicineNearStores })(StoreNearYou)
