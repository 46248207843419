import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import ContentLoader from 'react-content-loader'
import NormalOrders from "./NormalOrders";
import AnyStoreOrders from "./AnyStoreOrders";
import { getAllRunningOrders, cancelOrder, acceptOrder, cancelCourier } from "../../../redux/mobile/mobileOrder/action"
import { emptyCheckout } from '../../../redux/mobile/checkOut/action'
import { Link } from "react-router-dom";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CourierOrders from "./CourierOrders";
import { HiOutlineChevronLeft } from "react-icons/hi";

export class index extends Component {
    state = {
        loading: false,
        orders: [],
        anyStore: [],
        couriers: [],
        type: this.props.match.params.type
    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });

        this.interval = setInterval(() => {
            this.fetchOrders();
        }, 10000); // 10000ms = 10 seconds
        this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                // this.setState({ loading: false});
                this.setState({
                    loading: false,
                    orders: response.payload.orders,
                    anyStore: response.payload.anyStore,
                    couriers: response.payload.couriers,
                });
            }
        })
        this.props.emptyCheckout();
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchOrders = () => {
        const { user } = this.props;
        this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({
                    loading: false,
                    orders: response.payload.orders,
                    anyStore: response.payload.anyStore,
                    couriers: response.payload.couriers,
                });
            }
        });
        this.props.emptyCheckout();
    }
    cancelOrder = (unique_order_id) => {
        const { user } = this.props;
        this.setState({ loading: true, open: false, });
        this.props.cancelOrder(user.data.auth_token, unique_order_id, this.state.cancel_reason, 'WALLET', "ANYSTORE").then((response) => {
            if (response && response.payload && response.payload.success) {
                this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
                    if (response && response.payload && response.payload.success) {
                        // this.setState({ loading: false});
                        this.setState({
                            loading: false,
                            orders: response.payload.orders,
                            anyStore: response.payload.anyStore,
                            couriers: response.payload.couriers,
                        });
                    }
                })
            }
        });
    };

    cancelCourier = (unique_courier_id) => {
        const { user } = this.props;
        this.setState({ loading: true, open: false, });
        this.props.cancelCourier(user.data.auth_token, unique_courier_id, this.state.cancel_reason, "WALLET").then((response) => {
            if (response && response.payload && response.payload.success) {
                this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
                    if (response && response.payload && response.payload.success) {
                        // this.setState({ loading: false});
                        this.setState({
                            loading: false,
                            orders: response.payload.orders,
                            anyStore: response.payload.anyStore,
                            couriers: response.payload.couriers,
                        });
                    }
                })
            }
        });
    };

    acceptOrder = (unique_order_id) => {
        const { user } = this.props;
        this.setState({ loading: true, open: false, });
        this.props.acceptOrder(user.data.auth_token, unique_order_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.props.getAllRunningOrders(user.data.auth_token).then((response) => {
                    if (response && response.payload && response.payload.success) {
                        this.setState({
                            loading: false,
                            orders: response.payload.orders,
                            anyStore: response.payload.anyStore,
                            couriers: response.payload.couriers,
                        });
                    }
                })
            }
        });
    };
    render() {
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="12" y="26" rx="8" ry="8" width="22" height="22" />
                                <rect x="12" y="85" rx="10" ry="10" width="200" height="30" />
                                <rect x="12" y="127" rx="10" ry="10" width="200" height="10" />
                                <rect x="18" y="178" rx="14" ry="10" width="249" height="159" />
                                <rect x="12" y="500" rx="10" ry="18" width="250" height="30" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className=""  >
                                <React.Fragment>
                                    <div className='sticky-back-button d-flex flex-row justify-content-start align-items-center pt-2'>
                                        <Link to="/home">
                                            <div className='flex-shrink-1 text-center  mobile-back-button  position-relative m-3'
                                                onClick={() => this.onHandleBack()} >
                                                <span className="fw-bold fs-2 back-button-icon">
                                                    <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                                                </span>
                                            </div>
                                        </Link>

                                    </div>
                                </React.Fragment>
                            </div>
                            <div className="px-3">
                                <div className="pt-2 mobile-runningorders"
                                    style={{ fontWeight: "700", fontSize: "25px" }}>
                                    Running Orders
                                </div>
                                <div className="" style={{ fontWeight: "400", fontSize: "12px", color: "black" }}>
                                    Details about your running orders
                                </div>
                            </div>
                            <div className="mt-3">
                                <Tabs defaultActiveKey={this.state.type}
                                    transition={true}
                                    className="mb-3 text-center px-3 gap-2"
                                    justify
                                >
                                    <Tab eventKey="normal" title="Normal" >
                                        <NormalOrders orders={this.state.orders} cancelOrder={(e) => this.cancelOrder(e)} />
                                    </Tab>
                                    <Tab eventKey="anystore" title="AnyStore">
                                        <AnyStoreOrders anyStore={this.state.anyStore} acceptOrder={(e) => this.acceptOrder(e)} cancelOrder={(e) => this.cancelOrder(e)} history={this.props.history} />
                                    </Tab>
                                    <Tab eventKey="couriers" title="Couriers">
                                        <CourierOrders couriers={this.state.couriers} acceptOrder={(e) => this.acceptOrder(e)} cancelCourier={(e) => this.cancelCourier(e)} />
                                    </Tab>
                                    {/* <Tab eventKey="contact" title="Courier Orders">
                                    Contact
                                </Tab> */}
                                </Tabs>
                            </div>
                            <div className="position-fixed px-3 py-2 pb-3 w-100" style={{ bottom: "0px", backgroundColor: "#fff" }}>
                                <Link to={'/home'} >
                                    <button className="btn w-100 py-3" style={{ color: "#FEFEFF", fontWeight: "700", fontSize: "14px", background: '#FEA24B', borderRadius: '15px' }} >
                                        Back to home
                                    </button>
                                </Link>
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, {
    getAllRunningOrders,
    cancelOrder,
    emptyCheckout,
    acceptOrder,
    cancelCourier
})(index);
