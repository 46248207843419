import React, { Component } from "react";
import { connect } from "react-redux";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { VscBellDot,VscBell } from "react-icons/vsc";
import { withRouter } from "react-router-dom";
import { Link } from 'react-router-dom';


class BackButtonWithNotification extends Component {
    onHandleBack = () => {
        this.props.history.goBack();
    };
    render() {
        const { user } = this.props;
        return (
            <React.Fragment>
                <div className="d-flex flex-row justify-content-between w-100 position-fixed py-4 px-3" style={{ top: '0px' }}>
                    <span className="btn-back  fs-2 back-button-icon px-2" onClick={() => this.onHandleBack()}>
                        <HiOutlineChevronLeft
                            style={{ color: "#DA6317" }}
                        />
                    </span>
                    <div className="flex-grow-1 mt-2 back-button-title text-center fs-5 pe-4">
                        <span className="mt-3">{this.props.title}</span>
                    </div>
                    {user && user.success ? (
                        <Link to='/notifications'>
                            <span className="btn-back fs-2 back-button-icon px-2">
                                <VscBellDot
                                    style={{ color: "#DA6317" }}
                                />
                            </span>
                        </Link>
                    ) : (
                        <Link to="/signup" className='text-dark'>
                            <span className="btn-back fs-2 back-button-icon px-2">
                                <VscBell
                                    style={{ color: "#DA6317" }}
                                />
                            </span>
                        </Link>
                    )}
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user
});

export default withRouter(
    connect(mapStateToProps, {})(BackButtonWithNotification)
);
