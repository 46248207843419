import React, { Component } from "react";
import { connect } from "react-redux";
import { GiHamburger } from "react-icons/gi";
import { Link } from 'react-router-dom';
import ProgressiveImage from "react-progressive-image";
import { getTopDeals } from "../../../../redux/mobile/home/action.js";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
export class TopDeals extends Component {
    state = {
        data: false
    }
    componentDidMount() {
        this.props.getTopDeals(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ data: true });
            }
        });
    }
    render() {
        const { topDeals } = this.props;
        const { data } = this.state;
        return (
            <React.Fragment>
                {data ? topDeals && topDeals.data && topDeals.data.length > 0 && (
                    <div className="px-3 mt-3  ">
                        <GiHamburger
                            className="mb-2"
                            color="FF9A62"
                            size={20}
                        />{" "}
                        <span className="top-res-header">Top Deals</span>
                        <div className="d-flex flex-row align-items-center">
                            <div
                                className=" main-menu hidden-scroll-bar gap-3 "
                                style={{ gridTemplateColumns: 'repeat(' + topDeals?.data?.length + ',1fr)' }}
                            >
                                {topDeals?.data?.map((deal) => (
                                    <Link key={deal?.id} to={'/top-offer/deal/' + deal.id} style={{ borderRadius: "20px", }}>
                                        <div className="mt-2 position-relative" style={{ borderRadius: "20px", }}>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + deal?.image}
                                                placeholder={
                                                    PLACE_HOLDER_IMAGE
                                                }
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            width: "35vw", height: "43vw", borderRadius: "20px", objectFit: "cover",
                                                            backgroundRepeat: "round", boxShadow: "0px 0px 9px 2px #bfbfbf5e"
                                                        }}
                                                        className=""
                                                        alt="grosav"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                    </Link>
                                ))}

                            </div>
                        </div>
                    </div>
                ) : ''}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    topDeals: state.home.topDeals,
});

export default connect(mapStateToProps, { getTopDeals })(TopDeals);
