
import { GET_PAYOUTS_URL } from "../../../api";
import { GET_PAYOUTS,GET_PENDING_PAYOUTS} from "./actionType";

import Axios from "axios";


export const viewPayout = (token,from,to) => (dispatch) => {
    return Axios.post(GET_PAYOUTS_URL, {
        token, from,to
    })
        .then((response) => {
            let payouts = response.data;
            return dispatch({
                type: GET_PAYOUTS,
                payload: payouts
            })

        })
}

