import React, { Component } from 'react'
import { connect } from 'react-redux'
import MobileBackButtonWithCenterTitle from '../../Elements/MobileBackButtonWithCenterTitle';
import ProgressiveImage from 'react-progressive-image'
import { Rating } from "react-simple-star-rating";
// import { RiShareForwardLine } from "react-icons/ri";
import ContentLoader from 'react-content-loader'
import {
    getLikedItems, removeLikedItem
} from '../../../redux/mobile/item/action'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from './../../../api/index';
import { BottomSheet } from "react-spring-bottom-sheet";
import { FaRegTrashAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import image from '../../../assets/images/noitems.png'
import Truncate from 'react-truncate';

export class LikedItems extends Component {

    state = {
        loading: false,
        likeditems: '',
        likesheet: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        this.props.getLikedItems(this.props.user.data.auth_token)
            .then((response) => {
                this.setState({ likeditems: response.payload.data })
                ////// console.log(response, 'responseeeeeeeee')
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 1000)
            });
    }
    removeLike = (item) => {
        this.props.removeLikedItem(this.props.user.data.auth_token, item.id)
            .then((response) => {
                this.setState({
                    likeditems: response.data.data,
                    likesheet: false,
                })
                // ////// console.log(response)
            })
    }
    openLike = (item) => {
        this.setState({
            likesheet: true,
            removeitem: item,
        })
    }
    closeLike = () => {
        this.setState({
            likesheet: false,
            removeitem: ''
        })
    }
    render() {
        const { likeditems } = this.state;
        // ////// console.log(likeditems.length,'itemsssssssss')
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="21" y="33" rx="10" ry="10" width="30" height="30" />
                                <rect x="90" y="33" rx="10" ry="12" width="120" height="30" />
                                <rect x="21" y="120" rx="10" ry="12" width="239" height="95" />
                                <rect x="21" y="220" rx="10" ry="12" width="239" height="95" />
                                <rect x="21" y="320" rx="10" ry="12" width="239" height="95" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <div className="mobile-liked-heading pt-2">
                                <div className="px-3" style={this.state.likesheet ? { display: 'none' } : { display: 'block' }}>
                                    <MobileBackButtonWithCenterTitle title="Liked Items" history={this.props.history} />
                                </div>
                                {likeditems && likeditems.length > 0 ? (
                                    <>
                                        <div className="px-3 mt-5">
                                            {likeditems?.map((item) =>
                                                <div className="mobile-liked-item-div d-flex justify-content-between px-2 pt-3 pb-2 mt-2">
                                                    <div className="ps-3">
                                                        <Link to={"/single-product/" + item.id}>
                                                            <div className='mobile-liked-item' style={{ fontSize: '15px' }}>
                                                                <Truncate lines={2}>
                                                                    {item?.name}
                                                                </Truncate>
                                                            </div>
                                                            <div className='mobile-liked-item-desc'>
                                                                <Truncate lines={3}>
                                                                    {item?.description}
                                                                </Truncate>
                                                            </div>
                                                            <div>
                                                                <Rating
                                                                    size={"1em"}
                                                                    className="mobile-rate-star"
                                                                    readonly="true"
                                                                    initialValue={item.rating}
                                                                />
                                                            </div>
                                                            <div className='d-flex justify-content-start flex-column'>
                                                                {item?.addon_categories?.length > 0 ? (
                                                                    <div className="d-flex flex-column ">
                                                                        <div className="d-flex justify-content-between" >
                                                                            <div className="fw-bolder pt-1"
                                                                                style={{ color: 'grey', fontSize: "13px", }}  >
                                                                                CUSTOMIZABLE
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : ("")}
                                                                <div className= "d-flex justify-content-start align-items-start">
                                                                    {parseInt(item.market_price) > parseInt(item?.selling_price) ? (
                                                                        <div className="me-1 fw-bolder">
                                                                            <s style={{ color: "#FF9A62", fontSize: "11px", }} >
                                                                                Rs {item?.market_price}
                                                                            </s>
                                                                        </div>
                                                                    ) : ('')}
                                                                    {parseInt(item?.selling_price) > 0 ? (
                                                                        <div className="d-flex justify-content-between" >
                                                                            <div className=" text-black fw-bolder "
                                                                                style={{ fontSize: "16px", }}  >
                                                                                Rs {item?.selling_price}
                                                                            </div>
                                                                        </div>
                                                                    ) : ("")}
                                                                </div>
                                                            </div>
                                                        </Link>
                                                        <div className='d-flex justify-content-start pt-1'>
                                                            <div onClick={() => this.openLike(item)}>
                                                                <FaRegTrashAlt size={"1.1em"} color={"#6c6b6e"} />
                                                            </div>
                                                            {/* <div className="ps-1">
                                                                <RiShareForwardLine
                                                                    size={"1.6em"}
                                                                    color={"#FEA24B"}
                                                                />
                                                            </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="pe-2">
                                                        <Link to={"/single-product/" + item.id}>
                                                            <div className='mobile-liked-item-img'>
                                                                <ProgressiveImage
                                                                    delay={20}
                                                                    src={IMAGE_BASE_URL + item.image}
                                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                                >
                                                                    {(src, loading) => (
                                                                        <img
                                                                            src={src}
                                                                            className='mobile-liked-item-img' style={{
                                                                                objectFit: 'cover'
                                                                            }}
                                                                            alt='main-item'
                                                                        />
                                                                    )}
                                                                </ProgressiveImage>
                                                            </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <div className='mt-5 d-flex flex-column align-items-center'>
                                        <div className='mt-5 pt-3 d-flex flex-column align-items-center'>
                                            <img src={image} alt="no items"
                                                style={{
                                                    objectFit: 'cover',
                                                    width: '70%'
                                                }} />
                                        </div>
                                        <span className='mt-4 fs-7'>You haven't Liked any Items yet........</span>
                                    </div>)}
                            </div>
                        </React.Fragment>
                    )}
                <BottomSheet
                    open={this.state.likesheet}
                    onDismiss={() => this.setState({ likesheet: false })}
                    snapPoints={({ maxHeight }) => 0.4 * maxHeight}
                >
                    <div className="px-3">
                        <div className="px-3 d-flex flex-column align-items-center ">
                            <div className="w-100 text-dark mt-3 fs-2 text-bold">
                                Remove Item
                            </div>
                            <div className="w-100 text-dark mt-3 fs-6">
                                Do You Really Want to Remove this Item from Liked Items?
                            </div>
                        </div>
                    </div>
                    <div className="px-3 d-flex position-fixed w-100" style={{ bottom: '2%' }}>
                        <div className="filter-like-div w-50 text-center text-secondary me-1 pb-2 pt-3"
                            onClick={() => this.closeLike()}
                            style={{ border: '1px solid grey' }}>
                            <span className=''>Close</span>
                        </div>
                        <button className="filter-like-button w-50  text-white ms-1"
                            onClick={() => this.removeLike(this.state?.removeitem)}>
                            Yes
                        </button>
                    </div>
                </BottomSheet>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    likeditems: state.mobileitems.likeditems,
    user: state.user.user,
})

export default connect(mapStateToProps, { getLikedItems, removeLikedItem })(LikedItems)
