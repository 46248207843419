import React, { Component } from "react";
import PropTypes from "prop-types";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Location } from "react-iconly";
import { MdHomeFilled, MdLocationOn, MdMyLocation } from "react-icons/md";
import { BiSearch } from "react-icons/bi";

export class LocationSheet extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        Location: true,
    };

    componentDidMount() {}

    componentWillReceiveProps(nextProps) {}

    shouldComponentUpdate(nextProps, nextState) {}

    componentWillUpdate(nextProps, nextState) {}

    componentDidUpdate(prevProps, prevState) {}

    componentWillUnmount() {}

    render() {
        return (
            <div>
                <BottomSheet
                    snapPoints={({ maxHeight }) => 0.4 * maxHeight}
                    open={this.state.Location}
                    style={{ margin: 0, padding: 0 }} // Remove any default padding/margin
                >
                    <div
                        className="px-3"
                        style={{
                            backgroundColor: "#ff9a62",
                            margin: 0,
                            padding: 0,
                            borderRadius: '12px 12px 0 0',
                            height:'120px'
                        }}
                    >
                        <div className="d-flex justify-content-between ">
                            <div
                                style={{
                                    fontWeight: 700,
                                    fontSize: "18px",
                                    color: "#f3f9ff",
                                }}
                                className="mt-3"
                            >
                                <span
                                    style={{
                                        marginRight: "6px",
                                        color: "#f3f9ff",
                                    }}
                                >
                                    <MdMyLocation size={22} />
                                </span>
                                Location Permission is off
                            </div>
                            <button style={{ border:'none',borderRadius:'5px',color:'#ff9a62',backgroundColor:'#f3f9ff', fontWeight: 500 }} className="mt-3">GRANT</button>
                        </div>
                        <div
                            style={{
                                // fontWeight: 400,
                                fontSize: "14px",
                                color: "#f3f9ff",
                            }}
                        >
                            Granting location permission will ensure accurate<br/>
                            address and hassle-free delivery
                        </div>
                    </div>
                    {/* <div className="px-3 d-flex flex-column gap-3 pt-3">
                        <div style={{ fontWeight: 700, fontSize: "16px" }}>
                            Select Delivery Address
                        </div>
                        <hr />
                        <div className="d-flex  align-items-center">
                            <div>
                                {" "}
                                <MdHomeFilled style={{ fontSize: "2em" }} />
                            </div>
                            <div style={{ marginLeft: "5px" }}>
                                <div
                                    style={{
                                        fontWeight: 500,
                                        fontSize: "16px",
                                    }}
                                >
                                    Home
                                </div>
                                <div style={{  color: ' #7E7E7E'}}>dfsdgdfghfhjgjg</div>
                            </div>
                        </div>
                        <hr />

                        <div className="d-flex  align-items-center">
                            <div>
                                {" "}
                                <BiSearch
                                    set="curved"
                                    style={{
                                        fontSize: "1.7em",
                                        color: "#FEA24B",
                                    }}
                                />
                            </div>
                            <div style={{ marginLeft: "7px", color: ' #7E7E7E'}}>
                                Enter Location Manually
                            </div>
                        </div>
                    </div> */}
                </BottomSheet>
            </div>
        );
    }
}

LocationSheet.propTypes = {};

export default LocationSheet;
