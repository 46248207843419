import React, { Component } from 'react'
import { connect } from 'react-redux'
import { RiCoupon3Fill } from 'react-icons/ri';
import ProgressiveImage from 'react-progressive-image';
// import Ink from "react-ink";
import Truncate from 'react-truncate';
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api/index';
import { getStoreCategoryRecommendedStores } from '../../../../redux/mobile/storeCategory/action'

export class RecommendedStore extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        // ////// console.log(this.props,'iddd')
        this.props.getStoreCategoryRecommendedStores(this.props.id,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((res) => {
                this.setState({
                    loading: false
                })
            })
    }
    render() {
        const { recommendedstores } = this.props;
        return (
            <React.Fragment>
                {recommendedstores && recommendedstores?.data?.length > 0 && this.state.loading == false ? (
                    <div className='px-3 mt-3  '>
                        <RiCoupon3Fill className='mb-2' color='FF9A62' size={20} /> <span className='top-res-header'>Recommended For You</span>
                        <div className='d-flex flex-row align-items-center'>
                            <div className='mt-3 main-menu hidden-scroll-bar gap-3'
                                style={recommendedstores?.data?.length > 5 ?
                                    { gridTemplateColumns: 'repeat(' + recommendedstores?.data?.length + ',1fr)' } :
                                    { gridTemplateColumns: 'repeat(6,1fr)' }}>
                                {recommendedstores?.data?.map((store) =>
                                    <div>
                                        <Link to={"/single-store/" + store.id}>
                                            <div className='mt-2 position-relative'>
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={IMAGE_BASE_URL + store.image}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            style={{
                                                                objectFit: "cover",
                                                                backgroundRepeat: 'none',
                                                                height: '20vw',
                                                                width: '10vh',
                                                                borderRadius: '10px'
                                                            }}
                                                            className=""
                                                            alt="grosav"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </div>
                                            <div className='top-res-name text-center'>
                                                <Truncate lines={1}>
                                                    <span>
                                                        {store.name}
                                                    </span>
                                                </Truncate>

                                                <div className='d-inline-block'>
                                                    <div>
                                                        <span className='top-res-deli-time text-muted '>
                                                            {store.approx_time_delivery > 0 ?
                                                                <>
                                                                    {store.approx_time_delivery}Mins
                                                                </>
                                                                :
                                                                <>
                                                                    0Min
                                                                </>
                                                            }
                                                            {store.delivery_radius > 0 ?
                                                                <>
                                                                    - {store.delivery_radius}km
                                                                </>
                                                                :
                                                                <>
                                                                    - 0km
                                                                </>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                ) : (
                    ""
                )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    recommendedstores: state.mobilestorecategory.recommendedstores,
})

export default connect(mapStateToProps, { getStoreCategoryRecommendedStores })(RecommendedStore)
