import React, { Component } from "react";
import { connect } from "react-redux";
import ContentLoader from "react-content-loader";
import { getUserWalletAmount } from "../../../redux/mobile/user/action";
import { Link } from "react-router-dom";
import { RiMoneyDollarCircleLine } from 'react-icons/ri';
import {Badge} from 'react-bootstrap'
import moment from "moment";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
class Wallet extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        const { user } = this.props;
        this.props.getUserWalletAmount(user.data.auth_token);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
        // this.props.getAllHomeData()
    }
    render() {
        const { wallet } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="55"
                                y="75"
                                rx="10"
                                ry="10"
                                width="22"
                                height="22"
                            />
                            <rect
                                x="203"
                                y="95"
                                rx="8"
                                ry="8"
                                width="14"
                                height="14"
                            />
                            <rect
                                x="58"
                                y="198"
                                rx="5"
                                ry="5"
                                width="10"
                                height="10"
                            />
                            <rect
                                x="87"
                                y="75"
                                rx="60"
                                ry="60"
                                width="110"
                                height="110"
                            />
                            <rect
                                x="19"
                                y="241"
                                rx="10"
                                ry="10"
                                width="243"
                                height="30"
                            />
                            <rect
                                x="19"
                                y="278"
                                rx="10"
                                ry="10"
                                width="243"
                                height="30"
                            />
                            <rect
                                x="19"
                                y="318"
                                rx="10"
                                ry="10"
                                width="243"
                                height="30"
                            />
                            <rect
                                x="14"
                                y="499"
                                rx="10"
                                ry="10"
                                width="247"
                                height="37"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <MobileBackButtonWithCenterTitle title="Wallet" history={this.props.history} />
                        <div>
                            <div className="mobile-wallet-bg d-flex justify-content-center align-items-center">
                                <div className="mobile-wallet-round d-flex flex-column justify-content-center align-items-center mt-5">
                                    <div className="mobile-wallet-name">
                                        <span>Grosav Wallet</span>
                                    </div>
                                    <div className="mobile-wallet-total">
                                        <span>
                                            RS.
                                            {wallet.wallet}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            {wallet && wallet.transactions && wallet.transactions && wallet.transactions.length > 0 &&
                                <div className="px-4 mt-3 " style={{ paddingBottom: "20vh" }}>
                                    {wallet?.transactions?.map((transaction) => (
                                        <div className="d-flex justify-content-between align-items-center mobile-wallet-container p-2 mt-2">
                                            {/* <div>
                                    <RiMoneyDollarCircleLine size={30} style={{color: '#fd8814'}}/>
                                    </div> */}

                                            <div className="mobile-wallet-text d-flex flex-column mx-2 w-75">
                                                <span className="fw-700 fs-8">
                                                    {transaction?.meta?.description}
                                                </span>
                                                <span>
                                                    <Badge bg={transaction?.type == "deposit" ? "success text-white" : "danger text-white"}>
                                                        {transaction?.type}
                                                    </Badge>
                                                </span>
                                            </div>
                                            <div className="mobile-wallet-amt d-flex flex-column w-25">
                                                <span className="fw-700 fs-6">
                                                    {transaction?.amount / 100}
                                                </span>
                                                <span className="fw-400 text-muted" style={{ fontSize: "11px" }}>
                                                    {moment(transaction?.created_at).format('YYYY-MM-DD')}
                                                </span>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                            }

                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    wallet: state.user.userWallet,
});

export default connect(mapStateToProps, { getUserWalletAmount })(Wallet);
