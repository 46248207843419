import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { BiSearch, BiTimeFive } from "react-icons/bi";
import { BsToggleOff, BsToggleOn, BsPencilSquare } from "react-icons/bs";
import { SiSquare } from "react-icons/si";
import VendorFooter from "../../VendorFooter";
import { BottomSheet } from 'react-spring-bottom-sheet';
import { AiOutlinePlus } from 'react-icons/ai';
import axios from 'axios';
import { WEBSITE_URL } from '../../../../api';
import {
    getAllItemCategories,
    vendorToggleItemCategory,
    getVendorSearchItemCategories,
} from "../../../../redux/vendor/items/action";
import image from '../../../../assets/images/nodata.png';
import { Link } from "react-router-dom";
import moment from 'moment-timezone';

export class ItemCategories extends Component {
    state = {
        category: false,
        categories: [],
        searchcategories: [],
        showItem: [],
        category_id: 'ALL',
        searchinput: '',
        showScheduleModal: false,
        selectedItem: null,
        selectedDateTime: '',
        nextBusinessDay: null,
        nextBusinessSchedule: null,
        scheduleDataExists: false,
        customDateSelected: false, // New state for custom date selection
    };

    componentDidMount() {
        const { user } = this.props;
        this.props.getAllItemCategories(user.data.auth_token, user.data.store[0].id)
            .then((response) => {
                this.setState({ categories: response.payload.data, searchcategories: response.payload.data });
            });
    }

    toggleCategory = (category, input) => {
        const { user, setUpdatedItem } = this.props;

        if (category.is_active && category.is_schedule == 1) {
            const scheduleData = category.schedule_data ? JSON.parse(category.schedule_data) : {};
            const nextBusinessDay = this.getNextBusinessDay(); // Calculate next business day dynamically
            const nextBusinessSchedule = scheduleData[nextBusinessDay] || null;

            if (nextBusinessSchedule && nextBusinessSchedule?.length) {
                // Extract the first opening time for the next business day
                const openingTime = nextBusinessSchedule[0].open;

                // Combine the date of the next business day with the opening time in Asia/Kolkata time zone
                const nextBusinessDate = moment.tz('Asia/Kolkata');
                while (nextBusinessDate.format('dddd').toLowerCase() !== nextBusinessDay) {
                    nextBusinessDate.add(1, 'day');
                }
                const [hours, minutes] = openingTime.split(':').map(Number);
                nextBusinessDate.set({ hour: hours, minute: minutes, second: 0, millisecond: 0 });

                // Format the date to ISO 8601 in the local time zone
                const selectedDateTime = nextBusinessDate.format(); // This keeps it in the local time zone

                this.setState({
                    showScheduleModal: true,
                    selectedItem: category,
                    selectedDateTime,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: Boolean(nextBusinessSchedule),
                });
            } else {
                this.setState({
                    showScheduleModal: true,
                    selectedItem: category,
                    selectedDateTime: null,
                    nextBusinessDay,
                    nextBusinessSchedule,
                    scheduleDataExists: Boolean(nextBusinessSchedule),
                });
            }
        } else {
            this.props.vendorToggleItemCategory(category.id, input, user.user.stores[0].id)
                .then((response) => {
                    this.setState({ searchcategories: response.data.data });
                });
            setUpdatedItem(category);
        }
    };

// Example getNextBusinessDay function
getNextBusinessDay = () => {
    const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const today = new Date();
    let dayIndex = today.getDay();
    let nextBusinessDayIndex = (dayIndex + 1) % 7;

    // Assuming business days are from Monday to Friday
    while (nextBusinessDayIndex === 0 || nextBusinessDayIndex === 6) {
        nextBusinessDayIndex = (nextBusinessDayIndex + 1) % 7;
    }

    return days[nextBusinessDayIndex];
};





    handleOptionChange = (event) => {
        const isCustomDate = event.target.value === 'custom';
        this.setState({ customDateSelected: isCustomDate });
    }

    handleScheduleSubmit = () => {
        const { selectedItem, selectedDateTime } = this.state;
        const { user } = this.props;

        axios.post(WEBSITE_URL + "/add-category-reopen-time", {
            date: selectedDateTime,
            category: selectedItem?.id,
        }).then(response => {
            this.props.getAllItemCategories(user.data.auth_token, user.data.store[0].id)
                .then((response) => {
                    this.setState({ categories: response.payload.data, searchcategories: response.payload.data });
                });
        });

        this.setState({ showScheduleModal: false, selectedItem: null, selectedDateTime: '', customDateSelected: false });
    }

    render() {
        const {
            searchcategories, showScheduleModal, selectedItem, selectedDateTime,
            nextBusinessDay, nextBusinessSchedule, scheduleDataExists, customDateSelected,
        } = this.state;

        return (
            <React.Fragment>
                {/* Schedule Modal */}
                <Modal show={showScheduleModal} onHide={() => this.setState({ showScheduleModal: false })}>
                    <Modal.Header closeButton>
                        <Modal.Title>Schedule Item Reopening</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {scheduleDataExists ? (
                            <div>
                                <form>
                                    <div className="form-group">
                                        <label>Reopen Time</label>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`next-business-day-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="next-business-day"
                                                checked={!customDateSelected}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`next-business-day-radio${selectedItem?.id}`}>
                                                Next business day at {nextBusinessSchedule && nextBusinessSchedule[0].open}
                                            </label>
                                        </div>
                                        <div className="d-flex align-items-center mb-3">
                                            <input
                                                type="radio"
                                                id={`choose-time-radio${selectedItem?.id}`}
                                                name="open_time"
                                                value="custom"
                                                checked={customDateSelected}
                                                onChange={this.handleOptionChange}
                                            />
                                            <label className="ms-2" htmlFor={`choose-time-radio${selectedItem?.id}`}>
                                                I will choose the time
                                            </label>
                                        </div>
                                        {customDateSelected && (
                                            <input
                                                type="datetime-local"
                                                className="form-control"
                                                value={selectedDateTime}
                                                onChange={(e) => this.setState({ selectedDateTime: e.target.value })}
                                            />
                                        )}
                                    </div>
                                </form>
                            </div>
                        ) : (
                            <div className="text-danger fw-bold fs-7">
                                Oops... Please turn on auto schedule and add scheduled opening and closing time for this item.
                                Then only item and items will be automatically turned on for the selected date and time.
                                <br />

                                <Link to={'/vendor-item-category-schedule/' + selectedItem?.id}>

                                    <Button variant="primary" className='btn text-white inventory-browse-btn fs-6 mt-2' onClick={this.handleScheduleSubmit}>
                                        Click to add scheduled opening and closing time for your item
                                    </Button>
                                </Link>
                            </div>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => this.setState({ showScheduleModal: false })}>
                            Close
                        </Button>
                        {scheduleDataExists && (
                            <Button variant="primary" className='btn text-white inventory-browse-btn' onClick={this.handleScheduleSubmit}>
                                Save Changes
                            </Button>
                        )}
                    </Modal.Footer>
                </Modal>



                {/* Other components and JSX */}


                {/* <div className="d-flex flex-row align-items-center mt-3 searchBar py-2 px-2">
                    <input
                        type="text"
                        className="inventory-search w-100"
                        placeholder="Search for Items"
                        onChange={(event) => { this.search(event.target.value) }}
                    />
                    <span className="">
                        <BiSearch
                            set="curved"
                            style={{
                                fontSize: "2em",
                                color: "#FEA24B",
                            }}
                        />
                    </span>
                </div> */}
                <div className="inventory-add-on-count-head w-100 mt-3 px-2 py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <div className="ms-3">
                            <span style={{ fontSize: '17px', fontWeight: '600' }}>Item Categories</span>
                        </div>
                        <div className="mt-1 ms-3">
                            <span>{searchcategories?.length} Categories</span>
                        </div>
                    </div>
                    <Link to="/vendor-add-item-category">
                        <div className="py-2 px-2">
                            <button className="btn inventory-add-btn px-2">
                                <AiOutlinePlus /> ADD
                            </button>
                        </div>
                    </Link>
                </div>
                <div style={{ paddingBottom: '44vw' }}>
                    {searchcategories?.length > 0 ? (
                        <>
                            {searchcategories?.map(category =>
                                <div className="inventory-product-list mt-4">
                                    <div className="inventory-product-list-items mt-4">
                                        <div className="inventory-item-icon d-flex align-items-start justify-content-between">
                                            <div className=" d-flex">
                                                <div>
                                                    <SiSquare
                                                        set="curved"
                                                        style={{
                                                            fontSize: "9px",
                                                            color: "#32BA7C",
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-2 pt-1">
                                                    <div className="inventory-item-name">
                                                        <span>
                                                            {category.name}
                                                        </span>
                                                    </div>
                                                    <div className="inventory-item-desc mt-1">
                                                        <span>
                                                            {category.description}
                                                        </span>
                                                    </div>
                                                    <div className="inventory-item-addon-count mt-1" onClick={() => this.showItems(category.id)}>
                                                        <div>
                                                            <span className='text-blue' >
                                                                {category.items?.length} Items
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-around'>
                                                {/* {category.items?.length > 0 && ( */}
                                                {/* <div>
                                                        {category.items?.find(item => item.is_active === 1) ? (
                                                            <BsToggleOn set="curved" style={{ fontSize: "2em", color: "#59D69D", }} onClick={() => this.toggleCategory(category, 'OFF')} />
                                                        ) : (
                                                            <BsToggleOff set="curved" style={{ fontSize: "2em", }} onClick={() => this.toggleCategory(category, 'ON')} />
                                                        )}
                                                    </div> */}

                                                <div>
                                                    {category.is_active === 1 ? (
                                                        <BsToggleOn set="curved" style={{ fontSize: "2em", color: "#59D69D", }} onClick={() => this.toggleCategory(category, 'OFF')} />
                                                    ) : (
                                                        <BsToggleOff set="curved" style={{ fontSize: "2em", }} onClick={() => this.toggleCategory(category, 'ON')} />
                                                    )}
                                                </div>
                                                {/* )} */}
                                                <div>
                                                    <Link to={"/vendor-edit-item-category/" + category.id}>
                                                        <BsPencilSquare className='pt-1' style={{ fontSize: "1.6em", color: "#fea24b", }} />
                                                    </Link>
                                                </div>
                                                <div>
                                                    <Link to={"/vendor-item-category-schedule/" + category.id}>
                                                        <BiTimeFive className='pt-1' style={{ fontSize: "1.8em", color: "#fea24b", }} />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                        {category?.items?.map((item) =>
                                            <div style={{ display: this.state.showItem === category.id ? 'block' : 'none' }}>
                                                <div className="inventory-add-on-item-head d-flex justify-content-between mx-4 mt-2 ps-2 py-1 pe-2 ">
                                                    <div className='px-2 pb-1'>
                                                        <div className="inventory-item-name mt-1">
                                                            <span>{item.name}</span>
                                                        </div>
                                                        <div className='mt-1 inventory-item-price'>
                                                            <span>Rs.{item.selling_price}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className='pt-3 d-flex flex-column align-items-center'>
                                <div className='mt-3 d-flex flex-column align-items-center'>
                                    <img src={image} alt="no items"
                                        style={{
                                            objectFit: 'cover',
                                            width: '70%'
                                        }} />
                                </div>
                                <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No Categories Found !!!</span>
                            </div>
                        </>
                    )
                    }
                </div>
                <div className="d-flex justify-content-end inventory-btn pb-4 py-2 px-2" onClick={() => this.openCategoryMenu()}>
                    <button className=" btn text-white inventory-browse-btn">
                        Browse Menu
                    </button>
                </div>
                <VendorFooter active_inventory={true} />
                <BottomSheet
                    open={this.state.category}
                    onDismiss={() => this.closeCategoryMenu()}
                    snapPoints={({ maxHeight }) => 0.5 * maxHeight}
                >
                    <div className='px-3 pb-5'>
                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                            <div className="d-grid gap-2">
                                <button className="offer-sheet-btn" onClick={() => this.filterCategory('ALL')}>
                                    All
                                </button>
                                {searchcategories?.map((category) => (
                                    <button
                                        key={category.id}
                                        className={this.state?.category_id === category.id ? "offer-sheet-btn text-black border-dark" : "offer-sheet-btn"}
                                        onClick={() => this.filterCategory(category.id)}
                                    >
                                        {category.name}  {"( " + category.items.length + " Items )"}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    categories: state.items.itemcategories,
})

export default connect(mapStateToProps, {
    getAllItemCategories,
    vendorToggleItemCategory,
    getVendorSearchItemCategories
})(ItemCategories);
