import React, { Component } from "react";
import ContentLoader from "react-content-loader";
// import BackButtonHeader from "../../../../Elements/BackButtonHeader";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE, WEBSITE_BASE_URL, WEBSITE_URL } from "../../../../../api/index";
import moment from "moment";
import { applyCoupon, getUserOffers } from "../../../../../redux/mobile/mobileCoupon/action";
import Modal from "react-modal";
import couponTick from "../../../../../assets/lottie/coupon-tick.json";
import errorLot from "../../../../../assets/lottie/error.json";
import Lottie from "react-lottie";
import { AiFillInfoCircle } from "react-icons/ai";
import Confetti from "react-confetti";
import { Form, InputGroup } from "react-bootstrap";
import MobileBackButtonWithCenterTitle from "../../../../Elements/MobileBackButtonWithCenterTitle";
import noCoupon from "../../../../../assets/images/noCoupon.png";
import { BiErrorAlt, BiRefresh } from "react-icons/bi";
import { VscError } from "react-icons/vsc";
import { addProduct } from "../../../../../redux/mobile/cart/action";
import FloatCart from "../../FloatCart";

export class CouponList extends Component {
    state = {
        loading: true,
        coupons: [],
        appliedCode: null,
        show_auto_apply_modal: false,
        coupon_error: false,
        message: '',
        sedmessage: '',
    };


    componentDidMount() {
        const { user, cartProducts } = this.props;
        let store_id = localStorage.getItem("store_id");
        const ids = [];
    
        // Collect product IDs from the cart
        cartProducts.map(cp => {
            ids.push(cp?.id);
            return cp;
        });
    
        // Retrieve latitude and longitude from localStorage
        const userSetAddress = JSON.parse(localStorage.getItem("userSetAddress"));
        const latitude = userSetAddress?.latitude;
        const longitude = userSetAddress?.longitude;
    
        // Check if the user is authenticated and then make the API call
        if (user.success) {
            this.props.getUserOffers(user.data.auth_token, store_id, ids, latitude, longitude).then((response) => {
                if (response.payload.success) {
                    this.setState({ loading: false });
                }
            });
        }
    }
    
    handleInputCoupon = (e) => {
        e.preventDefault();
        this.setState({ appliedCode: e.target.value, coupon_error: false });
    };

    applyCoupon = (coupon) => {
        const { user, cartProducts, cartTotal, addProduct } = this.props;
        const token = user.success ? this.props.user.data.auth_token : null;
        let store_id = parseFloat(localStorage.getItem("store_id"));

        this.props.applyCoupon(token, coupon.code, cartProducts, cartTotal.totalPrice, store_id).then((response) => {
            if (response && response[0].payload && response[0].payload.success) {
                this.setState({ show_auto_apply_modal: true });
                if (response[0].payload.discount_type == "ITEM") {
                    if (cartProducts.find((cp) => cp.id === response[0].payload.item.id) == undefined) {
                        response[0].payload.item.quantity = 1;
                        addProduct(response[0].payload.item);
                        this.forceStateUpdate();
                    }
                }
                setTimeout(() => {
                    this.setState({ show_auto_apply_modal: false, applyCoupon: null });
                    this.props.history.push("/cart");
                }, 2000);
            } else {
                this.setState({ coupon_error: true, applyCoupon: null, message: response[0].payload.message, sedmessage: response[0].payload.sedmessage });
            }
        });
    };

    applyCouponInput = (e) => {
        e.preventDefault();
        const { user, cartProducts, cartTotal, addProduct } = this.props;
        const token = user.success ? this.props.user.data.auth_token : null;
        let store_id = parseFloat(localStorage.getItem("store_id"));

        this.props.applyCoupon(token, this.state.appliedCode, cartProducts, cartTotal.totalPrice, store_id)
            .then((response) => {
                if (response && response[0].payload && response[0].payload.success) {
                    this.setState({ show_auto_apply_modal: true });
                    if (response[0].payload.discount_type == "ITEM") {
                        if (cartProducts.find((cp) => cp.id === response[0].payload.item.id) == undefined) {
                            response[0].payload.item.quantity = 1;
                            addProduct(response[0].payload.item);
                            this.forceStateUpdate();
                        }
                    }
                    setTimeout(() => {
                        this.setState({ show_auto_apply_modal: false, applyCoupon: null });
                        this.props.history.push("/cart");
                    }, 2000);
                } else {
                    this.setState({ coupon_error: true, applyCoupon: null, message: response[0].payload.message });
                }
            });
    };

    __closeAutoApplyModal = () => {
        this.setState({ show_auto_apply_modal: false });
    };
    __openAutoApplyModal = () => {
        this.setState({ show_auto_apply_modal: true });
    };

    afterOpenModal = () => {
        // references are now sync'd and can be accessed.
    };

    __closeErrorCoupon = () => {
        this.setState({ coupon_error: false })
    }
    noCoupons = () => {
        this.setState({ noCoupons: true })
    }

    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };

    render() {
        const { user, coupons } = this.props;
        if (!user.success) {
            localStorage.setItem("LoginFrom", "OFFERS");
            return <Redirect to="/" />;
        }
        const defaultOptionsCoupons = {
            loop: true,
            autoplay: true,
            animationData: couponTick,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };
        const defaultOptionsError = {
            loop: true,
            autoplay: true,
            animationData: errorLot,
            rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
            },
        };

        const customStyles = {
            content: {
                top: "50%",
                left: "50%",
                right: "auto",
                bottom: "auto",
                marginRight: "-50%",
                transform: "translate(-50%, -50%)",
                width: "90vw",
                borderRadius: "10px",
                backgroundColor: "#fff",
                opacity: "200%",
                height: "220px",
            },
            overlay: {
                background: "#0000009e",
                // opacity: "94%",
            },
        };
        return (

            <React.Fragment>
                {/* <BackButtonHeader title={"Apply Coupon"} /> */}
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            width={420}
                            viewBox="0 0 420 800"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                        >
                            <rect x="30" y="90" rx="5" ry="5" width="70vw" height="10px" />
                            <rect x="30" y="120" rx="5" ry="5" width="30vw" height="10px" />
                            <rect x="200" y="120" rx="5" ry="5" width="5vw" height="10px" />
                            <rect x="300" y="120" rx="5" ry="5" width="10vw" height="10px" />
                            <rect x="30" y="140" rx="5" ry="5" width="30vw" height="10px" />
                            <rect x="200" y="140" rx="5" ry="5" width="5vw" height="10px" />
                            <rect x="300" y="140" rx="5" ry="5" width="10vw" height="10px" />
                            <rect x="30" y="160" rx="5" ry="5" width="30vw" height="10px" />
                            <rect x="200" y="160" rx="5" ry="5" width="5vw" height="10px" />
                            <rect x="300" y="160" rx="5" ry="5" width="10vw" height="10px" />
                            <rect x="260" y="180" rx="5" ry="5" width="20vw" height="10px" />
                            <rect x="30" y="220" rx="5" ry="5" width="80vw" height="40px" />
                            <rect x="260" y="280" rx="5" ry="5" width="20vw" height="10px" />

                            <rect x="30" y="340" rx="5" ry="5" width="70vw" height="10px" />
                            <rect x="30" y="360" rx="5" ry="5" width="30vw" height="10px" />
                            <rect x="200" y="360" rx="5" ry="5" width="5vw" height="10px" />
                            <rect x="300" y="360" rx="5" ry="5" width="10vw" height="10px" />
                            <rect x="30" y="380" rx="5" ry="5" width="30vw" height="10px" />
                            <rect x="200" y="380" rx="5" ry="5" width="5vw" height="10px" />
                            <rect x="300" y="380" rx="5" ry="5" width="10vw" height="10px" />
                            <rect x="30" y="400" rx="5" ry="5" width="30vw" height="10px" />
                            <rect x="200" y="400" rx="5" ry="5" width="5vw" height="10px" />
                            <rect x="300" y="400" rx="5" ry="5" width="10vw" height="10px" />
                            <rect x="260" y="420" rx="5" ry="5" width="20vw" height="10px" />
                            <rect x="30" y="450" rx="5" ry="5" width="80vw" height="40px" />
                            <rect x="260" y="500" rx="5" ry="5" width="20vw" height="10px" />

                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <MobileBackButtonWithCenterTitle title="Apply Coupon" history={this.props.history} />
                        {this.state.show_auto_apply_modal && (
                            <div>
                                <Confetti
                                    width={500}
                                    confettiSource={{ x: 30, y: 90, w: 500, h: 5000 }}
                                    gravity={0.3}
                                    friction={0.99}
                                    numberOfPieces={500}
                                    height={5000}
                                />
                                <Modal
                                    isOpen={this.state.show_auto_apply_modal}
                                    onAfterOpen={this.afterOpenModal}
                                    onRequestClose={this.__closeAutoApplyModal}
                                    style={customStyles}
                                    contentLabel="Example Modal"
                                >
                                    <React.Fragment>
                                        <div className="d-flex flex-column justify-content-center align-items-center">
                                            <Lottie
                                                options={defaultOptionsCoupons}
                                                height={100}
                                                width={100}
                                            />
                                            <div className="fw-bold fs-3">Woohoo!!</div>
                                            <div className="fw-normal fs-6">
                                                Coupon {coupons && coupons.code} applied
                                            </div>
                                            <div className="fw-normal fs-6">successfully</div>
                                        </div>
                                    </React.Fragment>
                                </Modal>
                            </div>
                        )}
                        <div className="px-4 pt-4">
                            {/* <div className='px-3 mt-3'> */}
                            {/* <h1 className='mobile-cart-apply px-3'>Apply Coupon</h1> */}
                            <div className='mobile-cart-coupon-card'>
                                <Form onSubmit={(e) => this.applyCouponInput(e)}>
                                    <InputGroup className="mb-3" style={{ backgroundColor: '#f4f4f4', borderRadius: '14px' }}>
                                        <Form.Control
                                            className='px-3'
                                            onChange={(e) => this.handleInputCoupon(e)}
                                            value={this.state.appliedCode}
                                            placeholder="Enter code here..."
                                            style={{ border: 'none', backgroundColor: '#f4f4f4', borderRadius: '14px' }}
                                            aria-describedby="basic-addon2"
                                            required
                                        />
                                        <div className='p-2'>
                                            <button type="submit" className='text-white btn px-4 apply-coupon-button' >
                                                Apply
                                            </button>
                                        </div>
                                    </InputGroup>
                                </Form>
                            </div>
                            {/* {this.state.appliedCode &&
                                this.state.coupon_error && (
                                    <React.Fragment>
                                        {this.props.coupons.type === "MINSUBTOTAL" ? (
                                            <div
                                                style={{ color: "red" }}
                                                className="coupon-not-loggedin-message d-flex align-items-center justify-content-center pt-10 pb-10"
                                            >
                                                <AiFillInfoCircle />
                                                {this.props.coupon.message
                                                    ? this.props.coupon.message
                                                    : " Invalid Coupon"}
                                            </div>
                                        ) : (
                                            <div
                                                style={{ color: "red" }}
                                                className="coupon-not-loggedin-message d-flex align-items-center justify-content-center pt-10 pb-10"
                                            >
                                                <AiFillInfoCircle />
                                                Invalid Coupon
                                            </div>
                                        )}
                                    </React.Fragment>
                                )} */}
                            {/* </form> */}
                            {/* <hr className="single-item-division-" /> */}
                            {coupons && coupons.coupons && coupons.coupons.length > 0 ? (
                                <div style={{ paddingBottom: '10vw' }}>
                                    <div className="d-flex p-3" style={{ backgroundColor: "#f8f8f8", borderRadius: "8px", }}  >
                                        Available Coupons
                                    </div>
                                    {coupons.coupons.map((coupon, index) => (
                                        <div className="d-flex align-items-center mt-3 p-3"
                                            style={{ backgroundColor: "#f8f8f8", borderRadius: "10px", }} >
                                            <div className="flex-shrink-1" style={{ borderRight: "1px dashed #666666" }}>
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={WEBSITE_BASE_URL + "/" + coupon.image}
                                                    placeholder={noCoupon} >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            style={{
                                                                height: "22vw", width: "22vw", objectFit: "cover", borderRadius: "10px", marginRight: "10px",
                                                            }}
                                                            alt={coupon.name}
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </div>
                                            <div className="flex-grow-1 d-flex flex-column align-items-start ps-3">
                                                <div className="fw-medium" style={{ fontSize: '1em' }}>
                                                    {coupon.name}
                                                </div>
                                                {coupon.discount_type == "ITEM" ? (
                                                    <div style={{ fontSize: '1.1em',fontWeight:'700' }}>
                                                     {coupon.item?.name} Coupon
                                                    </div>
                                                ) : coupon.discount_type == "FIXED" ? (
                                                    <div className="fw-bolder " style={{ fontSize: '1.2em' }}>
                                                        ₹ {coupon.coupon_discount} Off
                                                    </div>
                                                ) : (
                                                    <div className="fw-bolder" style={{ fontSize: '1.2em' }}>
                                                        {coupon.coupon_discount} % Off
                                                    </div>
                                                )}

                                                <div className="" style={{ color: "#c5c5c5", fontSize: '1em' }}>
                                                    Valid until{" "}
                                                    {moment(coupon.end_date).format("MMM Do YY")}
                                                </div>
                                                {/* {coupon.discount_type == "ITEM" ?
                                                    <div className="" style={{ color: "#cb2626", fontSize: '1em' }}>
                                                        Applicable to {coupon.item.name}
                                                    </div>
                                                    : null} */}

                                                <div className="d-flex align-items-center justify-content-between mt-2 w-100">
                                                    <div className="p-1 px-2" style={{ border: "1px dashed #4E4D4D", borderRadius: "10px", }} >
                                                        {coupon.code}
                                                    </div>
                                                    <div>
                                                        <button onClick={() => this.applyCoupon(coupon)} className="btn ml-2" style={{ backgroundColor: "#FD8814", color: "#fff", borderRadius: '10px' }} >
                                                            Apply Now
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <div className="text-center">
                                    <ProgressiveImage
                                        delay={20}
                                        src={noCoupon}
                                        placeholder={PLACE_HOLDER_IMAGE} >
                                        {(src, loading) => (
                                            <img
                                                src={src}
                                                style={{
                                                    width: "100%", objectFit: "cover", borderRadius: "10px", marginRight: "10px",
                                                }}
                                                alt={"grosav"}
                                            />
                                        )}
                                    </ProgressiveImage>
                                    <span className="fs-4" style={{ fontFamily: "fantasy", fontWeight: 'bold' }}>No Coupons</span>
                                </div>
                            )}
                            {this.state.noCoupons &&
                                <div className="text-center">
                                    <ProgressiveImage
                                        delay={20}
                                        src={noCoupon}
                                        placeholder={PLACE_HOLDER_IMAGE} >
                                        {(src, loading) => (
                                            <img
                                                src={src}
                                                style={{
                                                    width: "100%", objectFit: "cover", borderRadius: "10px", marginRight: "10px",
                                                }}
                                                alt={"grosav"}
                                            />
                                        )}
                                    </ProgressiveImage>
                                    <span className="fs-4" style={{ fontFamily: "fantasy", fontWeight: 'bold' }}>No Coupons</span>
                                </div>
                            }
                        </div>
                        <React.Fragment>
                            <Modal isOpen={this.state.coupon_error} style={customError} contentLabel="Example Modal">
                                <React.Fragment>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <Lottie options={defaultOptionsError} height={100} width={100} />
                                        <div className="text-center fs-6" style={{ color: '#fd8814', fontWeight: '700' }}>{this.state.message}<br />{this.state.sedmessage ? this.state.sedmessage : null}</div>
                                        <div className="text-center fs-6 py-2 try-btn" onClick={this.__closeErrorCoupon}><BiRefresh size={'1.3em'} /> Try Again</div>
                                    </div>
                                </React.Fragment>
                            </Modal>
                        </React.Fragment>
                    </React.Fragment>
                )
                }
                <FloatCart float={true} />
            </React.Fragment>

        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    coupon: state.mobileCoupon.appliedCoupon,
    cartProducts: state.cart.products,
    cartTotal: state.cart.total,
    // charges: state.cart.charges,
    coupons: state.mobileCoupon.coupons,
});

export default connect(mapStateToProps, {
    getUserOffers,
    addProduct,
    applyCoupon,
})(CouponList);


const customError = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        width: "90vw",
        borderRadius: "10px",
        backgroundColor: "#fff",
        opacity: "200%",
        height: "250px",
    },
    overlay: {
        background: "#0000009e",
        // opacity: "94%",
    },
};
