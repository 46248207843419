import React, { Component } from "react";
import { connect } from "react-redux";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import img from "../../../assets/images/mobileLogo.png";
import OtpInput from "react-otp-input";
import Ink from "react-ink";
import { BsFillShareFill, BsInfoCircle } from "react-icons/bs";
import Loading from "../../Elements/loading";
import HeadShake from "react-reveal/HeadShake";
import {
    sendOtp,
    verifyOtp,
    registerUser,
    checkReferral,
} from "./../../../redux/mobile/user/action";
import { HiOutlineChevronLeft } from "react-icons/hi";
import { Redirect } from "react-router";

export class SignUp extends Component {
    state = {
        loading: false,
        phone: "",
        name: "",
        email: "",
        email_exist: false,
        referral_code: "",
        otp_send: true,
        otp_enter: false,
        invalid_otp: false,
        ref_valid: false,
        ref_valid_message: "",
        newUser: false,
        Otp: "",
        login: true,
        referral_code: null,
    };

    handleInputPhone = (e) => {
        this.setState({ phone: e.target.value });
    };
    handleInputName = (e) => {
        this.setState({ name: e.target.value });
    };
    handleInputEmail = (e) => {
        this.setState({ email: e.target.value });
    };
    handleInputReferral = (e) => {
        this.setState({ referral_code: e.target.value });
    };

    __checkReferral = () => {
        this.props.checkReferral(this.state.referral_code).then((response) => {
            if (response.payload.success) {
                this.setState({ ref_valid: true });
            } else {
                this.setState({
                    ref_valid: false,
                    ref_valid_message: response.payload.message,
                });
            }
        });
    };

    backToEnterNumber = () => {
        this.setState({
            otp_send: true,
            otp_enter: false,
            oldAcc: false,
            Otp: "",
        });
    };

    __sendOtp = (e) => {
        // alert(e);
        // e.prevent.default();
        this.setState({ loading: true });
        this.props.sendOtp(this.state.phone).then((response) => {
            // ////// console.log(response.payload)
            if (
                response &&
                response.payload &&
                response.payload.success &&
                response.payload.message == "OLDUSER"
            ) {
                this.setState({
                    loading: false,
                    otp_send: false,
                    otp_enter: true,
                });
            } else if (
                response &&
                response.payload &&
                response.payload.success &&
                response.payload.data == "OTPSEND"
            ) {
                this.setState({
                    loading: false,
                    otp_send: false,
                    otp_enter: true,
                });
            } else {
                this.setState({ loading: true });
            }
        });
    };

    __verifyOtp = () => {
        this.setState({ loading: true });
        this.props
            .verifyOtp(this.state.phone, this.state.Otp)
            .then((response) => {
                if (response && response.payload && response.payload.success &&
                    response.payload.data && response.payload.data == "NEWUSER") {
                    this.setState({ loading: false, oldAcc: false, otp_enter: false, newUser: true, });
                } else if (response && response.payload && response.payload.success && !this.state.newUser) {
                    this.setState({ oldAcc: false });
                    if (localStorage.getItem("FromLogin") === "Cart") {
                        localStorage.setItem("loggedIn", true);
                        window.location.replace("/cart");
                    } else {
                        if (response && response.payload && response.payload.data && response.payload.data.default_address == null) {
                            window.location.replace("/set-location");
                        } else {
                            const saveUserSetAddress = new Promise(
                                (resolve) => {
                                    resolve("Address Saved");
                                    const userSetAddress =
                                        response.payload.data.default_address;
                                    localStorage.setItem(
                                        "userSetAddress",
                                        JSON.stringify(userSetAddress)
                                    );
                                }
                            );
                            saveUserSetAddress.then(() => {
                                if (localStorage.getItem("LocationFrom") === "CART") {
                                    localStorage.removeItem("LocationFrom");
                                    this.props.history.push("/cart");
                                } else {
                                    this.props.history.push("/home");
                                }
                            });
                            // window.location.replace("/home");
                        }
                    }
                } else if (response && response.payload.data === "DO NOT MATCH") {
                    this.setState({ loading: false, invalid_otp: true });
                }
            });
    };

    __handleInputOtp = (Otp) => {
        this.setState({ Otp });
    };

    starNumber = (num) => {
        let arr = Object.values(num);
        return (
            arr.splice(0, arr.length - 4).fill("*").join("") + arr.splice(-4).join("")
        );
    };

    __registerUser = () => {
        this.setState({ loading: true });
        this.props
            .registerUser(
                this.state.name,
                this.state.email,
                this.state.referral_code,
                this.state.phone
            )
            .then((response) => {
                if (response.payload.success && response.payload.data == "EMAIL ALREADY USED") {
                    this.setState({ email_exist: true, loading: false });
                } else if (response.payload.success) {
                    if (localStorage.getItem("FromLogin") === "CART") {
                        window.location.replace("/cart");
                    } else {
                        window.location.replace("/set-location");
                    }
                }
            });
    };

    render() {
        const { user } = this.props;
        if (user?.success && user?.data && user?.data?.id) {
            if (localStorage.getItem('userSetAddress') == undefined) {
                return <Redirect to={"/set-location"} />;
            } else {
                return <Redirect to={"/home"} />;
            }

        }
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <Loading />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {this.state.otp_send ? (
                            <React.Fragment>
                                <div className="mb-3">
                                    <div className="mobile-signup-style "></div>
                                    <div className="signup-logo">
                                        <img src={img} alt="" />
                                        <div className="text-center pt-5">
                                            <span
                                                style={{
                                                    fontSize: "22px",
                                                    fontWeight: "700",
                                                }}
                                            >
                                                Login To Your Account
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <Form onSubmit={(e) => this.__sendOtp(e)}>
                                    <div className="pt-5 ">
                                        <div className=" px-4 ">
                                            <InputGroup
                                                hasValidation
                                                className="b-r-10 mt-5 mobile-signup-input py-2"
                                                style={{
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                <InputGroup.Text
                                                    className="mobile-input-91 fw-bold"
                                                    id=""
                                                >
                                                    +91
                                                </InputGroup.Text>

                                                <FormControl
                                                    type="tel"
                                                    placeholder="Phone Number"
                                                    isValid={
                                                        this.state.phone &&
                                                        this.state.phone.match(
                                                            /^[6-9]{1}[0-9]{9}$/
                                                        )
                                                    }
                                                    isInvalid={
                                                        this.state.phone &&
                                                        !this.state.phone.match(
                                                            /^[6-9]{1}[0-9]{9}$/
                                                        )
                                                    }
                                                    value={this.state.phone}
                                                    required
                                                    minLength="10"
                                                    maxLength="10"
                                                    name="phone"
                                                    className="mobile-signup"
                                                    onChange={(e) =>
                                                        this.handleInputPhone(e)
                                                    }
                                                />
                                            </InputGroup>
                                            <Form.Control.Feedback type="invalid">
                                                Phone Number should start with
                                                6,7,8,9 "&" Min and Max length
                                                should be 10 digits
                                            </Form.Control.Feedback>
                                        </div>
                                        {/* <div className=" px-4 ">
                                            <InputGroup
                                                hasValidation
                                                className="b-r-10 mt-3 mobile-signup-input py-2"
                                                style={{ boxSizing: "border-box" }}
                                            >

                                                <FormControl
                                                    type="text"
                                                    placeholder="Referal Code"

                                                    name="referal"
                                                    className="mobile-signup"

                                                />

                                                <InputGroup.Text
                                                    className="text-warning"
                                                    id="" style={{ background: "#fff", border: 'none' }}
                                                >
                                                    optional
                                                </InputGroup.Text>
                                            </InputGroup>

                                        </div> */}
                                    </div>
                                    <div
                                        className="px-4 d-flex flex-column align-items-center w-100"
                                        style={{ paddingTop: "30vw" }}
                                    >
                                        {/* <Link to=""> */}
                                        <button
                                            type="submit"
                                            className="mobile-signup-btn btn py-3"
                                        >
                                            Login
                                        </button>
                                        {/* </Link> */}
                                    </div>
                                </Form>
                            </React.Fragment>
                        ) : this.state.otp_enter ? (
                            <React.Fragment>
                                <div className="w-100 mobile-add-pickup-bg">
                                    <div
                                        className="d-flex align-items-center position-fixed px-4 w-100 py-5"
                                        style={{
                                            top: "0px",
                                            zIndex: "99999999",
                                        }}
                                    >
                                        <div
                                            className="px-2 pb-1 back-button"
                                            onClick={this.backToEnterNumber}
                                        >
                                            <span className="fs-2 back-button-icon">
                                                <HiOutlineChevronLeft
                                                    style={{ color: "#DA6317" }}
                                                />
                                            </span>
                                        </div>

                                        <div className=" mt-3 mobile-back-button-title fs-5">
                                            <span className="mt-3">
                                                {this.props.title}
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="d-flex flex-column px-4 w-100"
                                        style={{ paddingTop: "18vw" }}
                                    >
                                        <div className="mobile-pickup-drop-heading">
                                            Enter 4-digit Verification code
                                        </div>
                                        <div className="code mt-2">
                                            OTP sent to +91{" "}
                                            {this.starNumber(this.state.phone)}
                                            {/* This OTP wil
                                            <br />
                                            expired in 01:30 */}
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around align-items-center mt-5">
                                    <div>
                                        <OtpInput
                                            value={this.state.Otp}
                                            onChange={this.__handleInputOtp}
                                            numInputs={4}
                                            separator={
                                                <span>
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            }
                                            isInputNum={true}
                                            inputStyle={{
                                                height: "12vw",
                                                width: "12vw",
                                                border: ".01px solid #00000030",
                                                backgroundColor: "#ffffff",
                                                outline: "none",
                                                borderRadius: "10px",
                                                color: "#000",
                                                fontSize: "1.5em",
                                                fontWeight: "500",
                                            }}
                                            focusStyle={true}
                                            shouldAutoFocus={true}
                                            required
                                        />
                                    </div>
                                </div>
                                {this.state.invalid_otp && (
                                    <HeadShake>
                                        <div className="d-flex flex-row pt-2 justify-content-center mt-3">
                                            <span className="text-danger text-center">
                                                <BsInfoCircle color={"red"} />{" "}
                                                Invalid OTP. Pls try again
                                            </span>
                                        </div>
                                    </HeadShake>
                                )}
                                <div className="fixed-bottom px-3 py-2 pb-4 w-100">
                                    <button
                                        className="footer-button btn btn py-3 w-100"
                                        style={{ border: "none" }}
                                        type="button"
                                        onClick={this.__verifyOtp}
                                    >
                                        <b style={{ color: "white" }}>
                                            Verify Otp
                                        </b>
                                    </button>
                                </div>
                            </React.Fragment>
                        ) : (
                            this.state.oldAcc && (
                                <React.Fragment>
                                    <div className="px-5">
                                        <InputGroup
                                            hasValidation
                                            className="b-r-10 mt-5 phone-input-signup py-2"
                                            style={{ boxSizing: "border-box" }}
                                        >
                                            <InputGroup.Text
                                                className="input-91 fw-bold"
                                                id=""
                                            >
                                                +91
                                            </InputGroup.Text>
                                            <FormControl
                                                type="tel"
                                                placeholder="Enter Your Phone Number"
                                                // isValid={this.state.phone && this.state.phone.match(/^[6-9]{1}[0-9]{9}$/)}
                                                // isInvalid={this.state.phone && !this.state.phone.match(/^[6-9]{1}[0-9]{9}$/)}
                                                value={this.state.phone}
                                                required
                                                disabled
                                                minLength="10"
                                                maxLength="10"
                                                name="phone"
                                                className="input-login-phone"
                                                onChange={(e) =>
                                                    this.handleInputPhone(e)
                                                }
                                            />
                                        </InputGroup>
                                        <div className="d-flex flex-row justify-content-center align-items-center py-3">
                                            <span className="fs-6 fw-semibold  text-center">
                                                You already have an account with
                                                this number
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        className="d-grid gap-2 px-2 position-fixed w-100 "
                                        style={{ bottom: "10vw" }}
                                    >
                                        <button
                                            className=" login-btn btn py-3 fs-5 fw-semibold b-r-10"
                                            onClick={() => this.restoreUser()}
                                        >
                                            {/* <Ink className="touch-icon" /> */}
                                            Restore Account
                                        </button>
                                    </div>
                                </React.Fragment>
                            )
                        )}
                        {this.state.newUser && (
                            <React.Fragment>
                                <div className="mb-3">
                                    <div className="mobile-signup-style "></div>
                                    <div className="signup-logo">
                                        <img src={img} alt="" />
                                        {/* <div className="text-center pt-5">
                                            <span style={{ fontSize: "22px", fontWeight: "700" }}  >
                                                Login To Your Account
                                            </span>
                                        </div> */}
                                    </div>
                                </div>
                                <Form onSubmit={() => this.__registerUser()}>
                                    <div className="px-5 d-flex flex-column justify-content-evenly">
                                        <div>
                                            <InputGroup
                                                hasValidation
                                                className="b-r-10 mt-5"
                                                style={{
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                {/* <InputGroup.Text className="input-91 fw-bold" id=""><BiUser /></InputGroup.Text> */}
                                                <FormControl
                                                    type="text"
                                                    placeholder="Name"
                                                    value={this.state.name}
                                                    required
                                                    autoComplete="off"
                                                    name="name"
                                                    className="py-3 phone-input-signup"
                                                    onChange={(e) =>
                                                        this.handleInputName(e)
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Enter your Name | Min
                                                    Character 3
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                        <div>
                                            <InputGroup
                                                hasValidation
                                                className="b-r-10 mt-3 "
                                                style={{
                                                    boxSizing: "border-box",
                                                }}
                                            >
                                                {/* <InputGroup.Text className="input-91 fw-bold " id=""><MdAlternateEmail /></InputGroup.Text> */}

                                                <FormControl
                                                    type="email"
                                                    placeholder="Email Id"
                                                    value={this.state.email}
                                                    required
                                                    autoComplete="off"
                                                    name="email"
                                                    className="py-3 phone-input-signup"
                                                    onChange={(e) =>
                                                        this.handleInputEmail(e)
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    Enter Valid Email
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </div>
                                        <div>
                                            <InputGroup
                                                className="mt-3"
                                                style={{
                                                    backgroundColor: "#fff",
                                                    borderRadius: "14px",
                                                }}
                                            >
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Referral Code (optional)"
                                                    value={
                                                        this.state.referral_code
                                                    }
                                                    autoComplete="off"
                                                    aria-describedby="basic-addon2"
                                                    name="referral_code"
                                                    className=" py-3 phone-input-signup"
                                                    onChange={(e) =>
                                                        this.handleInputReferral(
                                                            e
                                                        )
                                                    }
                                                />
                                                {/* <div className='p-2' style={{ backgroundColor: '#fff' }}>
                                                    <div className='text-white btn px-4 apply-coupon-button' onClick={this.__checkReferral}>
                                                        Apply
                                                    </div>
                                                </div> */}
                                            </InputGroup>
                                        </div>
                                    </div>
                                    {this.state.email_exist && (
                                        <HeadShake>
                                            <div className="d-flex flex-row pt-2 justify-content-center">
                                                <span className="text-danger text-center">
                                                    <BsInfoCircle
                                                        color={"red"}
                                                    />{" "}
                                                    Email Already Exist
                                                </span>
                                            </div>
                                        </HeadShake>
                                    )}

                                    <div
                                        className="d-grid gap-2 px-4 position-fixed w-100 "
                                        style={{ bottom: "10vw" }}
                                    >
                                        <button
                                            type="submit"
                                            className="position-relative login-btn py-3 fs-5 fw-semibold b-r-15"
                                            style={{ boxSizing: "border-box" }}
                                        >
                                            <Ink className="touch-icon" />
                                            Let's Get Started
                                        </button>
                                    </div>
                                </Form>
                            </React.Fragment>
                        )}
                        {/* {!this.state.newUser && !this.state.otp_enter && (
                            <div className="d-flex flex-row justify-content-center align-items-center my-3 pb-3">
                                {localStorage?.getItem("userSetAddress") ==
                                    null ? (
                                    <Link
                                        to="/set-location"
                                        className="text-dark text-decoration-underline"
                                    >
                                        <div className="position-relative px-1 b-r-10">
                                            <Ink className="touch-ink" />
                                            <span className="fw-bolder fs-6">
                                                Skip Login
                                            </span>
                                        </div>
                                    </Link>
                                ) : (
                                    <Link
                                        to="/home"
                                        className="text-dark text-decoration-underline"
                                    >
                                        <div className="position-relative px-1 b-r-10">
                                            <Ink className="touch-ink" />
                                            <span className="fw-bolder fs-6">
                                                Skip Login
                                            </span>
                                        </div>
                                    </Link>
                                )}
                            </div>
                        )} */}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
});

export default connect(mapStateToProps, {
    sendOtp,
    verifyOtp,
    registerUser,
    // checkReferral
})(SignUp);
