import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButton from "../../Elements/MobileBackButton";
import { Link } from 'react-router-dom';
import ContentLoader from "react-content-loader";
import { getMyCoupon } from "../../../redux/mobile/mobileCoupon/action";
import ProgressiveImage from 'react-progressive-image'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE, WEBSITE_BASE_URL } from './../../../api/index';
import noCoupon from "../../../assets/images/noCoupon.png";
import { MdEditNote } from "react-icons/md";
import Truncate from "react-truncate";
import { RiCoupon3Fill } from "react-icons/ri";
export class MyCoupons extends Component {
    state = {
        loading: false,
        myCoupon: [],
    };
    componentDidMount() {
        this.setState({ loading: true });
        const { user } = this.props;
        this.props.getMyCoupon(user.data.auth_token, JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success)
                this.setState({ loading: false, myCoupon: response.payload.data });
        });
    }
    render() {
        const { myCoupon } = this.state;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="10" y="16" rx="8" ry="8" width="26" height="34" />
                            <rect x="15" y="65" rx="8" ry="8" width="119" height="20" />
                            <rect x="15" y="98" rx="8" ry="8" width="243" height="89" />
                            <rect x="15" y="206" rx="8" ry="8" width="243" height="89" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="mobile-coupon-bg py-5">
                            <div> <MobileBackButton history={this.props.history} />  </div>
                            <div className="mt-5 px-4"><span className="mobile-coupon-header">My Coupons</span></div>
                            {myCoupon && myCoupon.length > 0 ? (
                                <div className="d-flex flex-column justify-content-center mt-4 px-3" >
                                    {myCoupon && myCoupon.length > 0 && myCoupon.map((coupon, index) =>
                                        <Link to={"/single-coupon/" + coupon.id}>
                                            <div className='d-flex flex-row mt-2 mb-2 w-100' style={{ boxShadow: '0px 0px 10px 0px #dddddd', borderRadius: '15px' }}>

                                                <div className="" style={{ backgroundColor: '#fd8814', height: '36vw', width: '10vw', borderRadius: '15px 0px 0px 15px' }}>
                                                    <div className="vendor-coupon-code">
                                                        {coupon.discount_type === "FIXED" ? "Rs." + coupon.coupon_discount : coupon.coupon_discount + "%"} OFF
                                                    </div>
                                                </div>
                                                <div className="flex-grow-1 position-relative w-100 coupon-borders" >
                                                    <ProgressiveImage
                                                        delay={20}
                                                        src={IMAGE_BASE_URL + "/" + coupon.image}
                                                        placeholder={PLACE_HOLDER_IMAGE}
                                                    >
                                                        {(src, loading) => (
                                                            <img
                                                                className="mobile-home-cpn-img" style={{
                                                                    objectFit: 'cover',
                                                                    width: '100%'
                                                                }}
                                                                src={src}
                                                                alt='main-item'
                                                            />
                                                        )}

                                                    </ProgressiveImage>
                                                    {/* <button className='cpn-btn position-absolute' style={{ top: '2px', right: '2px' }}> <RiCoupon3Fill /> {coupon.code}</button> */}

                                                    <div className="position-absolute px-2 py-1 w-100" style={{ top: '0', }}>
                                                        <div className="d-flex flex-row align-items-center justify-content-between px-2 py-1" style={{ backgroundColor: '#fff', borderRadius: ' 12px ' }}>
                                                            <div className="flex-grow-1 ms-2 py-2" style={{ color: '#000', fontWeight: '750', fontSize: '1.1em' }}>
                                                                <Truncate lines={1}>
                                                                    {coupon.name ? coupon.name : '--not given--'}
                                                                </Truncate>
                                                            </div>
                                                            <div className="px-2 py-1 ms-3" style={{ backgroundColor: '#fd8814', borderRadius: '10px ', color: '#fff' }} >
                                                                <RiCoupon3Fill /> {coupon.code}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    )}
                                </div>
                            ) : (
                                <div className="d-flex flex-column align-items-center  mt-5" >
                                    <ProgressiveImage delay={20} src={noCoupon}
                                        placeholder={PLACE_HOLDER_IMAGE} >
                                        {(src, loading) => (
                                            <img src={src} style={{ width: "80%", objectFit: "cover", borderRadius: "10px", marginRight: "10px", }} alt={"grosav"} />
                                        )}
                                    </ProgressiveImage>
                                    <span className="fs-4" style={{ fontFamily: "fantasy", fontWeight: 'bold' }}>No Coupons Available</span>
                                </div>
                            )}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}
const mapStateToProps = (state) => ({
    user: state.user.user,
    myCoupon: state.mobileCoupon.myCoupons,
});
export default connect(mapStateToProps, { getMyCoupon })(MyCoupons);
