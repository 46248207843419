import {
    LOGOUT_USER,
    GET_USER,
    GET_ORDER,
    LOGIN_STORE_USER,
    VIEW_CITIES,
    TOGGLE_SINGLE_STORE,
    GET_USER_STORES,
    GET_TOGGLE_DISPLAY_TOKEN,
    GET_STORE_USERS,
    GET_USER_PASSWORD,
    GET_SCHEDULE_DATE,
    ADD_SCHEDULE_DATE,
    GET_STORE_CATEGORIES,
    GET_CATEGORY_SCHEDULE_DATE,
    ADD_CATEGORY_SCHEDULE_DATE,
    GET_ORDER_STATUS,
    GET_SEARCH_ORDERS,
    EXPORT_DATE_WISE_SALES,
    GET_EXPORTED_DATE_WISE_SALES,
    GET_STORE_RATING,
    CHANGE_ITEM_SCHEDULE_STATUS,
    CHANGE_STORE_SCHEDULE_STATUS,
    SEND_STORE_USER_OTP,
    LOGIN_OTP_STORE_USER
} from "./actionType";
import {
    GET_USER_URL,
    GET_ORDER_URL,
    LOGIN_STORE_USER_URL,
    VIEW_ALL_CITIES_URL,
    VIEW_ALL_STORE_CATEGORIES_URL,
    TOGGLE_SINGLE_STORE_URL,
    GET_USER_STORES_URL,
    GET_VENDOR_REPORT_DATA_URL,
    GET_USER_PASSWORD_URL,
    GET_TOGGLE_DISPLAY_TOKEN_URL,
    CHANGE_PREPARATION_TIME_URL,
    GET_STORE_USERS_URL,
    GET_SCHEDULE_DATE_URL,
    ADD_SCHEDULE_DATE_URL,
    GET_STORE_CATEGORIES_URL,
    GET_CATEGORY_SCHEDULE_DATE_URL,
    ADD_CATEGORY_SCHEDULE_DATE_URL,
    GET_ORDER_STATUS_URL,
    GET_SEARCH_ORDERS_URL,
    EXPORT_DATE_WISE_SALES_URL,
    GET_EXPORTED_DATE_WISE_SALES_URL,
    DELETE_USER_URL,
    DELETE_DEVICE_TOKEN_URL,
    GET_STORE_RATING_URL,
    CHANGE_ITEM_SCHEDULE_STATUS_URL,
    CHANGE_STORE_SCHEDULE_STATUS_URL,
    LOGIN_OTP_STORE_USER_URL,
    SEND_STORE_USER_OTP_URL
} from "../../../api";

import Axios from "axios";

export const loginStoreUser = (email, password) => (dispatch) => {
    return Axios.post(LOGIN_STORE_USER_URL, {
        email: email,
        password: password,
    })
        .then((response) => {
            const store_user = response.data;
            return dispatch({ type: LOGIN_STORE_USER, payload: store_user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const sendOtp = (phone) => (dispatch) => {
    return Axios.post(SEND_STORE_USER_OTP_URL, { phone }).then((response) => {
        const user = response.data;
        return dispatch({ type: SEND_STORE_USER_OTP, payload: user });
    })
        .catch(function (error) {
            // console.log(error);
        });
};

export const verifyOtp = (phone, otp) => (dispatch) => {
    return Axios.post(LOGIN_OTP_STORE_USER_URL, {
        phone, otp
    })
        .then((response) => {
            const user = response.data;
            // // console.log(response)
            return dispatch({ type: LOGIN_OTP_STORE_USER, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};


export const getVendorAccountDetails = (token) => (dispatch) => {
    return Axios.post(GET_USER_URL, {
        token
    })
        .then((response) => {
            let user = response.data;
            return dispatch({
                type: GET_USER,
                payload: user
            })

        })
}

export const logoutUser = (token) => (dispatch) => {
    const user = [];
    // console.log(user, 'logout action')
    return Axios.post(DELETE_DEVICE_TOKEN_URL, { token }).then((response) => {

        // console.log(response)
        if (response.data === 'success') {
            return [
                dispatch({
                    type: LOGOUT_USER,
                    payload: user,
                }),

            ];
        } else {
            return "Api error"
        }
    })

};

export const getUserStore = (token) => (dispatch) => {
    return Axios.post(GET_USER_STORES_URL, { token })
        .then((response) => {
            let store = response.data;
            return dispatch({
                type: GET_USER_STORES,
                payload: store
            })

        })
}

export const getStoreUsers = (token, store_id) => (dispatch) => {
    return Axios.post(GET_STORE_USERS_URL, { token, store_id })
        .then((response) => {
            let store = response.data;
            return dispatch({
                type: GET_STORE_USERS,
                payload: store
            })

        })
}

export const getScheduleDate = (token, store_id) => (dispatch) => {
    return Axios.post(GET_SCHEDULE_DATE_URL, { token, store_id })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: GET_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}

export const addScheduleDate = (token, store_id, schedule_data) => (dispatch) => {
    return Axios.post(ADD_SCHEDULE_DATE_URL, { token, store_id, schedule_data })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: ADD_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}

export const getStoreCategories = (token, store_id) => (dispatch) => {
    return Axios.post(GET_STORE_CATEGORIES_URL, { token, store_id })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: GET_STORE_CATEGORIES,
                payload: schedule_date
            })
        })
}
export const getCategoryScheduleDate = (token, catetegory_id) => (dispatch) => {
    return Axios.post(GET_CATEGORY_SCHEDULE_DATE_URL, { token, catetegory_id })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: GET_CATEGORY_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}

export const addCategoryScheduleDate = (token, catetegory_id, schedule_data) => (dispatch) => {
    return Axios.post(ADD_CATEGORY_SCHEDULE_DATE_URL, { token, catetegory_id, schedule_data })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: ADD_CATEGORY_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}

export const changePreparationTime = (token, preparation_time) => (dispatch) => {
    return Axios.post(CHANGE_PREPARATION_TIME_URL, { token, preparation_time })

}

export const getVendorOrderDetails = (token) => (dispatch) => {
    return Axios.post(GET_ORDER_URL, {
        token,
    })
        .then((response) => {
            let user = response.data;
            return dispatch({
                type: GET_ORDER,
                payload: user
            })
        })
}

export const getSearchOrders = (token, order_id) => (dispatch) => {
    return Axios.post(GET_SEARCH_ORDERS_URL, {
        token,
        order_id
    })
        .then((response) => {
            let user = response.data;
            return dispatch({
                type: GET_SEARCH_ORDERS,
                payload: user
            })
        })
}

export const viewAllCities = () => () => {
    return Axios.post(VIEW_ALL_CITIES_URL)
        .catch(function (error) {
            // console.log(error);
        });
};
export const viewAllStoreCategories = () => () => {
    return Axios.post(VIEW_ALL_STORE_CATEGORIES_URL)
        .catch(function (error) {
            // console.log(error);
        });
};
export const getVendorReportData = (token, from, to) => () => {
    return Axios.post(GET_VENDOR_REPORT_DATA_URL, {
        token,
        from,
        to
    })
        .catch(function (error) {
            // console.log(error);
        });
};

export const getUserPassword = (token, password, new_password) => (dispatch) => {
    return Axios.post(GET_USER_PASSWORD_URL, {
        token, password, new_password
    })
        .then((response) => {
            const store_user = response.data;
            return dispatch({ type: GET_USER_PASSWORD, payload: store_user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const toggleSingleStore = (token) => (dispatch) => {
    return Axios.post(TOGGLE_SINGLE_STORE_URL, { token })
        .then((response) => {
            const store = response.data;
            return dispatch({ type: TOGGLE_SINGLE_STORE, payload: store });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const toggleDisplayOrder = (token) => (dispatch) => {
    return Axios.post(GET_TOGGLE_DISPLAY_TOKEN_URL, { token })
        .then((response) => {
            const store = response.data;
            return dispatch({ type: GET_TOGGLE_DISPLAY_TOKEN, payload: store });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const getOngoingOrders = (token) => (dispatch) => {
    return Axios.post(GET_ORDER_STATUS_URL, { token })
        .then((response) => {
            const store = response.data;
            return dispatch({
                type: GET_ORDER_STATUS,
                payload: store
            });
        })
        .catch(function (error) {
            // console.log(error);
        })
};
export const exportDateWise = (token, start_date, end_date) => (dispatch) => {
    return Axios.post(EXPORT_DATE_WISE_SALES_URL, { token, start_date, end_date })
        .then((response) => {
            const store = response.data;
            return dispatch({
                type: EXPORT_DATE_WISE_SALES,
                payload: store
            });
        })
        .catch(function (error) {
            // console.log(error);
        })
};
export const getExportedDateWise = (token, start_date, end_date) => (dispatch) => {
    return Axios.post(GET_EXPORTED_DATE_WISE_SALES_URL, { token, start_date, end_date })
        .then((response) => {
            const store = response.data;
            return dispatch({
                type: GET_EXPORTED_DATE_WISE_SALES,
                payload: store
            });
        })
        .catch(function (error) {
            // console.log(error);
        })
};
export const getStoreRating = (token, id) => (dispatch) => {
    return Axios.post(GET_STORE_RATING_URL, { token, id })
        .then((response) => {
            const rating = response.data;
            return dispatch({
                type: GET_STORE_RATING,
                payload: rating
            });
        })
        .catch(function (error) {
            // console.log(error);
        })
};

export const changeItemScheduleStatus = (token, id) => (dispatch) => {
    return Axios.post(CHANGE_ITEM_SCHEDULE_STATUS_URL, { token, id })
        .then((response) => {
            const schedule = response.data;
            return dispatch({
                type: CHANGE_ITEM_SCHEDULE_STATUS,
                payload: schedule
            });
        })
        .catch(function (error) {
            // console.log(error);
        })
};

export const changeStoreScheduleStatus = (token, id) => (dispatch) => {
    return Axios.post(CHANGE_STORE_SCHEDULE_STATUS_URL, { token, id })
        .then((response) => {
            const schedule = response.data;
            return dispatch({
                type: CHANGE_STORE_SCHEDULE_STATUS,
                payload: schedule
            });
        })
        .catch(function (error) {
            // console.log(error);
        })
};

// export const logoutUser = () => (dispatch) => {
//     const user = [];
//     // console.log(user, 'logout action')
//     //remove geoLocation and userSetAddress
//     // localStorage.removeItem("userSetAddress");
//     // localStorage.removeItem("geoLocation");
//     // localStorage.removeItem("main_items");
//     localStorage.removeItem("lastSavedNotificationToken");

//     return [
//         dispatch({
//             type: LOGOUT_USER,
//             payload: user,
//         }),

//     ];
// };
