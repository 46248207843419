import {
    GET_CART_PRODUCTS,
    ADD_PRODUCT,
    REMOVE_PRODUCT,
    UPDATE_CART,
    UPDATE_CART_ITEMS,
    CALCULATE_STORE_MAX_DISTANCE,
    CALCULATE_STORE_STORE_CHARGE_TAX,
    GET_DELIVERY_SLAB,
    UPDATE_CURRENT_DELIVERY_SLAB,
    UPDATE_NEXT_DELIVERY_SLAB,
    PRODUCT_STORE,
    PRESCRIPTION_IMAGE,
} from "./actionType";
import {
    GET_CART_PRODUCTS_URL,
    CALCULATE_STORE_MAX_DISTANCE_URL,
    GET_DELIVERY_SLAB_URL,
    GET_CALCULATE_STORE_STORE_CHARGE_TAX,
    ADD_PRESCRIPTION_IMAGE_URL,
    REMOVE_PRESCRIPTION_IMAGE_URL
} from "../../../api/index";
import Axios from "axios";
import { formatPrice } from "../../../components/Helpers/formatPrice";

export const addProduct = (product) => (dispatch, getState) => {
    const { cart } = getState();
    dispatch({ type: ADD_PRODUCT, payload: product });
    // dispatch({ type: PRODUCT_STORE, payload: product.store });
};

export const updateStore = (store) => (dispatch) => {
    localStorage.setItem('free_delivery_distance', store.free_base_delivery_distance_within)
    localStorage.setItem('free_delivery_total', store.free_delivery_on_above_subtotal)
    return dispatch({ type: PRODUCT_STORE, payload: store });
};

export const removeProduct = (product) => (dispatch) => {
    return dispatch({ type: REMOVE_PRODUCT, payload: product });
};
// export const saveDeliveryCharge = (charge) => (dispatch) => {
//     return dispatch({ type: SAVE_DELIVERY_CHARGE, payload: charge });
// };
export const updateCart = (cartProducts, callback) => (dispatch) => {
    let productQuantity = cartProducts.reduce((sum, p) => {
        // sum += p.quantity;
        sum++;
        return sum;
    }, 0);
    let totalProductPrice = cartProducts.reduce((sum, p) => {
        if (p.deal_price > 0) {
            let price = 0;
            if (p?.dealofdays?.length > 0) {
                price = p?.deal_price - (p?.deal_price * parseFloat(p.dealofdays[0].offer) / 100)
                sum += price * p.quantity;
            } else {
                sum += p?.deal_price * p.quantity;
            }
            sum = parseFloat(sum);
            formatPrice(sum);

            let totalAddonPrice = 0
            if (p.selectedcombos && p.selectedcombos.length > 0) {
                totalAddonPrice += p.selectedcombos.reduce((totalAddonPrice, p) => {
                    totalAddonPrice += parseFloat(p.price);
                    totalAddonPrice = parseFloat(totalAddonPrice) * p.quantity;
                    return totalAddonPrice
                }, 0);
            } else if (p.selectedaddons && p.selectedaddons.length > 0) {
                totalAddonPrice += p.selectedaddons.reduce((totalAddonPrice, p) => {
                    totalAddonPrice += parseFloat(p.price * p.quantity);
                    totalAddonPrice = parseFloat(totalAddonPrice) * p.quantity;
                    return totalAddonPrice
                }, 0);
                if (p?.dealofdays?.length > 0) {
                    totalAddonPrice = totalAddonPrice - (totalAddonPrice * parseFloat(p.dealofdays[0].offer) / 100)
                }
            }

            if(!p?.selectedaddons){
                sum += price;
            }

            let totalPrice = sum + totalAddonPrice;
            return totalPrice;
        } else {


            if (p.is_free_item) {
                sum = parseFloat(sum);
                formatPrice(sum);
            } else {

                let selling_price = 0;
                let price = 0;
                if (p?.dealofdays?.length > 0) {
                    selling_price = parseFloat(p.selling_price) - (parseFloat(p.selling_price) * parseFloat(p.dealofdays[0].offer) / 100)
                    price =  parseFloat(selling_price) * p.quantity;
                } else {
                    price = parseFloat(p.selling_price) * p.quantity;

                }

                if(!p?.selectedaddons){
                    sum += price;
                }

                sum = parseFloat(sum);
                formatPrice(sum);
                // // console.log('summmmm :', sum);
            }

            let totalAddonPrice = 0

            if (p.selectedcombos && p.selectedcombos.length > 0) {
                totalAddonPrice += p.selectedcombos.reduce((totalAddonPrice, ap) => {
                    totalAddonPrice += parseFloat(ap.price * p.quantity);
                    totalAddonPrice = parseFloat(totalAddonPrice);
                    return totalAddonPrice
                }, 0);
            } else if (p.selectedaddons && p.selectedaddons.length > 0) {

                totalAddonPrice += p.selectedaddons.reduce((totalAddonPrice, ap) => {

                    totalAddonPrice += parseFloat((parseFloat(ap.price) + parseFloat(p.selling_price)) * ap.quantity);
                    // // console.log(totalAddonPrice,"totalAddonPrice");
                    // // console.log(p.selling_price,"p.selling_price");
                    // // console.log(parseFloat(sum),"parseFloat(sum)");

                    // if (parseFloat(totalAddonPrice) > parseFloat(sum)) {
                       // totalAddonPrice = parseFloat(totalAddonPrice) - parseFloat(sum);

                    // } else {
                        // totalAddonPrice = parseFloat(sum) - parseFloat(totalAddonPrice);

                    // }
                    return totalAddonPrice
                }, 0);
                if (p?.dealofdays?.length > 0) {

                    totalAddonPrice = totalAddonPrice - (totalAddonPrice * parseFloat(p.dealofdays[0].offer) / 100)
                    // console.log(totalAddonPrice,'here...')
                }
            }
            // // console.log({"totalAddonPrice": totalAddonPrice,"sum" :sum})

            let totalPrice = sum + totalAddonPrice;
            // // console.log('---------------');
            // // console.log('main sum : ', sum);
            // // console.log('main totalAddonPrice : ', totalAddonPrice);
            // // console.log('---------------');

            return totalPrice;
        }

    }, 0);

    // // console.log(totalProductPrice, 'totalProductPrice')
    let totalDiscount = cartProducts.reduce((sum, p) => {
        if (p.deal_price > 0) {
            if (p.selling_price && p.selling_price > p.deal_price) {
                if (p?.dealofdays?.length > 0) {
                    let price = 0;
                    price = (p.selling_price * parseFloat(p.dealofdays[0].offer) / 100)
                    sum += (price - p.deal_price) * p.quantity;
                } else {
                    sum += (p.selling_price - p.deal_price) * p.quantity;
                }
            }
            if (p?.dealofdays?.length > 0) {
                sum += (parseFloat(sum) * parseFloat(p.dealofdays[0].offer) / 100);
                let totalAddonPrice = 0;
                if (p.selectedcombos && p.selectedcombos.length > 0) {
                    totalAddonPrice += p.selectedcombos.reduce((totalAddonPrice, ap) => {
                        totalAddonPrice += parseFloat(ap.price * p.quantity);
                        // // console.log(totalAddonPrice, 'totalAddonPrice', p.quantity)
                        totalAddonPrice = parseFloat(totalAddonPrice);
                        return totalAddonPrice
                    }, 0);
                } else if (p.selectedaddons && p.selectedaddons.length > 0) {
                    totalAddonPrice += p.selectedaddons.reduce((totalAddonPrice, ap) => {
                        totalAddonPrice += parseFloat(ap.price * ap.quantity);
                        // // console.log(totalAddonPrice, 'totalAddonPrice', p.quantity)
                        totalAddonPrice = parseFloat(totalAddonPrice);
                        return totalAddonPrice
                    }, 0);
                }
                if (p?.dealofdays?.length > 0) {
                    sum += totalAddonPrice * parseFloat(p.dealofdays[0].offer) / 100;
                }
            }
            sum = parseFloat(sum);
            formatPrice(sum);
            return sum;
        } else {
            if (p.market_price && p.market_price > p.selling_price) {
                sum += (p.market_price - p.selling_price) * p.quantity;
                if (p?.dealofdays?.length > 0) {
                    let price = 0;
                    price = p.selling_price * parseFloat(p.dealofdays[0].offer) / 100;
                    sum += (price - p.deal_price) * p.quantity;
                    sum += (p.market_price - price) * p.quantity;
                } else {
                    sum += (p.market_price - p.selling_price) * p.quantity;
                }
            }
            if (p?.dealofdays?.length > 0) {
                sum += (parseFloat(sum) * parseFloat(p.dealofdays[0].offer) / 100);
                let totalAddonPrice = 0;
                if (p.selectedcombos && p.selectedcombos.length > 0) {
                    totalAddonPrice += p.selectedcombos.reduce((totalAddonPrice, ap) => {
                        totalAddonPrice += parseFloat(ap.price * p.quantity);
                        // // console.log(totalAddonPrice, 'totalAddonPrice', p.quantity)
                        totalAddonPrice = parseFloat(totalAddonPrice);
                        return totalAddonPrice
                    }, 0);
                } else if (p.selectedaddons && p.selectedaddons.length > 0) {
                    totalAddonPrice += p.selectedaddons.reduce((totalAddonPrice, ap) => {
                        totalAddonPrice += parseFloat(ap.price * ap.quantity);
                        // // console.log(totalAddonPrice, 'totalAddonPrice', p.quantity)
                        totalAddonPrice = parseFloat(totalAddonPrice);
                        return totalAddonPrice
                    }, 0);
                }
                if (p?.dealofdays?.length > 0) {
                    sum += totalAddonPrice * parseFloat(p.dealofdays[0].offer) / 100;
                }
            }
            sum = parseFloat(sum);
            formatPrice(sum);
            return sum;
        }
    }, 0);
    // // console.log("Total price Gr: " + totalProductPrice);

    let cartTotal = {
        productQuantity,
        totalPrice: totalProductPrice,
        totalDiscount,
    };
    if (callback) callback();
    dispatch({
        type: UPDATE_CART,
        payload: cartTotal,
    });
};

export const getCartProducts = (latitude, longitude, store_id) => (dispatch) => {
    return Axios.post(GET_CART_PRODUCTS_URL, {
        latitude, longitude, store_id
    }).then((response) => {
        let items = response.data;
        //// console.log(response);
        return dispatch({ type: GET_CART_PRODUCTS, payload: items });
    });
};


export const calcualteStoreMaxDistance = (id, products, address) => (
    dispatch
) => {
    return Axios.post(CALCULATE_STORE_MAX_DISTANCE_URL, {
        id,
        products,
        address,
    })
        .then((response) => {
            const max_distance = response.data;
            return dispatch({
                type: CALCULATE_STORE_MAX_DISTANCE,
                payload: max_distance,
            });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const calculateStoreChargeTax = (products) => (dispatch) => {
    return Axios.post(GET_CALCULATE_STORE_STORE_CHARGE_TAX, {
        products,
    })
        .then((response) => {
            const charges = response.data;
            return dispatch({
                type: CALCULATE_STORE_STORE_CHARGE_TAX,
                payload: charges,
            });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const getDeliverySlab = (city_id, distance) => (dispatch) => {
    return Axios.post(GET_DELIVERY_SLAB_URL, {
        city_id,
        distance,
    })
        .then((response) => {
            const slabs = response.data;
            return dispatch({ type: GET_DELIVERY_SLAB, payload: slabs });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const updateSlabDetails = (current_slab, next_slab) => (dispatch) => {
    // // console.log(current_slab)
    dispatch({ type: UPDATE_CURRENT_DELIVERY_SLAB, payload: current_slab });
    dispatch({ type: UPDATE_NEXT_DELIVERY_SLAB, payload: next_slab });
};
export const addPrescription = (formData) => (dispatch) => {
    return Axios.post(ADD_PRESCRIPTION_IMAGE_URL, formData)
        .then((response) => {
            const image = response.data;
            return dispatch({ type: PRESCRIPTION_IMAGE, payload: image });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const prescriptionDelete = (token, id, type) => (dispatch) => {
    return Axios.post(REMOVE_PRESCRIPTION_IMAGE_URL, { token, id, type })
        .then((response) => {
            const image = response.data;
            return dispatch({ type: PRESCRIPTION_IMAGE, payload: image });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const clearPrescriptionImage = () => (dispatch) => {
    let data = [];
    return dispatch({ type: PRESCRIPTION_IMAGE, payload: data });
};

export const clearCartItems = () => (dispatch) => {
    let data = [];
    return dispatch({ type: UPDATE_CART_ITEMS, payload: data });
};

export const clearCart = () => (dispatch) => {
    let data = [];
    return dispatch({ type: UPDATE_CART, payload: data });
};

