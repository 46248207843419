import React, { Component } from "react";
import { connect } from "react-redux";
import BackButtonOnly from "../../Elements/BackButtonOnly";
import crab from "../../../assets/images/crab.png";
import Vector from "../../../assets/images/Vector.png";
import { Rating } from "react-simple-star-rating";
import { FiEdit } from "react-icons/fi";
import ContentLoader from "react-content-loader";

export class DetailMenu extends Component {
    state = {
        loading: false,
    };
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
        // this.props.getAllHomeData()
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="18"
                                y="32"
                                rx="8"
                                ry="8"
                                width="27"
                                height="28"
                            />
                            <rect
                                x="18"
                                y="100"
                                rx="8"
                                ry="8"
                                width="100"
                                height="22"
                            />
                            <rect
                                x="18"
                                y="134"
                                rx="8"
                                ry="8"
                                width="176"
                                height="8"
                            />
                            <rect
                                x="18"
                                y="180"
                                rx="8"
                                ry="8"
                                width="245"
                                height="159"
                            />
                            <rect
                                x="18"
                                y="360"
                                rx="8"
                                ry="8"
                                width="85"
                                height="7"
                            />
                            <rect
                                x="58"
                                y="419"
                                rx="8"
                                ry="8"
                                width="168"
                                height="20"
                            />
                            <rect
                                x="71"
                                y="450"
                                rx="8"
                                ry="8"
                                width="130"
                                height="10"
                            />
                            <rect
                                x="18"
                                y="467"
                                rx="8"
                                ry="8"
                                width="98"
                                height="19"
                            />
                            <rect
                                x="14"
                                y="498"
                                rx="8"
                                ry="8"
                                width="254"
                                height="30"
                            />
                            <rect
                                x="19"
                                y="534"
                                rx="8"
                                ry="8"
                                width="246"
                                height="31"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <div className="pt-3 px-1">
                            <BackButtonOnly />{" "}
                        </div>

                        <div className="px-4">
                            <div
                                className="mobile-runningorders"
                                style={{
                                    marginTop: "130px",
                                    fontWeight: "725",
                                    fontSize: "25px",
                                    lineHeight: "131.02%",
                                }}
                            >
                                My Orders
                            </div>
                            <div
                                className="py-1"
                                style={{
                                    fontweight: "410",
                                    fontSize: "12px",
                                    lineHeight: "180.52%",
                                }}
                            >
                                Details about your orders and previous orders
                            </div>
                        </div>

                        {/* card  starts*/}
                        <div className="mobile-runningorders-card mx-3 mt-5">
                            <div className="p-3">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row justify-content-between">
                                        <img src={crab} alt="crab" />
                                        <div className="ps-3">
                                            <span>Spicy Fresh Crab</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="mobile-detailmenu-deliversdbtn px-2 py-1">
                                            <span> Delivered</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="d-flex justify-content-between mt-3">
                                        <div
                                            style={{
                                                fontWeight: "500",
                                                fontSize: "13px",
                                                lineHeight: "131.02%",
                                            }}
                                        >
                                            <span>order id</span>
                                        </div>
                                        <div>
                                            <span style={{ color: "#FD8814" }}>
                                                GRO 098765
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3">
                                        <span>Payment details</span>
                                        <span>Cash on Delivery</span>
                                    </div>
                                    <div className="d-flex justify-content-between mt-3 pb-3">
                                        <span>Payment Status</span>
                                        <div>
                                            <span>
                                                <img src={Vector} alt="tick" />
                                            </span>
                                            <span>Success</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* rating */}
                        <div
                            className=" ps-4 px-3 mt-3 py-3 pb-4"
                            style={{
                                fontWeight: "700",
                                fontSize: "15px",
                                lineHeight: "131.02%",
                            }}
                        >
                            Rate your Food
                        </div>
                        <div className=" mobile-detailmnu-star-div ps-3 px-2 mt-2 py-2 pb-3">
                            <div className="mobile-detailmnu-rate-star ">
                                <Rating
                                    size={"3em"}
                                    style={{
                                        color:
                                            ("98.81deg",
                                            "#FEA24B",
                                            "-0.82%",
                                            "#FD8814 101.53%"),
                                        borderRadius: "3px",
                                    }}
                                    onClick={(e) => this._handleRating(e)}
                                />
                                {/* <div>
                            {" "}
                            <img src={Elli} alt="eli" />
                        </div> */}
                            </div>
                        </div>
                        <div
                            className=" mobile-detailmenu-starrating d-flex align-items-center "
                            style={{}}
                        >
                            3 Star rating(not bad)
                        </div>
                        <div
                            className="px-3"
                            style={{
                                fontWeight: "700",
                                fontSize: "20px",

                                fontColor: "#0000",
                            }}
                        >
                            Review

                            <div
                            className="px-3 mt-3"
                            style={{ paddingBottom: "30vh" }}
                        >
                            <div className="d-flex flex-row align-items-center  mobile-anystore-searchBar px-4 ">
                                <span className="">
                                    <FiEdit
                                        set="curved"
                                        style={{
                                            fontSize: "1.5em",
                                            color: "#FF9012",
                                        }}
                                    />
                                </span>
                                <input
                                    type="text"
                                    className="mobile-home-search w-100 px-2"
                                    placeholder="Leave feedback about food  "
                                />
                            </div>
                        </div>
                        </div>

                        {/* BUTTON STARTS */}
                        <div
                            className="position-fixed px-4 mt-3  py-2 w-100"
                            style={{ bottom: "0px" }}
                        >
                            <button
                                className="btn w-100 py-3"
                                style={{
                                    color: "#FEFEFF",
                                    fontWeight: "700",
                                    fontSize: "14px",
                                    background: "#FEA24B",
                                    borderRadius: "15px",
                                }}
                            >
                                Back to home
                            </button>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(DetailMenu);
