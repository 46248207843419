import React, { Component } from 'react';
import { connect } from 'react-redux';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import Ink from 'react-ink';
import { withRouter } from 'react-router-dom';

class backbutton extends Component {

    onHandleBack = () => {
        this.props.history.goBack()
    }
    render() {
        return (
            <React.Fragment>
                <div className="d-flex align-items-center position-fixed px-3 w-100 py-3 pt-4"
                    style={{
                        backgroundColor: '#fff', top: '0px',
                        // boxShadow: '#dddddd -1px -3px 20px 0px',
                        zIndex: '99999999'
                    }}>
                    <div className="px-2 back-button" onClick={() => this.onHandleBack()}>
                        <span className="fs-2 back-button-icon">
                            <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                        </span>
                    </div>
                    <div className='flex-grow-1 text-center back-button-title fs-5 pe-3'>
                        {this.props.title}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}


const mapStateToProps = (state) => ({

})


export default withRouter(connect(mapStateToProps, {

})(backbutton))
