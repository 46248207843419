import React, { Component } from "react";
import { connect } from "react-redux";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "./../../../../api/index";
import { getAllStoreCategories } from "../../../../redux/mobile/home/action";
import ContentLoader from "react-content-loader";

export class HomeEssential extends Component {
    state = {
        loading: true,
    };
    componentDidMount() {
        // ////// console.log(this.props,'iddd')

        this.props.getAllStoreCategories();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.storecategories && nextProps.storecategories.success) {
            this.setState({ loading: false });
        }
    }
    render() {
        const { storecategories } = this.props;
        // ////// console.log(storecategories,'store category')
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="12"
                                y="10"
                                rx="10"
                                ry="10"
                                width="254"
                                height="47"
                            />
                            <rect
                                x="12"
                                y="75"
                                rx="10"
                                ry="10"
                                width="180"
                                height="24"
                            />
                            <rect
                                x="18"
                                y="110"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="105"
                                y="110"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="190"
                                y="110"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="18"
                                y="210"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="105"
                                y="210"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                            <rect
                                x="190"
                                y="210"
                                rx="10"
                                ry="10"
                                width="69"
                                height="80"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {storecategories &&
                            storecategories.data &&
                            storecategories.data.length > 0 ? (
                            <>
                                <div className="px-3 home-heading pt-2 mt-1">
                                    Essentials Delivered to Your Doorstep
                                </div>
                                <div
                                    className="grid-template-columns-prod gap-2 pt-2 mobile-btm-brdr"
                                    style={{
                                        gridTemplateColumns: "repeat(3,1fr)",
                                        overflowX: "hidden",
                                    }}
                                >
                                    {storecategories.data ? (
                                        <>
                                            {storecategories?.data?.map(
                                                (category) => (
                                                    <>
                                                        {category.id === 1 ? (
                                                            <Link to="/food">
                                                                <div className="mobile-home-essential-div d-flex align-items-center">
                                                                    <ProgressiveImage
                                                                        delay={20}
                                                                        src={IMAGE_BASE_URL + category.image}
                                                                        placeholder={PLACE_HOLDER_IMAGE}  >
                                                                        {(src, loading) => (
                                                                            <img
                                                                                className="mobile-home-essential-div"
                                                                                style={{ objectFit: "cover", }}
                                                                                src={src}
                                                                                alt="main-item"
                                                                            />
                                                                        )}
                                                                    </ProgressiveImage>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {category.id === 2 ? (
                                                            <Link to="/medicines">
                                                                <div className="mobile-home-essential-div d-flex align-items-center">
                                                                    <ProgressiveImage
                                                                        delay={
                                                                            20
                                                                        }
                                                                        src={
                                                                            IMAGE_BASE_URL +
                                                                            category.image
                                                                        }
                                                                        placeholder={
                                                                            PLACE_HOLDER_IMAGE
                                                                        }
                                                                    >
                                                                        {(
                                                                            src,
                                                                            loading
                                                                        ) => (
                                                                            <img
                                                                                className="mobile-home-essential-div"
                                                                                style={{
                                                                                    objectFit:
                                                                                        "cover",
                                                                                }}
                                                                                src={
                                                                                    src
                                                                                }
                                                                                alt="main-item"
                                                                            />
                                                                        )}
                                                                    </ProgressiveImage>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}
                                                        {category.id > 2 ? (
                                                            <Link
                                                                to={
                                                                    "/store-category/" +
                                                                    category.id
                                                                }
                                                            >
                                                                <div className="mobile-home-essential-div d-flex align-items-center">
                                                                    <ProgressiveImage
                                                                        delay={
                                                                            20
                                                                        }
                                                                        src={
                                                                            IMAGE_BASE_URL +
                                                                            category.image
                                                                        }
                                                                        placeholder={PLACE_HOLDER_IMAGE}
                                                                    >
                                                                        {(
                                                                            src,
                                                                            loading
                                                                        ) => (
                                                                            <img
                                                                                className="mobile-home-essential-div"
                                                                                style={{
                                                                                    objectFit:
                                                                                        "cover",
                                                                                }}
                                                                                src={
                                                                                    src
                                                                                }
                                                                                alt="main-item"
                                                                            />
                                                                        )}
                                                                    </ProgressiveImage>
                                                                </div>
                                                            </Link>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </>
                                                )
                                            )}
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </>
                        ) : (
                            ""
                        )}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    storecategories: state.home.storecategories,
});

export default connect(mapStateToProps, { getAllStoreCategories })(
    HomeEssential
);
