import React, { Component } from 'react'
import { connect } from 'react-redux'
import successtick from '../../../assets/images/successtick.png'
import cancel from "../../../assets/images/cancel.png";
import { checkStatus } from "../../../redux/mobile/subscription/action"
import { Link } from 'react-router-dom';

class SubscriptionSuccess extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id,
        plan: []
    }
    componentDidMount() {
        this.setState({ loading: true })
        this.props.checkStatus(this.props.match.params.id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, plan: response.payload.data })
            }
        })
    }
    render() {
        const { plan } = this.state;
        const { subscription } = this.props;

        return (
            <React.Fragment>
                {this.state.loading ?
                    <React.Fragment>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <div>
                            <div className='mobile-subs-success-bg'></div>
                            <div className="d-flex justify-content-center mobile-subs-success-img">
                                {plan?.payment_status == "SUCCESS" ? (
                                    <img src={successtick} alt="Success" />
                                ) : plan?.payment_status == "PENDING" ? (
                                    <img src={cancel} alt="Success" />
                                ) : (
                                    <img src={cancel} alt="Success" />
                                )}
                            </div>
                            <div className="d-flex justify-content-center mobile-subs-success-head">
                                {plan?.payment_status == "SUCCESS" ? (
                                    <span>Congrats!</span>
                                ) : plan?.payment_status == "PENDING" ? (
                                    <span>Sorry</span>
                                ) : (
                                    <span>Sorry</span>
                                )}
                            </div>
                            <div className="mobile-subs-success-text px-4">
                                <div>
                                    {plan?.payment_status == "SUCCESS" ? (
                                        <span>
                                            Subscribed successfully <br/>
                                            <span className='text-success fs-6'>You have achieved free delivery for {subscription?.data?.duration} Days</span>
                                        </span>
                                    ) : plan?.payment_status == "PENDING" ? (
                                        <span>Transaction Failed</span>
                                    ) : (
                                        <span>Transaction Failed</span>
                                    )}
                                </div>
                            </div>
                            <Link to="/home">
                                <div className='d-grid gap-2 px-3 position-fixed w-100 mobile-subs-success-btn'>
                                    <button className="p-3 mobile-subs-success-button">Back to Home</button>
                                </div>
                            </Link>
                            {/* {plan?.payment_status == "SUCCESS" ? (
                                <Link to="/home">
                                    <div className='d-grid gap-2 px-3 position-fixed w-100 mobile-subs-success-btn'>
                                        <button className="p-3 mobile-subs-success-button">Back to Home</button>
                                    </div>
                                </Link>
                            ) : (
                                <Link to="/subscription-plans">
                                    <div className='d-grid gap-2 px-3 position-fixed w-100 mobile-subs-success-btn'>
                                        <button className="p-3 mobile-subs-success-button">Try Order</button>
                                    </div>
                                </Link>
                            )} */}
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    subscription: state.subscription.subscibedPlan

})

export default connect(mapStateToProps, {
    checkStatus
})(SubscriptionSuccess)

