import {
    APPLY_COUPON,
    COUPON_ERROR,
    AUTO_APPLY_COUPON,
    GET_USER_OFFERS,
    GET_MY_COUPON
} from "./actionTypes";

const initialState = {
    appliedCoupon: [],
    coupon_error: "",
    coupons: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_USER_OFFERS:
            return { ...state, coupons: action.payload };
        case APPLY_COUPON:
            return { ...state, appliedCoupon: action.payload };
        case AUTO_APPLY_COUPON:
            return { ...state, appliedCoupon: action.payload };
        case COUPON_ERROR:
            return { ...state, coupon_error: action.payload };
        default:
            return state;
    }
}
