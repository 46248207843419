import React, { Component } from 'react'
import { connect } from 'react-redux'
import { GiHamburger } from 'react-icons/gi';
import ProgressiveImage from 'react-progressive-image';
import { Link } from "react-router-dom";
// import Ink from "react-ink";
import Truncate from 'react-truncate';
import { getAllNearStores } from "../../../../redux/mobile/home/action.js";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api';
import { Rating } from 'react-simple-star-rating';



export class RestuarantNearYou extends Component {
    state = {
        data: false
    }
    componentDidMount() {
        this.props.getAllNearStores(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ data: true });
            }
        });
    }
    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }
    render() {
        const { nearStore } = this.props;
        const { data } = this.state;
        return (
            <React.Fragment>
                {data ? (nearStore && nearStore.success && nearStore?.data?.length > 0 ? (
                    <div className='px-3 mt-3  '>
                        <GiHamburger className='mb-2' color='FF9A62' size={20} /> <span className='top-res-header'>Restaurants Near You</span>
                        <div className='d-flex flex-row align-items-center'>
                            <div className='mt-3 main-menu hidden-scroll-bar gap-3 '
                                style={nearStore?.data?.length > 4 ?
                                    { gridTemplateColumns: `repeat(${nearStore?.data?.length},1fr)` } :
                                    { gridTemplateColumns: "repeat(4,1fr)" }}>
                                {nearStore?.data?.map((store) =>
                                    <Link to={'/single-store/' + store.id} key={store.id}>
                                        <div className={`mt-2 position-relative ${!store?.is_active && 'store-not-active'}`}>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + store.image}
                                                placeholder={
                                                    PLACE_HOLDER_IMAGE
                                                }
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        style={{
                                                            objectFit: "cover",
                                                            backgroundRepeat: 'none',
                                                            height: '20vw',
                                                            width: '10vh',
                                                            borderRadius: '10px'
                                                        }}
                                                        className=""
                                                        alt="grosav"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            {/* <div className='d-flex justify-content-center position-absolute w-100' style={{ bottom: '5px' }}>
                                        <Ink className="touch-ink " />
                                        <span className='restuarant-near-offer text-center px-2'>20 % off</span>
                                    </div> */}
                                        </div>
                                        <div className='top-res-name'>
                                            <Truncate lines={1}>
                                                <span>
                                                    {store.name}
                                                </span>
                                            </Truncate>
                                            {store?.rating > 0 ?
                                                <div>
                                                    <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={store?.rating}
                                                        readonly="true"
                                                    />
                                                </div>
                                                :
                                                <Rating
                                                    size={"1em"}
                                                    className="mobile-rate-star"
                                                    initialValue={0}
                                                    readonly="true"
                                                />
                                            }
                                            {store?.is_active ? (
                                                <div className='d-inline-block'>
                                                    <div>
                                                        <span className='top-res-deli-time text-muted '>
                                                            {store.approx_time_delivery > 0 ?
                                                                <>
                                                                    {store.approx_time_delivery}Mins
                                                                </>
                                                                :
                                                                <>
                                                                    0Min
                                                                </>
                                                            }
                                                            {store.delivery_radius > 0 ?
                                                                <>
                                                                    - {store.delivery_radius}km
                                                                </>
                                                                :
                                                                <>
                                                                    - 0km
                                                                </>
                                                            }
                                                        </span>
                                                    </div>
                                                </div>
                                            ) : (
                                                null
                                            )}
                                        </div>
                                        {!store?.is_active ? (
                                            <div className="text-danger">
                                                Store Closed
                                            </div>
                                        ) : (
                                            null
                                        )}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                ) : ("")) : ''}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    nearStore: state.home.nearStore,
})


export default connect(mapStateToProps, { getAllNearStores })(RestuarantNearYou)
