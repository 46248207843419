import React, { Component } from "react";
import { BiSearch } from "react-icons/bi";
import { AiFillCloseCircle } from "react-icons/ai";
import { IoCheckmarkCircleSharp } from "react-icons/io5";
import { TbTruckDelivery } from "react-icons/tb";
import { connect, Provider } from "react-redux";
import VendorFooter from "../VendorFooter";
import Loading from "../../Elements/loading";
import { Link } from "react-router-dom";
import Switch from "react-input-switch";
import Fade from 'react-reveal/Flip';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { BiRefresh } from "react-icons/bi"
import { getVendorOrderDetails, toggleSingleStore, getUserStore, getSearchOrders } from "../../../redux/vendor/user/action";
import {
    declineVendorOrder,
    acceptVendorOrder,
    prepareVendorOrder,
    pickupVendorOrder,
} from '../../../redux/vendor/order/action'
import { BottomSheet } from "react-spring-bottom-sheet";
import Ink from "react-ink";
import { set } from "lodash";
import Badge from 'react-bootstrap/Badge';
import { FaRegClock } from "react-icons/fa";

export class VendorOrder extends Component {
    state = {
        loading: false,
        value: "Close",
        all_order: true,
        order_id: '',
        order_details: [],
        acceptSheet: false,
        declineSheet: false,
        prepareSheet: false,
        pickupSheet: false,
        order_accepted: false,
        inputSearch: false,
        loadingSearch: false,
        store_id: '',
        tab: 'new',
        check: false
    };
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true });
        this.props.getUserStore(user && user.data && user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false })
                if (response.payload.store.is_active == 1) {
                    this.setState({ value: 'Opened' })
                } else {
                    this.setState({ value: 'Closed' })
                }
            } else {
                //// console.log(response, 'API error')
            }
        })
        this.props.getVendorOrderDetails(user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        order_details: response.payload.orders,
                    });
                    // //// console.log(response.payload.orders,'orders');
                } else {
                    //// console.log('api error')
                }
            });
    }
    refresh = () => {
        const { user } = this.props;
        this.setState({ loading: true });
        this.props.getVendorOrderDetails(user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loading: false,
                        order_details: response.payload.orders,
                    });
                    // //// console.log(response.payload.orders);
                } else {
                    //// console.log('api error')
                }
            });
    }
    _acceptSheetOpen = (order_id) => {
        this.setState({ acceptSheet: true, order_id: order_id, check: false })
    }
    _acceptSheetClose = () => {
        this.setState({ acceptSheet: false, check: false })
    }
    _declineSheetOpen = (order_id) => {
        this.setState({ declineSheet: true, order_id: order_id })
    }
    _declineSheetClose = () => {
        this.setState({ declineSheet: false })
    }
    _prepareSheetOpen = (order_id) => {
        this.setState({ prepareSheet: true, order_id: order_id })
    }
    _prepareSheetClose = () => {
        this.setState({ prepareSheet: false })
    }
    _pickupSheetOpen = (order_id) => {
        this.setState({ pickupSheet: true, order_id: order_id })
    }
    _pickupSheetClose = () => {
        this.setState({ pickupSheet: false })
    }

    openSearch = () => {
        this.setState({ inputSearch: !this.state.inputSearch })
    }

    getSearchOrders = (order_id) => {
        const { user } = this.props;
        this.setState({ loadingSearch: true })
        this.props.getSearchOrders(user.data.auth_token, order_id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({
                        loadingSearch: false,
                        order_details: response.payload.orders,
                    });
                    // //// console.log(response.payload.orders);
                } else {
                    //// console.log('api error')
                }
            });
    }

    __handleOrder = (Order) => {
        this.setState({ loading: true })
        const { user } = this.props;
        // e.prevent.default();
        if (Order == "Accept") {
            // var check = document.getElementById(this.state.order_id + 'check').checked;
            this.props.acceptVendorOrder(user && user.data && user.data.auth_token, this.state.order_id)
                .then((response) => {
                    if (response && response.payload && response.payload.success) {
                        this.props.getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (response && response.payload && response.payload.success) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        is_accepted: true, acceptSheet: false,
                                        order_accepted: true,
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                })
        } else {
            this.props.declineVendorOrder(user && user.data && user.data.auth_token, this.state.order_id)
                .then((response) => {
                    if (response && response.payload && response.payload.success) {
                        this.props.getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (response && response.payload && response.payload.success) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        is_declined: true, declineSheet: false
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                })
        }
    }
    __handlePrepareOrder = (order) => {
        this.setState({ loading: true })
        const { user } = this.props;
        // e.prevent.default();
        if (order == "prepare") {
            this.props.prepareVendorOrder(user && user.data && user.data.auth_token, this.state.order_id)
                .then((response) => {
                    if (response && response.payload && response.payload.success) {
                        this.props.getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (response && response.payload && response.payload.success) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        prepareSheet: false,
                                        check: false
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                })
        }
    }
    __handlePickupOrder = (order) => {
        this.setState({ loading: true })
        const { user } = this.props;
        if (order == "pickup") {
            this.props.pickupVendorOrder(user && user.data && user.data.auth_token, this.state.order_id)
                .then((response) => {
                    if (response && response.payload && response.payload.success) {
                        this.props.getVendorOrderDetails(user.data.auth_token)
                            .then((response) => {
                                if (response && response.payload && response.payload.success) {
                                    this.setState({
                                        loading: false,
                                        order_details: response.payload.orders,
                                        pickupSheet: false,
                                    });
                                    // //// console.log(response.payload.orders);
                                } else {
                                    //// console.log('api error')
                                }
                            });
                    }
                })
        }
    }
    changeStatus = () => {
        this.setState({ loading: true })
        const { user } = this.props;
        this.props.toggleSingleStore(user && user.data && user.data.auth_token)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    if (this.state.value == 'Closed') {
                        this.setState({ value: 'Opened' })
                        setTimeout(() => {
                            this.setState({ loading: false })
                        }, 500);
                    } else {
                        this.setState({ value: 'Closed' })
                        setTimeout(() => {
                            this.setState({ loading: false })
                        }, 500);
                    }
                } else {
                    //// console.log('API error')
                }
            })
    }

    showSheduleOrder = (date, time) => {
        let now = new Date();
        let year = now.getFullYear();
        let month = now.getMonth() + 1;
        let day = now.getDate();
        let hours = now.getHours();
        let minutes = now.getMinutes();

        let formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        let formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

        let currentDateTime = new Date(formattedDate + "T" + formattedTime);
        let scheduleDateTime = new Date(date + "T" + time);

        // console.log(scheduleDateTime, currentDateTime);

        let diffMs = scheduleDateTime - currentDateTime;
        let diffMins = diffMs / (1000 * 60);
        if (diffMins < 45) {
            return true
        } else {
            return false
        }
    }

    render() {

        const { order_details } = this.state;
        let showOrders = [];

        showOrders = order_details.filter((order) => {
            if (order.order_status_id == 1 && order.is_schedule == 1) {

                   // return this.showSheduleOrder(order.schedule_date, order.schedule_time);

                return true;
            }
        });


        // console.log(showOrders);

        const { order, user } = this.props;

        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    order &&
                    order.orders && (
                        <React.Fragment>
                            {order_details && (
                                <React.Fragment>
                                    <div
                                        className='position-sticky'
                                        style={{ top: '0px', zIndex: '9999999', backgroundColor: '#fff' }}
                                    >
                                        <div className='d-flex flex-row justify-content-between align-items-center pt-4 px-3'>
                                            <div className='d-flex flex-column'>
                                                <span className='nameRestaurant'>
                                                    {user.data && user.data.store[0] && user.data.store[0].name}
                                                </span>
                                                <span className='address text-muted'>
                                                    {user.data && user.data.store[0] && user.data.store[0].address}
                                                </span>
                                            </div>
                                        </div>

                                        <div className='d-flex flex-row justify-content-between align-items-center status px-3 py-2 mt-2'>
                                        <span className='fs-5 fw-bold'>Scheduled Orders</span>
                                            {this.state.value === "Opened" && (
                                                <div
                                                    className='btn'
                                                    style={{ borderRadius: '10px', color: '#fff', backgroundColor: '#ff9012' }}
                                                    onClick={this.refresh}
                                                >
                                                    <BiRefresh size='1.5em' /> Refresh
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {this.state.loadingSearch ? (
                                        <Loading />
                                    ) : (
                                        this.state.all_order && (
                                            <div style={{ paddingBottom: '30%' }}>
                                                {showOrders && showOrders?.length > 0 ? (
                                                    <div>
                                                        {showOrders.map((value, index) => (
                                                            <div className="card-restaurant p-3 mt-3" key={index}>
                                                                <Link to={`/vendor-single-order/${value.id}/order`}>
                                                                    {value.is_prescription && (
                                                                        <div className="text-end">
                                                                            <Badge bg="danger">
                                                                                With prescription
                                                                            </Badge>
                                                                        </div>
                                                                    )}
                                                                    <div className="d-flex flex-row justify-content-between">
                                                                        <div>
                                                                            <TbTruckDelivery style={{ color: '#888', fontSize: '1.5em' }} />
                                                                        </div>
                                                                        <div
                                                                            className="text-end"
                                                                            style={{ color: '#000', fontWeight: '800' }}
                                                                        >
                                                                            {value.orderstatus && value.orderstatus.name}
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between mb-1 mt-1">
                                                                        <div className="explore-general-one">
                                                                            <span className="text-muted">Order ID</span>
                                                                        </div>
                                                                        <div className="explore-general-two">
                                                                            <span className="text-muted">
                                                                                {value.unique_order_id && (
                                                                                    <>
                                                                                        {value.unique_order_id.length > 5
                                                                                            ? value.unique_order_id.slice(0, -5) // Display all characters except the last 5
                                                                                            : null}
                                                                                        <span
                                                                                            style={{ color: 'red', fontWeight: 'bold' }}
                                                                                        >
                                                                                            {value.unique_order_id.slice(-5)} {/* Last 5 characters in red */}
                                                                                        </span>
                                                                                    </>
                                                                                )}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="">
                                                                        {value.orderitems.map((data, index) => (
                                                                            <div className="d-flex justify-content-between mb-1" key={index}>
                                                                                <div className="explore-general-one">
                                                                                    <span className="text-muted">
                                                                                        {data.name}
                                                                                    </span>
                                                                                </div>
                                                                                <div className="explore-general-two">
                                                                                    <span className="text-muted">Qty: {data.quantity}</span>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                    {value.total && (
                                                                        <div className="d-flex justify-content-between mb-1">
                                                                            <div className="explore-general-one">
                                                                                <span className="text-muted">Total</span>
                                                                            </div>
                                                                            <div className="explore-general-two">
                                                                                <span className="text-muted">
                                                                                    Rs. {value.total}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                {value.is_schedule === 1 &&
                                                                    <div className="d-flex justify-content-between align-items-center"  style={{"color":"#fd8814","border":"1px solid","borderColor":"#fd8814","padding":"7px 10px","borderRadius":"10px","marginTop":"15px","fontWeight":"bold"}}>
                                                                       <div>
                                                                            <FaRegClock style={{"fontSize":"19px","marginRight":"3px"}} /> <span style={{"fontSize":"15px"}}>Scheduled</span>
                                                                       </div>

                                                                       <div>
                                                                            <span>{value.schedule_date} {value.schedule_time}</span>
                                                                       </div>
                                                                    </div>
                                                                }
                                                                </Link>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="no-order-text text-muted d-flex align-items-center justify-content-center">
                                                        No Orders Yet ....!
                                                    </div>
                                                )}
                                            </div>
                                        )
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                )}
            </React.Fragment>
        );


    }
}

const mapStateToProps = (state) => ({
    order: state.vendor.order,
    user: state.vendor.store_user,
});

export default connect(mapStateToProps, {
    getVendorOrderDetails,
    toggleSingleStore,
    declineVendorOrder,
    acceptVendorOrder,
    getUserStore,
    getSearchOrders,
    prepareVendorOrder,
    pickupVendorOrder,
})(VendorOrder);
