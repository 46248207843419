import React, { Component } from "react";
import { connect } from "react-redux";
import { FaClinicMedical } from "react-icons/fa";
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import { getAllTopDeals } from '../../../../redux/mobile/medicine/action'
import { Link } from "react-router-dom";
export class TopDeal extends Component {
    componentDidMount() {
        this.props.getAllTopDeals(JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude, JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude);
    }
    render() {
        const { topDeal } = this.props;
        return (
            <React.Fragment>
                {topDeal && topDeal?.neardeals && topDeal?.neardeals?.length > 0 && (
                    <div>
                        <div className="px-3 py-3 d-flex flex-row align-items-center ">
                            <FaClinicMedical
                                style={{ fontSize: "1.5rem", color: "#FF9A62" }}
                            />
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                }}
                            >
                                Top Deal
                            </span>
                        </div>

                        <div
                            className="toap-deal-med d-flex flex-row align-items-center justify-content-between p-3  hidden-scroll-bar"
                            style={{
                                gridTemplateColumns: `repeat(${topDeal?.neardeals?.length},1fr)`,
                            }}
                        >
                            {topDeal?.neardeals?.map((deal, index) => (
                                <div>
                                    <Link to={'/top-offer/deal/' + deal.id}>
                                        <ProgressiveImage
                                            delay={20}
                                            src={
                                                IMAGE_BASE_URL + deal.image
                                            }
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    className=""
                                                    style={{
                                                        width: "37vw",
                                                        objectFit: "cover",
                                                        height: "45vw",
                                                        borderRadius:
                                                            "15px",
                                                        backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                        backgroundRepeat:
                                                            "round",
                                                        boxShadow:
                                                            "0px 0px 15px 8px #cccccc50",
                                                    }}
                                                    src={src}
                                                    alt="main-item"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </Link>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    topDeal: state.medicine.topDeals,
});

export default connect(mapStateToProps, { getAllTopDeals })(TopDeal);
