import React, { Component } from "react";
import { connect } from "react-redux";
import MobileBackButtonWithCenterTitle from "../../Elements/MobileBackButtonWithCenterTitle";
import ProgressBar from "react-bootstrap/ProgressBar";
import Chart from "react-apexcharts";
import { HiCash, HiOutlineShare } from "react-icons/hi";
import { FaMedal } from "react-icons/fa";
import { BsArrowDownSquare, BsCashStack } from "react-icons/bs";
import { MdPersonAddAlt1 } from "react-icons/md";
import Footer from "../Footer";
import ContentLoader from "react-content-loader";
import CopyToClipboard from "react-copy-to-clipboard";
import { getUserDetails } from "../../../redux/mobile/user/action";
import { getMyRefferals } from "../../../redux/mobile/scratchCard/action";
import { RWebShare } from "react-web-share";
import Table from "react-bootstrap/Table";
import moment from "moment";
export class ReferAndEarn extends Component {
    state = {
        loading: false,
        open: false,
        copied: false,
    };
    componentDidMount() {
        const { user } = this.props;

        this.props.getUserDetails(user.data.auth_token);
        this.props.getMyRefferals(user.data.auth_token, user.data.id);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500);
    }

    __ProgressBar = (e) => {
        const now = e * (100 / 2000);

        return (
            <ProgressBar
                now={now}
                label={`${now}%`}
                visuallyHidden
                variant="custom"
            />
        );
    };
    render() {
        const { user } = this.props;
        const { userDetails } = this.props;
        const { refferals } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect
                                x="15"
                                y="25"
                                rx="8"
                                ry="8"
                                width="30"
                                height="30"
                            />
                            <rect
                                x="90"
                                y="30"
                                rx="8"
                                ry="8"
                                width="125"
                                height="20"
                            />
                            <rect
                                x="15"
                                y="100"
                                rx="8"
                                ry="8"
                                width="90"
                                height="15"
                            />
                            <rect
                                x="15"
                                y="123"
                                rx="8"
                                ry="8"
                                width="75"
                                height="25"
                            />
                            <rect
                                x="170"
                                y="115"
                                rx="8"
                                ry="8"
                                width="90"
                                height="20"
                            />
                            <rect
                                x="15"
                                y="170"
                                rx="8"
                                ry="8"
                                width="235"
                                height="20"
                            />
                            <rect
                                x="230"
                                y="160"
                                rx="20"
                                ry="20"
                                width="40"
                                height="40"
                            />
                            <rect
                                x="40"
                                y="220"
                                rx="8"
                                ry="8"
                                width="200"
                                height="30"
                            />
                            <rect
                                x="15"
                                y="270"
                                rx="8"
                                ry="8"
                                width="250"
                                height="40"
                            />
                            <rect
                                x="15"
                                y="345"
                                rx="8"
                                ry="8"
                                width="75"
                                height="25"
                            />
                            <rect
                                x="15"
                                y="380 "
                                rx="8"
                                ry="8"
                                width="250"
                                height="160"
                            />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <MobileBackButtonWithCenterTitle title="Refer and Earn" history={this.props.history} />
                        <div className="d-flex flex-row align-items-center justify-content-between px-4 py-3"
                            style={{ marginTop: "4vh" }}  >
                            <div className="d-flex flex-column align-item-center">
                                <div style={{ fontSize: "14px", fontWeight: "700", }}  >
                                    My Cash Reward
                                </div>
                                <div style={{ fontSize: "24px", fontWeight: "300", }}     >
                                    Rs {userDetails?.data?.ref_earning}
                                </div>
                            </div>
                        </div>




                        <div className=" px-4 py-4 w-100">
                            <div className="py-3 d-flex flex-row justify-content-around align-items-center" style={{ backgroundColor: "#f0f4f7",borderRadius:"12px" }}  >
                                <span className="text-black">{user?.data?.referral_code}</span>
                                <CopyToClipboard
                                    text={user?.data?.referral_code}
                                    onCopy={() => this.setState({ copied: true })}  >
                                    <span className="">
                                        {this.state.copied ? "Copied" : "Copy"}
                                    </span>
                                </CopyToClipboard>
                            </div>
                            <RWebShare data={{
                               text: "Hi, I just invited you to use the Grosav app, India’s biggest online Delivery Partner. \n\n*Step 1: Use the link to download the app*. \n\n*Step 2: Register using your mobile number and use my referral code*. \n\nMY REFERRAL CODE: *" + user?.data?.referral_code + "*  \n\nAndroid: https://play.google.com/store/apps/details?id=com.grosav.app\n\nIOS: https://apps.apple.com/in/app/grosav/id1546226248\n\n ",
url: "https://grosav.com/",
title: "Grosav",
                            }}
                                onClick={() => console.log("shared successfully!")}  >
                                <button className="invite-button btn btn py-3 w-100 mt-2" type="button" >
                                    <b style={{ color: "white", fontSize: "17px", fontWeight: "600", }}  >
                                        Invite new users to get cash
                                    </b>
                                </button>
                            </RWebShare>
                        </div>

                        <div className="pt-3 px-4 text-center">
                            <div className="fw-bolder text-muted fs-6 ">
                                People Joined Using Your Code
                            </div>
                            {refferals?.user?.length > 0 ? (
                                <Table className="table table-borderless">
                                    <thead className="text-center">
                                        <tr style={{ color: "#fd8814 " }}>
                                            <th>User Name</th>
                                            <th>Joined At</th>
                                        </tr>
                                    </thead>

                                    <tbody className="text-center">
                                        {refferals?.user?.map((user) => (
                                            <tr>
                                                <td>{user?.name}</td>
                                                <td>
                                                    {moment(user?.created_at).format("YYYY-MM-DD")}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            ) : (<div className="text-center text-muted fs-10">
                                No Joinees
                            </div>)}

                        </div>

                        <div
                            className="d-flex flex-column px-3"
                            style={{ backgroundColor: "#ffffff" }}
                        >
                            <div className="fw-bolder text-muted fs-6 mt-3">
                                How to GET
                            </div>

                            <div
                                className="position-relative py-2"
                                style={{
                                    height: "300px",
                                    borderRadius: "40px",
                                }}
                            >
                                <div className="d-flex flex-column py-3">
                                    <div
                                        className="d-flex flex-row mb-4"
                                        style={{ paddingLeft: "10px" }}
                                    >
                                        <div style={{ zIndex: "2" }}>
                                            <div
                                                className="p-1"
                                                style={{
                                                    zIndex: "2",
                                                    color: "#8399A9",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <HiOutlineShare
                                                    color="#6c757d"
                                                    size={"2em"}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 d-flex flex-column ms-4">
                                            <div className="fw-bolder text-muted fs-6">
                                                Share refferal id to Friends
                                            </div>
                                            <div className="text-muted">
                                                Tips：Share to groups and reach
                                                your cash rewards faster
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="d-flex flex-row mb-4"
                                        style={{ paddingLeft: "10px" }}
                                    >
                                        <div style={{ zIndex: "2" }}>
                                            <div
                                                className="p-1"
                                                style={{
                                                    zIndex: "2",
                                                    color: "#8399A9",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <BsArrowDownSquare
                                                    color="#6c757d"
                                                    size={"2em"}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 d-flex flex-column ms-4">
                                            <div
                                                className="fw-bolder text-muted"
                                                style={{ width: "80%" }}
                                            >
                                                {" "}
                                                <span>
                                                    Invitee fills in the code in{" "}
                                                </span>
                                                <span>referal borx</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="d-flex flex-row mb-4"
                                        style={{ paddingLeft: "10px" }}
                                    >
                                        <div style={{ zIndex: "2" }}>
                                            <div
                                                className="p-1"
                                                style={{
                                                    zIndex: "2",
                                                    color: "#8399A9",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <MdPersonAddAlt1
                                                    color="#6c757d"
                                                    size={"2em"}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 d-flex flex-column ms-4">
                                            <div className="text-muted">
                                                Invitee signs up on grosav you
                                                claims the new user reward{" "}
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="d-flex flex-row mb-5"
                                        style={{ paddingLeft: "10px" }}
                                    >
                                        <div style={{ zIndex: "2" }}>
                                            <div
                                                className="p-1"
                                                style={{
                                                    zIndex: "2",
                                                    color: "#8399A9",
                                                    backgroundColor: "#fff",
                                                    borderRadius: "50%",
                                                }}
                                            >
                                                <FaMedal
                                                    color="#6c757d"
                                                    size={"2em"}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow-1 d-flex flex-column ms-4">
                                            <div className="text-muted">
                                                Get your cash reward
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="position-absolute"
                                        style={{ marginLeft: "30px" }}
                                    >
                                        <div
                                            style={{
                                                borderLeft: "1px dashed #000",
                                                height: "260px",
                                                zIndex: "-999",
                                            }}
                                        ></div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="d-flex flex-column mt-2 px-"
                                style={{
                                    backgroundColor: "#ffffff",
                                    paddingBottom: "5rem",
                                    boxShadow:
                                        " 0px 0px 10px 6px rgb(255 255 255 / 100%)",
                                }}
                            >
                                <div className="fw-bolder text-muted fs-6 text-center">
                                    Rules
                                </div>
                                <hr width=" w-100%" />
                                <div className="text-muted fw-bolder">
                                    1. Each cash spree earning task is valid for
                                    7 days after which it expires. When the task
                                    expires, if you haven't collected your
                                    reward by clicking on "Withdraw", your
                                    reward amount will be automatically
                                    withdrawn and added to your PalmPay account
                                    balance;
                                </div>
                                <div className="text-muted fw-bolder mt-4">
                                    2. After the task expires, all of your
                                    invitees details for that particular task
                                    will be cleared out. If your invitees have
                                    not signed up yet, you may re-invite them on
                                    a new earning task.
                                </div>
                                <div className="text-muted fw-bolder mt-4">
                                    3. If the status of your invitee on the
                                    invitees list is marked as "Reassigned", you
                                    will not get the reward for this invitee
                                    because the invitee has successfully
                                    registered to PalmPay through another
                                    referral feature at the same time as with
                                    your cash spree earning link.
                                </div>
                                <div className="text-muted fw-bolder mt-4">
                                    4. A mobile phone can only be invited once
                                    and one invitee can only be associated with
                                    one inviter.
                                </div>
                            </div>
                        </div>

                        {/* <Footer active_store={true} /> */}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    userDetails: state.user.userDetails,
    refferals: state.cards.refferals,
});

export default connect(mapStateToProps, { getUserDetails, getMyRefferals })(
    ReferAndEarn
);
