import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom';
import store from '../../../assets/images/store.png';
import { MdFoodBank } from 'react-icons/md';
import { AiFillMedicineBox, AiFillHome } from 'react-icons/ai';
import { RiBookMarkFill } from 'react-icons/ri';

class Footer extends Component {
    state = {
        active_store: false,
        active_food: false,
        active_medicine: false,
        active_any_store: false,
    }

    componentDidMount() {
        if (this.props.active_store === true) {
            this.setState({ active_store: true });
        }
        if (this.props.active_food === true) {
            this.setState({ active_food: true });
        }
        if (this.props.active_medicine === true) {
            this.setState({ active_medicine: true });
        }
        if (this.props.active_any_store === true) {
            this.setState({ active_any_store: true });
        }
    }
    render() {
        return (
            <React.Fragment>
                <div className="position-fixed bottom-0 start-50 translate-middle-x w-100 user-select-none Vendor-fixed py-3 px-4 mobile-footer-container" style={{ boxShadow: '0px 0px 20px 5px #d6d6d6' }} >
                    <div className='d-flex flex-row justify-content-between align-items-center '>

                        <Link to="/home" className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_store ? (
                                    <React.Fragment>
                                        <div className="mobile-footer-btn py-1">
                                            <button type="button" className="btn">
                                                <span>
                                                    <AiFillHome size={30} color="#ff9a62" className='me-2' />
                                                    <span className="mobile-footer-icon-text me-1 ">Home</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>

                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <AiFillHome size={30} color="#ff9a62" />
                                        </span>
                                    </React.Fragment>

                                )}
                            </div>
                        </Link>

                        <Link to="/food" className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_food ? (
                                    <React.Fragment>
                                        <div className="mobile-footer-btn py-1">
                                            <button type="button" className="btn">
                                                <span>
                                                    <MdFoodBank size={30} color="#ff9a62" className='me-2' />
                                                    <span className="mobile-footer-icon-text me-1 ">Food</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <MdFoodBank size={30} color="#ff9a62" />
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Link>
                        <Link to="/medicines" className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_medicine ? (
                                    <React.Fragment>
                                        <div className="mobile-footer-btn py-1">
                                            <button type="button" className="btn">
                                                <span>
                                                    <AiFillMedicineBox size={30} color="#ff9a62" className='me-2' />
                                                    <span className="mobile-footer-icon-text me-1 ">Medicine</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <AiFillMedicineBox size={30} color="#ff9a62" />
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Link>

                        <Link to="/any-store" className='position-relative b-r-10 p-2'>
                            <div className="d-flex flex-column justify-content-center align-items-center  h-100">
                                {this.props.active_any_store ? (
                                    <React.Fragment>
                                        <div className="mobile-footer-btn py-1">
                                            <button type="button" className="btn">
                                                <span>
                                                    <RiBookMarkFill size={30} color="#ff9a62" className='me-2' />
                                                    <span className="mobile-footer-icon-text me-1 ">Any Store</span>
                                                </span>
                                            </button>
                                        </div>
                                    </React.Fragment>

                                ) : (
                                    <React.Fragment>
                                        <span>
                                            <RiBookMarkFill size={30} color="#ff9a62" />
                                        </span>
                                    </React.Fragment>
                                )}
                            </div>
                        </Link>

                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

export default connect(mapStateToProps, {})(Footer)
