import React from "react";
import ReactDOM from "react-dom/client";
import Root from "./Root";
import "./assets/css/main.css";
import "./assets/css/mobile.css";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import VendorHome from "./components/Vendor/VendorHome";
import VendorDateWiseSale from "./components/Vendor/VendorDateWiseSale";
import Inventory from "./components/Vendor/VendorInventory";
import VendorLogin from "./components/Vendor/VendorLogin";
import VendorOrder from "./components/Vendor/VendorOrder";
import VendorSingleOrder from "./components/Vendor/VendorSingleOrder";
import ViewPrescription from "./components/Vendor/ViewPrescription";
import VendorPastOrder from "./components/Vendor/VendorPastOrder/index";
import ScheduledOrders from "./components/Vendor/VendorScheduledOrders/index";
import VendorOffer from "./components/Vendor/VendorOffers";
import VendorProfile from "./components/Vendor/VendorProfile";
import VendorOutletInfo from "./components/Vendor/VendorOutletInfo";
import VendorCoupon from "./components/Vendor/VendorCoupon";
import VendorTiming from "./components/Vendor/VendorTiming";
import VendorCategoryTiming from "./components/Vendor/VendorCategoryTiming";
import VendorAccount from "./components/Vendor/VendorAccount";
import VendorAccSettings from "./components/Vendor/VendorAccSettings";
import VendorCompletedOrders from "./components/Vendor/VendorCompletedOrders";
import VendorPayoutDetails from "./components/Vendor/VendorPayoutDetails";
import VendorPreparationTime from "./components/Vendor/VendorPreparationTime";
import VendorBusinessReport from "./components/Vendor/VendorBusinessReport";
import VendorStoreUsers from "./components/Vendor/VendorOutletInfo/VendorStoreUsers";
import VendorAddMenu from "./components/Vendor/VendorAddMenu";
import VendorDealsToday from "./components/Vendor/VendorDealsToday";
import VendorEditItem from "./components/Vendor/VendorEditItem";
import VendorEditCoupon from "./components/Vendor/VendorEditCoupon";


import Home from "./components/Mobile/Home";
import VendorAddItem from "./components/Vendor/VendorAddItem";
import StoreOrderPayment from "./components/Mobile/RunningOrders/AnyStoreOrders/StoreOrderPayment";
import OnBoarding from "./components/Mobile/OnBoarding";
import SignUp from "./components/Mobile/SignUp";
import Notifications from "./components/Mobile/Notifications";
import SubscriptionPlans from "./components/Mobile/SubscriptionPlans";
import SingleSubscriptionPlan from "./components/Mobile/SubscriptionPlans/SingleSubscriptionPlan";
import SingleDealsToday from "./components/Mobile/SingleDealsToday";
import SubscriptionSuccess from "./components/Mobile/SubscriptionSuccess/index";
import OrderSuccess from "./components/Mobile/OrderSuccess";
import TaskSuccess from "./components/Mobile/TaskSuccess";
import MyProfile from "./components/Mobile/Profile";
import Wallet from "./components/Mobile/Wallet";
import AddPickUpAndDrop from "./components/Mobile/AddPickUpAndDrop";
import CompletedOrder from "./components/Mobile/CompletedOrder";
import RateMeal from "./components/Mobile/RateMeal";
import Courier from "./components/Mobile/Courier";

// import RunningOreders from "./components/Mobile/RunningOrders";
import MyCoupon from "./components/Mobile/Coupon";
import SignUpProcess from "./components/Mobile/SignUpProcess";
import RunningOrders from "./components/Mobile/RunningOrders";
import EditLocation from "./components/Mobile/EditLocation";
import Product from "./components/Mobile/Product";
import PaymentMethod from "./components/Mobile/PaymentMethod";
import PickUpAndDrop from "./components/Mobile/PickUpAndDrop";
import Medicines from "./components/Mobile/Medicines";
import WithPrescription from "./components/Mobile/Medicines/WithPrescription";
import ReferAndEarn from "./components/Mobile/ReferAndEarn";
import EditProfile from "./components/Mobile/EditProfile";
import CouponList from "./components/Mobile/Cart/ApplyCoupon/CouponList";
import CourierSuccess from "./components/Mobile/Courier/CourierSuccess";
import PickupSuccess from "./components/Mobile/AnyStore/AnyStoreSuccess";
import AnyStore from "./components/Mobile/AnyStore";
import Cart from "./components/Mobile/Cart";
// import Store from "./components/Mobile/Store/back";
import Store from "./components/Mobile/Store";
import Food from "./components/Mobile/Food";
import DetailMenu from "./components/Mobile/DetailMenu";
import ConfirmOrder from "./components/Mobile/ConfirmOrder";
import ExploreMenu from "./components/Mobile/ExploreMenu";
import YourCoupon from "./components/Mobile/YourCoupon";
import Groceries from "./components/Mobile/Groceries";
import TopOffer from "./components/Mobile/TopOffer";
import RestarauntFilter from "./components/Mobile/RestarauntFilter";
import MyRewards from "./components/Mobile/Profile/MyRewards";
import SingleCoupon from "./components/Mobile/SingleCoupon";
import SetLocation from "./components/Mobile/SetLocation";
import LikedItems from "./components/Mobile/LikedItems";
import MyAddress from "./components/Mobile/Profile/MyAddress";
import VendorAddItemCategory from "./components/Vendor/VendorAddItemCategory";
import VendorEditItemCategory from "./components/Vendor/VendorEditItemCategory";
import VendorAddAddonCategory from "./components/Vendor/VendorAddAddonCategory";
import VendorEditAddonCategory from "./components/Vendor/VendorEditAddonCategory";
import VendorAddAddon from "./components/Vendor/VendorAddAddon";
import VendorEditAddon from "./components/Vendor/VendorEditAddon";
import ListItemGroup from "./components/Mobile/Food/ListItemGroup";
import ListStoreGroup from "./components/Mobile/Food/ListStoreGroup";
import VendorItemShedule from "./components/Vendor/VendorItemShedule";
import VendorRating from "./components/Vendor/VendorRating";
import PlaceReview from "./components/Mobile/RunningOrders/SingleOrder/PlaceReview";
import SingleOrder from "./components/Mobile/RunningOrders/SingleOrder";
import RefreshWrapper from "./RefreshWrapper";
import VendorItemCategoryShedule from "./components/Vendor/VendorItemCategoryShedule";
import '../src/components/Helpers/logger'
import Otp from "./components/Mobile/Editotp";
import LocationSheet from "./components/Mobile/LocationSheet";
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <Root>
        <BrowserRouter>
            {/* <RefreshWrapper> */}

                <Switch>
                    <Route exact path="/vendor-home" component={VendorHome} />
                    <Route exact path="/vendor-inventory" component={Inventory} />
                    <Route exact path="/vendor-login" component={VendorLogin} />
                    <Route exact path="/vendor-datewise-sale" component={VendorDateWiseSale} />
                    <Route exact path="/vendor-order" component={VendorOrder} />
                    <Route exact path="/vendor-single-order/:id/:type" component={VendorSingleOrder} />
                    <Route exact path="/vendor-prescription/:id" component={ViewPrescription} />
                    <Route exact path="/vendor-past-order/:id" component={VendorPastOrder} />
                    <Route exact path="/vendor-scheduled-orders" component={ScheduledOrders} />
                    <Route exact path="/vendor-completed-orders/:id" component={VendorCompletedOrders} />
                    <Route exact path="/vendor-profile" component={VendorProfile} />
                    <Route exact path="/vendor-offer" component={VendorOffer} />
                    <Route exact path="/vendor-coupon" component={VendorCoupon} />
                    <Route exact path="/vendor-outlet-info" component={VendorOutletInfo} />
                    <Route exact path="/vendor-account" component={VendorAccount} />
                    <Route exact path="/vendor-store-users" component={VendorStoreUsers} />
                    <Route exact path="/vendor-timing" component={VendorTiming} />
                    <Route exact path="/vendor-deals-today" component={VendorDealsToday} />
                    <Route exact path="/vendor-category-timing" component={VendorCategoryTiming} />
                    <Route exact path="/vendor-account-settings" component={VendorAccSettings} />
                    <Route exact path="/vendor-preparation-time" component={VendorPreparationTime} />
                    <Route exact path="/vendor-payout-details" component={VendorPayoutDetails} />
                    <Route exact path="/vendor-business-report" component={VendorBusinessReport} />
                    <Route exact path="/vendor-add-menu" component={VendorAddMenu} />
                    <Route exact path="/vendor-edit-coupon/:id" component={VendorEditCoupon} />
                    <Route exact path="/vendor-add-item" component={VendorAddItem} />
                    <Route exact path="/vendor-edit-item/:id" component={VendorEditItem} />
                    <Route exact path="/vendor-store-rating" component={VendorRating} />
                    <Route exact path="/vendor-item-schedule/:id" component={VendorItemShedule} />
                    <Route exact path="/vendor-item-category-schedule/:id" component={VendorItemCategoryShedule} />
                    <Route exact path="/vendor-add-item-category" component={VendorAddItemCategory} />
                    <Route exact path="/vendor-edit-item-category/:id" component={VendorEditItemCategory} />
                    <Route exact path="/vendor-add-addon-category" component={VendorAddAddonCategory} />
                    <Route exact path="/vendor-edit-addon-category/:id" component={VendorEditAddonCategory} />
                    <Route exact path="/vendor-add-addon" component={VendorAddAddon} />
                    <Route exact path="/vendor-edit-addon/:id" component={VendorEditAddon} />
                    {/* MOBILE  */}
                    <Route exact path="/" component={OnBoarding} />
                    <Route exact path="/home" component={Home} />
                    <Route exact path="/signup" component={SignUp} />
                    <Route exact path="/login" component={SignUp} />
                    <Route exact path="/otp-verify" component={Otp} />
                    <Route exact path="/location-sheet" component={LocationSheet} />


                    <Route exact path="/notifications" component={Notifications} />
                    <Route exact path="/subscription-plans" component={SubscriptionPlans} />
                    <Route exact path="/single-subscription-plan/:id" component={SingleSubscriptionPlan} />
                    <Route exact path="/subscription-success/:id" component={SubscriptionSuccess} />
                    <Route exact path="/order-success/:id" component={OrderSuccess} />
                    <Route exact path="/task-succes" component={TaskSuccess} />
                    <Route exact path="/my-profile" component={MyProfile} />
                    <Route exact path="/wallet" component={Wallet} />
                    <Route exact path="/completed-order" component={CompletedOrder} />
                    <Route exact path="/rate-meal" component={RateMeal} />
                    <Route exact path="/running-orders/:type" component={RunningOrders} />
                    <Route exact path="/single-order/:id" component={SingleOrder} />
                    <Route exact path="/product-review/:id" component={PlaceReview} />
                    <Route exact path="/store-order-payment/:id" component={StoreOrderPayment} />
                    <Route exact path="/single-product/:id" component={Product} />
                    <Route exact path="/add-pickup-drops" component={AddPickUpAndDrop} />
                    <Route exact path="/courier" component={Courier} />
                    <Route exact path="/my-coupon" component={MyCoupon} />
                    <Route exact path="/coupon-list" component={CouponList} />
                    <Route exact path="/courier-success/:id" component={CourierSuccess} />
                    <Route exact path="/pickup-success/:id" component={PickupSuccess} />    
                    <Route exact path="/add-address" component={SignUpProcess} />
                    <Route exact path="/edit-location" component={EditLocation} />
                    <Route exact path="/payment-method" component={PaymentMethod} />
                    <Route exact path="/pickup-drop" component={PickUpAndDrop} />
                    <Route exact path="/explore-medicines" component={Medicines} />
                    <Route exact path="/medicines" component={Medicines} />
                    <Route exact path="/refer-and-earn" component={ReferAndEarn} />
                    <Route exact path="/my-address" component={MyAddress} />
                    <Route exact path="/edit-profile" component={EditProfile} />
                    <Route exact path="/any-store" component={AnyStore} />
                    <Route exact path="/cart" component={Cart} />
                    <Route exact path="/single-store/:id?/:category?" component={Store} />
                    <Route exact path="/food" component={Food} />
                    <Route exact path="/detail-menu" component={DetailMenu} />
                    <Route exact path="/confirm-order" component={ConfirmOrder} />
                    <Route exact path="/explore-menu" component={ExploreMenu} />
                    <Route exact path="/restaraunt-filter" component={RestarauntFilter} />
                    <Route exact path="/membership" component={YourCoupon} />
                    <Route exact path="/store-category/:id" component={Groceries} />
                    <Route exact path="/top-offer/:type/:id" component={TopOffer} />
                    <Route exact path="/single-coupon/:id" component={SingleCoupon} />
                    <Route exact path="/item-group-items/:id" component={ListItemGroup} />
                    <Route exact path="/item-group-stores/:id" component={ListStoreGroup} />
                    <Route exact path="/my-rewards" component={MyRewards} />
                    <Route exact path="/liked-items" component={LikedItems} />
                    <Route exact path="/set-location" component={SetLocation} />
                    <Route exact path="/single-deals-today" component={SingleDealsToday} />
                    <Route exact path="/with-prescription" component={WithPrescription} />


                </Switch>
            {/* </RefreshWrapper> */}
        </BrowserRouter>
    </Root>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(// console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
