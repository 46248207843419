import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import VendorBackButton from '../../Elements/VendorBackButton';
import Loading from '../../Elements/loading'
import { addItemCategory } from "../../../redux/vendor/items/action";
import Modal from "react-modal";
import image from '../../../assets/images/greentick.png'


export class VendorAddItemCategory extends Component {
    state = {
        loading: false,
        category: {},
        imageName: '',
        open: false,
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    handleInput(e, name) {
        let itemcategory = this.state.category;
        itemcategory[name] = e;
        this.setState({ category: itemcategory });
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { category } = this.state;
        if (category.image && category.image.size > 200000) {
            const alert = document.getElementById('alertdiv')
            alert.innerHTML = '<p>*The image may not be greater than 200 kilobytes.</p>'
        } else {
            let formData = new FormData()
            formData.append("name", category.name)
            formData.append("image", category.image)
            formData.append("description", category.description)
            formData.append("store_id", user.data.store[0].id)
            formData.append("token", user.data.auth_token)
            this.props.addItemCategory(formData)
                .then((response) => {
                    if (response) {
                        document.getElementById('itemcategoryform').reset()
                        document.getElementById('alertdiv').innerHTML = ''
                        this.setState({ imageName: '' })
                        this.handleModal()
                    }
                })
        }
    }
    handleModal = () => {
        this.setState({ open: !this.state.open });
    };
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Add Item Category" />
                        </div>
                        <div className="px-4 mt-4 pt-3">
                            <div className="mt-5 px-4 pt-4">
                                <div className="coupon-heading-one">
                                    <span>Add Item Categories to your Store</span>
                                </div>
                                <div className="coupon-heading-two text-muted">
                                    <span>Enter Category Details</span>
                                </div>
                            </div>
                            <div className="form-coupon">
                                <Form onSubmit={this.onSubmit} id="itemcategoryform">
                                    <InputGroup className="mt-4 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="text"
                                            placeholder="Enter category title"
                                            className="input-coupon"
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "name");
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="coupon-input mt-3 p-2 d-flex justify-content-between ">
                                        <div style={{ wordBreak: 'break-word', width: "30px" }}>
                                            <label className='text-muted px-3'>
                                                {this.state.imageName ? (
                                                    <span>
                                                        {this.state.imageName}
                                                    </span>
                                                ) : (
                                                    <>
                                                        Category Image
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                        <input className='upload-button-coupon' style={{ display: 'none' }} name='image' type="file" id="upload-button-image" onChange={(event) => {
                                            let file = event.target.files[0]
                                            this.setState({ file: URL.createObjectURL(file) });
                                            this.handleInput(file, "image");
                                            this.setState({ imageName: file.name });
                                        }} />
                                        <label className='upload-button-coupon px-3 py-1 ml-3' for='upload-button-image' >
                                            Upload File
                                        </label>
                                    </div>
                                    <div id="alertdiv" className='text-danger ms-2' style={{ fontSize: '12px' }}></div>
                                    <InputGroup className="mt-3 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            as="textarea" rows={3}
                                            placeholder="Category Description"
                                            className="input-coupon"
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "description");
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="position-fixed w-100 me-3 px-4" style={{ bottom: '0px', left: '0px' }}>
                                        <button type='submit' className='button-coupon p-3' >
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.open}
                            ariaHideApp={false}
                            onRequestClose={() => this.handleModal()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div
                                className="container"
                                style={{
                                    borderRadius: "5px",
                                    height: "210px",
                                    zIndex: "9999",
                                }}
                            >
                                <React.Fragment>
                                    <div className="mb-20 d-flex justify-content-center align-items-center">
                                        <img src={image} style={{ objectFit: 'contain', height: '110px' }} alt="success" />
                                    </div>
                                    <div className="col-12 pb-3 d-flex flex-column align-items-center justify-content-center">
                                        <h1 className="pt-3 mb-0 font-weight-black h4">
                                            Category
                                        </h1>
                                        <h1 className="pt-1 mb-0 font-weight-black h4">
                                            Added Successfully
                                        </h1>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}

            </React.Fragment>)
    }
}
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
})

export default connect(mapStateToProps, { addItemCategory })(VendorAddItemCategory)
