import {
    GET_SINGLE_STORE,
} from "./actionType";
const initialState = {
    store: []
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_STORE:
            return { ...state, store: action.payload };
        default:
            return state;
    }
}
