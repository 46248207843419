import {
    GET_ORDER_RATING,
    COURIER_STATUS,
    PICKUP_STATUS,
    GET_ORDERS_TO_REVIEW,
    GET_SINGLE_ORDER
} from './actionType';

const initialState = {
    ratings: [],
    courierStatus: [],
    anystoreStatus: [],
    reviewOrder: [],
    singleOrder: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ORDER_RATING:
            return { ...state, ratings: action.payload };
        case COURIER_STATUS:
            return { ...state, courierStatus: action.payload };
        case GET_SINGLE_ORDER:
            return { ...state, singleOrder: action.payload };
        case PICKUP_STATUS:
            return { ...state, anystoreStatus: action.payload };
        case GET_ORDERS_TO_REVIEW:
            return { ...state, reviewOrder: action.payload };
        default:
            return state;
    }
}
