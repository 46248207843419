import { set } from 'lodash'
import React, { Component } from 'react'
import ContentLoader from 'react-content-loader'
import ProgressiveImage from 'react-progressive-image'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../api'
import MobileBackButton from '../../Elements/MobileBackButton'
import { getAllPlans } from '../../../redux/mobile/subscription/action'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import Truncate from 'react-truncate'
import coin from '../../../assets/images/coins.png'
import bike from '../../../assets/images/003.png'
import sub from "../../../assets/images/001.png"
import moment from "moment";
export class SubscriptionPlans extends Component {
    state = {
        loading: false,
        plans: [],
        plan: []
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { user } = this.props;
        this.props.getAllPlans(user.data.auth_token).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, plans: response.payload.plans, plan: response.payload.plan })
            }
        })
    }
    getData = (date) => {
        var a = moment(date);
        var b = moment(new Date());
        let c = a.diff(b, 'days') // 1
        return c
    }
    render() {
        const { plan, plans } = this.state;
        const { user } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ?
                    (
                        <React.Fragment>
                            <ContentLoader
                                speed={1}
                                viewBox="0 0 280 576"
                                backgroundColor={'#F3F3F3'}
                                foregroundColor={'#FFFFFF'}>
                                <rect x="12" y="26" rx="8" ry="8" width="22" height="22" />
                                <rect x="12" y="85" rx="10" ry="10" width="200" height="30" />
                                <rect x="12" y="127" rx="10" ry="10" width="200" height="10" />
                                <rect x="18" y="178" rx="14" ry="10" width="249" height="159" />
                                <rect x="12" y="500" rx="10" ry="18" width="250" height="30" />
                            </ContentLoader>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {plan ? (
                                <React.Fragment>
                                    <div className="d-flex flex-column px-3">
                                        <div className="mobile-ntfcn-head py-4">
                                            <div className="pt-3 px-1">
                                                <MobileBackButton
                                                    history={this.props.history}
                                                />
                                            </div>
                                            <div className="text-center w-100 mt-3">
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={sub}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            style={{
                                                                height: "45vw",
                                                                borderRadius: "10px",
                                                                objectFit: "cover",
                                                                backgroundRepeat:
                                                                    "round",
                                                            }}
                                                            className=""
                                                            alt="grosav"
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                            </div>
                                            <div className="text-center">
                                                <span className="">{plan?.subscription?.plan_name}</span>
                                            </div>
                                            <div className="text-center">
                                                <span style={{ fontSize: '0.9em', color: '#fea24b' }}>Ends In {this.getData(plan?.end_date)} Days</span>
                                            </div>
                                            <div className="text-center" style={{ fontSize: '0.7em', color: '#fea24b' }}>
                                                <Truncate lines={2}>
                                                    {plan?.subscription?.plan_description}
                                                </Truncate>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="position-fixed  w-100" style={{ zIndex: "1", bottom: '0px', backgroundColor: "#fff", backgroundImage: `url(${bike})`, backgroundRepeat: 'round', height: '100vw' }}>
                                    </div>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {plans && plans.length > 0 ? (
                                        <div className="d-flex flex-column">
                                            <div className="mobile-ntfcn-head py-4">
                                                <div className="pt-3 px-1">
                                                    <MobileBackButton
                                                        history={this.props.history}
                                                    />
                                                </div>
                                                <div className="pt-5 ps-4 text-center">
                                                    <span className="">Plans for You</span>
                                                </div>
                                            </div>
                                            {plans && plans.map((item, index) => (
                                                <div className="px-4 mt-3">
                                                    <Link to={"/single-subscription-plan/" + item.id} style={{ color: '#000' }}>
                                                        <div className='d-flex w-100 p-3 position-relative' style={{ border: '1px solid #FEA24B', borderRadius: '15px' }}>
                                                            <div >
                                                                <ProgressiveImage
                                                                    delay={20}
                                                                    src={IMAGE_BASE_URL + item?.image}
                                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                                >
                                                                    {(src, loading) => (
                                                                        <img
                                                                            src={src}
                                                                            style={{
                                                                                width: "25vw",
                                                                                height: "25vw",
                                                                                borderRadius: "10px",
                                                                                objectFit: "cover",
                                                                                backgroundRepeat:
                                                                                    "round",
                                                                            }}
                                                                            className=""
                                                                            alt="grosav"
                                                                        />
                                                                    )}
                                                                </ProgressiveImage>
                                                            </div>
                                                            <div className='flex-grow-1 d-flex flex-column ps-3'>
                                                                <div style={{ fontSize: '1.2em', fontWeight: '700' }}>
                                                                    {item.plan_name} <span style={{ fontSize: '.8em', fontWeight: '500' }}>({item.period} {item.period_type})</span>
                                                                </div>
                                                                <div style={{ fontSize: '1.1em', fontWeight: '600' }} >
                                                                    Rs {Math.round(item.price)}
                                                                </div>

                                                                <div className='flex-grow-1'>
                                                                    <Truncate lines={2}>
                                                                        {item.plan_description}
                                                                    </Truncate>
                                                                </div>
                                                                {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 && user?.data?.activePlan?.subscription_id == item?.id &&

                                                                    <div className="text-center">
                                                                        <span style={{ fontSize: '1em', color: '#fea24b', fontWeight: 'bold' }}>Ends In {user?.data?.remainining_plan_days} Days</span>
                                                                    </div>
                                                                }
                                                            </div>

                                                            {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 && user?.data?.activePlan?.subscription_id == item?.id &&
                                                                <div className='position-absolute' style={{ top: '5px', right: '15px', color: '#42bb42', fontWeight: '600', }}>
                                                                    Active <IoMdCheckmarkCircleOutline />
                                                                </div>
                                                            }

                                                        </div>
                                                    </Link>
                                                </div>
                                            ))}


                                        </div>
                                    ) : (
                                        <div className="d-flex flex-column">
                                            <div className="mobile-ntfcn-head py-4">
                                                <div className="pt-3 px-1">
                                                    <MobileBackButton
                                                        history={this.props.history}
                                                    />
                                                </div>
                                                <div className="pt-5 ps-4 text-center">

                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <img
                                                    src={coin}
                                                    style={{
                                                        width: "90%",
                                                        borderRadius: "10px",
                                                        objectFit: "cover",
                                                        backgroundRepeat:
                                                            "round",
                                                    }}
                                                    className=""
                                                    alt="grosav"
                                                />

                                            </div>
                                            <div className="text-center" style={{ fontSize: '1.2em', color: '#fea24b' }}>
                                                No Plans Found
                                            </div>
                                        </div>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user
})
export default connect(mapStateToProps, {
    getAllPlans
})(SubscriptionPlans)
