import {
    GET_SINGLE_ITEM,
    GET_STORE_INFO,
    GET_ALL_CATEGORY,
    GET_ALL_LIKED_ITEMS,
    GET_ALL_ANY_STORE_COURIER_CATEGORY
} from "./actionType";


const initialState = {
    singleItem: [],
    store_info: [],
    category: [],
    likeditems: [],
    anystorecategory: [],
    couriercategory: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_SINGLE_ITEM:
            return { ...state, singleItem: action.payload };
        case GET_STORE_INFO:
            return { ...state, store_info: action.payload };
        case GET_ALL_CATEGORY:
            return { ...state, category: action.payload };
        case GET_ALL_LIKED_ITEMS:
            return { ...state, likeditems: action.payload };
        case GET_ALL_ANY_STORE_COURIER_CATEGORY:
            return { ...state, anystorecategory: action.payload.anystore, couriercategory: action.payload.courier }
        default:
            return state;
    }
}
