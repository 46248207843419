import {
    GET_ITEMS,
    GET_ITEM_CATEGORIES,
    GET_ADDON_CATEGORIES,
    GET_ADDONS,
    GET_ALL_STORE_ITEMS,
    ADD_DEAL_OF_TODAY,
    CHANGE_ITEM_STATUS,
    CHANGE_DEAL_STATUS,
    GET_ITEM_SCHEDULE_DATE,
    ADD_ITEM_SCHEDULE_DATE,
    GET_ITEM_CATEGORY_SCHEDULE_DATE,
    ADD_ITEM_CATEGORY_SCHEDULE_DATE
} from "./actionType";

import {
    GET_ITEMS_URL,
    GET_SEARCH_ITEMS_URL,
    GET_TOGGLE_ITEM_URL,
    GET_VENDOR_SINGLE_ITEM_URL,
    EDIT_VENDOR_ITEM_URL,
    ADD_VENDOR_ITEM_URL,
    GET_ITEM_CATEGORIES_URL,
    GET_TOGGLE_ITEM_CATEGORY_URL,
    GET_VENDOR_SINGLE_ITEM_CATEGORY_URL,
    GET_SEARCH_ITEM_CATEGORIES_URL,
    ADD_ITEM_CATEGORY_URL,
    EDIT_ITEM_CATEGORY_URL,
    GET_CATEGORY_FILTER_URL,
    GET_ADDON_CATEGORIES_URL,
    GET_VENDOR_SINGLE_ADDON_CATEGORY_URL,
    GET_TOGGLE_ADDON_CATEGORY_URL,
    GET_SEARCH_ADDONS_CATEGORIES_URL,
    ADD_ADDON_CATEGORY_URL,
    EDIT_ADDON_CATEGORY_URL,
    GET_ADDONS_URL,
    GET_VENDOR_SINGLE_ADDON_URL,
    ADD_VENDOR_ADDON_URL,
    EDIT_VENDOR_ADDON_URL,
    GET_SEARCH_ADDONS_URL,
    GET_TOGGLE_ADDON_URL,
    GET_ALL_STORE_ITEMS_URL,
    ADD_DEAL_OF_TODAY_URL,
    CHANGE_ITEM_STATUS_URL,
    CHANGE_DEAL_STATUS_URL,
    ADD_ITEM_SCHEDULE_DATE_URL,
    GET_ITME_SCHEDULE_DATE_URL,
    GET_ITME_CATEGORY_SCHEDULE_DATE_URL,
    ADD_ITEM_CATEGORY_SCHEDULE_DATE_URL
} from "../../../api";

import Axios from "axios";


//items
export const getVendorItems = (token, id) => (dispatch) => {
    return Axios.post(GET_ITEMS_URL, {
        token, id: id
    })
        .then((response) => {
            let items = response.data;
            return dispatch({
                type: GET_ITEMS,
                payload: items
            })

        })
}
export const getVendorSearchItems = (input, category_id) => () => {
    return Axios.post(GET_SEARCH_ITEMS_URL, {
        input: input, category_id: category_id
    })
}
export const vendorToggleItem = (id) => () => {
    return Axios.post(GET_TOGGLE_ITEM_URL, { id })
}
export const getVendorSingleItem = (id) => () => {
    return Axios.post(GET_VENDOR_SINGLE_ITEM_URL, { id })
}
export const getAllItemCategories = (token, id) => (dispatch) => {
    return Axios.post(GET_ITEM_CATEGORIES_URL, {
        token, id
    })
        .then((response) => {
            let itemcategories = response.data;
            return dispatch({
                type: GET_ITEM_CATEGORIES,
                payload: itemcategories
            })

        })
}
export const vendorfilterCategory = (id) => () => {
    return Axios.post(GET_CATEGORY_FILTER_URL, {
        id: id
    })
}

//item category
export const vendorToggleItemCategory = (category_id, input, id) => () => {
    return Axios.post(GET_TOGGLE_ITEM_CATEGORY_URL, {
        category_id, input, id
    })
}
export const getVendorSearchItemCategories = (input, category_id) => () => {
    return Axios.post(GET_SEARCH_ITEM_CATEGORIES_URL, {
        input: input, category_id: category_id
    })
}
export const getVendorSingleItemCategory = (id) => () => {
    return Axios.post(GET_VENDOR_SINGLE_ITEM_CATEGORY_URL, { id })
}

//addon
export const getAllAddons = (token, id) => (dispatch) => {
    return Axios.post(GET_ADDONS_URL, {
        token, id: id
    })
        .then((response) => {
            let addons = response.data;
            return dispatch({
                type: GET_ADDONS,
                payload: addons
            })

        })
}
export const getVendorSingleAddon = (id) => () => {
    return Axios.post(GET_VENDOR_SINGLE_ADDON_URL, { id })
}
export const vendorToggleAddon = (id, input) => () => {
    return Axios.post(GET_TOGGLE_ADDON_URL, {
        id, input
    })
}
export const getVendorSearchAddons = (input, category_id) => () => {
    return Axios.post(GET_SEARCH_ADDONS_URL, {
        input: input, category_id: category_id
    })
}

//addon categories
export const getVendorSearchAddonCategories = (input, category_id) => () => {
    return Axios.post(GET_SEARCH_ADDONS_CATEGORIES_URL, {
        input: input, category_id: category_id
    })
}
export const getVendorSingleAddonCategory = (id) => () => {
    return Axios.post(GET_VENDOR_SINGLE_ADDON_CATEGORY_URL, { id })
}

export const getAllAddonCategories = (token, id) => (dispatch) => {
    return Axios.post(GET_ADDON_CATEGORIES_URL, {
        token, id: id
    })
        .then((response) => {
            let addoncategories = response.data;
            return dispatch({
                type: GET_ADDON_CATEGORIES,
                payload: addoncategories
            })

        })
}
export const vendorToggleAddonCategory = (category_id, input, id) => () => {
    return Axios.post(GET_TOGGLE_ADDON_CATEGORY_URL, {
        category_id, input, id
    })
}

//add data inventory
export const addVendorItem = (formdata) => () => {
    return Axios.post(ADD_VENDOR_ITEM_URL, formdata, {})
}
export const editVendorItem = (formdata) => () => {
    return Axios.post(EDIT_VENDOR_ITEM_URL, formdata, {})
}
export const addItemCategory = (formdata) => () => {
    return Axios.post(ADD_ITEM_CATEGORY_URL, formdata, {})
}
export const editItemCategory = (formdata) => () => {
    return Axios.post(EDIT_ITEM_CATEGORY_URL, formdata, {})
}
export const addAddonCategory = (formdata) => () => {
    return Axios.post(ADD_ADDON_CATEGORY_URL, formdata, {})
}
export const editAddonCategory = (formdata) => () => {
    return Axios.post(EDIT_ADDON_CATEGORY_URL, formdata, {})
}
export const addVendorAddon = (formdata) => () => {
    return Axios.post(ADD_VENDOR_ADDON_URL, formdata, {})
}
export const editVendorAddon = (formdata) => () => {
    return Axios.post(EDIT_VENDOR_ADDON_URL, formdata, {})
}

export const getAllStoreItems = (token, id) => (dispatch) => {
    return Axios.post(GET_ALL_STORE_ITEMS_URL, {
        token, id: id
    })
        .then((response) => {
            let addoncategories = response.data;
            return dispatch({
                type: GET_ALL_STORE_ITEMS,
                payload: addoncategories
            })

        })
}

export const addDealOfToday = (token, store_id, selectedItems, start_time, end_time, percentage, name) => (dispatch) => {
    return Axios.post(ADD_DEAL_OF_TODAY_URL, {
        token, store_id, selectedItems, start_time, end_time, percentage, name
    })
        .then((response) => {
            let addoncategories = response.data;
            return dispatch({
                type: ADD_DEAL_OF_TODAY,
                payload: addoncategories
            })

        })
}

export const changeDealStatus = (token, store_id, deal_id) => (dispatch) => {
    return Axios.post(CHANGE_DEAL_STATUS_URL, {
        token, store_id, deal_id
    })
        .then((response) => {
            let deal = response.data;
            return dispatch({
                type: CHANGE_DEAL_STATUS,
                payload: deal
            })

        })
}

export const changeItemStatus = (token, store_id, item_id, deal_id) => (dispatch) => {
    return Axios.post(CHANGE_ITEM_STATUS_URL, {
        token, store_id, item_id, deal_id
    })
        .then((response) => {
            let deal = response.data;
            return dispatch({
                type: CHANGE_ITEM_STATUS,
                payload: deal
            })

        })
}
// item scheduleing

export const getScheduleDate = (token, item_id) => (dispatch) => {
    return Axios.post(GET_ITME_SCHEDULE_DATE_URL, { token, item_id })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: GET_ITEM_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}

export const addScheduleDate = (token, item_id, schedule_data) => (dispatch) => {
    return Axios.post(ADD_ITEM_SCHEDULE_DATE_URL, { token, item_id, schedule_data })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: ADD_ITEM_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}


export const getScheduleCategoryDate = (token, item_category_id) => (dispatch) => {
    return Axios.post(GET_ITME_CATEGORY_SCHEDULE_DATE_URL, { token, item_category_id })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: GET_ITEM_CATEGORY_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}

export const addScheduleCategoryDate = (token, item_category_id, schedule_data) => (dispatch) => {
    return Axios.post(ADD_ITEM_CATEGORY_SCHEDULE_DATE_URL, { token, item_category_id, schedule_data })
        .then((response) => {
            let schedule_date = response.data;
            return dispatch({
                type: ADD_ITEM_CATEGORY_SCHEDULE_DATE,
                payload: schedule_date
            })
        })
}
