import React, { Component } from "react";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import { connect } from "react-redux";
import VendorFooter from "../VendorFooter";
import Loading from "../../Elements/loading";
import {
    getVendorItems,
    getVendorSearchItems,
    vendorToggleItem,
    getAllItemCategories,
    vendorfilterCategory,
    getAllAddonCategories,
    getAllAddons,
} from "../../../redux/vendor/items/action";
import Addons from "./Addons"
import ItemCategories from './ItemCategories';
import AddonCategories from './AddonCategories';
import InventoryItems from './InventoryItems';

const { TabPane } = Tabs;
export class VendorInventory extends Component {
    state = {
        activeTab: "1",
        size: "small",
        loading: false,
        order: false,
        updatedItem: [],
        updatedItemCategory: [],
        updatedAddOn: [],
        updatedAddOnCategory: [],
    };

    // ---inventory---
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1000);
    }
    changeTab = (activeKey) => {
        this.setState({ activeTab: activeKey, });
    };

    //Item
    updatedItem = (data) => {
        this.state.updatedItem.push(data);
    }

    //Item category
    updatedItemCategory = (data) => {
        this.state.updatedItemCategory.push(data);
    }

    //addon
    updatedAddOn = (data) => {
        this.state.updatedAddOn.push(data);
    }

    //addon category
    updatedAddOnCategory = (data) => {
        this.state.updatedAddOnCategory.push(data);
    }

    render() {
        const { size } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div className="pt-5">
                            <span className="inventory-title fs-5 px-4">Inventory</span>
                        </div>
                        <Tabs
                            defaultActiveKey="1"
                            className="px-4 mt-2"
                            activeKey={this.state.activeTab}
                            onChange={this.changeTab}
                            size={size}>
                            <TabPane tab={<span>Items</span>} key="1">
                                <InventoryItems updatedItem={this.state.updatedItem} key={this.state.updatedItem.length}
                                    setUpdatedItemCategory={this.updatedItemCategory} />
                            </TabPane>
                            <TabPane tab={<span> Item Categories</span>} key="2">
                                <ItemCategories updatedItemCategory={this.state.updatedItemCategory} key={this.state.updatedItemCategory.length}
                                    setUpdatedItem={this.updatedItem} />
                            </TabPane>
                            <TabPane tab={<span>Add-Ons</span>} key="3">
                                <Addons updatedAddOn={this.state.updatedAddOn} key={this.state.updatedAddOn.length}
                                    setUpdatedAddOnCategory={this.updatedAddOnCategory} />
                            </TabPane>
                            <TabPane tab={<span>Addon Categories</span>} key="4">
                                <AddonCategories updatedAddOnCategory={this.state.updatedAddOnCategory} key={this.state.updatedAddOnCategory.length}
                                    setUpdatedAddOn={this.updatedAddOn} />
                            </TabPane>
                        </Tabs>

                        <VendorFooter active_inventory={true} />
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    items: state.items.items,
    itemcategories: state.items.itemcategories,
    addoncategories: state.items.addoncategories,
});

export default connect(mapStateToProps, {
    getVendorItems,
    getVendorSearchItems,
    vendorToggleItem,
    getAllItemCategories,
    vendorfilterCategory,
    getAllAddonCategories,
    getAllAddons,
})(VendorInventory);
