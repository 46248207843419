import { GET_ITEMS, GET_ITEM_CATEGORIES, GET_ADDONS, GET_ADDON_CATEGORIES } from "./actionType";


const initialState = {
    items: [],
    itemcategories: [],
    addons: [],
    addoncategories: [],
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ITEMS:
            return { ...state, items: action.payload };
        case GET_ITEM_CATEGORIES:
            return { ...state, itemcategories: action.payload };
        case GET_ADDON_CATEGORIES:
            return { ...state, addoncategories: action.payload };
        case GET_ADDONS:
            return { ...state, addons: action.payload };
        default:
            return state;
    }
}
