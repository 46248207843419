import React, { Component } from 'react'
import { connect } from 'react-redux'
import MobileBackButton from '../../Elements/BackButtonOnly'
import { Link } from 'react-router-dom'
import Premium from '../../../assets/images/premium.png'
import { Rating } from "react-simple-star-rating";
import { AiFillStar } from 'react-icons/ai'
import Standard from '../../../assets/images/standard.png'
import Basic from '../../../assets/images/basic.png'
import ContentLoader from 'react-content-loader'
export class YourCoupon extends Component {
    state = {
        loading: false
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 2500)
    }


    render() {
        return (
            <React.Fragment>
                {this.state.loading ?
                    <React.Fragment>
                        <ContentLoader
                            speed={1}

                            viewBox="0 0 280 576"
                            backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>
                        
                            <rect x="10" y="20" rx="5" ry="5" width="22" height="22" />
                            <rect x="100" y="60" rx="8" ry="8" width="80" height="15" />
                            <rect x="10" y="95" rx="15" ry="15" width="258" height="90" />
                            <rect x="10" y="195" rx="15" ry="15" width="258" height="90" />
                            <rect x="10" y="295" rx="15" ry="15" width="258" height="90" />
                            <rect x="10" y="530" rx="10" ry="10" width="258" height="40" />

                        </ContentLoader>
                    </React.Fragment>
                    :
                    <React.Fragment>

                        <div className='mobile-sign-up-bg-img py-4'>
                            <div className='pt-3 px-1'>
                                <MobileBackButton />
                            </div>
                            <div className='px-3 mobile-sign-up-head text-center'>
                                <div><span style={{fontSize:"21px"}}>Plans For You</span></div>
                            </div>
                        </div>

                        <div className='px-4'>
                            <Link to="">
                                <div className='mobile-your-coupon-card p-3 d-flex flex-row'>
                                    <div className='ps-2'>
                                        <img src={Premium} alt="" />
                                    </div>
                                    <div className='d-flex flex-column mx-3'>
                                        <div className='mobile-yr-cpn-pr'>
                                            Premium
                                        </div>
                                        <div className="d-flex flex-row " style={{ color: " #FF7A28", fontSize: "1.5em" }}>
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                        </div>
                                        <div style={{ fontWeight: "500", fontSize: "14px", color: "black" }}>1 year free delivery</div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='px-4 mt-4'>
                            <Link to="">
                                <div className='mobile-your-coupon-card p-3 d-flex flex-row'>
                                    <div className='ps-2'>
                                        <img src={Standard} alt="" />
                                    </div>
                                    <div className='d-flex flex-column mx-3'>
                                        <div className='mobile-yr-cpn-pr'>
                                            Standard
                                        </div>
                                        <div className="d-flex flex-row " style={{ color: " #FF7A28", fontSize: "1.5em" }}>
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                        </div>
                                        <div style={{ fontWeight: "500", fontSize: "14px", color: "black" }}>5 month free delivery</div>
                                    </div>
                                </div>
                            </Link>
                        </div>

                        <div className='px-4 mt-4'>
                            <Link to="">
                                <div className='mobile-your-coupon-card p-3 d-flex flex-row'>
                                    <div className='ps-2'>
                                        <img src={Basic} alt="" />
                                    </div>
                                    <div className='d-flex flex-column mx-3'>
                                        <div className='mobile-yr-cpn-pr'>
                                            Basic
                                        </div>
                                        <div className="d-flex flex-row " style={{ color: " #FF7A28", fontSize: "1.5em" }}>
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                            <AiFillStar className='mobile-yr-cpn-str' />
                                        </div>
                                        <div style={{ fontWeight: "500", fontSize: "14px", color: "black" }}>1 month free delivery</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className=" px-3  pb-4 w-100 position-fixed" style={{ bottom: "0" }}>
                            <button
                                className="mobile-yr--cpn-sbcr-btn p-3 w-100"
                                type="button"
                            >
                                <b style={{ color: "white", fontWeight: "700", fontSize: "16px" }}>Subscribe Now</b>
                            </button>
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})



export default connect(mapStateToProps)(YourCoupon)