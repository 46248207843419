import React, { Component } from "react";
import { connect } from "react-redux";
import { FaClinicMedical } from "react-icons/fa";
import { AiFillStar, AiFillHeart, AiOutlineHeart, AiOutlineMinus, AiOutlineFieldTime, AiOutlinePlus, AiOutlineStop } from "react-icons/ai";
// import { ImSpoonKnife } from "react-icons/im";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api';
import { addProduct, removeProduct } from '../../../../redux/mobile/cart/action'
import { toggleFavItem } from "../../../../redux/mobile/item/action.js";
import FloatCart from '../../Cart/FloatCart'
import Customization from "../../Customization";
import Ink from "react-ink";
import Truncate from 'react-truncate';
import { ImSpoonKnife } from "react-icons/im";
import { TbDiscount2 } from "react-icons/tb";

export class MostOrderMedicines extends Component {
    state = {
        reload: true,
        float: false,
        isRunning: true,
    };
    toggleFavItem = (item) => {
        // alert('hiii')
        const { mostOrders } = this.props;
        const { user } = this.props;
        const singleItem = mostOrders.items.find(data => data.id === item);
        const favUser = singleItem.favorites.find((fav) => fav.user_id === user.data.id)
        const favIndex = singleItem.favorites.indexOf(favUser);
        if (favUser) {
            singleItem.favorites.splice(favIndex, 1);
        } else {
            singleItem.favorites.push({ user_id: user.data.id });
        }
        this.props = { ...this.props, mostOrders: mostOrders }
        this.setState({ reload: !this.state.reload })
        this.props.toggleFavItem(this.props.user.data.auth_token, item)
    }
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    floatCart = (on) => {
        this.setState({ float: on })
    }
    render() {
        const { user, mostOrders, addProduct, cartProducts, removeProduct } = this.props;
        // ////// console.log(mostOrders,'most order')
        return (
            <React.Fragment>
                {mostOrders && mostOrders.items && mostOrders.items.length > 0 &&
                    <div>
                        <div className="px-3 pt-3 d-flex flex-row align-items-center ">
                            <FaClinicMedical
                                style={{ fontSize: "1.5rem", color: "#FF9A62" }}
                            />
                            <span
                                style={{
                                    fontWeight: "700",
                                    fontSize: "18px",
                                    marginLeft: "10px",
                                }}
                            >
                                Most orderd Medicines
                            </span>
                        </div>
                            <div className="d-flex flex-row align-items-center ">
                                <div className="main-menu hidden-scroll-bar gap-3 p-3 "
                                    style={{ gridTemplateColumns: `repeat(${mostOrders.items.length},1fr)` }} >
                                    {mostOrders && mostOrders.items && mostOrders.items.map((most, index) => (
                                        <div className="p-3 top-food-item-card  ">
                                            <div className="d-flex flex-column position-relative b-r-40">
                                                <Link to={'/single-product/' + most.id}>
                                                    <ProgressiveImage
                                                        delay={20}
                                                        src={IMAGE_BASE_URL + most.image}
                                                        placeholder={PLACE_HOLDER_IMAGE}
                                                    >
                                                        {(src, loading) => (
                                                            <img
                                                                src={src}
                                                                style={{
                                                                    width: "55vw",
                                                                    height: "35vw",
                                                                    borderRadius: "20px",
                                                                    objectFit: "cover",
                                                                    backgroundRepeat:
                                                                        "round",
                                                                }}
                                                                className=""
                                                                alt="grosav"
                                                            />
                                                        )}
                                                    </ProgressiveImage>
                                                    {most?.market_price > most?.selling_price ? (
                                                        <div className="ribbon">
                                                            <span> <TbDiscount2 size={13} /> {Math.round(((most.market_price - most.selling_price) / most.market_price) * 100)} % off</span>
                                                        </div>
                                                    ) : most.is_popular ? (
                                                        <div className="ribbon">
                                                            <span> POPULAR</span>
                                                        </div>
                                                    ) : ""}
                                                </Link>
                                                {user && user.success > 0 ? (
                                                    <div className="d-flex position-absolute"
                                                        style={{ color: '#FF9012', top: '8px', right: '11px' }}
                                                        onClick={() => this.toggleFavItem(most.id)}>
                                                        {most?.favorites?.length > 0 && most?.favorites?.find((fav) => fav.user_id === user.data.id) ? (
                                                            <AiFillHeart size={"1.6em"} />
                                                        ) : (
                                                            <AiOutlineHeart size={"1.6em"} />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <Link to="/signUp">
                                                        <div className="d-flex position-absolute"
                                                            style={{ color: '#FF9012', top: '8px', right: '11px' }}>
                                                            <AiOutlineHeart size={"1.6em"} />
                                                        </div>
                                                    </Link>
                                                )}
                                                <div className="d-flex flex-column mt-2 ">
                                                    <Link to={'/single-product/' + most.id}>
                                                        <div className=" d-flex flex-row justify-content-between align-items-center home-heading">
                                                            <div className="flex-grow-1">
                                                                <Truncate lines={1}>
                                                                    {most?.name}
                                                                </Truncate>
                                                            </div>
                                                            <di className="m-0 top-food-color">
                                                                {most?.rating} <AiFillStar style={{ color: "FF9A62", }} size={15} />{" "}
                                                            </di>
                                                        </div>
                                                    </Link>
                                                    <Link to={'/single-product/' + most.id}>
                                                        <div className="d-flex align-items-center">
                                                            <ImSpoonKnife
                                                                style={{
                                                                    color: "FF9A62",
                                                                }}
                                                                size={10}
                                                            />
                                                            <span
                                                                className=" ms-2 fw-700"
                                                                style={{
                                                                    color: "#3B3B3B",
                                                                }}
                                                            >
                                                                {most?.store?.name}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                    <Link to={'/single-product/' + most.id}>
                                                        <div className="d-flex align-items-center ">
                                                            <AiOutlineFieldTime
                                                                style={{
                                                                    color: "FF9A62",
                                                                    fontSize: "12px",
                                                                }}
                                                                size={14}
                                                            />
                                                            <span
                                                                className=" ms-2"
                                                                style={{
                                                                    color: "#3B3B3B",
                                                                    fontSize: "10px",
                                                                }}
                                                            >
                                                                {most?.store?.approx_time_delivery ? most?.store.approx_time_delivery + ' Min - ' : ""}  {most?.store?.delivery_radius ? most?.store.delivery_radius + ' Km ' : ""}
                                                            </span>
                                                        </div>
                                                    </Link>
                                                    <div className="d-flex justify-content-between">
                                                        {most?.addoncategories?.length > 0 ? (
                                                            <Link to={'/single-product/' + most.id} className="d-flex flex-column ">
                                                                <div className="d-flex justify-content-between" >
                                                                    <div className="fw-bolder mt-3 pt-1"
                                                                        style={{ color: 'grey', fontSize: "15px", }}  >
                                                                        Customizable
                                                                    </div>
                                                                </div>
                                                            </Link>
                                                        ) : ("")}
                                                        <Link to={'/single-product/' + most.id} className={parseInt(most.market_price) > parseInt(most?.selling_price) ? "d-flex flex-column " : "d-flex flex-column justify-content-end align-items-end"}>
                                                            {parseInt(most.market_price) > parseInt(most?.selling_price) ? (
                                                                <div className=" fw-bolder">
                                                                    <s style={{ color: "#FF9A62", fontSize: "11px", }} >
                                                                        Rs {most?.market_price}
                                                                    </s>
                                                                </div>) : ('')
                                                            }
                                                            {parseInt(most?.selling_price) > 0 ? (
                                                                <div className="d-flex justify-content-between" >
                                                                    <div className=" text-black fw-bolder "
                                                                        style={{ fontSize: "16px", }}  >
                                                                        Rs {most?.selling_price}
                                                                    </div>
                                                                </div>
                                                            ) : ("")}
                                                        </Link>
                                                        <div className="d-flex flex-column justify-content-between align-items-center mt-2  ">
                                                            {user && user.success > 0 ? (
                                                                <>
                                                                    {most?.stock > 0 ? (
                                                                        <>
                                                                            {most?.addoncategories?.length > 0 ? (
                                                                                <>
                                                                                    {/* {////// console.log(item, 'iteemmmmmm')} */}
                                                                                    <Customization
                                                                                        floatCart={(e) => this.floatCart(e)}
                                                                                        product={most}
                                                                                        addProduct={addProduct}
                                                                                        forceUpdate={this.forceStateUpdate}
                                                                                        homeBtn={true} />
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {cartProducts.find((cp) => cp.id === most.id) !== undefined ? (
                                                                                        <>
                                                                                            {cartProducts.filter((cp) => cp.id === most.id).map((product) =>
                                                                                                <div className="d-flex align-items-center">
                                                                                                    <div className="top-food-add-min-btn" onClick={() => {
                                                                                                        most.quantity = 1;
                                                                                                        removeProduct(most);
                                                                                                        this.forceStateUpdate();
                                                                                                    }}>
                                                                                                        <AiOutlineMinus
                                                                                                            style={{
                                                                                                                color: "green",
                                                                                                            }}
                                                                                                            size={14}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="top-food-count ms-2 me-2">
                                                                                                        {product.quantity}
                                                                                                    </div>
                                                                                                    {product.stock > product.quantity ? (
                                                                                                        <div className="top-food-add-min-btn" onClick={() => {
                                                                                                            most.quantity = 1;
                                                                                                            addProduct(most);
                                                                                                            this.forceStateUpdate();
                                                                                                        }}>
                                                                                                            <AiOutlinePlus
                                                                                                                style={{
                                                                                                                    color: "green",
                                                                                                                }}
                                                                                                                size={14}
                                                                                                            />
                                                                                                        </div>
                                                                                                    ) : (
                                                                                                        <div className="top-food-add-min-btn">
                                                                                                            <AiOutlineStop
                                                                                                                style={{
                                                                                                                    color: "green",
                                                                                                                }}
                                                                                                                size={17}
                                                                                                            />
                                                                                                        </div>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="top-food-add-min-btn" onClick={() => {
                                                                                                most.quantity = 1;
                                                                                                addProduct(most);
                                                                                                this.forceStateUpdate();
                                                                                            }} >
                                                                                                <AiOutlinePlus
                                                                                                    style={{
                                                                                                        color: "green",
                                                                                                    }}
                                                                                                    size={14}
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="top-food-add-min-btn" style={{ background: '#fff' }}>
                                                                                <AiOutlineStop
                                                                                    style={{
                                                                                        color: "#FF9A62",
                                                                                    }}
                                                                                    size={17}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <Link to="/signUp">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="top-food-add-min-btn">
                                                                            <AiOutlinePlus
                                                                                style={{
                                                                                    color: "green",
                                                                                }}
                                                                                size={14}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </Link>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                    </div>
                }
                <FloatCart float={this.state.float} isRunning={this.state.isRunning} />
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
    cartProducts: state.cart.products
});

export default connect(mapStateToProps, { toggleFavItem, addProduct, removeProduct })(MostOrderMedicines);
