import React, { Component } from "react";
import Ink from "react-ink";
import { connect } from "react-redux";
import { formatPrice } from "../../../Helpers/formatPrice";
import {
    getDeliverySlab,
    updateSlabDetails,
} from "../../../../redux/mobile/cart/action";
import { Link } from "react-router-dom";

export class Bill extends Component {
    state = {
        delivery_charges: 0,
        delivery_charges_for_subscribers: 0,
        distance: 0,
        couponAppliedAmount: 0,
        totalTax: 0,
        surge_fee: 0,
        chargePopup: false,
        error_message: null,
        address: []
    };
    componentDidMount() {
        this.setState({ address: JSON.parse(localStorage.getItem("userSetAddress")), });
        const { store_info, max_distance, total, maxDistanceStore, distance, user } = this.props
        ////// // console.log(this.state.delivery_charges, "DDD");

        // ////// // console.log(parseFloat(total) >= parseFloat(store_info?.free_delivery_on_above_subtotal), 'asdasd=-=')
        if (user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0) {
            this.setState({ delivery_charges: 0 })
            this.calculateDynamicCityDeliveryChargeForSubscribers(total);

        } else if (store_info?.is_freedelivery && parseFloat(max_distance) <= parseFloat(store_info?.free_base_delivery_distance_within)) {

            this.calculateDynamicCityDeliveryCharge(total);
        } else if (store_info?.is_freedelivery && parseFloat(total) >= parseFloat(store_info?.free_delivery_on_above_subtotal)) {

            this.calculateDynamicCityDeliveryCharge(total);
        } else {

            if (store_info?.delivery_charge_type === "DYNAMIC" && store_info?.base_delivery_distance && store_info?.extra_delivery_distance && store_info?.extra_delivery_distance && store_info?.base_delivery_charge) {

                this.setState({ distance: distance }, () => {
                    this.calculateDynamicDeliveryCharge();
                });
            } else if (store_info?.delivery_charge_type === "FIXED" && parseFloat(store_info?.delivery_charge) > 0) {
                this.setState({ delivery_charges: store_info?.delivery_charge })
                localStorage.setItem("delivery_charges", store_info?.delivery_charge);
                // // // console.log("1", store_info?.delivery_charge);
            } else if (store_info?.city?.delivery_charge_type === "DYNAMIC" && store_info?.city?.base_delivery_distance && store_info?.city?.extra_delivery_distance && store_info?.city?.extra_delivery_distance && store_info?.city?.base_delivery_charge) {
                this.setState({ distance: distance }, () => {
                    //check if restaurant has dynamic delivery charge..
                    this.calculateDynamicCityDeliveryCharge(total);
                });
            } else if (store_info?.city?.delivery_charge_type === "FIXED" && parseFloat(store_info?.city?.delivery_charge) > 0) {
                this.setState({ delivery_charges: store_info?.city?.delivery_charge })
                localStorage.setItem("delivery_charges", store_info?.city?.delivery_charge);
                // // // console.log("2", store_info?.city?.delivery_charge);

            }
        }

        if (maxDistanceStore && maxDistanceStore?.store_id) {
            if (maxDistanceStore?.store_id?.city?.is_surge === 1) {
                this.setState({
                    surge_fee:
                        maxDistanceStore?.store_id?.city?.surge_fee,
                });
            }
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps?.user?.data?.is_active_plan == 1 && nextProps?.user?.data?.remainining_plan_days > 0) {
            this.setState({ delivery_charges: 0 })
            this.calculateDynamicCityDeliveryChargeForSubscribers(nextProps?.total);

        } else if (nextProps.store_info?.is_freedelivery && parseFloat(nextProps?.max_distance) <= parseFloat(nextProps.store_info?.free_base_delivery_distance_within)) {

            this.calculateDynamicCityDeliveryCharge(nextProps?.total);
        } else if (nextProps.store_info?.is_freedelivery && parseFloat(nextProps?.total) >= parseFloat(nextProps.store_info?.free_delivery_on_above_subtotal)) {

            this.calculateDynamicCityDeliveryCharge(nextProps?.total);
        } else {

            if (nextProps.store_info?.delivery_charge_type === "DYNAMIC" && nextProps.store_info?.base_delivery_distance && nextProps.store_info?.extra_delivery_distance && nextProps.store_info?.extra_delivery_distance && nextProps.store_info?.base_delivery_charge) {

                this.setState({ distance: nextProps.distance }, () => {
                    this.calculateDynamicDeliveryCharge();
                });
            } else if (nextProps.store_info?.delivery_charge_type === "FIXED" && parseFloat(nextProps.store_info?.delivery_charge) > 0) {
                this.setState({ delivery_charges: nextProps.store_info?.delivery_charge })
                localStorage.setItem("delivery_charges", nextProps.store_info?.delivery_charge);
                // // console.log("3", nextProps.store_info?.delivery_charge);

            } else if (nextProps.store_info?.city?.delivery_charge_type === "DYNAMIC" && nextProps.store_info?.city?.base_delivery_distance && nextProps.store_info?.city?.extra_delivery_distance && nextProps.store_info?.city?.extra_delivery_distance && nextProps.store_info?.city?.base_delivery_charge) {
                this.setState({ distance: nextProps.distance }, () => {
                    //check if restaurant has dynamic delivery charge..
                    this.calculateDynamicCityDeliveryCharge(nextProps?.total);
                });
            } else if (nextProps.store_info?.city?.delivery_charge_type === "FIXED" && parseFloat(nextProps.store_info?.city?.delivery_charge) > 0) {
                this.setState({ delivery_charges: nextProps.store_info?.city?.delivery_charge })
                localStorage.setItem("delivery_charges", nextProps.store_info?.city?.delivery_charge);
                // // // console.log("4", nextProps.store_info?.city?.delivery_charge);

            }
        }

        if (nextProps && nextProps.maxDistanceStore && nextProps.maxDistanceStore?.store_id) {
            if (nextProps.maxDistanceStore?.store_id?.city?.is_surge === 1) {
                this.setState({
                    surge_fee:
                        nextProps.maxDistanceStore?.store_id?.city?.surge_fee,
                });
            }
        }
    }

    calculateDynamicDeliveryCharge = () => {
        const { maxDistanceStore } = this.props;
        //this.setState({ delivery_charges: 0 })

        const distanceFromUserToStore = maxDistanceStore.distance;
        if (distanceFromUserToStore > maxDistanceStore.store_id?.base_delivery_distance) {

            const extraDistance = distanceFromUserToStore - maxDistanceStore.store_id?.base_delivery_distance;
            const extraCharge = (extraDistance / maxDistanceStore.store_id?.extra_delivery_distance) * maxDistanceStore.store_id?.extra_delivery_charge;
            //let dynamicDeliveryCharge
            let dynamicDeliveryCharge = parseFloat(maxDistanceStore.store_id?.base_delivery_charge) + parseFloat(extraCharge);
            // if (maxDistanceStore.store_id?.is_freedelivery) {
            //     let changeByOne = parseFloat(maxDistanceStore.store_id?.base_delivery_charge / maxDistanceStore.store_id?.free_base_delivery_distance_within)
            //     dynamicDeliveryCharge = changeByOne + parseFloat(extraCharge);
            // } else {
            //     dynamicDeliveryCharge = parseFloat(maxDistanceStore.store_id?.base_delivery_charge) + parseFloat(extraCharge);
            // }
            dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
            // alert(dynamicDeliveryCharge)
            this.setState({ delivery_charges: dynamicDeliveryCharge });
            localStorage.setItem("delivery_charges", dynamicDeliveryCharge);
            // // // console.log("5", dynamicDeliveryCharge);

        } else {
            this.setState({
                delivery_charges: maxDistanceStore.store_id?.base_delivery_charge,
            });
            localStorage.setItem("delivery_charges", maxDistanceStore.store_id?.base_delivery_charge);
            localStorage.removeItem("delivery_charges_distance_free_delivery");
            localStorage.setItem("delivery_charges_distance_free_delivery_to_take", maxDistanceStore.store_id?.base_delivery_charge);
            // // // console.log("6", maxDistanceStore.store_id?.base_delivery_charge);

        }
        setTimeout(() => {
        }, 500);
    };


    calculateDynamicCityDeliveryChargeForSubscribers = (total) => {
        const { maxDistanceStore } = this.props;
        //this.setState({ delivery_charges: 0 })

        const distanceFromUserToStore = maxDistanceStore.distance;
        if (distanceFromUserToStore > maxDistanceStore.store_id?.base_delivery_distance) {

            const extraDistance = distanceFromUserToStore - maxDistanceStore.store_id?.base_delivery_distance;
            const extraCharge = (extraDistance / maxDistanceStore.store_id?.extra_delivery_distance) * maxDistanceStore.store_id?.extra_delivery_charge;
            //let dynamicDeliveryCharge
            let dynamicDeliveryCharge = parseFloat(maxDistanceStore.store_id?.base_delivery_charge) + parseFloat(extraCharge);

            dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);
            this.setState({ delivery_charges_for_subscribers: dynamicDeliveryCharge });

        } else {
            this.setState({
                delivery_charges_for_subscribers: maxDistanceStore.store_id?.base_delivery_charge,
            });
            localStorage.setItem("delivery_charges_for_subscribers", maxDistanceStore.store_id?.base_delivery_charge);

        }
        setTimeout(() => {
        }, 500);

    }
    calculateDynamicCityDeliveryCharge = (total) => {

        const { maxDistanceStore, store_info } = this.props;

        const distanceFromUserToStore = maxDistanceStore.distance;
        let city_free_delivery = false;


        if (maxDistanceStore.store_id?.is_freedelivery) {
            let newDeliveryCharge = 0;

            if (total >= store_info?.free_delivery_on_above_subtotal && store_info?.free_delivery_on_above_subtotal !== '0') {

                if (maxDistanceStore.store_id?.is_freedelivery && distanceFromUserToStore < maxDistanceStore.store_id?.free_base_delivery_distance_within) {
                    city_free_delivery = true;
                    this.setState({ delivery_charges: newDeliveryCharge });
                    localStorage.setItem("delivery_charges", newDeliveryCharge);
                    // // console.log("7", newDeliveryCharge);
                    let dicountDeliveryCharge = localStorage.getItem("delivery_charges_distance_free_delivery_to_take");
                    // localStorage.setItem("delivery_charges_distance_free_delivery_to_take", maxDistanceStore.store_id?.base_delivery_charge);
                    localStorage.setItem("delivery_charges_distance_free_delivery", dicountDeliveryCharge);

                }
                else if (maxDistanceStore.store_id?.is_freedelivery && distanceFromUserToStore > maxDistanceStore.store_id?.free_base_delivery_distance_within) {

                    if (store_info?.free_delivery_on_above_subtotal === '0.00' || store_info?.free_base_delivery_distance_within === '0.00') {
                        city_free_delivery = true;

                    } else {
                        newDeliveryCharge = store_info?.city?.delivery_charge;
                        city_free_delivery = false;

                    }
                }

                else {
                    city_free_delivery = true;
                }

            } else {

                // newDeliveryCharge = store_info?.city?.delivery_charge;
                city_free_delivery = false;
                //this.calculateDynamicDeliveryCharge()
            }

            //this.setState({ delivery_charges: newDeliveryCharge });
            this.forceStateUpdate();

        }



        if (city_free_delivery === false) {
            if (distanceFromUserToStore > maxDistanceStore.store_id?.base_delivery_distance) {
                const extraDistance = distanceFromUserToStore - maxDistanceStore.store_id?.base_delivery_distance;
                const extraCharge = (extraDistance / maxDistanceStore.store_id?.extra_delivery_distance) * maxDistanceStore.store_id?.extra_delivery_charge;
                let dynamicDeliveryCharge = 0;
                let changeByOne;
                if (store_info?.is_freedelivery && store_info?.free_delivery_on_above_subtotal !== '0' && total >= store_info?.free_delivery_on_above_subtotal && distanceFromUserToStore <= store_info?.free_base_delivery_distance_within) {
                    if (extraDistance <= 0) {
                        changeByOne = 0;
                    } else {
                        changeByOne = (parseFloat(maxDistanceStore.store_id?.base_delivery_charge) * parseFloat(extraDistance)) / parseFloat(maxDistanceStore.store_id?.base_delivery_distance)
                    }
                    dynamicDeliveryCharge = changeByOne;
                    ////// // console.log("dynamicDeliveryCharge : ", changeByOne, parseFloat(maxDistanceStore.store_id?.base_delivery_charge), parseFloat(extraDistance), parseFloat(maxDistanceStore.store_id?.base_delivery_distance), distanceFromUserToStore);
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);

                    this.setState({ delivery_charges: dynamicDeliveryCharge });
                    localStorage.setItem("delivery_charges", dynamicDeliveryCharge);
                    // // // console.log("8", dynamicDeliveryCharge);

                } else if (store_info?.is_freedelivery && store_info?.free_base_delivery_distance_within !== '0' && distanceFromUserToStore >= store_info?.free_base_delivery_distance_within) {
                    if (extraDistance <= 0) {
                        changeByOne = 0;
                    } else {
                        changeByOne = (parseFloat(maxDistanceStore.store_id?.base_delivery_charge) * parseFloat(extraDistance)) / parseFloat(maxDistanceStore.store_id?.base_delivery_distance)
                    }
                    dynamicDeliveryCharge = changeByOne;
                    dynamicDeliveryCharge = Math.ceil(dynamicDeliveryCharge);

                    this.setState({ delivery_charges: dynamicDeliveryCharge });
                    localStorage.setItem("delivery_charges", dynamicDeliveryCharge);
                    // // // console.log("9", dynamicDeliveryCharge);

                }
                else {
                    ////// // console.log("here...1");

                    //dynamicDeliveryCharge = parseFloat(maxDistanceStore.store_id?.base_delivery_charge) + parseFloat(extraCharge);
                    this.calculateDynamicDeliveryCharge()

                }

            } else {
                // this.setState({
                //     delivery_charges: maxDistanceStore.store_id?.base_delivery_charge,
                // });

                this.calculateDynamicDeliveryCharge()

            }
        }
        setTimeout(() => {
        }, 500);
    };



    getTotalSavings = () => {
        const { cartProducts } = this.props;
        let itemSaving = 0;
        let totalsaving = 0;
        cartProducts.forEach((item) => {
            if (item.market_price && item.market_price > 0) {
                itemSaving +=
                    (item.market_price - item.selling_price) * item.quantity;
            }
        });
        totalsaving =
            parseFloat(itemSaving) +
            parseFloat(formatPrice(this.props.coupon.finalDiscount));
        return formatPrice(totalsaving);
    };

    getTotalAfterCalculation = () => {
        const {
            total,
            coupon,
            charges,
            maxDistanceStore,
            cartProducts,
            user,
            current_slab,
            store_info,
        } = this.props;
        // ////// // console.log(store_info);
        let calc = 0;
        let totalTax = 0;

        if (coupon.code) {


            calc = formatPrice(
                parseFloat(total) -
                (parseFloat(coupon.finalDiscount) || 0.0) +
                (parseFloat(charges.store_charge) || 0.0)
            );



        } else {
            calc = formatPrice(
                parseFloat(total) + parseFloat(charges.store_charge || 0.0)
            );
        }


        // cartProducts.forEach((item) => {
        //         const matchedItem = coupon?.items?.find((cp) => cp?.id === item.id);
        //         if (matchedItem) {
        //            ////// // console.log("yes");
        //         }
        //       });


        cartProducts.forEach((item) => {
            if (item.store?.is_tax === 1 && item.store?.tax > 0) {
                let total = 0;
                let price = 0;
                if (item?.dealofdays?.length > 0 && item.selling_price > 0) {
                    price = item.selling_price - parseFloat(item.selling_price * parseFloat(item.dealofdays[0].offer) / 100);
                    total += price * item.quantity;
                } else if (item.selling_price > 0) {
                    total += item.selling_price * item.quantity;
                }
                if (item.is_combo) {
                    if (item.selectedcombos) {
                        item.selectedcombos.forEach((combo) => {
                            total += combo.price * item.quantity;
                        });
                    }
                } else {
                    if (item.selectedaddons) {
                        item.selectedaddons.forEach((addon) => {
                            let price = 0;
                            if (item?.dealofdays?.length > 0 && addon.price > 0) {
                                price = addon.price - parseFloat(addon.price * parseFloat(item.dealofdays[0].offer) / 100);
                                total += price * addon.quantity;
                            } else if (addon.price > 0) {
                                total += addon.price * addon.quantity;
                            }
                        });
                    }
                }
                if (item.store?.tax_type === "PERCENTAGE") {
                    totalTax += (total * item.store.tax) / 100;
                } else {
                    totalTax += item.store.tax;
                }
            }
        });

        calc = formatPrice(parseFloat(calc) + parseFloat(totalTax || 0.0));
        //// // console.log('calc 1 : ', calc);
        // ////// // console.log(calc, this.state.delivery_charges, '44')
        if (this.state.delivery_charges && this.state.delivery_charges > 0) {
            calc = formatPrice(
                parseFloat(calc) +
                parseFloat(this.state.delivery_charges || 0.0)
            );


        }

        if (this.props.tip && this.props.tip > 0) {
            calc = formatPrice(
                parseFloat(calc) + parseFloat(this.props.tip || 0.0)
            );
        }

        if (store_info.handling_charges > 0) {
            calc = formatPrice(
                parseFloat(calc) + parseFloat(store_info.handling_charges || 0.0)
            );


        }
        if (this.state.surge_fee && this.state.surge_fee > 0) {
            calc = parseFloat(calc) + parseFloat(this.state.surge_fee);
            calc = formatPrice(parseFloat(calc));
        } else {
            if (
                store_info &&
                store_info.city &&
                store_info.city.is_surge === 1 &&
                store_info.city.surge_fee > 0
            ) {
                calc = formatPrice(
                    parseFloat(calc) +
                    parseFloat(store_info.city.surge_fee || 0.0)
                );
            }
        }

        return Math.round(formatPrice(calc));
    };
    // checkDeliverySlab = () => {
    //     const { slabs, total } = this.props;
    //     // ////// // console.log("slab is in progress");
    //     // ////// // console.log(total)
    //     // var all_slabs = J
    //     if (slabs && slabs.success && slabs.sub_slabs) {

    //         var index = slabs.sub_slabs.findIndex(function (element) {
    //             return (
    //                 element.bucket_price_from <= total && element.bucket_price_to > total
    //             );
    //         });
    //         //   ////// // console.log(slabs.sub_slabs[index + 1]);

    //         var current_slab = slabs.sub_slabs[index];
    //         var next_slab = slabs.sub_slabs[index + 1];
    //         var length = slabs.sub_slabs.length;
    //         if (total > slabs.sub_slabs[length - 1].bucket_price_to) {
    //             current_slab = slabs.sub_slabs[length - 1];
    //             next_slab = undefined;
    //         }
    //         //   ////// // console.log(slab_details);
    //         this.props.updateSlabDetails(current_slab, next_slab);
    //     } else {
    //         current_slab = undefined;
    //         next_slab = undefined;
    //         this.props.updateSlabDetails(current_slab, next_slab);
    //     }
    // };

    StoresTax = () => {
        const { cartProducts, charges } = this.props;
        let totalTax = 0;
        console.log("cartProducts", cartProducts);
        
        cartProducts.forEach((item) => {
            if (item.store?.is_tax === 1 && item.store?.tax > 0) {
                let total = 0;
                let price = 0;
                if (item?.dealofdays?.length > 0 && item.selling_price > 0) {
                    price = item.selling_price - parseFloat(item.selling_price * parseFloat(item.dealofdays[0].offer) / 100);
                    total += price * item.quantity;
                } else if (item.selling_price > 0) {
                    total += item.selling_price * item.quantity;
                }
                if (item.is_combo) {
                    if (item.selectedcombos) {
                        item.selectedcombos.forEach((combo) => {
                            total += combo.price * item.quantity;
                        });
                    }
                } else {
                    if (item.selectedaddons) {
                        item.selectedaddons.forEach((addon) => {
                            let price = 0;
                            if (item?.dealofdays?.length > 0 && addon.price > 0) {
                                price = addon.price - parseFloat(addon.price * parseFloat(item.dealofdays[0].offer) / 100);
                                total += price * addon.quantity;
                            } else if (addon.price > 0) {
                                total += addon.price * addon.quantity;
                            }
                        });
                    }
                }
                if (item.store?.tax_type === "PERCENTAGE") {
                    totalTax += (total * item.store.tax) / 100;
                } else {
                    totalTax += item.store.tax;
                }
            }
        });
        if (charges.store_charge !== "0.00" || charges.store_charge === null) {
            totalTax = formatPrice(totalTax + charges.store_charge);
        }
        //// // console.log(totalTax, 'totalTax totalTax')
        return formatPrice(totalTax);
    };

    checkAndSetAppliedAmount = () => {
        let elem = "";
        elem = "(₹" + localStorage.getItem("couponAppliedAmount") + ")";

        if (this.refs.appliedAmount) {
            this.refs.appliedAmount.innerHTML = elem;
        }
    };

    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    render() {
        const { address } = this.state;
        const {
            total,
            discount,
            tip,
            charges,
            user,
            current_slab,
            store,
            max_distance,
            store_info,
            cartProducts,
            noPrescription,
            prescriptionImage,
            height
        } = this.props;

        let isFreeDel = false;

        if (user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0) {
            // User has an active plan with remaining days
            isFreeDel = true;
        } else if (cartProducts[0]?.store?.is_freedelivery == 1) {
            // Store offers free delivery
            isFreeDel = true;
        } else {
            isFreeDel = false;
        }
        return (
            <React.Fragment>
                {isFreeDel && (
                    <React.Fragment>

                        {user?.data?.is_active_plan == 1 && user?.data?.remainining_plan_days > 0 ? (
                            <div className="mobile-cart-bill-card_free_delivery_offer py-1">
                                <div className="flex flex-column text-center text-white ">
                                    <div>

                                        Woah!! You've achieved Free Delivery!!
                                    </div>
                                    <div className="text-white fs-7">
                                        You've subscribed to our "{user?.data?.activePlan?.subscription?.plan_name}" Plan
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                {cartProducts[0]?.store?.is_freedelivery == 1 &&
                                    <div className="mobile-cart-bill-card_free_delivery_offer py-1">
                                        <div className="flex flex-column text-center text-white ">

                                            <div className="text-white fs-7">
                                                Free Delivery Above Rs.{cartProducts[0]?.store?.free_delivery_on_above_subtotal} Upto {cartProducts[0]?.store?.free_base_delivery_distance_within} Kms                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        )}
                    </React.Fragment>
                )}

                <div className="mobile-cart-bill-card px-3 py-3" style={{
                    position: 'none', zIndex: 100,
                    borderRadius: !isFreeDel ? "22px 22px 0px 0px" : "0px"
                }}>
                    <div className="pb-1">
                        <div className="d-flex flex-row justify-content-between px-2">
                            <div style={{ color: "white" }}>Sub Total</div>
                            <div style={{ color: "white" }}>

                                Rs {formatPrice(total)}
                            </div>
                            {/* <div style={{ color: "white" }}>Rs {formatPrice(total) + discount}</div> */}
                        </div>
                        {/* <div className='d-flex flex-row justify-content-between px-2'>
                        <div style={{ color: "white" }}>Discount</div>
                        <div style={{ color: "white" }}>- Rs {discount}</div>
                    </div> */}
                        <div className="d-flex flex-row justify-content-between px-2">
                            {this.state.delivery_charges > 0 ? (
                                <>
                                    <div style={{ color: "white" }}>Delivery Charge ({parseFloat(max_distance).toFixed(2)}km)</div>
                                    <div style={{ color: "white" }}>
                                        {" "}
                                        Rs{" "}
                                        {this.state.delivery_charges ? this.state.delivery_charges : 0.0}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div style={{ color: "green", fontWeight: "bold" }}>Delivery Charge ({parseFloat(max_distance).toFixed(2)}km)</div>
                                    <div style={{ color: "green", fontWeight: "bold" }}>
                                        FREE DELIVERY
                                    </div>
                                </>
                            )}
                        </div>

                        {tip ? (
                            <div className="d-flex flex-row justify-content-between px-2">
                                <div style={{ color: "white" }}>Tip amount</div>
                                <div style={{ color: "white" }}>
                                    Rs {formatPrice(tip)}
                                </div>
                            </div>
                        ) : (
                            <div></div>
                        )}



                        {this.StoresTax() > 0 && (
                            <div className="d-flex flex-row justify-content-between px-2">
                                <div style={{ color: "white" }}>Tax & Changes</div>
                                <div style={{ color: "white" }}>
                                    Rs {this.StoresTax()}
                                </div>
                            </div>

                        )}
                        {store_info.handling_charges && (
                            <div className="d-flex flex-row justify-content-between px-2">
                                <div style={{ color: "white" }}>Handling Charges</div>
                                <div style={{ color: "white" }}>

                                    Rs {parseFloat(store_info.handling_charges).toFixed(2)}
                                </div>
                            </div>
                        )}

                        {store_info && store_info.city && store_info.city.is_surge == 1 && store_info.city.surge_fee > 0 ? (
                            <div className="d-flex flex-row justify-content-between px-2">
                                <div style={{ color: "white" }}>Rainy Charge</div>
                                <div style={{ color: "white" }}>
                                    Rs{" "}
                                    {store_info &&
                                        store_info.city &&
                                        store_info.city.surge_fee
                                        ? formatPrice(store_info.city.surge_fee)
                                        : 0.0}
                                </div>
                            </div>
                        ) : null}
                        {this.props.coupon && this.props.coupon.code ? (
                            <div className="d-flex flex-row justify-content-between px-2">
                                <div style={{ color: "white" }}>Coupon</div>
                                <div style={{ color: "white" }}>
                                    - Rs{" "}
                                    {formatPrice(this.props.coupon.finalDiscount)}
                                </div>
                            </div>
                        ) : null}


                    </div>
                    <hr style={{ border: '1px dashed #fff', margin: '0 0' }} />
                    <div className="" style={{ bottom: '20px' }}>
                        {/* window.innerHeight ? { backgroundColor: "#fff", bottom: '20px' } : */}
                        <div className="pt-2" >
                            {user && user.success ? (
                                <>
                                    {store_info &&
                                        store_info.store_category_id === 2 ? (
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex flex-column justify-content-between px-2 fw-bolder">
                                                <div style={{ color: "white", fontSize: "1.2em" }}>
                                                    Total
                                                </div>
                                                <div style={{ color: "white", fontSize: "1.2em" }}>
                                                    Rs {this.getTotalAfterCalculation()}
                                                </div>
                                            </div>
                                            {cartProducts.filter((item) => (item.is_prescription == 1)).length > 0 ? (
                                                prescriptionImage && prescriptionImage?.image && prescriptionImage?.image?.length > 0 ? (
                                                    <Link to="/confirm-order" className="position-relative" >
                                                        <button className="mobile-cart-my-ordr-btn w-100 p-3">
                                                            Proceed To CheckOut
                                                            <Ink duration={500}
                                                                style={{ color: "#aaa" }}
                                                            />
                                                        </button>
                                                    </Link>
                                                ) : (
                                                    <div>
                                                        <button className="mobile-cart-my-ordr-btn p-3 position-relative"
                                                            onClick={noPrescription} >
                                                            Proceed To CheckOut
                                                            <Ink duration={500} style={{ color: "#aaa" }} />
                                                        </button>
                                                    </div>
                                                )) : (
                                                <Link to="/confirm-order" className="position-relative" >
                                                    <button className="mobile-cart-my-ordr-btn w-100 p-3">
                                                        Proceed To CheckOut
                                                        <Ink duration={500}
                                                            style={{ color: "#aaa" }}
                                                        />
                                                    </button>
                                                </Link>
                                            )}
                                        </div>
                                    ) : (
                                        <div className="d-flex justify-content-between">
                                            <div className="d-flex flex-column justify-content-between px-2 fw-bolder">
                                                <div style={{ color: "white", fontSize: "1.2em" }}>
                                                    Total
                                                </div>
                                                <div style={{ color: "white", fontSize: "1.2em" }}>
                                                    Rs {this.getTotalAfterCalculation()}
                                                </div>
                                            </div>
                                            <Link to="/confirm-order" className="position-relative"  >
                                                <button className="mobile-cart-my-ordr-btn p-3">
                                                    Proceed To CheckOut
                                                    <Ink duration={500} style={{ color: "#aaa" }} />
                                                </button>
                                            </Link>
                                        </div>
                                    )}
                                </>
                            ) : (<div className="d-flex justify-content-between">
                                <div className="d-flex flex-column justify-content-between px-2 fw-bolder">
                                    <div style={{ color: "white", fontSize: "1.2em" }}>
                                        Total
                                    </div>
                                    <div style={{ color: "white", fontSize: "1.2em" }}>
                                        Rs {this.getTotalAfterCalculation()}
                                    </div>
                                </div>
                                <Link to="/signup" className="position-relative">
                                    <button className="mobile-cart-my-ordr-btn p-3">
                                        Please Login To CheckOut
                                        <Ink duration={500} style={{ color: "#aaa" }} />
                                    </button>
                                </Link>
                            </div>
                            )}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    store_info: state.cart.store_info,
    maxDistanceStore: state.cart.max_distance,
    charges: state.cart.charges,
    slabs: state.cart.slabs,
    prescriptionImage: state.cart.prescriptionImage,
});

export default connect(mapStateToProps, {
    getDeliverySlab,
    updateSlabDetails,
})(Bill);
