import React, { Component } from "react";
// import Modal from "react-modal";
import { BottomSheet } from "react-spring-bottom-sheet";
import { AiOutlineStop, AiOutlineClose, AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";
import { addProduct, updateCart } from '../../../redux/mobile/cart/action'
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { BsDot } from "react-icons/bs";
import { formatPrice } from "../../Helpers/formatPrice";
import Truncate from "react-truncate";
import { ImMinus, ImPlus } from "react-icons/im";

class Customization extends Component {
    state = {
        open: false,
        showAddon: '',
        comboSelected: [],
        itemQuantity: 1,
    };
    __addonSum = (item) => {
        let data = item?.selectedaddons;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price * i.quantity + item?.selling_price * i.quantity)
        });
        let subtotal = sum;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }


    __comboSum = (item) => {
        let data = item?.selectedcombos;
        let sum = 0;
        data?.forEach((i) => {
            sum += parseFloat(i.price)
        });
        let subtotal = sum + item?.selling_price;
        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = subtotal - parseFloat(subtotal * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = subtotal;
        }
        let total = sell_price * item?.quantity;
        return formatPrice(total);
    }

    superSum = (item) => {

        if (item.selectedcombos?.length > 0) {
            return parseFloat(this.__comboSum(item));
        }

        if (item.selectedaddons?.length > 0) {
            return parseFloat(this.__addonSum(item));
        }

        let sell_price = 0;
        if (item?.dealofdays?.length > 0) {
            sell_price = item?.selling_price - parseFloat(item?.selling_price * parseFloat(item.dealofdays[0].offer) / 100);
        } else {
            sell_price = item.selling_price;
        }
        return sell_price * item.quantity;
    }

    _processAddons = (product) => {
        const { itemQuantity } = this.state;
        const { cartProducts, storeInfo } = this.props;
        let addons = [];
        addons["selectedaddons"] = [];
        const index = cartProducts.findIndex((p) => p.id === product.id);
        let radio = document.querySelectorAll("input[type=radio]:checked");
        let checkboxes = document.querySelectorAll("input[type=checkbox]:checked");
        if (index > -1) {
            cartProducts.forEach((cp) => {
                ////// console.log(cp.id === product.id && cp.selectedaddons?.length > 0, 'sss')
                if (cp.id === product.id) {
                    let updatedAddons = [...cp?.selectedaddons];

                    // Update addons for radio inputs
                    for (let i = 0; i < radio.length; i++) {
                        const addonId = parseInt(radio[i].getAttribute("data-addon-id"));
                        const existingAddonIndex = updatedAddons.findIndex((cd) => cd.addon_id === addonId);

                        if (existingAddonIndex !== -1) {
                            updatedAddons[existingAddonIndex].quantity += itemQuantity;
                        } else if (updatedAddons.every((ft) => ft.addon_id !== addonId)) {
                            updatedAddons.push({
                                addon_category_name: radio[i].name,
                                addon_id: addonId,
                                addon_name: radio[i].getAttribute("data-addon-name"),
                                quantity: itemQuantity,
                                type: "SINGLE",
                                price: radio[i].value,
                            });
                        }
                    }

                    // Update addons for checkboxes
                    for (let i = 0; i < checkboxes.length; i++) {
                        const addonId = parseInt(checkboxes[i].getAttribute("data-addon-id"));
                        const existingAddonIndex = updatedAddons.findIndex((cd) => cd.addon_id === addonId);

                        if (existingAddonIndex !== -1) {
                            updatedAddons[existingAddonIndex].quantity += itemQuantity;
                        } else if (updatedAddons.every((ft) => ft.addon_id !== addonId)) {
                            updatedAddons.push({
                                addon_category_name: checkboxes[i].name,
                                addon_id: addonId,
                                addon_name: checkboxes[i].getAttribute("data-addon-name"),
                                quantity: itemQuantity,
                                type: "MULTIPLE",
                                price: checkboxes[i].value,
                            });
                        }
                    }

                    cartProducts.splice(index, 1);
                    if (storeInfo?.is_free_item) {
                        const freeItemIndex = cartProducts.findIndex((cp) => cp.id === storeInfo?.free_item_id);
                        if (freeItemIndex > -1) {
                            cartProducts.splice(freeItemIndex, 1);
                        }
                    }
                    addons["selectedaddons"] = updatedAddons;
                    this.props.addProduct(Object.assign(addons, product));
                    updateCart(cartProducts);
                    this.forceStateUpdate();
                    setTimeout(() => {
                        const isFreeItemActive = storeInfo?.is_free_item === 1;

                        ////// console.log(cartProducts, 'cartProducts')
                        const newTotalPriceWithoutCombo = cartProducts.reduce((total, item) => {
                            if (item?.is_combo == 0 && item?.combo_categories?.length == 0 && item?.selectedcombos?.length < 0) {
                                return total + parseInt(this.superSum(item));
                            }
                            return total;
                        }, 0);
                        const hasReachedMinSubtotal = newTotalPriceWithoutCombo >= parseFloat(storeInfo?.free_item_min_subtotal);
                        if (storeInfo?.free_item_min_subtotal !== 0 && isFreeItemActive && hasReachedMinSubtotal) {
                            if (cartProducts.find(cp => cp.id === storeInfo?.free_item_id) === undefined) {
                                if (storeInfo && storeInfo.free_product) {
                                    storeInfo.free_product.quantity = 1;
                                    cartProducts.push(storeInfo.free_product);
                                }

                                this.setState({ confetti: true, free_item_added: true });
                                setTimeout(() => {
                                    this.setState({ confetti: false, free_item_added: false });
                                }, 3000);
                            }
                        }

                        updateCart(cartProducts);
                    }, 1000);
                } else {
                    for (let i = 0; i < radio.length; i++) {
                        addons["selectedaddons"].push({
                            addon_category_name: radio[i].name,
                            addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                            addon_name: radio[i].getAttribute("data-addon-name"),
                            quantity: itemQuantity,
                            type: "SINGLE",
                            price: radio[i].value,
                        });
                    }

                    for (let i = 0; i < checkboxes.length; i++) {
                        addons["selectedaddons"].push({
                            addon_category_name: checkboxes[i].name,
                            addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                            addon_name: checkboxes[i].getAttribute("data-addon-name"),
                            quantity: itemQuantity,
                            type: "MULTIPLE",
                            price: checkboxes[i].value,
                        });
                    }
                    this.props.addProduct(Object.assign(addons, product));
                }
            });
        } else {
            for (let i = 0; i < radio.length; i++) {
                addons["selectedaddons"].push({
                    addon_category_name: radio[i].name,
                    addon_id: parseInt(radio[i].getAttribute("data-addon-id")),
                    addon_name: radio[i].getAttribute("data-addon-name"),
                    type: "SINGLE",
                    quantity: itemQuantity,
                    price: radio[i].value,
                });
            }

            for (let i = 0; i < checkboxes.length; i++) {
                addons["selectedaddons"].push({
                    addon_category_name: checkboxes[i].name,
                    addon_id: parseInt(checkboxes[i].getAttribute("data-addon-id")),
                    addon_name: checkboxes[i].getAttribute("data-addon-name"),
                    type: "MULTIPLE",
                    quantity: itemQuantity,
                    price: checkboxes[i].value,
                });
            }
            this.props.addProduct(Object.assign(addons, product));
        }
    };
    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    handlePopupOpen = () => {
        if (this.props.singleBtn) {
            this.props.floatCart(true)
        }
        this.setState({ itemQuantity: 1 });
        this.setState({ open: true });
    };

    handlePopupClose = () => {
        if (this.props.singleBtn) {
            this.props.floatCart(false)
        }
        this.setState({ open: false, itemQuantity: 1 });
        this.props.forceUpdate();
    };

    onMinus = () => {
        const { itemQuantity } = this.state;
        if (itemQuantity === 1) {
            this.handlePopupClose()
        } else {
            let quantity;
            quantity = itemQuantity - 1;
            this.setState({ itemQuantity: quantity })
        }
    }

    onPlus = () => {
        const { itemQuantity } = this.state;
        let quantity;
        quantity = itemQuantity + 1;
        this.setState({ itemQuantity: quantity })
    }

    // totalPrice = () => {
    //     let total = 0;
    //     const { product,cartProducts } = this.props;
    //     const { comboItems, freeItems, extraItems, itemQuantity } = this.state;
    //     total += parseFloat(product?.selling_price);

    //     this.setState({ itemQuantity: 1 });
    //     if (cartProducts.find((cp) => cp.id === product.id) !== undefined) {
    //         let item = cartProducts.find((cp) => cp.id === product.id)
    //         this.setState({
    //             itemQuantity: item?.quantity
    //         });
    //     }

    //     comboItems.filter((c) => {
    //         if (c?.price) {
    //             total += parseFloat(c?.price);
    //         }
    //     })
    //     freeItems.filter((free) => {
    //         if (free?.price) {
    //             total += parseFloat(free?.price);
    //         }
    //     })
    //     extraItems.filter((free) => {
    //         if (free?.price) {
    //             total += parseFloat(free?.price);
    //         }
    //     })
    //     if (itemQuantity) {
    //         total = parseFloat(total) * itemQuantity;
    //     }
    //     return formatPrice(total);
    // }
    calculateTotalAddonQuantity = (selectedAddons) => {
        if (!Array.isArray(selectedAddons)) {
            return 0;
        }
        return selectedAddons.reduce((total, addon) => total + addon.quantity, 0);
    };
    render() {
        const { product, singleBtn, addProduct, cartProducts, dealToday, homeBtn, storeBtn } = this.props;
        const { itemQuantity } = this.state;
        const cartProduct = cartProducts.find((cp) => cp.id === product.id);
        const totalAddonQuantity = cartProduct ? this.calculateTotalAddonQuantity(cartProduct.selectedaddons) : 0;

        return (
            <React.Fragment>
                {singleBtn ? (
                    <>
                        {cartProducts.find((cp) => cp.id === product.id) !== undefined ? (
                            <Link to="/cart">
                                <div className='px-3 pt-2 mt-1'>
                                    <button
                                        type="button"
                                        className='text-center w-100  add-button py-3 px-2'
                                    >
                                        Go to Cart
                                    </button>
                                </div>
                            </Link>
                        ) : (
                            <div className='px-3 w-100 position-fixed ms-2 me-2' style={{ bottom: '15px', left: '-7px' }}>
                                <button
                                    className='text-center w-100 add-button py-3 px-5'
                                    onClick={this.handlePopupOpen}
                                >
                                    Add to Cart
                                </button>
                            </div>
                        )}
                    </>
                ) : homeBtn ? (
                    <div className='top-food-add-min-btn' onClick={this.handlePopupOpen}>
                        <AiOutlinePlus
                            style={{
                                color: "green",
                            }}
                            size={14}
                        />
                    </div>
                ) : storeBtn ? (
                    <>
                        {cartProducts.find((cp) => cp.id === product.id) !== undefined ? (
                            <div className="d-flex justify-content-center align-items-center p-1" style={{ backgroundColor: '#fff', borderRadius: '12px' }}>
                                <div className="top-food-add-min-btn"
                                    onClick={this.handlePopupOpen}>
                                    <AiOutlineMinus style={{ color: "green", }} size={14} />
                                </div>
                                <div className="top-food-count ms-1 me-1">
                                    {totalAddonQuantity}
                                </div>
                                <>
                                    <div className="top-food-add-min-btn"
                                        onClick={this.handlePopupOpen}>
                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                    </div>
                                </>

                            </div>
                        ) : (


                            <div className='text-secondary fw-bolder fs-7 text-center pt-1'>
                                <div className="d-flex justify-content-center pt-1">
                                    <div className="top-food-btn px-3"
                                        onClick={this.handlePopupOpen}   >
                                        <AiOutlinePlus style={{ color: "green", }} size={14} />
                                        <span style={{ color: "green", }}   >
                                            ADD
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                ) : dealToday ? (
                    <div className="d-flex align-items-center" style={{ color: "green", }}>
                        <div className="top-food-btn" onClick={this.handlePopupOpen}  >
                            Add <AiOutlinePlus
                                size={14}
                            />
                        </div>
                    </div>
                ) : (
                    <button
                        type="button"
                        className="p-2 w-100 text-center add-button"
                        onClick={this.handlePopupOpen}
                    >
                        + ADD
                    </button>
                )}
                <BottomSheet
                blocking={true}
                skipInitialTransition={true}
                    open={this.state.open}
                    onDismiss={() => this.handlePopupClose()}
                    snapPoints={({ maxHeight }) => (0.6 * maxHeight)}
                >
                    <div style={{ paddingBottom: '10vh', overflowY: 'scroll' }} className="px-3 pt-1">
                        <div className='d-flex justify-content-end' onClick={() => this.handlePopupClose()}>
                            <AiOutlineClose style={{
                                color: "#b34700",
                            }}
                                size={20} />
                        </div>
                        {/* {////// console.log(product, 'customizaaaaaation')} */}
                        {product?.addoncategories?.map((addon_category) => (
                            <div key={addon_category.id} className="addon-category-block">
                                <React.Fragment>
                                    <div className="mt-2 w-100 p-1 text-dark"
                                        style={{ fontWeight: '600', fontSize: '18px' }}>
                                        {addon_category.name} {'(' + addon_category?.addons?.length + ' Addons)'}
                                    </div>
                                    {addon_category?.addons?.length > 0 && (
                                        <React.Fragment>
                                            {addon_category?.addons?.sort((a, b) => a.price - b.price)?.map((addon, index) => (
                                                <React.Fragment key={addon.id}>
                                                    <div className="form-group addon-list ">
                                                        {product?.store?.store_category_id === 2 && addon.stock > 0 ? (
                                                            <label htmlFor={addon.name} className="d-flex justify-content-between py-2">
                                                                <div>
                                                                    <input type={addon_category.type === 'SINGLE' ? "radio" : "checkbox"}
                                                                        className={addon_category.type === 'SINGLE' ? "magic-radio" : "magic-checkbox"}
                                                                        name={addon_category.name}
                                                                        data-addon-id={addon.id}
                                                                        data-addon-name={addon.name}
                                                                        id={addon.name}
                                                                        value={addon.price}
                                                                        defaultChecked={
                                                                            addon_category.type === "SINGLE" &&
                                                                            index === 0 &&
                                                                            true
                                                                        }
                                                                    />
                                                                    {addon_category.type === "SINGLE" && (
                                                                        <label htmlFor={addon.name} />
                                                                    )}

                                                                    <label className="text addon-label mx-2" htmlFor={addon.name}   >
                                                                        {addon.name}

                                                                    </label>
                                                                </div>
                                                                <span className="addon-price ms-2 d-flex justify-content-end">
                                                                    {product?.dealofdays?.length > 0 && addon.price > 0 ?
                                                                        <div className='d-flex align-items-center gap-3'>
                                                                            <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                ₹ {addon.price - (addon.price * parseFloat(product.dealofdays[0].offer) / 100)}
                                                                            </span>
                                                                            <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                                ₹ {addon.price}
                                                                            </s>
                                                                        </div>
                                                                        : <>
                                                                            ₹ {addon.price}
                                                                        </>
                                                                    }
                                                                </span>
                                                            </label>
                                                        ) : (
                                                            <label htmlFor={addon.name} className="d-flex justify-content-between py-2">
                                                                <div>
                                                                    <input type={addon_category.type === 'SINGLE' ? "radio" : "checkbox"}
                                                                        className={addon_category.type === 'SINGLE' ? "magic-radio" : "magic-checkbox"}
                                                                        name={addon_category.name}
                                                                        data-addon-id={addon.id}
                                                                        data-addon-name={addon.name}
                                                                        id={addon.name}
                                                                        value={addon.price}
                                                                        defaultChecked={
                                                                            // addon_category.type === "SINGLE" &&
                                                                            index === 0 &&
                                                                            true
                                                                        }
                                                                    />
                                                                    {addon_category.type === "SINGLE" && (
                                                                        <label htmlFor={addon.name} />
                                                                    )}
                                                                    <label className="text addon-label mx-2" htmlFor={addon.name}>
                                                                        {addon.name}
                                                                    </label>
                                                                </div>
                                                                <span className="addon-price ms-2 d-flex justify-content-end">
                                                                    {product?.dealofdays?.length > 0 && addon.price > 0 ?
                                                                        <div className='d-flex align-items-center gap-3'>
                                                                            <span style={{ fontSize: "14px", fontWeight: "600" }}>
                                                                                ₹ {addon.price - (addon.price * parseFloat(product.dealofdays[0].offer) / 100)}
                                                                            </span>
                                                                            <s style={{ color: "#FF9A62", fontSize: "13px", }} >
                                                                                ₹ {addon.price}
                                                                            </s>
                                                                        </div>
                                                                        : <>
                                                                            ₹ {addon.price}
                                                                        </>
                                                                    }
                                                                </span>
                                                            </label>
                                                        )}
                                                    </div>
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    )}
                                </React.Fragment>
                            </div>
                        ))}
                        <div className='d-flex justify-content-between align-items-center position-fixed w-100 py-3 gap-3 px-3' style={{ bottom: '0px', left: '0px', backgroundColor: '#fff', boxShadow: '0px -5px 20px 5px #0000001a' }}>
                            <div className='d-flex justify-content-between align-items-center py-2 px-3' style={{ border: '1px solid #e7e7e7', borderRadius: '8px' }} >
                                <div style={{ fontSize: '1em', color: '#009c27', fontWeight: '650' }} onClick={() => this.onMinus()}> <ImMinus /></div>
                                <div className="px-4" style={{ fontSize: '1.4em', color: '#009c27', fontWeight: '650' }}> {itemQuantity} </div>
                                <div style={{ fontSize: '1em', color: '#009c27', fontWeight: '650' }} onClick={() => this.onPlus()}> <ImPlus /> </div>
                            </div>
                            <button className='flex-grow-1 addon-category-add p-3'
                                onClick={() => {
                                    product.quantity = 1;
                                    this._processAddons(product);
                                    this.handlePopupClose()
                                    this.forceStateUpdate();
                                }}>
                                Add Item
                                {/* |  ₹ {this.totalPrice()} */}
                            </button>
                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cartProducts: state.cart.products,
    storeInfo: state.cart.store_info,
});

export default connect(mapStateToProps, { addProduct, updateCart })(Customization);

// export default Customization;
