import React, { Component } from 'react'
import { connect } from 'react-redux'
import VendorBackButton from '../../Elements/VendorBackButton';
import { TbEdit, TbFloatNone } from "react-icons/tb";
import moment from 'moment';
import { RiDeleteBinLine } from "react-icons/ri";
import "antd/dist/antd.css";
import Loading from '../../Elements/loading'
import { getStoreCategories, getCategoryScheduleDate, addCategoryScheduleDate, changeItemScheduleStatus } from '../../../redux/vendor/user/action'
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { MdToggleOff, MdToggleOn } from 'react-icons/md';

export class VendorCategoryTiming extends Component {
    state = {
        editMonday: false,
        editTuesday: false,
        editWednesday: false,
        editThursday: false,
        editFriday: false,
        editSaturday: false,
        editSunday: false,
        loading: false,
        newTuesday: false,
        newWednesday: false,
        newThursday: false,
        newFriday: false,
        newSaturday: false,
        newSunday: false,
        schedule_data: {},
        items: [],
        catetegory_id: '',
        category: []
    }
    componentDidMount() {
        this.setState({ loading: true })
        const { store, user } = this.props;
        this.props.getStoreCategories(user?.data?.auth_token, store?.store?.id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, items: response.payload.category })
                    //// console.log(response.payload, 'data coming')
                } else {
                    //// console.log(response, 'API error')
                }
            })

    }
    onHandleBack = () => {
        this.setState({catetegory_id:''})
    }
    selectCategory = (catetegory_id) => {
        let dummy = {
            monday: [],
            tuesday: [],
            wednesday: [],
            thursday: [],
            friday: [],
            saturday: [],
            sunday: [],
        }
        this.setState({ catetegory_id: catetegory_id });
        this.setState({ loading: true })
        const { store, user } = this.props;
        this.props.getCategoryScheduleDate(user?.data?.auth_token, catetegory_id)
            .then((response) => {
                if (response && response.payload) {
                    this.setState({ loading: false, schedule_data: response.payload.dates || dummy, category: response.payload.category })
                    //// console.log(response.payload, 'data coming')
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }
    backFromCategory = () => {
        this.setState({ catetegory_id: null });
    }
    handleSchedule = (type) => {

        const data = this.state.schedule_data[type];
        data.push({ open: '', close: '' })
        this.setState((prev) => ({ ...prev, [type]: [...data] }))
    }

    __saveTimings = (day) => {
        // this.setState({ loading: true })
        const { store, user } = this.props;
        const schedule_date = this.state.schedule_data;
        //// console.log(schedule_date)
        this.props.addCategoryScheduleDate(user?.data?.auth_token, this.state.catetegory_id, schedule_date)
            .then((response) => {
                if (response && response.payload) {
                    this.setState({
                        loading: false, schedule_data: response.payload.dates, editMonday: false, editTuesday: false,
                        editWednesday: false, editThursday: false, editFriday: false, editSaturday: false, editSunday: false,
                    });
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }

    _addNewDate = (key, value, day, type) => {
        const { schedule_data, items } = this.state;
        const single_schedule = this.state.schedule_data[day];
        const data = single_schedule[key]
        data[type] = value
        this.setState((prev) => ({ ...prev, [day]: [...single_schedule] }));
    }

    __handleDay = (day) => {
        if (day == 'editMonday') {
            this.setState({ editMonday: !this.state.editMonday });
        } else if (day == 'editTuesday') {
            this.setState({ editTuesday: !this.state.editTuesday });
        } else if (day == 'editWednesday') {
            this.setState({ editWednesday: !this.state.editWednesday });
        } else if (day == 'editThursday') {
            this.setState({ editThursday: !this.state.editThursday });
        } else if (day == 'editFriday') {
            this.setState({ editFriday: !this.state.editFriday });
        } else if (day == 'editSaturday') {
            this.setState({ editSaturday: !this.state.editSaturday });
        } else if (day == 'editSunday') {
            this.setState({ editSunday: !this.state.editSunday });
        }
    }

    __handleCopyToAll = (type) => {
        this.setState({ loading: true })
        const data = this.state.schedule_data[type];
        const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
        const newState = {}
        for (let day of days) {
            newState[day] = data
        }
        this.setState({ schedule_data: newState })
        this.setState({ loading: false })
    }

    deleteTime = (day, key) => {
        const data = this.state.schedule_data[day]
        data.splice(key, 1)
        this.setState((prev) => ({ ...prev, [day]: [...data] }))
    }

    getTime = (time) => {
        if (typeof time === 'string') {
            const hour = time.split(":")[0];
            const minute = time.split(":")[1];
            const today = hour + ':' + minute;
            const dates = moment(today, 'HH:mm').format('hh:mm A')
            return dates
        } else {
            return time
        }
    }
    getEditTime = (time, index) => {
        if (typeof time === 'string') {
            const hour = time.split(":")[0];
            const minute = time.split(":")[1];
            const format = time.split(" ")[1];
            const today = hour + ':' + minute + format;
            const dates = moment(today, 'HH:mm').format('HH:mm')
            //// console.log(dates)
            return dates
        } else {
            return time
        }
    }

    changeSchedule = () => {
        const { user } = this.props;
        this.setState({ loading: true });
        this.props.changeItemScheduleStatus(user?.data?.auth_token, this.state.catetegory_id)
            .then((response) => {
                if (response && response.payload) {
                    this.setState({
                        category: response.payload.category, loading: false,
                    });
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }
    render() {
        const { schedule_data, items } = this.state;

        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading /> :
                    <React.Fragment>


                        {this.state.catetegory_id ?
                            <div>
                                <div className="d-flex align-items-center position-fixed px-3 w-100 py-3" style={{ backgroundColor: '#fff', top: '0px', boxShadow: '#dddddd -1px -3px 20px 0px', zIndex: '99999999' }}>
                                    <div className="px-2 back-button" onClick={() => this.onHandleBack()}>
                                        <span className="fs-2 back-button-icon">
                                            <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                                        </span>
                                    </div>
                                    <div className='flex-grow-1 text-center  back-button-title fs-5 pe-4'>
                                    {this.state.category.name ? this.state.category.name : 'Store Categories'}
                                    </div>
                                </div>

                                <div className='d-flex justify-content-between align-items-center px-3' style={{ paddingTop: '25vw' }}>
                                    <div className='fs-6 fw-bolder'>
                                        Item Category Schedule
                                    </div>
                                  <div onClick={this.changeSchedule}>
                                        {this.state.category.is_schedule == 1 ?
                                            <MdToggleOn set="curved" style={{ fontSize: "4em", color: "#59D69D", }} />
                                            :
                                            <MdToggleOff set="curved" style={{ fontSize: "4em", color: "#cfcfcf" }} />
                                        }
                                    </div>
                                </div>
                                <hr className="seperator-timing" />
                                <div>
                                    <div className="d-flex justify-content-between px-3">
                                        <div>
                                            <span className="timing-day">Monday</span>
                                        </div>
                                        <div onClick={() => this.__handleDay('editMonday')}>
                                            <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                        </div>
                                    </div>
                                    {this.state.editMonday ?
                                        <div className='mt-3 px-3'>
                                            <div className="d-flex flex-column px-3 mb-3">
                                                {schedule_data && schedule_data.monday && schedule_data.monday.map((item, index) => (
                                                    <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                        <div className='flex-grow-1'>
                                                            <input type="time" value={this.getEditTime(item.open)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min="01:00" max={this.getEditTime(item.close)}
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'monday', "open")
                                                                }} />
                                                        </div>
                                                        <div className='flex-grow-1 ms-2'>
                                                            <input type="time" value={this.getEditTime(item.close)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min={this.getEditTime(item.open)} max="23:59"
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'monday', "close")
                                                                }} />
                                                        </div>
                                                        <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('monday', index)}>
                                                            <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex justify-content-between  px-2 mb-3'>
                                                <div onClick={() => { this.handleSchedule('monday') }}>
                                                    <span className='slot-add'>Add Slot +</span>
                                                </div>
                                                <div onClick={() => { this.__handleCopyToAll('monday') }}>
                                                    <span className='copy-days'>Copy To All Days</span>
                                                </div>
                                            </div>
                                            <div className='px-4'>
                                                <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('monday',)}>
                                                    <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {schedule_data && schedule_data.monday && schedule_data.monday.map((item, index) => (
                                                <div className="d-flex flex-row" >
                                                    <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <hr className="seperator-timing" />

                                <div>
                                    <div className="d-flex justify-content-between px-3 mt-4">
                                        <div>
                                            <span className="timing-day">Tuesday</span>
                                        </div>
                                        <div onClick={() => this.__handleDay('editTuesday')}>
                                            <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                        </div>
                                    </div>
                                    {this.state.editTuesday ?
                                        <div className='mt-3 px-3'>
                                            <div className="d-flex flex-column px-3 mb-3">
                                                {schedule_data && schedule_data.tuesday && schedule_data.tuesday.map((item, index) => (
                                                    <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                        <div className='flex-grow-1'>
                                                            <input type="time" value={this.getEditTime(item.open)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min="01:00" max={this.getEditTime(item.close)}
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'tuesday', "open")
                                                                }} />
                                                        </div>
                                                        <div className='flex-grow-1 ms-2'>
                                                            <input type="time" value={this.getEditTime(item.close)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min={this.getEditTime(item.open)} max="23:59"
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'tuesday', "close")
                                                                }} />
                                                        </div>
                                                        <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('tuesday', index)}>
                                                            <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex justify-content-between  px-2 mb-3'>
                                                <div onClick={() => { this.handleSchedule('tuesday') }}>
                                                    <span className='slot-add'>Add Slot +</span>
                                                </div>
                                                <div onClick={() => { this.__handleCopyToAll('tuesday') }}>
                                                    <span className='copy-days' >Copy To All Days</span>
                                                </div>
                                            </div>
                                            <div className='px-4'>
                                                <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('tuesday',)}>
                                                    <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {schedule_data && schedule_data.tuesday && schedule_data.tuesday.map((item, index) => (
                                                <div className="d-flex flex-row" >
                                                    <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <hr className="seperator-timing" />
                                <div>
                                    <div className="d-flex justify-content-between px-3 mt-4">
                                        <div>
                                            <span className="timing-day">Wednesday</span>
                                        </div>
                                        <div onClick={() => this.__handleDay('editWednesday')}>
                                            <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                        </div>
                                    </div>
                                    {this.state.editWednesday ?
                                        <div className='mt-3 px-3'>
                                            <div className="d-flex flex-column px-3 mb-3">
                                                {schedule_data && schedule_data.wednesday && schedule_data.wednesday.map((item, index) => (
                                                    <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                        <div className='flex-grow-1'>
                                                            <input type="time" value={this.getEditTime(item.open)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min="01:00" max={this.getEditTime(item.close)}
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'wednesday', "open")
                                                                }} />
                                                        </div>
                                                        <div className='flex-grow-1 ms-2'>
                                                            <input type="time" value={this.getEditTime(item.close)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min={this.getEditTime(item.open)} max="23:59"
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'wednesday', "close")
                                                                }} />
                                                        </div>
                                                        <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('wednesday', index)}>
                                                            <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex justify-content-between  px-2 mb-3'>
                                                <div onClick={() => { this.handleSchedule('wednesday') }}>
                                                    <span className='slot-add'>Add Slot +</span>
                                                </div>
                                                <div>
                                                    <span className='copy-days'>Copy To All Days</span>
                                                </div>
                                            </div>
                                            <div className='px-4'>
                                                <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('wednesday',)}>
                                                    <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {schedule_data && schedule_data.wednesday && schedule_data.wednesday.map((item, index) => (
                                                <div className="d-flex flex-row" >
                                                    <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <hr className="seperator-timing" />

                                <div>
                                    <div className="d-flex justify-content-between px-3 mt-4">
                                        <div>
                                            <span className="timing-day">Thursday</span>
                                        </div>
                                        <div onClick={() => this.__handleDay('editThursday')}>
                                            <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                        </div>
                                    </div>
                                    {this.state.editThursday ?
                                        <div className='mt-3 px-3'>
                                            <div className="d-flex flex-column px-3 mb-3">
                                                {schedule_data && schedule_data.thursday && schedule_data.thursday.map((item, index) => (
                                                    <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                        <div className='flex-grow-1'>
                                                            <input type="time" value={this.getEditTime(item.open)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min="01:00" max={this.getEditTime(item.close)}
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'thursday', "open")
                                                                }} />
                                                        </div>
                                                        <div className='flex-grow-1 ms-2'>
                                                            <input type="time" value={this.getEditTime(item.close)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min={this.getEditTime(item.open)} max="23:59"
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'thursday', "close")
                                                                }} />
                                                        </div>
                                                        <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('thursday', index)}>
                                                            <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex justify-content-between  px-2 mb-3'>
                                                <div onClick={() => { this.handleSchedule('thursday') }}>
                                                    <span className='slot-add'>Add Slot +</span>
                                                </div>
                                                <div>
                                                    <span className='copy-days'>Copy To All Days</span>
                                                </div>
                                            </div>
                                            <div className='px-4'>
                                                <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('thursday',)}>
                                                    <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {schedule_data && schedule_data.thursday && schedule_data.thursday.map((item, index) => (
                                                <div className="d-flex flex-row" >
                                                    <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <hr className="seperator-timing" />

                                <div>
                                    <div className="d-flex justify-content-between px-3 mt-4">
                                        <div>
                                            <span className="timing-day">Friday</span>
                                        </div>
                                        <div onClick={() => this.__handleDay('editFriday')}>
                                            <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                        </div>
                                    </div>
                                    {this.state.editFriday ?
                                        <div className='mt-3 px-3'>
                                            <div className="d-flex flex-column px-3 mb-3">
                                                {schedule_data && schedule_data.friday && schedule_data.friday.map((item, index) => (
                                                    <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                        <div className='flex-grow-1'>
                                                            <input type="time" value={this.getEditTime(item.open)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min="01:00" max={this.getEditTime(item.close)}
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'friday', "open")
                                                                }} />
                                                        </div>
                                                        <div className='flex-grow-1 ms-2'>
                                                            <input type="time" value={this.getEditTime(item.close)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min={this.getEditTime(item.open)} max="23:59"
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'friday', "close")
                                                                }} />
                                                        </div>
                                                        <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('friday', index)}>
                                                            <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex justify-content-between  px-2 mb-3'>
                                                <div onClick={() => { this.handleSchedule('friday') }}>
                                                    <span className='slot-add'>Add Slot +</span>
                                                </div>
                                                <div>
                                                    <span className='copy-days'>Copy To All Days</span>
                                                </div>
                                            </div>
                                            <div className='px-4'>
                                                <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('friday',)}>
                                                    <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {schedule_data && schedule_data.friday && schedule_data.friday.map((item, index) => (
                                                <div className="d-flex flex-row" >
                                                    <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <hr className="seperator-timing" />

                                <div>
                                    <div className="d-flex justify-content-between px-3 mt-4">
                                        <div>
                                            <span className="timing-day">Saturday</span>
                                        </div>
                                        <div onClick={() => this.__handleDay('editSaturday')}>
                                            <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                        </div>
                                    </div>
                                    {this.state.editSaturday ?
                                        <div className='mt-3 px-3'>
                                            <div className="d-flex flex-column px-3 mb-3">
                                                {schedule_data && schedule_data.saturday && schedule_data.saturday.map((item, index) => (
                                                    <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                        <div className='flex-grow-1'>
                                                            <input type="time" value={this.getEditTime(item.open)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min="01:00" max={this.getEditTime(item.close)}
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'saturday', "open")
                                                                }} />
                                                        </div>
                                                        <div className='flex-grow-1 ms-2'>
                                                            <input type="time" value={this.getEditTime(item.close)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min={this.getEditTime(item.open)} max="23:59"
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'saturday', "close")
                                                                }} />
                                                        </div>
                                                        <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('saturday', index)}>
                                                            <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex justify-content-between  px-2 mb-3'>
                                                <div onClick={() => { this.handleSchedule('saturday') }}>
                                                    <span className='slot-add'>Add Slot +</span>
                                                </div>
                                                <div>
                                                    <span className='copy-days'>Copy To All Days</span>
                                                </div>
                                            </div>
                                            <div className='px-4'>
                                                <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('saturday',)}>
                                                    <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {schedule_data && schedule_data.saturday && schedule_data.saturday.map((item, index) => (
                                                <div className="d-flex flex-row" >
                                                    <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <hr className="seperator-timing" />

                                <div>
                                    <div className="d-flex justify-content-between px-3 mt-4">
                                        <div>
                                            <span className="timing-day">Sunday</span>
                                        </div>
                                        <div onClick={() => this.__handleDay('editSunday')}>
                                            <TbEdit style={{ fontSize: '23', color: '#FF9A62' }} />
                                        </div>
                                    </div>
                                    {this.state.editSunday ?
                                        <div className='mt-3 px-3'>
                                            <div className="d-flex flex-column px-3 mb-3">
                                                {schedule_data && schedule_data.sunday && schedule_data.sunday.map((item, index) => (
                                                    <div className='d-flex flex-row justify-content-around align-items-center py-2'>
                                                        <div className='flex-grow-1'>
                                                            <input type="time" value={this.getEditTime(item.open)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min="01:00" max={this.getEditTime(item.close)}
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'sunday', "open")
                                                                }} />
                                                        </div>
                                                        <div className='flex-grow-1 ms-2'>
                                                            <input type="time" value={this.getEditTime(item.close)}
                                                                style={{ backgroundColor: 'rgba(255, 144, 18, 0.1)', border: 'none', borderRadius: '8px' }} className="p-2"
                                                                min={this.getEditTime(item.open)} max="23:59"
                                                                onChange={e => {
                                                                    let value = e.target.value;
                                                                    this._addNewDate(index, value, 'sunday', "close")
                                                                }} />
                                                        </div>
                                                        <div className='flex-shrink-1 ms-2' onClick={() => this.deleteTime('sunday', index)}>
                                                            <RiDeleteBinLine style={{ fontSize: '16', color: '#EB001B', marginTop: '5px' }} />
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className='d-flex justify-content-between  px-2 mb-3'>
                                                <div onClick={() => { this.handleSchedule('sunday') }}>
                                                    <span className='slot-add'>Add Slot +</span>
                                                </div>
                                                <div>
                                                    <span className='copy-days'>Copy To All Days</span>
                                                </div>
                                            </div>
                                            <div className='px-4'>
                                                <button className='save-btn-timing py-3' onClick={() => this.__saveTimings('sunday',)}>
                                                    <span style={{ fontWeight: 'bold', color: '#ffffff' }}>Save Timings</span>
                                                </button>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            {schedule_data && schedule_data.sunday && schedule_data.sunday.map((item, index) => (
                                                <div className="d-flex flex-row" >
                                                    <span className="text-muted timing-text-one" key={index}>{this.getTime(item.open)} to {this.getTime(item.close)}</span>
                                                </div>
                                            ))}
                                        </div>
                                    }

                                </div>
                                <hr className="seperator-timing" />
                            </div>
                            :
                            items && items.length > 0 ?
                                <div className='d-flex flex-column' style={{ paddingTop: '25vw' }}>
                                    <VendorBackButton title="Category Timing" />
                                    <div className="px-3" >
                                        <h4 className='fw-bolder'>Categories</h4>
                                        {items && items.map((item, index) => (
                                            <div className='save-btn-timing text-center py-3 mt-2' onClick={() => this.selectCategory(item.id)}>
                                                <span style={{ fontWeight: 'bold', color: '#ffffff' }}>{item.name}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                :
                                <div className='d-flex flex-column text-center' style={{ paddingTop: '25vw' }}>
                                    <VendorBackButton title="Category Timing" />

                                    <div className="px-3" >
                                        <h1>No Category Found</h1>
                                    </div>
                                </div>

                        }
                    </React.Fragment>
                }
            </React.Fragment>

        )
    }
}

const mapStateToProps = (state) => ({
    store: state.vendor.store,
    user: state.vendor.store_user
})
export default connect(mapStateToProps, {
    getStoreCategories,
    getCategoryScheduleDate,
    addCategoryScheduleDate,
    changeItemScheduleStatus
})(VendorCategoryTiming)


