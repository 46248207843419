import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import VendorBackButton from '../../Elements/VendorBackButton';
import Loading from '../../Elements/loading'
import { HiOutlineChevronRight } from 'react-icons/hi';
import { BottomSheet } from 'react-spring-bottom-sheet'
import { addAddonCategory } from "../../../redux/vendor/items/action";
import Modal from "react-modal";
import image from '../../../assets/images/greentick.png'


export class VendorAddAddonCategory extends Component {
    state = {
        loading: false,
        category: {},
        imageName: '',
        open: false,
        typesheet: false,
        type: '',
    }
    componentDidMount() {
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    handleInput(e, name) {
        let addoncategory = this.state.category;
        addoncategory[name] = e;
        this.setState({ category: addoncategory });
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { category } = this.state;
        let formData = new FormData()
        formData.append("name", category.name)
        formData.append("description", category.description)
        formData.append("type", this.state.type)
        formData.append("store_id", user.data.store[0].id)
        formData.append("token", user.data.auth_token)
        this.props.addAddonCategory(formData)
            .then((response) => {
                if (response) {
                    document.getElementById('addoncategoryform').reset()
                    this.setState({ type: '' })
                    this.handleModal()
                }
            })
    }
    handleModal = () => {
        this.setState({ open: !this.state.open });
    };
    closeTypeMenu = () => {
        this.setState({ typesheet: false })
    };
    openTypeMenu = () => {
        this.setState({ typesheet: true })
    }
    render() {
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Add Addon Category" />
                        </div>
                        <div className="px-4 mt-4 pt-3">
                            <div className="mt-5 px-4 pt-4">
                                <div className="coupon-heading-one">
                                    <span>Add Addon Categories to your Store</span>
                                </div>
                                <div className="coupon-heading-two text-muted">
                                    <span>Enter Category Details</span>
                                </div>
                            </div>
                            <div className="form-coupon">
                                <Form onSubmit={this.onSubmit} id="addoncategoryform">
                                    <InputGroup className="mt-4 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="text"
                                            placeholder="Enter category title"
                                            className="input-coupon"
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "name");
                                            }}
                                        />
                                    </InputGroup>
                                    <div id="alertdiv" className='text-danger ms-2' style={{ fontSize: '12px' }}></div>
                                    <InputGroup className="mt-3 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            as="textarea" rows={3}
                                            placeholder="Category Description"
                                            className="input-coupon"
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "description");
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        className="mt-3 coupon-input p-2 d-flex justify-content-between"
                                        style={{ boxSizing: 'border-box' }}
                                        onClick={() => this.openTypeMenu()}
                                    >
                                        <div>
                                            <input type="hidden" value={this.state.category} required />
                                            <label for='category-type' className='text-muted px-3 py-1'>
                                                {this.state.type ? (
                                                    <>
                                                        {this.state.type}
                                                    </>
                                                ) : (
                                                    <>
                                                        select an option
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                        <div>
                                            <span className='px-1 py-2 ml-3' id='category-type'  ><HiOutlineChevronRight style={{ color: '#DA6317' }} /></span>
                                        </div>
                                    </InputGroup>
                                    <BottomSheet
                                        open={this.state.typesheet}
                                        onDismiss={() => this.closeTypeMenu()}
                                        snapPoints={({ maxHeight }) => 0.36 * maxHeight}
                                    >
                                        <div className='px-3 pb-5' >
                                            <div className="mt-1 mb-2 " style={{
                                                fontWeight: '600',
                                                fontSize: '16px'
                                            }}>
                                                Select Category Type
                                            </div>
                                            <div className='px-3 pb-5' >
                                                <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                    <div className="d-grid gap-2">
                                                        <button className="offer-sheet-btn" onClick={() => this.setState({ type: 'SINGLE', typesheet: false })} >
                                                            Single
                                                        </button>
                                                        <button className="offer-sheet-btn" onClick={() => this.setState({ type: 'MULTIPLE', typesheet: false })} >
                                                            Multiple
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                    <div className="position-fixed w-100 me-3 px-4" style={{ bottom: '0px', left: '0px' }}>
                                        <button type='submit' className='button-coupon p-3' >
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.open}
                            ariaHideApp={false}
                            onRequestClose={() => this.handleModal()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div
                                className="container"
                                style={{
                                    borderRadius: "5px",
                                    height: "210px",
                                    zIndex: "9999",
                                }}
                            >
                                <React.Fragment>
                                    <div className="mb-20 d-flex justify-content-center align-items-center">
                                        <img src={image} style={{ objectFit: 'contain', height: '110px' }} alt="success"/>
                                    </div>
                                    <div className="col-12 pb-3 d-flex flex-column align-items-center justify-content-center">
                                        <h1 className="pt-3 mb-0 font-weight-black h4">
                                            Category
                                        </h1>
                                        <h1 className="pt-1 mb-0 font-weight-black h4">
                                            Added Successfully
                                        </h1>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}

            </React.Fragment>)
    }
}
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
})

export default connect(mapStateToProps, { addAddonCategory })(VendorAddAddonCategory)
