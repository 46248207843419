import React, { Component } from "react";
import { connect } from "react-redux";
// import { DatePicker } from "antd";
import "antd/dist/antd.css";
import VendorBackButton from "../../Elements/VendorBackButton";
import Loading from "../../Elements/loading";
import { Form, FormControl, InputGroup } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import { MdOutlineArrowForwardIos } from "react-icons/md";
import { viewPayout } from "../../../redux/vendor/payout/action";
import moment from "moment";
import { formatPrice } from "../../Helpers/formatPrice";
import Nosales from "../../../assets/images/noSales.png"

class VendorPayoutDetails extends Component {
    state = {
        loading: false,
        payout: false,
        from: "",
        to: "",
    };
    // showDetails = (e) => {
    //     if (this.state.payout === true) {
    //         this.setState({ payout: false });
    //     } else {
    //         this.setState({ payout: true });
    //     }
    // };
    changeStartDate = (e) => {
        this.setState({
            from: e,
        });
        //// console.log(e, 'e')
    };
    changeEndDate = (e) => {
        this.setState({
            to: e,
        });
    };
    componentDidMount() {
        const { user } = this.props;
        var from = new Date().toISOString().slice(0, 10);
        var to = new Date().toISOString().slice(0, 10);
        this.setState({ from: from, to: to, loading: true });
        this.props.viewPayout(user.data.auth_token, from, to).then((response) => {
            if (response) {
                //// console.log(response.payload);
                this.setState({ payout: response.payload?.payouts, loading: false });
            }
        });
    }
    onSubmit = (e) => {
        this.setState({ loading: true });
        e.preventDefault();
        const { user } = this.props;
        this.props.viewPayout(user.data.auth_token, this.state.from, this.state.to)
            .then((response) => {
                if (response) {
                    this.setState({ payout: response.payload, loading: false });
                }
            });
    };

    getStatusBadge = (status) => {
        switch (status) {
            case 'pending':
                return (
                    <span className="text-capitalize bg-info px-3 py-1 text-white rounded-3">
                        {status}
                    </span>
                )
                break;
            case 'processed':
                return (
                    <span className="text-capitalize bg-yellow px-3 py-1 text-white rounded-3">
                        {status}
                    </span>
                )
                break;

            case 'completed':
                return (
                    <span className="text-capitalize bg-success px-3 py-1 text-white rounded-3">
                        {status}
                    </span>
                )
                break;

            case 'failed':
                return (
                    <span className="text-capitalize bg-danger px-3 py-1 text-white rounded-3">
                        {status}
                    </span>
                )
                break;
            default:
                break;
        }
    }
    render() {
        const { payout } = this.state;
        const today = moment().format('YYYY-MM-DD');
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <div>
                                <VendorBackButton title="Payout Details" />
                            </div>



                            {payout?.length > 0 ?
                                <div style={{ marginTop: 100 }}>

                                    <div>
                                        {payout && payout.map((data, index) =>
                                            <div className=" mx-3  d-flex flex-column  mt-3 p-3 gap-2" style={{
                                                background: "rgba(217,217,217,0.2)",
                                                borderRadius: "10px",
                                            }}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <span className="orders">
                                                        #{index + 1}
                                                    </span>

                                                    {this.getStatusBadge(data.status)}
                                                </div>
                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Period :
                                                    </span>
                                                    <span className="order-date">
                                                        {data.start_date} - {data.end_date}
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Total No Of Orders :
                                                    </span>
                                                    <span className="order-date">
                                                        {data.orders_count}
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Customer Paid :
                                                    </span>
                                                    <span className="order-date">
                                                        Rs.{data.customer_total}
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Earnings :
                                                    </span>
                                                    <span className="order-date">
                                                        Rs.{data.store_earnings}
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Packaging Charges :
                                                    </span>
                                                    <span className="order-date">
                                                        Rs.{data.store_charge}
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Coupon Contributions :
                                                    </span>
                                                    <span className="order-date">
                                                        Rs.{data.store_coupon_contribution}
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Delivery CHarge Contributions :
                                                    </span>
                                                    <span className="order-date">
                                                        Rs.{data.store_delivery_contribution}
                                                    </span>
                                                </div>

                                                <div className="d-flex align-items-center justify-content-between ">
                                                    <span className="fw-bold order-date">
                                                        Tax :
                                                    </span>
                                                    <span className="order-date">
                                                        Rs.{data.tax}
                                                    </span>
                                                </div>

                                                {data?.status == "processed" || data?.status == "completed" &&
                                                    <React.Fragment>
                                                        <div className="d-flex align-items-center justify-content-between ">
                                                            <span className="fw-bold order-date">
                                                                Transaction Mode :
                                                            </span>
                                                            <span className="order-date">
                                                                Rs.{data.transaction_mode}
                                                            </span>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-between ">
                                                            <span className="fw-bold order-date">
                                                                Transaction ID :
                                                            </span>
                                                            <span className="order-date">
                                                                Rs.{data.transaction_id}
                                                            </span>
                                                        </div>
                                                        {/* <div className="d-flex align-items-center justify-content-between ">
                                                            <span className="fw-bold order-date">
                                                                Transaction Date :
                                                            </span>
                                                            <span className="order-date">
                                                                Rs.{data.transaction_id}
                                                            </span>
                                                        </div> */}
                                                    </React.Fragment>

                                                }
                                            </div>
                                        )}
                                    </div>
                                </div>
                                :
                                <div className="d-flex flex-column align-items-center justify-content-center mt-4 px-3">
                                    <img src={Nosales} style={{ width: '80%', objectFit: 'cover' }} alt="no_sale" />
                                    <span className="fw-bolder" style={{ fontSize: '1.1em' }}>
                                        No Sales Found
                                    </span>
                                </div>
                            }

                            {/* <div className="" style={{ paddingTop: "20vw" }}>
                                <Form onSubmit={this.onSubmit}>
                                    <div style={{ margin: "22px 18px 18px 18px", }}>
                                        <h5 className="date-wise-sale">
                                            Start Date
                                        </h5>
                                        <input
                                            type="date"
                                            placeholder="Date"
                                            style={{
                                                width: "100%",
                                                height: "52px",
                                                background: "rgba(255,144,18,0.1)",
                                                boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
                                                borderRadius: "8px",
                                                border: "none",
                                                padding: "10px",
                                            }}
                                            value={this.state.from}
                                            max={`${today}`}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.changeStartDate(value);
                                            }}
                                            required
                                        />
                                    </div>
                                    <div style={{ margin: "22px 18px 18px 18px", }}>
                                        <h5 className="date-wise-sale">
                                            End Date
                                        </h5>
                                        <input
                                            type="date"
                                            placeholder="Date"
                                            style={{
                                                width: "100%",
                                                height: "52px",
                                                background: "rgba(255,144,18,0.1)",
                                                boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.05)",
                                                borderRadius: "8px",
                                                border: "none",
                                                padding: "10px",
                                            }}
                                            min={`${moment(this.state.from).format('YYYY-MM-DD')}`}
                                            max={`${today}`}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                this.changeEndDate(value);
                                            }}
                                            value={this.state.to}
                                            required
                                        />
                                    </div>
                                    <div className="pt-3 px-4 text-end">
                                        <button
                                            type="submit"
                                            className="order-btn py-3 w-100"
                                            // onClick={this.showDetails}
                                            style={{ fontSize: '15px' }}
                                        >
                                            Show Payouts
                                        </button>
                                    </div>
                                </Form>
                                <div>
                                    {payout?.success ? (
                                        <div className="mt-5">
                                            <div className=" text-center">
                                                <div className=" mx-4 mt-5">
                                                    {payout && payout.earningData ? (
                                                        <h5
                                                            style={{
                                                                fontWeight: "700",
                                                                fontSize: "16px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            Total Earnings : Rs{" "}
                                                            {payout && payout.earningData && payout.earningData}
                                                        </h5>
                                                    ) : (
                                                        <div
                                                            className="text-center "
                                                            style={{
                                                                fontWeight: "700",
                                                                fontSize: "16px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            No Earnings
                                                        </div>
                                                    )}
                                                    <br />
                                                    {payout && payout.pendingPayout ? (
                                                        <h5
                                                            style={{
                                                                fontWeight: "700",
                                                                fontSize: "16px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            Pending Payout : Rs{" "}
                                                            {payout && payout.pendingPayout}
                                                        </h5>
                                                    ) : (
                                                        <div
                                                            className="text-center "
                                                            style={{
                                                                fontWeight: "700",
                                                                fontSize: "16px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            No Pending Payout
                                                        </div>
                                                    )}
                                                    <br />
                                                    {payout && payout.completedPayout ? (
                                                        <h5
                                                            style={{
                                                                fontWeight: "700",
                                                                fontSize: "16px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            Completed Payout :
                                                            Rs{" "}
                                                            {payout && payout.completedPayout}
                                                        </h5>
                                                    ) : (
                                                        <div
                                                            className="text-center "
                                                            style={{
                                                                fontWeight: "700",
                                                                fontSize: "16px",
                                                                marginTop: "15px",
                                                            }}
                                                        >
                                                            No Completed Payout
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div> */}
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    payout: state.payouts.payouts,
});

export default connect(mapStateToProps, { viewPayout })(VendorPayoutDetails);
