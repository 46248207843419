import {
    GET_ALL_MEDICINE_DATA,
    GET_ALL_TOP_DEALS,
    GET_MOST_ORDERS,
    GET_ALL_RECOMMENDED_MEDICINE,
    GET_MEDICINE_NEAR_STORES,
    PRESCRIPTION_IMAGE,
    GET_ALL_MEDICAL_STORES,
    PRESCRIPTION_DELIVERY_CHARGE
} from "./actionType";


const initialState = {
    medicine: [],
    topDeals: [],
    mostorders: [],
    recommendedMedicines: [],
    nearMedicines: [],
    nearStores: [],
    prescriptionImage: [],
    prescriptionCharge: []
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ALL_MEDICINE_DATA:
            return { ...state, medicine: action.payload };
        case GET_ALL_TOP_DEALS:
            return { ...state, topDeals: action.payload };
        case GET_MOST_ORDERS:
            return { ...state, mostOrders: action.payload };
        case GET_ALL_RECOMMENDED_MEDICINE:
            return { ...state, recommendedMedicines: action.payload };
        case GET_MEDICINE_NEAR_STORES:
            return { ...state, nearMedicines: action.payload };
        case GET_ALL_MEDICAL_STORES:
            return { ...state, nearStores: action.payload };
        case PRESCRIPTION_IMAGE:
            return { ...state, prescriptionImage: action.payload };
        case PRESCRIPTION_DELIVERY_CHARGE:
            return { ...state, prescriptionCharge: action.payload };
        default:
            return state;
    }
}
