import React, { Component } from 'react'
import { connect } from 'react-redux'
import VendorBackButton from '../../Elements/VendorBackButton'
import Loading from '../../Elements/loading'
import { getStoreRating } from '../../../redux/vendor/user/action'
import { BsStarHalf } from 'react-icons/bs'
import { Rating } from "react-simple-star-rating";
import { Tabs } from "antd";
import "antd/dist/antd.css";
import { PLACE_HOLDER_IMAGE } from '../../../api'
import ProgressiveImage from 'react-progressive-image'
import moment from 'moment'


const { TabPane } = Tabs;


const Itemratings = ({ rating }) => {
    return (
        <div>
            {rating?.length > 0 ?
                <div className='px-3' >
                    {rating?.map((item) => (
                        <div className="card my-3">
                            <div className="card-body">
                                <div className='row'>
                                    <div className='col-md-4' style={{ width: "20%", float: "right" }}>
                                        {/* <img src={`https://grosav.com/public/${item?.item?.image}`} style={{ width: "100%", borderRadius: "12px" }} alt='' /> */}
                                        <ProgressiveImage
                                            delay={20}
                                            src={`https://grosav.com/public/${item?.item?.image}`}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src} style={{
                                                        width: '16vw',
                                                        height: '16vw',
                                                        borderRadius: '15px',
                                                        objectFit: 'cover'
                                                    }}
                                                    alt='main-item'
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                    <div className='col-md-8 ' style={{ width: "70%", float: "left", marginLeft: "10px" }}>
                                        <div className='d-flex justify-content-between'>
                                            <div className='flex flex-column gap-2'>
                                                <h6 className="">Item : {item?.item?.name}  </h6>

                                                <h6 className=""> Customer : {item?.user?.name}  </h6>
                                                <p className="">Rated At :
                                                    {moment(item?.created_at).format("DD/MM/YYYY")}

                                                </p>
                                                <p className="">Review :
                                                    {item?.review}

                                                </p>

                                                <Rating size={".9em"} className="top-res-name mobile-rate-star" initialValue={item?.rating} readonly="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                : <div className='px-3' style={{ paddingTop: "10vh" }}>
                    <div className='d-flex align-items-center justify-content-center mt-5 fw-bolder fs-3'>
                        No Review's
                    </div>
                </div>
            }
        </div>
    )
}
const Storeratings = ({ rating }) => {
    return (
        <div>
            {rating?.length > 0 ?
                <div className='px-3' >
                    {rating?.map((item) => (
                        <div className="card my-3">
                            <div className="card-body">
                                <div className='row'>

                                    <div className='col-md-12 ' style={{ width: "70%", float: "left", marginLeft: "10px" }}>
                                        <div className='d-flex justify-content-between'>
                                            <div className='flex flex-column gap-2'>

                                                <h6 className=""> Customer : {item?.user?.name}  </h6>
                                                <p className="">Rated At :
                                                    {moment(item?.created_at).format("DD/MM/YYYY")}

                                                </p>
                                                <p className="">Review :
                                                    {item?.review}

                                                </p>

                                                <Rating size={".9em"} className="top-res-name mobile-rate-star" initialValue={item?.rating} readonly="true" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    ))}
                </div>
                : <div className='px-3' style={{ paddingTop: "10vh" }}>
                    <div className='d-flex align-items-center justify-content-center mt-5 fw-bolder fs-3'>
                        No Review's
                    </div>
                </div>
            }
        </div>
    )
}

export class VendorRating extends Component {
    state = {
        loading: false,
        activeTab: "1",
        item_ratings: [],
        store_ratings: [],

    }
    componentDidMount() {
        const { user, store } = this.props;
        this.setState({ loading: true });
        this.props.getStoreRating(user?.data?.auth_token, store?.store?.id).then((response) => {
            if (response && response.payload) {
                this.setState({ loading: false, item_ratings: response.payload?.item_ratings, store_ratings: response.payload?.store_ratings });
            }
        })
    }

    changeTab = (activeKey) => {
        this.setState({ activeTab: activeKey, });
    };
    render() {
        const { rating } = this.props;
        //// console.log(rating)
        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading /> :
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Rating & Review" />

                        </div>
                        <Tabs
                            defaultActiveKey="1"
                            className="px-4 mt-2 "
                            activeKey={this.state.activeTab}
                            onChange={this.changeTab}
                            style={{ paddingTop: '100px' }}
                            size={"small"}>
                            <TabPane tab={<span>Item ratings</span>} key="1">
                                <Itemratings rating={this.state.item_ratings} key={this.state.item_ratings?.length}
                                />
                            </TabPane>
                            <TabPane tab={<span> Store ratings</span>} key="2">
                                <Storeratings rating={this.state.store_ratings} key={this.state.store_ratings?.length} />
                            </TabPane>
                        </Tabs>
                    </React.Fragment>
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    store: state.vendor.store,
    rating: state.vendor.rating,
})



export default connect(mapStateToProps, { getStoreRating })(VendorRating)
