import React, { Component } from "react";
import { connect } from "react-redux";
import { FaClinicMedical } from "react-icons/fa";
import { Link } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from "../../../../api";
import Truncate from "react-truncate";
import { Rating } from "react-simple-star-rating";

export class RecommendedStore extends Component {

    ratingCount = (ratings, count) => {
        let total = 0;
        ratings?.map((rating) => {
            total += rating.rating;
        })
            total = Math.round(total/count * 100) / 100;
;
        return total;
    }

    render() {
        const { recommendedMedicines } = this.props;
        // ////// console.log(recommendedMedicines, 'recommended');
        return (
            <React.Fragment>
                {recommendedMedicines &&
                    recommendedMedicines.success &&
                    recommendedMedicines?.data.length > 0 && (
                        <React.Fragment>
                            <div className="px-3 py-3 d-flex flex-row align-items-center ">
                                <FaClinicMedical
                                    style={{
                                        fontSize: "1.5rem",
                                        color: "#FF9A62",
                                    }}
                                />
                                <span
                                    style={{
                                        fontWeight: "700",
                                        fontSize: "18px",
                                        marginLeft: "10px",
                                    }}
                                >
                                    Recommended For You
                                </span>
                            </div>

                            <div className="Recommended-store-near  d-flex flex-row hidden-scroll-bar p-3  gap-3"
                                style={recommendedMedicines?.data?.length > 4 ? {
                                    gridTemplateColumns: `repeat(${recommendedMedicines?.data?.length},1fr)`,
                                } : { gridTemplateColumns: "repeat(4,1fr)" }} >
                                {recommendedMedicines && recommendedMedicines.data && recommendedMedicines.data.map((store, index) => (
                                    <Link to={"/single-store/" + store.id} style={{ width: "25vw" }}>
                                        <div className="d-flex flex-column" style={{ width: "25vw" }}>
                                            <ProgressiveImage delay={20}
                                                src={IMAGE_BASE_URL + store.image}
                                                placeholder={PLACE_HOLDER_IMAGE}  >
                                                {(src, loading) => (
                                                    <img className=""
                                                        style={{
                                                            width: "25vw", objectFit: "cover", height: "25vw",
                                                            borderRadius: "15px", backgroundRepeat: "round",
                                                            backgroundImage: `url(${PLACE_HOLDER_IMAGE})`,
                                                        }}
                                                        src={src}
                                                        alt="main-item"
                                                    />
                                                )}
                                            </ProgressiveImage>

                                            <div className="offer-store-name text-black fw-700">
                                                <Truncate>
                                                    {store.name}
                                                </Truncate>
                                            </div>
                                            {store?.ratings?.length > 0 ?
                                                <div>
                                                    <Rating
                                                        size={"1em"}
                                                        className="mobile-rate-star"
                                                        initialValue={this.ratingCount(store?.ratings, store?.ratings.length)}
                                                        readonly="true"
                                                    />
                                                </div>
                                                :
                                                <Rating
                                                    size={"1em"}
                                                    className="mobile-rate-star"
                                                    initialValue={store?.rating}
                                                    readonly="true"
                                                />
                                            }
                                            <div>
                                                <span className="top-res-deli-time text-muted ">
                                                    {store.approx_time_delivery > 0 ? (store.approx_time_delivery + " Mins") : ("0Min")}
                                                    {store.delivery_radius > 0 ? ("- " + store.delivery_radius + "km") : ("- 0km")}
                                                </span>
                                            </div>
                                        </div>
                                    </Link>
                                )
                                )}
                            </div>
                        </React.Fragment>
                    )}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state) => ({
    recommendedMedicines: state.medicine.recommendedMedicines,
});

export default connect(mapStateToProps, {})(RecommendedStore);
