import React, { Component } from "react";
import { connect } from "react-redux";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api/index';
import Ink from "react-ink";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
import { getStoreCategoryBanners } from '../../../../redux/mobile/storeCategory/action'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export class GroceryBanner extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        this.setState({
            loading: true
        })
        // ////// console.log(this.props,'iddd')
        this.props.getStoreCategoryBanners(this.props.id,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((res) => {
                this.setState({
                    loading: false
                })
            })
    }
    render() {
        const { banners } = this.props;
        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            arrows: false,
            speed: 400,
            autoplaySpeed: 8000,
            cssEase: "linear",
            customPaging: function (i) {
              return <div className="dot"></div>;
            },
            dotsClass: "slick-dots slick-thumb"
          };
        return (
            <React.Fragment>
                {banners && banners?.data?.length > 0 && this.state.loading == false ? (
                    <div className="px-3 py-3">

                        <Slider {...settings} >
                            {banners?.data?.map((banner) =>
                                <Link to={"/top-offer/banner/" + banner.id}>
                                    <div className="d-flex flex-column justify-content-center align-items-center text-center position-relative b-r-40">
                                        <Ink className="touch-ink " />
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + banner.image}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img src={src}
                                                    style={{ width: "88vw", height: "35vw", borderRadius: "20px", objectFit: "cover", backgroundRepeat: "round", }}
                                                    className=""
                                                    alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                    </div>
                                </Link>
                            )}
                        </Slider>

                    </div>
                ) : (
                    ""
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    banners: state.mobilestorecategory.banners,
});

export default connect(mapStateToProps, { getStoreCategoryBanners })(GroceryBanner);
