import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import MobileBackButton from "../../../../Elements/MobileBackButton";
// import { formatPrice } from "../../Helpers/formatPrice";
import editlocation from "../../../../../assets/images/location.png";
// // import cod from "../../../assets/images/cod.png";
// import ContentLoader from "react-content-loader";
import cod from "../../../../../assets/images/cash-on-delivery 1.png";
import walletImg from "../../../../../assets/images/entypo_wallet.png";
import upi from "../../../../../assets/images/UPI1.jpg";
// import mscard1 from "../../../assets/images/Mastercard_logo 1.png";
// import { BottomSheet } from "react-spring-bottom-sheet";
// import { placeOrder } from "../../../redux/mobile/checkOut/action";
// import { updateUserReduxInfo } from "../../../redux/mobile/user/action";
// import { MdDelete } from 'react-icons/md';
// import { PAYTM_PAYMENT_URL } from "../../../api";

export class index extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="p-2 d-flex flex-column align-items-center px-4">
                    <Link to="/my-address">
                        <div className="mobile-card p-2 d-flex flex-column">
                            <div
                                style={{
                                    paddingLeft: "10px",
                                    paddingTop: "0.6em",
                                }}
                                className="d-flex justify-content-between align-items-center"
                            >
                                <p
                                    style={{
                                        color: "#3B3B3B",
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        opacity: "0.3",
                                    }}
                                    className="text-muted"
                                >
                                    Deliver To
                                </p>
                                <div className="edit-confirm">Edit</div>
                            </div>

                            <div
                                className="mobile-location d-flex flex-row align-items-center"
                                style={{ paddingLeft: "10px" }}
                            >
                                <img
                                    src={editlocation}
                                    className="loc"
                                    alt="EditLocation"
                                />
                                <div
                                    style={{ paddingLeft: "10px" }}
                                    className="d-flex flex-column"
                                >
                                    <div
                                        style={{
                                            paddingBottom: "0px",
                                            color: "#000",
                                        }}
                                        className="fs-10"
                                    >
                                        {/* {this.state.address?.house} */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                    <div
                        className="mobile-card5  d-flex flex-column "
                        onClick={() => this.openPayment()}
                    >
                        <div
                            style={{
                                paddingLeft: "20px",
                                paddingTop: "1em",
                            }}
                            className="d-flex justify-content-between"
                        >
                            <p
                                style={{
                                    color: "#3B3B3B",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    opacity: "0.3",
                                }}
                                className="text-muted"
                            >
                                Payment Method
                            </p>
                            <div className="edit-confirm">Edit</div>
                        </div>
                        {/* {this.state.paymentSelected == true ? ( */}
                        <React.Fragment>
                            {/* {this.state.payment_type == "WALLET" ? ( */}
                            <div
                                className="mobile-location d-flex flex-row align-items-center"
                                style={{ paddingLeft: "20px" }}
                            >
                                <img
                                    src={walletImg}
                                    className="loc"
                                    alt="EditLocation"
                                />
                                <span
                                    style={{
                                        paddingLeft: "10px",
                                    }}
                                    className="d-flex flex-column"
                                >
                                    <h6
                                        style={{
                                            marginBottom: "0",
                                        }}
                                    >
                                        Wallet Payment
                                    </h6>
                                </span>
                            </div>
                            {/* ) : this.state.payment_type == "ONLINE" ? ( */}
                            <div
                                className="mobile-location d-flex flex-row align-items-center"
                                style={{ paddingLeft: "20px" }}
                            >
                                <img
                                    src={upi}
                                    className="loc"
                                    alt="EditLocation"
                                />
                                {/* <span
                      style={{ paddingLeft: "10px" }}
                      className="d-flex flex-column"
                  >
                      <h6 style={{ paddingBottom: "0px" }}>
                          UPI PAYMENT
                      </h6>
                  </span> */}
                            </div>
                            {/* ) : ( */}
                            <div
                                className="mobile-location d-flex flex-row align-items-center"
                                style={{ paddingLeft: "20px" }}
                            >
                                <img
                                    src={cod}
                                    className="loc"
                                    alt="EditLocation"
                                />
                                <span
                                    style={{
                                        paddingLeft: "10px",
                                    }}
                                    className="d-flex flex-column"
                                >
                                    <h6
                                        style={{
                                            paddingBottom:
                                                "0px",
                                        }}
                                    >
                                        Cash on Delivery
                                    </h6>
                                </span>
                            </div>
                            {/* )} */}
                        </React.Fragment>
                        {/* ) : ( */}
                        <div
                            className="mobile-location d-flex flex-row align-items-center"
                            style={{ paddingLeft: "20px" }}
                        >
                            <span
                                style={{ paddingLeft: "10px" }}
                                className="d-flex flex-column"
                            >
                                <h6
                                    style={{ paddingBottom: "0px" }}
                                >
                                    Select a Payment Method
                                </h6>
                            </span>
                        </div>
                        {/* )} */}
                    </div>
                    {/* {user.data.wallet_balance > 0 ? (
                        <div
                            className="mobile-card2 py-3 w-100  d-flex flex-row justify-content-between align-items-center "
                        >
                            <div className="px-3">
                                <img
                                    src={walletImg}
                                    className="mscard1"
                                    alt="mscard1"
                                />
                                {this.state.canPayFullWithWallet ? (
                                    <span
                                        style={{
                                            fontWeight: "800",
                                            paddingLeft: "10px",
                                        }}
                                    >
                                        {user.data.wallet_balance - this.getTotalAfterCalculation()}
                                    </span>
                                ) : (<span
                                    style={{
                                        fontWeight: "800",
                                        paddingLeft: "10px",
                                    }}
                                >
                                    {user.data.wallet_balance - this.state.walletAmount}
                                </span>)}

                            </div>
                            <div className="d-flex px-3">
                                <div className="redeem-btn me-3 p-1" onClick={(e) => this.redeemWalletAmt(e, user.data.wallet_balance)}>Redeem</div>
                                <span style={{ color: 'red' }} onClick={this.removeWalletAmt}>remove</span>
                            </div>
                        </div>
                    ) : ('')} */}
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(index)
