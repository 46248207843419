import React, { Component } from "react";
import { connect } from "react-redux";
import { HiOutlineChevronLeft } from "react-icons/hi";
import Loading from '../../Elements/loading'
import { getVendorSingleOrder } from '../../../redux/vendor/order/action'
import moment from "moment";

export class VendorCompletedOrders extends Component {
    state = {
        loading: false,
        order_id: this.props.match.params.id,
        order: ''
    }
    onHandleBack = () => {
        this.props.history.goBack()
    }
    componentDidMount() {
        const { user } = this.props;
        this.setState({ loading: true })
        //// console.log(user, this.props.match.params.id)
        this.props.getVendorSingleOrder(user && user.data && user.data.auth_token, this.state.order_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, order: response.payload.order });
            } else {
                //// console.log('API Error')
            }
        })
    }
    render() {
        const { order } = this.state;
        return (
            <React.Fragment>
                {this.state.loading ?
                    <Loading />
                    :
                    <React.Fragment>
                        <div className="d-flex align-items-center position-fixed px-3 w-100 py-3" style={{ backgroundColor: '#fff', top: '0px', boxShadow: '#dddddd -1px -3px 20px 0px', zIndex: '99999999' }}>
                            <div className="px-2 back-button" onClick={() => this.onHandleBack()}>
                                <span className="fs-2 back-button-icon">
                                    <HiOutlineChevronLeft style={{ color: '#DA6317' }} />
                                </span>
                            </div>
                            <div className="ps-4 fs-5 d-flex flex-column">
                                <span className="">Order #{order && order.unique_order_id ? order.unique_order_id : null}</span>
                                <span className="" style={{ fontWeight: 500, fontSize: "11px", lineHeight: "15px", color: "#696B6D" }}>
                                    {moment(order && order.order_delivered_at).format("D-M-Y h:m A")} | {order && order.orderitems?.length} Items</span>
                            </div>
                        </div>
                        <div className="" style={{ paddingTop: '20vw' }}>
                        </div>
                        <div
                            className="py-2"
                            style={{ backgroundColor: "#F9F7F7" }}
                        >
                            <span className="px-3" style={{ fontWeight: 700, fontSize: "16px", color: "#FF9012", }} >Delivered </span>
                            <span>{moment(order && order.order_delivered_at).fromNow()}</span>
                        </div>
                        {order && order.orderitems.map((item, index) => (
                            <div className="card-restaurant py-3 mt-3 px-3">
                                <div className="d-flex justify-content-between mb-1">
                                    <div className="explore-general-one">
                                        <span className="text-muted">Item</span>
                                    </div>
                                    <div className="explore-general-two">
                                        <span className="text-muted">
                                            {item.name}
                                        </span>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div className="explore-general-one">
                                        <span className="text-muted"> Quantity</span>
                                    </div>
                                    <div className="explore-general-two">
                                        <span className="text-muted">{item.quantity}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                        <div className="card-restaurant py-3 mt-3 px-3">
                            <div className="d-flex justify-content-between mb-1">
                                <div className="explore-general-one">
                                    <span className="text-muted">Subtotal</span>
                                </div>
                                <div className="explore-general-two">
                                    <span className="text-muted">Rs. {order && order.sub_total}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-1">
                                <div className="explore-general-one">
                                    <span className="text-muted"> Store Charge</span>
                                </div>
                                <div className="explore-general-two">
                                    <span className="text-muted">Rs. {order && order.store_charges}</span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-1">
                                <div className="explore-general-one">
                                    <span className="text-muted">Delivery Charge</span>
                                </div>
                                <div className="explore-general-two">
                                    <span className="text-muted">Rs. {order && order.delivery_charge} </span>
                                </div>
                            </div>
                            <div className="d-flex justify-content-between mb-1 mt-2">
                                <div className="explore-general-total">
                                    <span className="text-muted">Total</span>
                                </div>
                                <div className="explore-general-amount">
                                    <span className="text-muted">Rs. {order && order.total}</span>
                                </div>
                            </div>
                        </div>
                        <div className="order-completed-btn" style={{ color: "#32BA7C", fontWeight: 700, fontSize: "22px" }}>
                            Order Completed
                        </div>
                    </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user
});

export default connect(mapStateToProps, {
    getVendorSingleOrder
})(VendorCompletedOrders);
