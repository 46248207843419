import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import VendorBackButton from '../../Elements/VendorBackButton';
import { HiOutlineChevronRight } from 'react-icons/hi';
import { HiOutlinePlusSmall } from "react-icons/hi2";

import { BottomSheet } from 'react-spring-bottom-sheet'
import Loading from '../../Elements/loading'
import {
    getAllItemCategories,
    addVendorItem,
} from "../../../redux/vendor/items/action";
import Modal from "react-modal";
import image from '../../../assets/images/greentick.png'
import { Link } from 'react-router-dom';

export class VendorAddItem extends Component {
    state = {
        loading: false,
        categorysheet: false,
        category: '',
        categoryname: '',
        showDiscount: false,
        VendorItem: {},
        market_price: 0,
        imageName: '',
        open: false,
    }
    componentDidMount() {
        const { user } = this.props;
        this.props.getAllItemCategories(user.data.auth_token, user.data.store[0].id);
        this.setState({ loading: true });
        setTimeout(() => {
            this.setState({ loading: false });
        }, 1500);
    }
    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { VendorItem } = this.state;
        if (VendorItem.image && VendorItem.image.size > 200000) {
            const alert = document.getElementById('alertdiv')
            alert.innerHTML = '<p>*The image may not be greater than 200 kilobytes.</p>'
        } else {
            let formData = new FormData()
            formData.append("title", VendorItem.title)
            formData.append("image", VendorItem.image)
            formData.append("description", VendorItem.description)
            formData.append("stock", VendorItem.stock)
            formData.append("category", this.state.category)
            formData.append("selling_price", VendorItem.selling_price)
            formData.append("market_price", this.state.market_price)
            formData.append("token", user.data.auth_token)
            formData.append("store_id", user.data.store[0].id)
            this.props.addVendorItem(formData)
                .then((response) => {
                    if (response) {
                        document.getElementById('itemform').reset()
                        document.getElementById('alertdiv').innerHTML = ''
                        this.setState({ categoryname: '', imageName: '' })
                        this.handleModal()
                    }
                })
        }
    }
    handleInput(e, name) {
        let item = this.state.VendorItem;
        item[name] = e;
        this.setState({ VendorItem: item });
    }
    selectCategory = (category) => {
        const id = category.id;
        const name = category.name;
        this.setState({
            category: id,
            categoryname: name,
        })
        this.closeCategoryMenu();
    }
    showDiscount = () => {
        if (this.state.showDiscount === false) {
            this.setState({ showDiscount: true })
        } else {
            this.setState({ showDiscount: false })
        }
    }
    closeCategoryMenu = () => {
        this.setState({ categorysheet: false })
    };
    openCategoryMenu = () => {
        this.setState({ categorysheet: true })
    }
    handleModal = () => {
        this.setState({ open: !this.state.open });
    };
    render() {
        const { itemcategories } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <React.Fragment>
                        <div>
                            <VendorBackButton title="Add Item" />
                        </div>
                        <div className="px-4 mt-4 pt-3">
                            <div className="mt-5 px-4 pt-4">
                                <div className="coupon-heading-one">
                                    <span>Add Items to your Store</span>
                                </div>
                                <div className="coupon-heading-two text-muted">
                                    <span>Enter Item Details</span>
                                </div>
                            </div>
                            <div className="form-coupon">
                                <Form onSubmit={this.onSubmit} id="itemform">
                                    <InputGroup className="mt-4 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            type="text"
                                            placeholder="Enter item title"
                                            className="input-coupon"
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "title");
                                            }}
                                        />
                                    </InputGroup>
                                    <div className="coupon-input mt-3 p-2 d-flex justify-content-between ">
                                        <div style={{ wordBreak: 'break-word', width: "30px" }}>
                                            <label className='text-muted px-3'>
                                                {this.state.imageName ? (
                                                    <span>
                                                        {this.state.imageName}
                                                    </span>
                                                ) : (
                                                    <>
                                                        Item Image
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                        <input className='upload-button-coupon' style={{ display: 'none' }} name='image'  accept="image/*" type="file" id="upload-button-image"
                                        onChange={(event) => {
                                            let file = event.target.files[0]
                                            this.setState({ file: URL.createObjectURL(file) });
                                            this.handleInput(file, "image");
                                            this.setState({ imageName: file.name });
                                        }}

                                        />
                                        <label className='upload-button-coupon px-3 py-1 ml-3' for='upload-button-image' >
                                            Upload File
                                        </label>
                                    </div>
                                    <div id="alertdiv" className='text-danger ms-2' style={{ fontSize: '12px' }}></div>
                                    <InputGroup className="mt-3 coupon-input" style={{ boxSizing: 'border-box' }} >
                                        <FormControl
                                            as="textarea" rows={3}
                                            placeholder="Item Description"
                                            className="input-coupon"
                                            required
                                            onChange={e => {
                                                let value = e.target.value;
                                                this.handleInput(value, "description");
                                            }}
                                        />
                                    </InputGroup>
                                    <InputGroup
                                        className="mt-3 coupon-input p-2 d-flex justify-content-between"
                                        style={{ boxSizing: 'border-box' }}
                                        onClick={() => this.openCategoryMenu()}
                                    >
                                        <div>
                                            <input type="hidden" value={this.state.category} required />
                                            <label for='category-type' className='text-muted px-3 py-1'>
                                                {this.state.categoryname ? (
                                                    <>
                                                        {this.state.categoryname}
                                                    </>
                                                ) : (
                                                    <>
                                                        Select an Item Category
                                                    </>
                                                )}
                                            </label>
                                        </div>
                                        <div>
                                            <span className='px-1 py-2 ml-3' id='category-type'  ><HiOutlineChevronRight style={{ color: '#DA6317' }} /></span>
                                        </div>
                                    </InputGroup>
                                    <BottomSheet
                                        open={this.state.categorysheet}
                                        onDismiss={() => this.closeCategoryMenu()}
                                        snapPoints={({ maxHeight }) => 0.36 * maxHeight}
                                    >
                                        <div className='px-3 pb-5' >
                                            <div className="mt-1 mb-2 " style={{
                                                fontWeight: '600',
                                                fontSize: '16px'
                                            }}>
                                                Select Item Category
                                            </div>
                                            <div className='px-3 pb-5' >
                                                <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                                                    {itemcategories?.data?.length > 0 ? (
                                                        <>
                                                            <div className="d-grid gap-2">
                                                                {itemcategories?.data?.map((category) =>
                                                                    <>
                                                                        <button className="offer-sheet-btn" onClick={() => this.selectCategory(category)} >
                                                                            {category.name}
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </BottomSheet>
                                    <div>
                                        <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                            <FormControl
                                                type="number" min={0}
                                                placeholder="Stock"
                                                className="input-coupon"
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.handleInput(value, "stock");
                                                }}
                                                required
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <InputGroup className="mt-3 coupon-input" style={{ boxSizing: 'border-box' }} >
                                                <FormControl
                                                    type="number"
                                                    min={0}
                                                    placeholder="Item Price"
                                                    className="input-coupon"
                                                    required
                                                    onChange={e => {
                                                        let value = e.target.value;
                                                        this.handleInput(value, "selling_price");
                                                    }}
                                                />
                                            </InputGroup>
                                        </div>
                                        <div className='d-flex flex-column'>
                                            <button className='add-on-btn mt-3 py-2 ms-2 w-100' type="button" onClick={() => this.showDiscount()}><HiOutlinePlusSmall /></button>
                                            <small className='ms-2 text-muted'>*Discount</small>
                                        </div>
                                    </div>
                                    <div style={{ display: this.state.showDiscount ? 'block' : 'none' }}>
                                        <InputGroup hasValidation className="mt-2 coupon-input" style={{ boxSizing: 'border-box' }} >
                                            <FormControl
                                                type="number" min={0}
                                                placeholder="Market Price"
                                                className="input-coupon"
                                                onChange={e => {
                                                    let value = e.target.value;
                                                    this.setState({ market_price: value });
                                                }}
                                            />
                                        </InputGroup>
                                    </div>
                                    <div className="position-fixed w-100 me-3 px-4" style={{ bottom: '0px', left: '0px' }}>
                                        <button type='submit' className='button-coupon p-3' >
                                            Save
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <Modal
                            isOpen={this.state.open}
                            ariaHideApp={false}
                            onRequestClose={() => this.handleModal()}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <div
                                className="container"
                                style={{
                                    borderRadius: "5px",
                                    height: "210px",
                                    zIndex: "9999",
                                }}
                            >
                                <React.Fragment>
                                    <div className="px-10 mb-20 d-flex justify-content-center align-items-center">
                                        <img src={image} style={{ objectFit: 'contain', height: '110px' }} alt="success" />
                                    </div>
                                    <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center">
                                        <h1 className="mt-2 pt-3 mb-0 font-weight-black h4">
                                            Item Added Successfully
                                        </h1>
                                    </div>
                                    <div className=" text-center w-100 px-4">
                                        <Link to="/vendor-inventory">
                                            <button type='submit' className='p-3' style={{ background: 'linear-gradient(98.81deg, #fea24b -0.82%, #fd8814 101.53%)', border: 'none', borderRadius: '10px', fontWeight: '700', lineHeight: '131.02%', color: '#ffffff' }} >
                                                go Back
                                            </button>
                                        </Link>
                                    </div>
                                </React.Fragment>
                            </div>
                        </Modal>
                    </React.Fragment>
                )}

            </React.Fragment>
        )
    }
}
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "2px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
    },
};

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    itemcategories: state.items.itemcategories,
})

export default connect(mapStateToProps, { getAllItemCategories, addVendorItem })(VendorAddItem)
