/* eslint-disable import/no-anonymous-default-export */

import { LOGOUT_USER, GET_USER, GET_ORDER, LOGIN_STORE_USER, SEND_STORE_USER_OTP, LOGIN_OTP_STORE_USER, TOGGLE_SINGLE_STORE, GET_USER_STORES, GET_USER_PASSWORD, GET_TOGGLE_DISPLAY_TOKEN, GET_ORDER_STATUS, EXPORT_DATE_WISE_SALES, GET_STORE_RATING } from "./actionType";

const initialState = {
    user: [],
    order: [],
    store_user: [],
    store: [],
    display_order: [],
    rating: [],
};

export default function (state = initialState, action) {
    switch (action.type) {

        // case LOGOUT_USER:
        //     return { ...state, user: action.payload };
        case GET_USER:
            return { ...state, user: action.payload };
        case GET_ORDER:
            return { ...state, order: action.payload };
        case LOGIN_STORE_USER:
            return { ...state, store_user: action.payload };
        case SEND_STORE_USER_OTP:
            return { ...state, store_user: action.payload };
        case LOGIN_OTP_STORE_USER:
            return { ...state, store_user: action.payload };
        case GET_USER_STORES:
            return { ...state, store: action.payload };
        case TOGGLE_SINGLE_STORE:
            return { ...state, store: action.payload };
        case GET_TOGGLE_DISPLAY_TOKEN:
            return { ...state, display_order: action.payload }
        case GET_STORE_RATING:
            return { ...state, rating: action.payload }
        default:
            return state;
    }
}
