
import {
    GET_ALL_PLANS,
    GET_SINGLE_PLAN,
    SUBSCRIPTION_PLACE,
    PAYTM_SUBSCRIPTION_STATUS_CHECK
} from './actionType';
import {
    GET_ALL_PLANS_URL,
    GET_SINGLE_PLAN_URL,
    SUBSCRIPTION_PLACE_URL,
    PAYTM_SUBSCRIPTION_STATUS_CHECK_URL
} from "../../../api";

import Axios from 'axios'

export const getAllPlans = (token) => (dispatch) => {
    return Axios.post(GET_ALL_PLANS_URL, { token })
        .then((response) => {
            let plans = response.data;
            return dispatch({
                type: GET_ALL_PLANS,
                payload: plans
            })
        })
}

export const getSinglePlan = (token, id) => (dispatch) => {
    return Axios.post(GET_SINGLE_PLAN_URL, { token, id })
        .then((response) => {
            let plan = response.data;
            return dispatch({
                type: GET_SINGLE_PLAN,
                payload: plan
            })
        })
}

export const subscribeNow = (token, id) => (dispatch) => {
    return Axios.post(SUBSCRIPTION_PLACE_URL, { token, id })
        .then((response) => {
            let plan = response.data;
            return dispatch({
                type: SUBSCRIPTION_PLACE,
                payload: plan
            })
        })
}

export const checkStatus = (id) => (dispatch) => {
    return Axios.post(PAYTM_SUBSCRIPTION_STATUS_CHECK_URL, { id })
        .then((response) => {
            let plan = response.data;
            return dispatch({
                type: PAYTM_SUBSCRIPTION_STATUS_CHECK,
                payload: plan
            })
        })
}
