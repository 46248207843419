export const GET_ORDER_RATING = "GET_ORDER_RATING";
export const GET_ALL_RUNNING_ORDERS = "GET_ALL_RUNNING_ORDERS";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const ACCEPT_ORDER = "ACCEPT_ORDER";
export const COURIER_CHECK_OUT = "COURIER_CHECK_OUT";
export const COURIER_STATUS = "COURIER_STATUS";
export const PICKUP_STATUS = "PICKUP_STATUS";
export const CHECK_COURIER_PICKUP_ADDRESS = "CHECK_COURIER_PICKUP_ADDRESS";
export const CHECK_COURIER_DELIVERY_ADDRESS = "CHECK_COURIER_DELIVERY_ADDRESS";
export const CANCEL_COURIER = "CANCEL_COURIER";
export const GET_ORDERS_TO_REVIEW = "GET_ORDERS_TO_REVIEW";
export const SUBMIT_ORDER_REVIEW = "SUBMIT_ORDER_REVIEW";
export const GET_SINGLE_ORDER = "GET_SINGLE_ORDER";
export const ADD_STORE_REVIEW = "ADD_STORE_REVIEW";
