import React, { Component } from 'react'
import { connect } from 'react-redux'
// import BurgerLayer from '../../../../assets/images/burgerlayer.png'
// import { AiOutlineMinus } from 'react-icons/ai'
// import { AiOutlinePlus } from 'react-icons/ai'
import ProgressiveImage from 'react-progressive-image'
import {
    addProduct,
    removeProduct
} from '../../../../redux/mobile/cart/action'
import FloatCart from '../FloatCart'
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api'
import Customization from '../../Customization'
import Ink from 'react-ink'
import ComboCustomization from '../../ComboCustomization'

export class Meals extends Component {
    state = {
        count: 0,
        button: false,
        floatCart: true,
    };
    handleIncrement = (e) => {
        this.setState({ count: this.state.count + 1 });
    };
    handleDecrement = (e) => {
        this.state.count && this.setState({ count: this.state.count - 1 });
    };

    forceStateUpdate = () => {
        setTimeout(() => {
            this.forceUpdate();
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };
    discountPrice = (e) => {
        let discount
        if (e.market_price > e.selling_price) {
            discount = Math.round(((e.market_price - e.selling_price) / e.market_price) * 100) + "% off";
        } else {
            discount = "";
        }
        return discount;
    }
    render() {
        const { items, addProduct, cartProducts, removeProduct, addProductQuantity } = this.props;
        return (
            <React.Fragment>

                {items && items?.product && items?.product?.length > 0 &&
                    <div className='mobile-cart-complete-bg mt-4 px-3 pb-4'>
                        <h2 className='mobile-cart-complete pt-3'>Add more releted items</h2>
                        <div className="main-menu hidden-scroll-bar gap-3 mt-3" style={{ gridTemplateColumns: `repeat(${items.product.length},1fr)` }}>
                            {items && items?.product && items?.product?.length > 0 && items?.product?.filter((cp) => cp?.id).map((product, index) => (
                                product.is_free_item === 0 &&
                                (
                                    <div className='mobile-meals-card d-flex flex-column p-3'>
                                        <div className='mobile-cart-chicken text-center' style={{
                                            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                                        }}>
                                            {product?.name}
                                        </div>
                                        <div className='mobile-cart-wijie text-center' style={{
                                            textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'
                                        }}>{product?.store?.name}</div>
                                        <div className="text-center">
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + product?.image}
                                                placeholder={PLACE_HOLDER_IMAGE} >
                                                {(src, loading) => (
                                                    <img
                                                        className=""
                                                        style={{
                                                            width: "100%", objectFit: "cover", height: "25vw",
                                                            borderRadius: "14px", backgroundRepeat: "round",
                                                        }}
                                                        src={src}
                                                        alt="main-item"
                                                    />
                                                )}
                                            </ProgressiveImage>
                                        </div>
                                        <div className='flex-grow-1 d-flex flex-column justify-content-between'>
                                            <div className='text-center' style={{ color: "#32BA7C", fontWeight: "600" }}>{this.discountPrice(product)}</div>
                                            <div className='text-center' style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
                                                {parseInt(product?.selling_price) > 0 ? (
                                                    <>
                                                        <span className='mobile-cart-item-price' style={{ fontWeight: '650', fontSize: '16px' }}>Rs {parseInt(product?.selling_price)}</span> {parseInt(product?.market_price) > parseInt(product?.selling_price) ? <span style={{ color: "orange" }}><s>Rs {product?.market_price}</s></span> : ''}
                                                    </>
                                                ) : ("")}
                                            </div>
                                            <div className='d-flex flex-column'>
                                                {product?.is_combo ? (
                                                    <span className='mobile-cart-item-price text-center text-secondary' style={{ fontWeight: '500', fontSize: '12px' }}>CUSTOMIZABLE</span>
                                                ) : product?.addoncategories?.length > 0 ? (
                                                    <span className='mobile-cart-item-price text-center text-secondary' style={{ fontWeight: '500', fontSize: '12px' }}>CUSTOMIZABLE</span>
                                                ) : ("")}
                                                <div className='d-flex flex-row justify-content-between align-items-center'>
                                                    {cartProducts?.find((cp) => cp.id === product?.id) !== undefined && (
                                                        <React.Fragment>
                                                            <div className="d-flex flex-row justify-content-around mt-1 w-100" role="group" aria-label="btnGroupIcons1" >
                                                                {product?.is_active ? (
                                                                    <React.Fragment>
                                                                        {product?.is_combo ? (
                                                                            <>
                                                                                {product?.combo_categories?.length ? null : (
                                                                                    <button type="button"
                                                                                        className="add-button-meals p-2 px-3 py-2 position-relative"
                                                                                        onClick={() => {
                                                                                            product.quantity = 1;
                                                                                            removeProduct(product);
                                                                                            this.forceStateUpdate();
                                                                                        }} >
                                                                                        -
                                                                                        <Ink duration={500} style={{ color: "#aaa" }} />
                                                                                    </button>
                                                                                )}
                                                                                {product?.combo_categories?.length ? null : (
                                                                                    <div className="btn-qnt py-2">
                                                                                        {cartProducts.find((cp) => cp.id === product?.id).quantity}
                                                                                    </div>
                                                                                )}

                                                                                {product?.combo_categories?.length ? (
                                                                                    <ComboCustomization
                                                                                        product={product}
                                                                                        addProduct={addProduct}
                                                                                        mealBtn={true}
                                                                                        forceUpdate={this.forceStateUpdate} />
                                                                                ) : (
                                                                                    <button type="button"
                                                                                        className="add-button-meals p-2 px-3 py-2 position-relative"
                                                                                        onClick={() => {
                                                                                            product.quantity = 1;
                                                                                            addProduct(product);
                                                                                            this.forceStateUpdate();
                                                                                        }} >
                                                                                        +
                                                                                        <Ink duration={500} style={{ color: "#aaa" }} />
                                                                                    </button>
                                                                                )}
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                {product?.addoncategories?.length ? null : (
                                                                                    <button type="button"
                                                                                        className="add-button-meals p-2 px-3 py-2 position-relative"
                                                                                        onClick={() => {
                                                                                            product.quantity = 1;
                                                                                            removeProduct(product);
                                                                                            this.forceStateUpdate();
                                                                                        }} >
                                                                                        -
                                                                                        <Ink duration={500} style={{ color: "#aaa" }} />
                                                                                    </button>
                                                                                )}
                                                                                {product?.addoncategories?.length ? null : (
                                                                                    <div className="btn-qnt py-2">
                                                                                        {cartProducts.find((cp) => cp.id === product?.id)?.quantity}
                                                                                    </div>
                                                                                )}

                                                                                {product?.addoncategories?.length ? (
                                                                                    <Customization
                                                                                        product={product}
                                                                                        addProduct={addProduct}
                                                                                        forceUpdate={this.forceStateUpdate} />
                                                                                ) : (
                                                                                    <button
                                                                                        type="button"
                                                                                        className="add-button-meals p-2 px-3 py-2 position-relative"
                                                                                        onClick={() => {
                                                                                            if (product.stock > product.quantity) {
                                                                                                product.quantity += 1;
                                                                                                addProduct(product);
                                                                                                this.forceStateUpdate();
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        +
                                                                                        <Ink duration={500} style={{ color: "#aaa" }} />
                                                                                    </button>
                                                                                )}

                                                                            </>
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <div className="robo text-danger text-item-not-available">
                                                                        Item Not Available
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                    {cartProducts.find((cp) => cp.id === product?.id) === undefined && (
                                                        <React.Fragment>
                                                            <div className="w-100 mt-1">
                                                                {product?.is_active ? (
                                                                    <React.Fragment>
                                                                        {product?.is_combo ? (
                                                                            <>
                                                                                <ComboCustomization
                                                                                    product={product}
                                                                                    addProduct={addProduct}
                                                                                    mealBtn={true}
                                                                                    forceUpdate={this.forceStateUpdate} />
                                                                            </>
                                                                        ) : product?.addoncategories?.length ? (
                                                                            <Customization
                                                                                product={product}
                                                                                addProduct={addProduct}
                                                                                forceUpdate={this.forceStateUpdate} />
                                                                        ) : (
                                                                            <React.Fragment>
                                                                                {/* {product.stock > 0 ? ( */}
                                                                                <button type="button"
                                                                                    className="py-2 text-center w-100 add-button-meals p-2 px-2 position-relative"
                                                                                    onClick={() => {
                                                                                        product.quantity = 1;
                                                                                        addProduct(product);
                                                                                        this.forceStateUpdate();
                                                                                    }} >
                                                                                    + ADD
                                                                                    <Ink duration={500} style={{ color: "#aaa" }} />
                                                                                </button>
                                                                                {/* ) : (
                                                                                <button className="py-2 text-center w-100 delete-button-meals p-2 px-2 position-relative"
                                                                                    type="button" >
                                                                                    NO STOCK
                                                                                    <Ink duration={500} style={{ color: "#aaa" }} />
                                                                                </button>
                                                                            )} */}
                                                                            </React.Fragment>
                                                                        )}
                                                                    </React.Fragment>
                                                                ) : (
                                                                    <div className="robo text-danger text-item-not-available">
                                                                        Item Not Available
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ))}
                        </div>
                    </div>
                }
                {!this.state.loading && (
                    <React.Fragment>
                        <FloatCart float={this.state.floatCart} />
                    </React.Fragment>
                )}
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    items: state.cart.items,
    cartProducts: state.cart.products
})


export default connect(mapStateToProps, {
    addProduct,
    removeProduct
})(Meals)
