import { Provider } from "react-redux";
import React from 'react';
import store from "./redux/store";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/custom.css';
import './assets/css/main.css';
import './assets/css/mobile.css'
import { Offline, Online } from "react-detect-offline";
// import './assets/css/register.css'
import 'react-spring-bottom-sheet/dist/style.css'
import 'antd/dist/antd.css';
// import 'react-phone-input-2/lib/style.css'
import OfflineComponent from './components/Elements/OfflineComponent';
// import * as Sentry from "@sentry/react";

const polling = {
    enabled: false,
};

// Sentry.init({
//     dsn: "https://71a8b3bc78a3b5c2f73d0c0d26e2826a@o1233765.ingest.us.sentry.io/4507960821153792",
//     integrations: [
//       Sentry.browserTracingIntegration(),
//       Sentry.browserProfilingIntegration(),
//       Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 1.0, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/grosav\.com\/public\/api/],
//     // Set profilesSampleRate to 1.0 to profile every transaction.
//     // Since profilesSampleRate is relative to tracesSampleRate,
//     // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
//     // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
//     // results in 25% of transactions being profiled (0.5*0.5=0.25)
//     profilesSampleRate: 1.0,
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
//   });


const Root = ({ children, initialState = {} }) => (
    <React.Fragment>
        <Provider store={store(initialState)}>
				{children}
        </Provider>
    </React.Fragment>
);
export default Root;
