import React, { Component } from 'react'
import { connect } from 'react-redux'
import noOrder from "../../../../assets/images/NoOrder.png";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE, WEBSITE_URL } from '../../../../api';
import ProgressiveImage from 'react-progressive-image'
import { getSingleOrder, addStoreReview } from '../../../../redux/mobile/mobileOrder/action';
import { paymentStatusCheck } from '../../../../redux/mobile/checkOut/action';
import ContentLoader from 'react-content-loader';
import MobileBackButtonWithCenterTitle from '../../../Elements/MobileBackButtonWithCenterTitle';
import Truncate from 'react-truncate';
import { Badge, Form } from 'react-bootstrap';
import { IoCheckmarkCircle } from 'react-icons/io5';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { BsExclamationCircleFill } from 'react-icons/bs';
import { formatPrice } from '../../../Helpers/formatPrice';
import { FiDownload } from 'react-icons/fi';
import Countdown from 'react-countdown';
import moment from 'moment';
import { Rating } from 'react-simple-star-rating';
import Modal from "react-modal";

export class SingleOrder extends Component {
    state = {
        order_id: this.props.match.params.id,
        order: [],
        loading: true,
        modal_open: false,
        noRating: false,
        rating: 0,
        review: '',
        storeRating: 0,
        storeReview: false
    }

    componentDidMount() {
        const { user } = this.props;
        this.props.getSingleOrder(user.data.auth_token, this.state.order_id).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ loading: false, order: response.payload.order });
                this.setState({ storeRating: response.payload?.order?.store_review?.rating })

            } else {
                ////// console.log("API error")
            }


        })
    }
    handleRating = (e) => {
        this.setState({ noRating: false })
        this.setState({ rating: e / 20 })
    }

    sumStoreCharge = (store, surge, commission, tax) => {
        let sur = parseInt(surge, 10) ? parseInt(surge, 10) : 0;
        let com = parseInt(commission, 10) ? parseInt(commission, 10) : 0;
        let store_charge = store ? store : 0;
        let tax_charge = tax ? tax : 0;
        let sum = parseFloat(store_charge) + parseFloat(sur) + parseFloat(tax_charge);
        return formatPrice(sum);
    }

    checkTimeOut = () => {
        if (this.state.order.cancel === "timeout") {
            return false;
        }
        return true;
    };

    recheckPayment = (id) => {
        this.props.paymentStatusCheck(id).then((response) => {

            if (response.data.success) {
                window.location.reload();
            } else {
                ////// console.log(response.data.message);
            }
        })
    }

    printOrderBill = (order_id) => {
        const { user } = this.props;
        ////// console.log(this.props);
        window.location = WEBSITE_URL + "/print-order/" + user.data.auth_token + "/" + order_id;
    }


    handlePopupOpen = () => {
        this.setState({ modal_open: true });
    };
    handlePopupClose = () => {
        this.setState({ modal_open: false });
    };

    handleInput = (e) => {
        this.setState({ review: e })
    }

    onSubmit = (e) => {
        e.preventDefault();
        const { user } = this.props;
        const { order } = this.state;

        let rating = this.state.rating * 20;
        let review = this.state.review;

        // //// console.log('token', user.data.auth_token);
        // //// console.log('order_id',  this.state.order_id);
        // //// console.log('store_id',  order?.store_id);
        // //// console.log('rating', rating);
        // //// console.log('review', review);

        this.props.addStoreReview(user.data.auth_token, this.state.order_id, order?.store_id, rating, review).then((response) => {
            if (response && response.payload && response.payload.success) {
                this.setState({ modal_open: false })
                this.setState({ storeReview: true })
                this.setState({ storeRating: rating })
            }
        })

    }

    render() {
        const { order } = this.state;
        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return null;
            } else {
                return (
                    <span>
                        {seconds}s
                    </span>
                );
            }
        };
        return (
            <React.Fragment>

                {this.state.loading ?
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={'#F3F3F3'}
                            foregroundColor={'#FFFFFF'}>
                            <rect x="12" y="26" rx="8" ry="8" width="22" height="22" />
                            <rect x="12" y="85" rx="10" ry="10" width="200" height="30" />
                            <rect x="12" y="127" rx="10" ry="10" width="200" height="10" />
                            <rect x="18" y="178" rx="14" ry="10" width="249" height="159" />
                            <rect x="12" y="500" rx="10" ry="18" width="250" height="30" />
                        </ContentLoader>
                    </React.Fragment>
                    : (
                        <React.Fragment>
                            <MobileBackButtonWithCenterTitle title="Single Order" history={this.props.history} />
                            <div className='px-3'>

                                <div className='mobile-runningorders-card p-3 mt-2' style={{ position: "relative" }}>
                                    <div>
                                        <div className="d-flex flex-row mt-2 mb-3" style={{ "background": "#ffa50029", "padding": "10px", "borderRadius": "10px" }}>
                                            <ProgressiveImage
                                                delay={20}
                                                src={IMAGE_BASE_URL + order?.store?.image}
                                                placeholder={PLACE_HOLDER_IMAGE}
                                            >
                                                {(src, loading) => (
                                                    <img
                                                        src={src}
                                                        className="" style={{ width: '22vw', borderRadius: '12px', height: '22vw', objectFit: 'cover' }}
                                                        alt='main-item'
                                                    />
                                                )}
                                            </ProgressiveImage>
                                            <div className="flex-grow-1 d-flex flex-column mt-2 ms-3">
                                                <span className='fw-700 fs-6'>{order?.store?.name}</span>

                                                {(this.state?.storeReview || order?.store_review) ?
                                                    (
                                                        <Rating
                                                            size={"1em"}
                                                            fillColor="#ff9012"
                                                            initialValue={parseInt(this.state.storeRating)}
                                                            readonly />

                                                    ) : (
                                                        <>
                                                            {order?.order_status_id == 5 &&

                                                                <span className='fw-bolder py-2' style={{ color: '#007eff' }} onClick={() => this.handlePopupOpen()}>
                                                                    Rate this store
                                                                </span>
                                                            }
                                                        </>
                                                    )
                                                }

                                            </div>
                                        </div>


                                        <Modal
                                            isOpen={this.state.modal_open}
                                            ariaHideApp={false}
                                            onRequestClose={this.handlePopupClose}
                                            contentLabel="Example Modal"
                                            style={customStyles}
                                        >

                                            <div style={{ width: '100%', textAlign: 'center' }}>
                                                <Form onSubmit={(e) => this.onSubmit(e)}>
                                                    <div class="mt-3 mb-2 text-center fs-5 fw-bolder">{order?.store?.name}</div>
                                                    <Rating
                                                        size={"3em"}
                                                        fillColor="#ff9012"
                                                        ratingValue={this.state.rating && this.state.rating * 20}
                                                        onClick={(e) => this.handleRating(e)}
                                                    />

                                                    <Form.Control
                                                        className='px-3'
                                                        onChange={(e) => {
                                                            let value = e.target.value;
                                                            this.handleInput(value)
                                                        }}
                                                        placeholder="Leave Feedback"
                                                        style={{ "backgroundColor": "rgb(255, 255, 255)", "borderRadius": "7px", "marginTop": "20px", "padding": "9px 23px", "border": "1px solid #d0d0d0", "width": "100%" }}
                                                        aria-describedby="basic-addon2"
                                                        required
                                                    />
                                                    <button type="submit" className="py-3 px-3 mobile-place-review-btn w-100 mt-3">
                                                        Submit
                                                    </button>
                                                </Form>
                                            </div>


                                        </Modal>
                                        <div className="d-flex justify-content-between mt-2">
                                            <div> Order ID </div>
                                            <div style={{ color: '#FD8814', fontWeight: '600' }}> #{order?.unique_order_id} </div>
                                        </div>

                                        <div className="d-flex justify-content-between mt-2">
                                            <div> Order Status </div>
                                            <div className="" style={order?.order_status_id == 6 ? { color: 'red', fontWeight: '700' } : { color: '#FD8814', fontWeight: '700' }} >{order?.order_status_id == 7 ? 'Order Preparing' : order?.orderstatus?.name}</div>
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Payment Details</span>
                                            <span>{order?.payment_mode === "COD" ? "Cash On Delivery" : order?.payment_mode === "ONLINE" ? "ONLINE" : order?.payment_mode === "WALLET" ? "WALLET" : '--'}</span>
                                        </div>
                                        {order?.payment_mode === "ONLINE" &&
                                            <div className="d-flex justify-content-between mt-2">
                                                <span>Payment Status</span>
                                                {order?.payment_status === "PENDING" ? (
                                                    <div>
                                                        <BsExclamationCircleFill size={17} color='#ff4c4c' />
                                                        <span className='ps-1 fw-650' style={{ color: '#ff4c4c' }}>Pending</span>
                                                    </div>
                                                ) : (order?.payment_status === "SUCCESS" &&
                                                    <div>
                                                        <IoCheckmarkCircle size={20} color='#18D26B' />
                                                        <span className='ps-1 fw-650' style={{ color: '#18D26B' }}>Success</span>
                                                    </div>
                                                )}
                                            </div>
                                        }


                                        {order?.orderitems?.map((item) => (
                                            <div className="d-flex flex-row mt-2">
                                                <ProgressiveImage
                                                    delay={20}
                                                    src={IMAGE_BASE_URL + item?.item?.image}
                                                    placeholder={PLACE_HOLDER_IMAGE}
                                                >
                                                    {(src, loading) => (
                                                        <img
                                                            src={src}
                                                            className="" style={{ width: '22vw', borderRadius: '12px', height: '22vw', objectFit: 'cover' }}
                                                            alt='main-item'
                                                        />
                                                    )}
                                                </ProgressiveImage>
                                                <div className="flex-grow-1 d-flex flex-column mt-2 ms-3">
                                                    {item?.orderitemaddons.length > 0 ?
                                                        item?.orderitemaddons[0]?.is_combo && item?.orderitemaddons[0]?.combo_type == "COMBO" ?
                                                            <>
                                                                <span className='d-flex justify-content-between fw-700'> <span>{item?.name} <span className='badge bg-info'>COMBO</span></span>
                                                                    {item?.deal_offer > 0 ?
                                                                        <span>₹{formatPrice(item?.item?.selling_price - (item?.item?.selling_price * (item?.deal_offer / 100)))}</span>
                                                                        : <span>₹{formatPrice(item?.item?.selling_price)}</span>
                                                                    }
                                                                </span>
                                                                {item?.orderitemaddons?.map((addn) => (
                                                                    <span className='d-flex justify-content-between fw-600' style={{ fontSize: '12px' }}> {addn?.name} x {item?.quantity} <span>₹{formatPrice(addn?.price)}</span></span>
                                                                ))}
                                                            </>
                                                            : item?.orderitemaddons[0]?.is_combo == 0 && item?.orderitemaddons[0]?.combo_type == null &&
                                                            <>
                                                                <span className='d-flex justify-content-between fw-700'> <span>{item?.name} <span className='badge bg-info'>ADDON</span></span> <span>₹{formatPrice(item?.item?.selling_price)}</span></span>
                                                                {item?.orderitemaddons?.map((addn) => (
                                                                    <span className='d-flex justify-content-between fw-600' style={{ fontSize: '12px' }}> {addn?.name} x {addn?.addon_quantity} <span>₹{formatPrice(addn?.price / addn?.addon_quantity)}</span></span>
                                                                ))}
                                                            </>
                                                        :
                                                        <span className='d-flex justify-content-between fw-700'> {item?.name} x {item?.quantity} <span>₹{formatPrice(item?.price)}</span></span>
                                                    }
                                                    <span className='text-end'><span style={{ borderTop: '1px dashed #000' }}>₹{formatPrice(item?.price)}</span></span>
                                                    {item?.is_free_item == 1 &&
                                                        <span className='d-flex justify-content-between fw-700 text-success'>Free Item</span>
                                                    }
                                                    {order?.order_status_id == 5 && !item?.rating ? (
                                                        <Link to={"/product-review/" + item.id}>
                                                            <span className='fw-bolder py-2' style={{ color: '#007eff' }}>
                                                                Rate this item
                                                            </span>
                                                        </Link>
                                                    ) : item?.rating ?
                                                        <>

                                                            <Rating
                                                                size={"1em"}
                                                                fillColor="#ff9012"
                                                                initialValue={parseInt(item?.rating?.rating)}
                                                                readonly
                                                            />

                                                        </>
                                                        : null
                                                    }
                                                </div>

                                            </div>
                                        ))}
                                        <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>
                                        {order?.sub_total ?
                                            <div className="d-flex justify-content-between mt-2">
                                                <span>Item Total</span>
                                                <span>₹ {formatPrice(order?.sub_total)}</span>
                                            </div>
                                            : null}
                                        {formatPrice(order?.delivery_charge) > 0 &&

                                            <div className="d-flex justify-content-between mt-2">
                                                <span>Delivery Charge</span>
                                                <span>₹ {order?.delivery_charge ? formatPrice(order?.delivery_charge) : 0.00}</span>
                                            </div>
                                        }
                                        {order?.tip_amount ?
                                            <div className="d-flex justify-content-between mt-2">
                                                <span>Tip Amount</span>
                                                <span>₹ {order?.tip_amount ? formatPrice(order?.tip_amount) : 0.00}</span>
                                            </div>
                                            : null}
                                        {this.sumStoreCharge(order?.store_charges, order?.surge_fee, order?.total_commission, order?.tax) > 0 ?
                                            <div className="d-flex justify-content-between mt-2">
                                                <span>Tax & Charges</span>
                                                <span>₹ {
                                                    this.sumStoreCharge(order?.store_charges, order?.surge_fee, order?.total_commission, order?.tax)}</span>
                                            </div>
                                            : null}

                                        {order?.handling_charges > 0 &&
                                            <div className="d-flex justify-content-between mt-2">
                                                <span>Handling Charge</span>
                                                <span>₹ {formatPrice(order?.handling_charges)}</span>
                                            </div>
                                        }
                                        {order?.coupon_discount ?
                                            <div className="d-flex justify-content-between mt-2">
                                                <span>Coupon Discount</span>
                                                <span>- ₹ {formatPrice(order?.coupon_discount)}</span>
                                            </div>
                                            : null}
                                        <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>

                                        {order?.order_status_id == 5 &&

                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Download</span>
                                            <button className='border px-2 py-1 rounded-5 bg-white' onClick={() => this.printOrderBill(order.id)} >
                                                <span className='me-2' style={{ color: "#FD8814" }}>Download Invoice</span>
                                                <FiDownload color='#FD8814' />
                                            </button>
                                        </div>
                                        }
                                        <div className="d-flex justify-content-between mt-2 pb-2">
                                            <span className='fw-bolder fs-6'>Total Amount</span>
                                            <span className='fw-bolder fs-6'>₹ {formatPrice(order?.total ? order?.total : 0)}</span>
                                        </div>
                                        <div className=' d-flex flex-column'>
                                            {order?.order_status_id == 8 && moment(order?.created_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? (
                                                <React.Fragment>

                                                    <div className="d-grid">
                                                        <button
                                                            className="btn border-radius-10 user-select-none p-2 text-white" style={{
                                                                background: '#FD8814',
                                                            }}
                                                            onClick={() => {
                                                                this.recheckPayment(order.id)
                                                            }} >
                                                            Re-check Payment
                                                        </button>

                                                    </div>
                                                </React.Fragment>
                                            ) : ("")
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            </React.Fragment>
        )
    }
}


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-38%",
        border: "1px solid #fead1d",
        transform: "translate(-50%, -50%)",
        borderRadius: "15px",
        width: '90%'
    },
};
const mapStateToProps = (state) => ({
    user: state.user.user,
})
export default connect(mapStateToProps, {
    getSingleOrder, paymentStatusCheck, addStoreReview
})(SingleOrder)
