import React, { Component } from "react";
import { connect } from "react-redux";
import VendorBackButton from "../../../Elements/VendorBackButton";
import Loading from '../../../Elements/loading'
import { getVendorAccountDetails, getStoreUsers } from "../../../../redux/vendor/user/action";
import moment from 'moment';
import { toUpper } from "lodash";
export class VendorStoreUsers extends Component {
    state = {
        account: false,
        finance: false,
        loading: false,
        store_id: '',
        store_users: ''
    };
    componentDidMount() {
        const { user } = this.props;
        this.props.getStoreUsers(user.data.auth_token, user && user.user && user.user.stores && user.user.stores[0].id)
            .then((response) => {
                if (response && response.payload && response.payload.success) {
                    this.setState({ loading: false, store_users: response.payload.store })
                    //// console.log(response.payload.orders)
                } else {
                    //// console.log(response, 'API error')
                }
            })
    }

    render() {
        const { user } = this.props;
        const { store_users } = this.state;
        return (
            <React.Fragment >
                {
                    this.state.loading ?
                        <Loading />
                        :
                        <React.Fragment>
                                <VendorBackButton title="Users" />
                            <div className="d-flex justify-content-between vendor-account-head px-3 pb-3" style={{paddingTop:'25vw'}}>
                                <div className="">
                                    <h6 className="mt-2 mb-0" style={{ fontWeight: 700, fontSize: "18px", color: "#38393D", lineHeight: "30px", }} >
                                        {user && user.user && user.user.stores && user.user.stores[0].name}

                                    </h6>
                                    <span className="mt-1">
                                        {user && user.user && user.user.stores && user.user.stores[0].phone}
                                    </span>
                                    <div className="mt-3">
                                        <h6 className=" mb-0" style={{ fontWeight: 600, fontSize: "14px", color: "#09051C", }} >
                                            Address
                                        </h6>
                                        <div className="mt-1">
                                            <span>
                                                {user && user.user && user.user.stores && user.user.stores[0].address}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {user && user.user && user.user.stores && user.user.stores[0].is_active == 1 ?
                                    <span className="mt-4 me-2" style={{ color: "#32BA7C", fontSize: "12px", fontWeight: 700, }} >
                                        Active
                                    </span>
                                    :
                                    <span className="mt-4 me-2" style={{ color: "#FF0000", fontSize: "12px", fontWeight: 700, }} >
                                        In Active
                                    </span>
                                }
                            </div>
                            <div className="px-3 py-3 vendor-account-container">
                                {store_users && store_users.users && store_users.users.map((item, index) => (
                                    <div className="past-order-container px-2 py-3 mt-3">
                                        {/* <p className="vendor-account-title">Name</p> */}
                                        <span className="vendor-account-details1">{toUpper(item.name)}</span>
                                        <div className="mt-3">
                                            <p className="vendor-account-title">Mobile No</p>
                                            <span className="vendor-account-details">{item.phone}</span>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </React.Fragment>
                }
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    vendor: state.vendor.store_user,
    user: state.vendor.store_user,

});

export default connect(mapStateToProps, {
    getVendorAccountDetails,
    getStoreUsers
})(VendorStoreUsers);
