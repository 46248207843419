import { GET_ALL_SCRATCH_CARD_URL,COMPLETED_SCRATCH_CARD_URL,GET_MY_REFFERALS_URL } from "../../../api";
import { GET_SCRATCH_CARD,COMPLETED_SCRATCH_CARD,GET_REFFERALS} from "../scratchCard/actionType";
import Axios from "axios";


export const getAllScratchCard = (token,id) => (dispatch) => {
    return Axios.post(GET_ALL_SCRATCH_CARD_URL, {
        token, id:id
    })
        .then((response) => {
            let cards = response.data;
            return dispatch({
                type: GET_SCRATCH_CARD,
                payload: cards
            })

        })
}

export const scratchComplete = (token,id) => (dispatch) => {
    return Axios.post(COMPLETED_SCRATCH_CARD_URL, {
        token, id:id
    })
}


// refer and earn
export const getMyRefferals = (token,id) => (dispatch) => {
    return Axios.post(GET_MY_REFFERALS_URL, {
        token, id:id
    })
        .then((response) => {
            let refferals = response.data;
            return dispatch({
                type: GET_REFFERALS,
                payload: refferals
            })

        })
}
