import {
    SEND_OTP,
    LOGIN_OTP_USER,
    REGISTER_USER,
    CHECK_REFERRAL,
    GET_USER_DETAILS,
    GET_DEFAULT_ADDRESS,
    SAVE_ADDRESS,
    DELETE_ADDRESS,
    GET_USER_ADDRESS,
    GET_USER_WALLET,
    UPDATE_USER_REDUX_INFO,
    DELETE_USER,
    LOGOUT_USER,
    LOGIN_OTP_USER_UPDATE
} from "./actionType";
import {
    SEND_OTP_URL,
    LOGIN_OTP_USER_URL,
    REGISTER_USER_URL,
    CHECK_REFERRAL_URL,
    GET_USER_DETAILS_URL,
    DELETE_ADDRESS_URL,
    SAVE_ADDRESS_URL,
    EDIT_USER_DETAILS_URL,
    GET_ALL_USER_ADDRESS_URL,
    UPDATE_DEFAULT_ADDRESS_URL,
    GET_USER_WALLET_URL,
    GET_DEFAULT_ADDRESS_URL,
    UPDATE_USER_REDUX_INFO_URL,
    DELETE_USER_URL,
    LOGIN_OTP_USER_UPDATE_URL
} from "../../../api/index";
import Axios from "axios";

export const sendOtp = (phone) => (dispatch) => {
    return Axios.post(SEND_OTP_URL, {
        phone
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: SEND_OTP, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const verifyOtp = (phone, otp) => (dispatch) => {
    return Axios.post(LOGIN_OTP_USER_URL, {
        phone, otp
    })
        .then((response) => {
            const user = response.data;
            // // console.log(response)
            return dispatch({ type: LOGIN_OTP_USER, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};
export const updateverifyOtp = (phone,name,email, otp,auth_token) => (dispatch) => {
    return Axios.post(LOGIN_OTP_USER_UPDATE_URL, {
        phone, name,email, otp,auth_token
    })
        .then((response) => {
            const user = response.data;
            // // console.log(response)
            return dispatch({ type: LOGIN_OTP_USER_UPDATE, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};

export const registerUser = (name, email, referral_code, phone) => (dispatch) => {
    return Axios.post(REGISTER_USER_URL, {
        name, email, referral_code, phone
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: REGISTER_USER, payload: user });
        })
        .catch(function (error) {
            // console.log(error);
        });
};


// user profile
export const getUserDetails = (token) => (dispatch) => {
    return Axios.post(GET_USER_DETAILS_URL, {
        token
    })
        .then((response) => {
            let userDetails = response.data;
            return dispatch({
                type: GET_USER_DETAILS,
                payload: userDetails
            })

        }) .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Dispatch the logout action
                dispatch(logoutUser());

            }

        });
}

export const deleteUser = (token) => (dispatch) => {
    return Axios.post(DELETE_USER_URL, {
        token,
    })
        .then((response) => {
            localStorage.clear();
            localStorage.setItem("SPLASH", true);

            const user = response.data;
            return dispatch({ type: DELETE_USER, payload: user });
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Dispatch the logout action
                dispatch(logoutUser());

            }

        });


};

export const saveAddress = (token, latitude, longitude, address, house, tag) => dispatch => {
    return Axios.post(SAVE_ADDRESS_URL, {
        token, latitude, longitude, address, house, tag
    }).then(response => {
        const addresses = response.data;
        return dispatch({
            type: SAVE_ADDRESS, payload: addresses
        });
    })
    .catch(function (error) {
        if (error.response && error.response.status === 401) {
            // Dispatch the logout action
            dispatch(logoutUser());

        }

    });
};
export const deleteAddress = (token, address_id) => dispatch => {
    return Axios.post(DELETE_ADDRESS_URL, {
        token, address_id
    })
        .then(response => {
            const addresses = response.data;
            return dispatch({
                type: DELETE_ADDRESS, payload: addresses
            });
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Dispatch the logout action
                dispatch(logoutUser());

            }

        });
};

export const setDefaultAddress = (token) => dispatch => {
    return Axios.post(GET_DEFAULT_ADDRESS_URL, {
        token
    })
        .then(response => {
            const addresses = response.data;
            return dispatch({
                type: GET_DEFAULT_ADDRESS, payload: addresses
            });
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Dispatch the logout action
                dispatch(logoutUser());

            }

        });
};
export const editUserDetails = (formdata) => (dispatch) => {
    // console.log(formdata, 7899);
    return Axios.post(EDIT_USER_DETAILS_URL, formdata, {

    }) .catch(function (error) {
        if (error.response && error.response.status === 401) {
            // Dispatch the logout action
            dispatch(logoutUser());

        }

    });
}

export const getAllUserAddress = (token, id) => (dispatch) => {
    return Axios.post(GET_ALL_USER_ADDRESS_URL, {
        token, id
    })
        .then((response) => {
            let userDetails = response.data;
            return dispatch({
                type: GET_USER_ADDRESS,
                payload: userDetails
            })

        }) .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Dispatch the logout action
                dispatch(logoutUser());

            }

        });
}

export const getUserWalletAmount = (token) => (dispatch) => {
    return Axios.post(GET_USER_WALLET_URL, {
        token
    })
        .then((response) => {
            let user = response.data;
            return dispatch({
                type: GET_USER_WALLET,
                payload: user
            })

        }) .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Dispatch the logout action
                dispatch(logoutUser());

            }

        });
}

export const updateDefaultAddress = (id, token) => (dispatch) => {
    return Axios.post(UPDATE_DEFAULT_ADDRESS_URL, { id, token })
}

export const updateUserReduxInfo = (token) => (dispatch) => {
    return Axios.post(UPDATE_USER_REDUX_INFO_URL, {
        token,
    })
        .then((response) => {
            const user = response.data;
            return dispatch({ type: UPDATE_USER_REDUX_INFO, payload: user });
        })
        .catch(function (error) {
            if (error.response && error.response.status === 401) {
                // Dispatch the logout action
                dispatch(logoutUser());

            }

        });
};


// user profile
export const logoutUser = () => (dispatch) => {
    const user = [];
    const walletEmpty = [];
    const emptyAddress = [];

    //remove geoLocation and userSetAddress
    localStorage.removeItem("userSetAddress");
    localStorage.removeItem("userSetLat");
    localStorage.removeItem("userSetLng");
    localStorage.removeItem("activeRestaurant");
    localStorage.removeItem("geoLocation");
    localStorage.removeItem("lastSavedNotificationToken");

    return [
        dispatch({
            type: LOGOUT_USER,
            payload: user,
        }),
    ];
};
