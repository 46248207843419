import React, { Component } from 'react'
import { connect } from 'react-redux'
import { getUserStore } from '../../../redux/vendor/user/action'

export class VendorHome extends Component {

    componentDidMount() {
        const { user } = this.props;
        this.props.getUserStore(user && user.data && user.data.auth_token)
    }
    render() {
        return (
            <div>kjndisiuhidbciuxbiuiiuiuiu</div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user
})
export default connect(mapStateToProps, {
    getUserStore
})(VendorHome)

