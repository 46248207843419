import React, { Component } from "react";
import { connect } from "react-redux";
import { FaAppleAlt } from "react-icons/fa";
import { IMAGE_BASE_URL, PLACE_HOLDER_IMAGE } from '../../../../api/index';
import Ink from "react-ink";
import ProgressiveImage from "react-progressive-image";
import { Link } from "react-router-dom";
// import { BiCategory } from "react-icons/bi";
import { getStoreCategoryItemCategory } from './../../../../redux/mobile/storeCategory/action';
import { GiHamburger } from "react-icons/gi";

export class GroceryCategory extends Component {
    state = {
        loading: true
    }
    componentDidMount() {
        // ////// console.log(this.props,'iddd')
        this.props.getStoreCategoryItemCategory(this.props.id,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.latitude,
            JSON.parse(localStorage?.getItem("userSetAddress"))?.longitude)
            .then((res) => {
                this.setState({
                    loading: false
                })
            })
    }
    render() {
        const { itemcategory } = this.props;
        return (
            <React.Fragment>
                {itemcategory && itemcategory?.data?.length > 0 && this.state.loading == false ? (
                    <>
                        <div className="px-3 mt-3 d-flex align-items-center">
                            <GiHamburger size={20} style={{ color: "FF9A62" }} />
                            <span className="home-heading ms-2">Category</span>
                        </div>

                        <div className="d-grid gap-2 pt-2 hidden-scroll-bar pt-3 px-3"
                            style={{ gridTemplateColumns: `repeat(${itemcategory?.data.length > 4 ? itemcategory?.data?.length : '5'},1fr)`, overflowX: "scroll" }}    >
                            {itemcategory?.data.map((item, index) => (
                                <Link to={'/item-group-stores/' + item.id} key={item.id}>
                                    <div className="d-flex flex-column  text-center position-relative">
                                        <Ink className="touch-ink" />
                                        <ProgressiveImage
                                            delay={20}
                                            src={IMAGE_BASE_URL + item.image}
                                            placeholder={PLACE_HOLDER_IMAGE}
                                        >
                                            {(src, loading) => (
                                                <img
                                                    src={src}
                                                    style={{
                                                        height: "20vw",
                                                        width: "20vw", borderRadius: "50%", objectFit: "cover", backgroundRepeat: "round",
                                                    }}
                                                    className="" alt="grosav"
                                                />
                                            )}
                                        </ProgressiveImage>
                                        <span className="mt-1 text-black" style={{ fontWeight: "700", fontSize: "11px", }}>
                                            {item.name}
                                        </span>
                                    </div>
                                </Link>

                            ))}
                        </div>
                    </>
                ) : ("")}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    itemcategory: state.mobilestorecategory.itemcategory,
});

export default connect(mapStateToProps, { getStoreCategoryItemCategory })(GroceryCategory);
