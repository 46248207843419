import React, { Component } from "react";
import { connect } from "react-redux";
import tick from "../../../assets/images/tick.png";
import dollar from "../../../assets/images/dollar.png";
import cross from "../../../assets/images/cross.png";
import bell from "../../../assets/images/bell.png";
import offer from "../../../assets/images/offer.png";
import MobileBackButton from "../../Elements/MobileBackButton";
import ContentLoader from "react-content-loader";
import image from '../../../assets/images/noNotification.png'
import { getAllNotifictions } from "../../../redux/mobile/notifications/action";

class Notifications extends Component {
    state = {
        loading: false,
        id: this.props.match.params.id
    };

    componentDidMount() {
        this.setState({ loading: true });
        this.props.getAllNotifictions(this.props.user.data.auth_token, this.props.user.data.id)
            .then(() => {
                setTimeout(() => {
                    this.setState({ loading: false });
                }, 2500);
            })
    }

    render() {
        const { notifications } = this.props;
        return (
            <React.Fragment>
                {this.state.loading ? (
                    <React.Fragment>
                        <ContentLoader
                            speed={1}
                            viewBox="0 0 280 576"
                            backgroundColor={"#F3F3F3"}
                            foregroundColor={"#FFFFFF"}
                        >
                            <rect x="15" y="40" rx="8" ry="8" width="30" height="30" />
                            <rect x="15" y="90" rx="5" ry="5" width="150" height="25" />
                            <rect x="15" y="140" rx="5" ry="5" width="250" height="65" />
                            <rect x="15" y="220" rx="5" ry="5" width="250" height="65" />
                            <rect x="15" y="300" rx="5" ry="5" width="250" height="65" />
                        </ContentLoader>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        {notifications?.success ? (
                            <div>
                                {/* {Notifications.is_read} */}
                                <div className="mobile-ntfcn-head py-4">
                                    <div className="pt-3 px-1">
                                        <MobileBackButton history={this.props.history} />
                                    </div>
                                    <div className="mt-5 ms-4">
                                        <span className="">Notification</span>
                                    </div>
                                </div>
                                <div
                                    className="px-3"
                                    style={{ background: "#FEFEFF", paddingBottom: '10vw' }}
                                >
                                    {notifications?.data?.length > 0 ? (
                                        <>
                                            {notifications?.data?.map((data) =>
                                                <div className=" d-flex mobile-ntfcn-box p-4 mt-2">
                                                    {data.type === 'SUCCESS' ? (
                                                        <div className="me-3">
                                                            <img src={tick} alt="" />
                                                        </div>
                                                    ) : ("")}
                                                    {data.type === 'WALLET' || data.type === 'REFERRAL' ? (
                                                        <div className="me-3">
                                                            <img src={dollar} alt="" />
                                                        </div>
                                                    ) : ("")}
                                                    {data.type === 'CANCEL' ? (
                                                        <div className="me-3">
                                                            <img src={cross} alt="" />
                                                        </div>
                                                    ) : ("")}
                                                    {data.type === 'OFFER' ? (
                                                        <div className="me-3">
                                                            <img src={offer} alt="" />
                                                        </div>
                                                    ) : ("")}
                                                    {data.type === 'OTHER' ? (
                                                        <div className="me-3">
                                                            <img src={bell} alt="" />
                                                        </div>
                                                    ) : ("")}
                                                    <div>
                                                        <div className="mobile-ntfcn-box-head">
                                                            <span>
                                                                {data.name}
                                                            </span>
                                                        </div>
                                                        <div className="mobile-ntfcn-box-text">
                                                            <span>
                                                                {data.data}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <div className='pt-3 d-flex flex-column align-items-center'>
                                            <div className='mt-5 d-flex flex-column align-items-center'>
                                                <img src={image} alt="no items"
                                                    style={{
                                                        objectFit: 'cover',
                                                        width: '70%'
                                                    }} />
                                            </div>
                                            <span className='mt-4 fs-7'>Sorry...No Notifications !!!</span>
                                        </div>
                                    )}


                                    {/* <div className="mt-3 d-flex mobile-ntfcn-box p-4">
                                        <div className="me-3">
                                            <img src={dollar} alt="" />
                                        </div>
                                        <div>
                                            <div className="mobile-ntfcn-box-head">
                                                <span>
                                                    {
                                                        notifications?.notification
                                                            ?.type
                                                    }
                                                </span>
                                            </div>
                                            <div className="mobile-ntfcn-box-text text-muted">
                                                <span>
                                                    {
                                                        notifications?.notification
                                                            ?.created_at
                                                    }
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-3 d-flex mobile-ntfcn-box p-4">
                                        <div className="me-3">
                                            <img src={cross} alt="" />
                                        </div>
                                        <div>
                                            <div className="mobile-ntfcn-box-head">
                                                <span></span>
                                            </div>
                                            <div className="mobile-ntfcn-box-text text-muted">
                                                <span></span>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        ) : ("")}
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    notifications: state.notification.notification,
    user: state.user.user,
});

export default connect(mapStateToProps, { getAllNotifictions })(Notifications);
