import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BiSearch } from "react-icons/bi";
import { BsToggleOff, BsToggleOn, BsPencilSquare } from "react-icons/bs";
import { SiSquare } from "react-icons/si";
import VendorFooter from '../../VendorFooter';
import { BottomSheet } from 'react-spring-bottom-sheet';
import {
    getAllAddons,
    getAllAddonCategories,
    vendorToggleAddon,
    getVendorSearchAddons,
} from "../../../../redux/vendor/items/action";
import image from '../../../../assets/images/nodata.png'
import { Link } from "react-router-dom";
import { AiOutlinePlus } from 'react-icons/ai';

export class Addons extends Component {
    state = {
        addoncategory: false,
        addons: [],
        category_id: 'ALL',
        category_name: 'ALL',
        showItem: [],
        searchinput: '',
    };
    componentDidMount() {
        const { user } = this.props;
        this.props.getAllAddons(user.data.auth_token, user.data.store[0].id)
            .then((response) => {
                this.setState({ addons: response.payload.data });
            })
        this.props.getAllAddonCategories(user.data.auth_token, user.data.store[0].id);
    }
    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }
    toggleAddon = (addon) => {
        const { addons } = this.state
        const { setUpdatedAddOnCategory } = this.props;
        const selected = addons.find((data, index) => data.id === addon.id)
        selected.is_active = !selected.is_active;
        this.setState({ addons: addons });
        this.props.vendorToggleAddon(addon.id)
            .then((response) => {
                this.setState({ addons: [...response.data.data] });
            });
        setUpdatedAddOnCategory(addon);
    };
    filterAddonCategory = (id, name) => {
        this.setState({ category_id: id, category_name: name }, () => {
            this.props.getVendorSearchAddons(this.state.searchinput, this.state.category_id)
                .then((response) => {
                    this.setState({ addons: response.data.data });
                });
        })
        const { addons } = this.state;
        if (id === 'ALL') {
            const newaddons = addons;
            this.setState({ addons: newaddons });
        } else {
            const newaddons = addons?.filter((addon) => addon.addon_category_id === id);
            this.setState({ addons: newaddons });
        }
        this.closeAddOnMenu();
    };
    search = (input) => {
        this.setState({ searchinput: input })
        this.props.getVendorSearchAddons(input, this.state.category_id)
            .then((response) => {
                this.setState({ addons: response.data.data });
            });
    };
    closeAddOnMenu = () => {
        this.setState({ addoncategory: false })
    };
    openAddOnMenu = () => {
        this.setState({ addoncategory: true })
    }
    render() {
        const { addoncategories } = this.props;
        const { addons } = this.state;
        return (
            <React.Fragment>
                {/* <div className="d-flex flex-row align-items-center mt-3 searchBar py-2 px-2">
                    <input
                        type="text"
                        className="inventory-search w-100"
                        placeholder="Search for Add-Ons"
                        onChange={(event) => { this.search(event.target.value) }}
                    />
                    <span className="">
                        <BiSearch
                            set="curved"
                            style={{
                                fontSize: "2em",
                                color: "#FEA24B",
                            }}
                        />
                    </span>
                </div> */}
                <div className="inventory-add-on-count-head w-100 mt-3 px-2 py-3 d-flex justify-content-between align-items-center">
                    <div>
                        <div className="ms-3">
                            <span style={{ fontSize: '17px', fontWeight: '600' }}>{this.state.category_name}</span>
                        </div>
                        <div className="mt-1 ms-3">
                            <span>{addons?.length} Add-Ons</span>
                        </div>
                    </div>
                    <Link to="/vendor-add-addon">
                        <div className="py-2 px-2">
                            <button className="btn inventory-add-btn px-2">
                                <AiOutlinePlus /> ADD
                            </button>
                        </div>
                    </Link>
                </div>
                <div style={{ paddingBottom: '44vw' }}>
                    {addons?.length > 0 ? (
                        <>
                            {addons?.map(addon =>
                                <div className="inventory-product-list mt-4">
                                    <div className="inventory-product-list-items mt-4">
                                        <div className="inventory-item-icon d-flex align-items-start justify-content-between">
                                            <div className=" d-flex">
                                                <div>
                                                    <SiSquare
                                                        set="curved"
                                                        style={{
                                                            fontSize: "9px",
                                                            color: "#32BA7C",
                                                        }}
                                                    />
                                                </div>
                                                <div className="ms-2 pt-1">
                                                    <div className="inventory-item-name">
                                                        <span>
                                                            {addon.name}
                                                        </span>
                                                    </div>
                                                    <div className="inventory-item-price mt-1">
                                                        <span>
                                                            RS.{addon.price}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='d-flex justify-content-around'>
                                                <div onClick={() => this.toggleAddon(addon)}>
                                                    {addon.is_active ? (
                                                        <BsToggleOn set="curved" style={{ fontSize: "2em", color: "#59D69D", }} />
                                                    ) : (
                                                        <BsToggleOff set="curved" style={{ fontSize: "2em", }} />
                                                    )}
                                                </div>
                                                <div>
                                                    <Link to={"/vendor-edit-addon/" + addon.id}>
                                                        <BsPencilSquare className='pt-1' style={{ fontSize: "1.6em", color: "#fea24b", }} />
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className='pt-3 d-flex flex-column align-items-center'>
                                <div className='mt-3 d-flex flex-column align-items-center'>
                                    <img src={image} alt="no items"
                                        style={{
                                            objectFit: 'cover',
                                            width: '70%'
                                        }} />
                                </div>
                                <span className='mt-4 fs-6' style={{ fontWeight: '700' }}>Sorry...No Addons Found !!!</span>
                            </div>
                        </>
                    )
                    }
                </div>
                <div className="d-flex justify-content-end inventory-btn pb-4 py-2 px-2" onClick={() => this.openAddOnMenu()}>
                    <button className=" btn text-white inventory-browse-btn">
                        Browse Menu
                    </button>
                </div>
                <VendorFooter active_inventory={true} />

                <BottomSheet
                    open={this.state.addoncategory}
                    onDismiss={() => this.closeAddOnMenu()}
                    snapPoints={({ maxHeight }) => 0.5 * maxHeight}
                >
                    <div className='px-3 pb-5' >
                        <div className="d-flex flex-column justify-content-center mt-4 h-100 align-content-around">
                            <div className="d-grid gap-2">
                                <button className="offer-sheet-btn" onClick={() => this.filterAddonCategory('ALL', 'ALL')}>
                                    All
                                </button>
                                {addoncategories?.addoncategories?.map((category) =>
                                    <>
                                        <button className={this.state?.category_id === category.id
                                            ? "offer-sheet-btn text-black border-dark"
                                            : "offer-sheet-btn"}
                                            onClick={() => this.filterAddonCategory(category.id, category.name)} >
                                            {category.name}  {"( " + category.addons.length + " Items )"}
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </BottomSheet>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.vendor.store_user,
    addons: state.items.addons,
    addoncategories: state.items.addoncategories,
})

export default connect(mapStateToProps, {
    getAllAddons,
    getAllAddonCategories,
    vendorToggleAddon,
    getVendorSearchAddons
})(Addons)
