
import React, { Component } from 'react'
import { IoCheckmarkCircle } from 'react-icons/io5';
import { BsExclamationCircleFill, BsFillExclamationCircleFill } from 'react-icons/bs';
import { connect } from 'react-redux'
import noOrder from "../../../../assets/images/NoOrder.png";
import { IMAGE_BASE_URL, WEBSITE_URL } from '../../../../api';
import ProgressiveImage from 'react-progressive-image'
import Countdown from "react-countdown";
import { BottomSheet } from "react-spring-bottom-sheet";
import Ink from 'react-ink';
import Modal from "react-modal";
import { Link } from 'react-router-dom';
import moment from 'moment';
import { anyStorePaymentStatusCheck } from '../../../../redux/mobile/checkOut/action';
import { formatPrice } from '../../../Helpers/formatPrice';
import { FiDownload } from 'react-icons/fi';
import { MdDeliveryDining } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";

export class AnyStoreOrders extends Component {
    state = {
        loading: true,
        open: false,
        unique_order_id: "",
        cancel: true,
        orders: [],
        hasMore: false,
        cancel_reason: [],
        type: '',
        cancelCourier: false
        // cancelHide: false
    };
    componentDidMount() {
        const { user, anyStore } = this.props;
        this.setState({ anyStore: anyStore });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.anyStore !== this.props.anyStore) {
            // console.log('Orders updated:', this.props.orders);
            this.setState({ anyStore: this.props.anyStore });

            // Do something with the new orders, e.g., update the state or rerender
        }
    }
    openSheet = (unique_order_id) => {
        this.setState({ open: true, unique_order_id: unique_order_id, });
    };
    cancelCourier = (unique_order_id) => {
        this.setState({ cancelCourier: true, unique_order_id: unique_order_id, });
    };

    onSheetClose = () => {
        this.setState({ open: false, cancelCourier: false });
    };

    callItems = (page_count) => {
        // ////// console.log('call item')
        const { user } = this.props;
        this.props
            .getUserOrders(user.data.auth_token, page_count)
            .then((response) => {
                if (response.payload.success) {
                    let orders = this.state.orders;
                    orders += response.payload.orders.data;
                    // ////// console.log(orders, "obj");
                    this.setState({
                        loading: false,
                        orders: [...this.state.orders, ...response.payload.orders.data],
                    });
                    if (
                        response.payload.orders.current_page ==
                        response.payload.orders.last_page
                    ) {
                        this.setState({ hasMore: false });
                    }
                } else {
                    this.setState({ loading: true });
                }
            });
    };
    recheckPayment = (id) => {
        this.props.anyStorePaymentStatusCheck(id).then((response) => {
            if (response.data.success) {
                window.location.reload();
            } else {
                //////// console.log(response.data.message);
            }
        })
    }
    checkTimeOut = (id) => {
        // ////// console.log(this.props.anyStore,'sssssssssss')
        // ////// console.log(this.state.anyStore.find((o) => o.id === id).cancel === "timeout")
        if (this.props.anyStore.find((o) => o.id === id).cancel === "timeout") {
            return false;
        }
        return true;
    };

    printOrderBill = (order_id) => {
        const { user } = this.props;
        window.location = WEBSITE_URL + "/print-order/" + user.data.auth_token + "/" + order_id;
    }

    getOrderStatus = (created_at) => {
        const date = new Date(created_at);
        const today = new Date();
        var dif = Math.abs((date.getTime() - today.getTime()) / 1000);
        // window.location.reload()
        if (dif < 60) {
            return "Cancel";
        } else {
            return "placed";
        }
    };
    hideCancel = (id) => {
        this.props.anyStore.find((order) => order.id === id).cancel = "timeout";
        this.forceUpdate();
    };
    render() {
        const { cancelOrder, acceptOrder, anyStore } = this.props;
        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
                return null;
            } else {
                return (
                    <span>
                        {seconds}s
                    </span>
                );
            }
        };
        return (
            <React.Fragment>
                <div className="px-3 " style={{ paddingBottom: '30vw' }}>

                    {anyStore && anyStore.length > 0 ?
                        anyStore.map((order, index) => (
                            <div className='mobile-runningorders-card p-3 mt-2' style={{ position: "relative" }}>
                                <div className="d-flex flex-row">

                                    <div className=' d-flex flex-column'>
                                        {order?.order_status_id === 1 ? (
                                            <React.Fragment>

                                                <div className="" style={{ color: '#FD8814', fontWeight: '700' }} >{order?.orderstatus.name}</div>
                                            </React.Fragment>
                                        ) : order?.order_status_id === 5 ? (
                                            <div className="" style={{ color: '#1bae1b', fontWeight: '700' }} >{order?.orderstatus.name}</div>
                                        ) : order?.order_status_id === 6 ? (
                                            <div className="" style={{ color: '#ff4c4c', fontWeight: '700' }} >{order?.orderstatus.name}</div>
                                        ) : <div>
                                            {(order?.order_status_id === 2 ||
                                                order?.order_status_id === 3 ||
                                                order?.order_status_id === 4 ||
                                                order?.order_status_id === 7 ||
                                                order?.order_status_id === 10 ||
                                                order?.order_status_id === 11) && (
                                                    <div className="" style={{ color: '#FD8814', fontWeight: '700' }} >
                                                        {order?.orderstatus.name}
                                                    </div>
                                                )}
                                        </div>

                                        }
                                    </div>
                                </div>
                                <div>
                                    <div className='text-end'>
                                        {order?.any_store_status == 10 ? (
                                            <div className='fw-bolder'>
                                                <IoCheckmarkCircle size={20} color='#18D26B' />
                                                <span className='ps-1' style={{ color: '#18D26B' }}>Order Completed</span>
                                            </div>
                                        )
                                            : null
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between mt-2">
                                        <div> Order ID </div>
                                        <div style={{ color: '#FD8814', fontWeight: '600' }}> #{order?.unique_order_id}
                                        </div>
                                    </div>

                                    {order?.pickup_status &&
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Payment Status</span>
                                            <span>{order?.pickup_status?.name}</span>
                                        </div>
                                    }

                                    {order?.orderitems?.map((item, index) => (
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>{item?.name}</span>
                                            <span>x{item?.quantity}</span>
                                            <span>{item?.is_not_available ? <span className='text-danger'> Not Available</span> : item.price > 0 ? formatPrice(item.price) : "--"}</span>
                                        </div>
                                    ))}
                                    <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>
                                    <>
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Delivery Charge</span>
                                            <span>{formatPrice(order?.delivery_charge)}</span>
                                        </div>
                                        <div className="d-flex justify-content-between mt-2">
                                            <span>Convenience Fee</span>
                                            <span>{formatPrice(order?.city.any_store_fee)}</span>
                                        </div>

                                        <div className='text-center' style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}>------------------------------------------------------</div>
                                    </>
                                    {order?.sub_total > 0 && order?.total > 0 ?
                                        <>
                                            <div className="d-flex justify-content-between">
                                                <div className='fw-bolder fs-6'> Sub Total</div>
                                                <div className='fw-bolder fs-6'>  {formatPrice(order?.sub_total)} </div>
                                            </div>


                                            {(order?.order_status_id >= 3 && order?.order_status_id != 5 && order?.agent_phone) &&

                                                <div className="d-flex justify-content-between mb-1">
                                                    <span className='mt-1'><MdDeliveryDining style={{
                                                        color: '#FD8814',
                                                        fontSize: '19px',
                                                        marginRight: '2px'
                                                    }} /> Agent: {order?.agent_name}</span>

                                                    <a href={`tel:${order?.agent_phone}`} className='border px-2 py-1 rounded-5 bg-white' style={{ textDecoration: 'none' }}>
                                                        <FaPhoneAlt style={{
                                                            color: '#FD8814',
                                                            marginRight: '5px',
                                                            fontSize: '11px'
                                                        }} />
                                                        <span className='me-2' style={{ color: "#FD8814" }}>{order?.agent_phone}</span>
                                                    </a>
                                                </div>
                                            }
                                            {order?.order_status_id == 5 &&

                                                <div className="d-flex justify-content-between mt-2 mb-3">
                                                    <span>Download</span>
                                                    <button className='border px-2 py-1 rounded-5 bg-white' onClick={() => this.printOrderBill(order.id)} >
                                                        <span className='me-2' style={{ color: "#FD8814" }}>Download Invoice</span>
                                                        <FiDownload color='#FD8814' />
                                                    </button>
                                                </div>
                                            }

                                            <div className="d-flex justify-content-between">
                                                <div className='fw-bolder fs-6'> Total Amount </div>
                                                <div className='fw-bolder fs-6'>  {formatPrice(order?.total)} </div>
                                            </div>

                                        </>
                                        : ([2, 3, 13, 5].includes(order?.any_store_status)) && //Order Accepted , Delivery Guy Assigned, Delivery Guy Accepted
                                        (
                                            <div className='mt-3 text-center fw-bolder fs-12 text-danger'>
                                                You have only paid the partial amount for this order. The amount will be updated by the delivery partner soon after reaching the pickup point.
                                            </div>
                                        )

                                    }
                                    {[7, 8, 9, 13].includes(order?.any_store_status) &&
                                        <>
                                            <div className='mt-3 text-center fw-bolder fs-12 text-danger'>
                                                You have not paid the delivery charge yet. Your transaction may have failed. Please reorder.
                                            </div>
                                            <div className='d-flex flex-row justify-content-between text-center gap-2 mt-2'>
                                                <div className="w-100 add-button-meals user-select-none p-2"
                                                    onClick={() => this.props.history.push('/any-store')} >
                                                    Re-Order Now
                                                </div>

                                            </div>
                                        </>
                                    }
                                    {/* {(order?.any_store_status === 4 && order.order_items_total_price > 0) &&
                                        <div className='d-flex flex-row justify-content-between text-center gap-2 mt-2'>
                                            <div className="w-100 add-button-meals user-select-none p-2"
                                                onClick={() => this.openSheet(order.unique_order_id, 'accept')} >
                                                Accept Order
                                            </div>

                                        </div>
                                    } */}



                                    {order?.any_store_status === 5 || order?.any_store_status === 2 && order?.order_status_id == 1 && this.checkTimeOut(order.id) ? (
                                        <React.Fragment>
                                            {this.getOrderStatus(order.created_at) === "Cancel" ? (
                                                <div className="d-grid mt-3">
                                                    <button className="btn btn-danger border-radius-10 user-select-none p-2"
                                                        onClick={() => this.cancelCourier(order.unique_order_id)} style={{ borderRadius: '12px' }} >
                                                        Cancel Order({" "}
                                                        <Countdown date={Date.parse(order.created_at) + 60000}
                                                            id={order.id} renderer={renderer}
                                                            onComplete={() => this.hideCancel(order.id)}
                                                        />
                                                        )
                                                    </button>
                                                </div>
                                            ) : ("")}
                                        </React.Fragment>
                                    ) : order?.any_store_status == 5 ?
                                        <div className='text-end gap-2 mt-2'>
                                            <button className="add-button-meals user-select-none p-2"
                                                onClick={() => this.openSheet(order.unique_order_id,)} >
                                                Mark As Paid
                                            </button>
                                        </div>
                                        : order?.any_store_status == 7 && moment(order?.created_at).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? (
                                            <React.Fragment>
                                                <div className="d-grid mt-3">
                                                    <button className="btn border-radius-15 user-select-none p-2 text-white" style={{
                                                        background: '#FD8814', borderRadius: '12px'
                                                    }}
                                                        onClick={() => {
                                                            this.recheckPayment(order.id)
                                                        }} >
                                                        Re-check Payment

                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        ) : null}
                                </div>
                            </div>
                        )) : (
                            <div className='d-flex flex-column text-center pt-4'>
                                <img
                                    src={noOrder}
                                    className="" style={{ width: '100%', borderRadius: '12px' }}
                                    alt='main-item'
                                />
                                <div style={{ fontSize: '1.2em', fontWeight: '700' }}>No Order Found</div>
                            </div>
                        )
                    }
                </div>
                <Modal
                    isOpen={this.state.open}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={this.onSheetClose}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{ borderRadius: "5px", height: "160px", zIndex: '999999999999999999999' }}
                    >

                        <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center text-center">
                            <span className="mt-2 mb-0" style={{ fontSize: '1.3em', fontWeight: '700', padding: '0px' }}>
                                Confirm if all items added by the delivery boy are correct before updating your order.
                            </span>
                        </div>

                        <div className="d-flex justify-content-around w-100 gap-4  mt-3">
                            <div className="text-center mr-4 w-100">
                                <button
                                    className="delete-button-meals p-2 px-2 w-100 position-relative"
                                    style={{
                                        borderColor: "#fd8814",
                                        color: "#fd8814",
                                        // border: '1px solid #fd8814',
                                        width: "100%",
                                    }} onClick={this.onSheetClose} >
                                    Close
                                    <Ink duration={500} style={{ color: "#000" }} />
                                </button>
                            </div>

                            <div className="text-center w-100">
                                {/* <Link to={"/store-order-payment/" + this.state.unique_order_id}> */}
                                <button
                                    className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                    style={{
                                        width: "100%",
                                        backgroundColor: "#ce0060",
                                        borderColor: "#ce0060",
                                    }}
                                    onClick={() => acceptOrder(this.state.unique_order_id)}
                                >
                                    Confirm
                                    <Ink duration={500} style={{ color: "#000" }} />
                                </button>
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal isOpen={this.state.cancelCourier}
                    // onAfterOpen={afterOpenModal}
                    ariaHideApp={false}
                    onRequestClose={this.onSheetClose}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div
                        className="container"
                        style={{ borderRadius: "5px", height: "145px", zIndex: '999999999999999999999' }}
                    >
                        <React.Fragment>
                            <div className="px-10 col-12 pb-3 d-flex justify-content-between align-items-center text-center">
                                <span className="mt-2 mb-0" style={{ fontSize: '1.3em', fontWeight: '700' }}>
                                    Would you like to cancel this order ?
                                </span>
                            </div>

                            <div className="d-flex justify-content-around w-100 gap-4  mt-3">
                                <div className="text-center mr-4 w-100">
                                    <button className="delete-button-meals p-2 px-2 w-100 position-relative"
                                        style={{
                                            borderColor: "#fd8814",
                                            color: "#fd8814",
                                            // border: '1px solid #fd8814',
                                            width: "100%",
                                        }} onClick={this.onSheetClose} >
                                        Close
                                        <Ink duration={500} style={{ color: "#000" }} />
                                    </button>
                                </div>

                                <div className="text-center w-100">
                                    <button
                                        className="add-button-meals p-2 px-2 w-100 text-center text-white position-relative"
                                        style={{
                                            width: "100%",
                                            backgroundColor: "#ce0060",
                                            borderColor: "#ce0060",
                                        }} onClick={() =>
                                            cancelOrder(this.state.unique_order_id)} >
                                        Confirm
                                        <Ink duration={500} style={{ color: "#000" }} />
                                    </button>
                                </div>
                            </div>
                        </React.Fragment>
                    </div>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    user: state.user.user,
})
export default connect(mapStateToProps, {
    anyStorePaymentStatusCheck
})(AnyStoreOrders)

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-38%',
        border: '2px solid #fead1d',
        transform: 'translate(-50%, -50%)',
        borderRadius: '15px'
    },
};

